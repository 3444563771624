import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

const STATUS = {
  "purchased":"Purchased", 
  "failed":"Failed"
}

const TYPE = {
  "web":"Web", 
  "ios":"IOS"
}
export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (t) => [
  {
    dataField: "user_id.username",
    text: t("Username"),
    formatter: (_, row) => (row.user?.username ? row.user?.username : "-"),
  },
  {
    dataField: "user_id.email",
    text: t("Email"),
    formatter: (_, row) => (row.user?.email ? row.user?.email : "-"),
  },
  {
    dataField: "totalPaid",
    text: t("Amount"),
    formatter: (_, row) => (row.totalPaid ? row.totalPaid : 0),
  },
  {
    text: t("Currency"),
    dataField: "currency",
    formatter: (_, row) => (row?.currency ? row?.currency : "-"),
  },
  {
    dataField: "crystalAmount",
    text: t("Crystals Awarded"),
    formatter: (_, row) => (row.crystalAmount ? row?.crystalAmount : 0),
  },
  {
    text: t("Transaction Id"),
    dataField: "transactionId",
    formatter: (_, row) => (row.transactionId ? row?.transactionId : "-"),
  },
  {
    dataField: "productId",
    text: t("Product Id"),
    formatter: (_, row) => (row?.productId ? row?.productId : "-"),
  },
  {
    dataField: "type",
    text: t("Type"),
    formatter: (cellContent, row) => (
      <Badge
        className={
          `text-capitalize font-size-13 badge-soft-${row.type}`
        }
        color={row.badgeClass}
      >
        {t(TYPE[row?.type])}
      </Badge>
    ),
  },
  {
    dataField: "status",
    text: t("Status"),
    formatter: (cellContent, row) => (
      <Badge
        className={
          `text-capitalize font-size-13 badge-soft-${row.status}`
        }
        color={row.badgeClass}
      >
        {t(STATUS[row.status])}
      </Badge>
    ),
  },
  {
    dataField: "date_created_utc",
    text: t("Date"),
    sort: true,
    sortValue: (_, row) =>row?.date_created_utc,
    formatter: (_, row) => (row?.date_created_utc ? moment(row?.date_created_utc).format("YYYY-MM-DD HH:mm:ss") : "--"),
  },
 

  // {
  //   isDummyField: true,
  //   text: t("Action"),
  //   dataField: "action",
  //   formatter: (_, row) => (
  //     <>
  //       <Link
  //         to={`/match-history/view/${row._id}`}
  //         className="mr-3 text-secondary"
  //       >
  //         <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />

  //         <UncontrolledTooltip
  //           placement="top"
  //           target={`view-${row._id}-tooltip`}
  //         >
  //           View
  //         </UncontrolledTooltip>
  //       </Link>
  //     </>
  //   ),
  // },
];

export default ListColumns;
