import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (toggleDetailsModal, history, t) => [
  {
    text: t("Time"),
    dataField: "created_date",
    sort: true,
    formatter: (_, row) => moment(row.created_date).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    text: t("Type"),
    dataField: "type",
    formatter: (_, row) => <span className="text-capitalize">{row.type}</span>,
  },
  {
    dataField: "amount",
    text: t("Amount"),
  },
  {
    dataField: "author",
    text: t("From"),
    formatter: (_, row) => (
      <Link to={`/users/profile/${row.author?._id}`}>{row.author?.name}</Link>
    ),
  },
  {
    dataField: "walletAddress",
    text: t("Wallet Address"),
    formatter: (_, row) => (
      <>
        <div id={`wallet-address-${row._id}`}>
          {row.walletAddress?.toString()?.slice(0, 8)}
          ...
          {row.walletAddress?.toString()?.slice(-8)}
        </div>

        <UncontrolledTooltip
          placement="top"
          target={`wallet-address-${row._id}`}
        >
          {row.walletAddress}
        </UncontrolledTooltip>
      </>
    ),
  },
  {
    isDummyField: true,
    text: t("action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <Link
          to={`#`}
          className="mr-3 text-secondary"
          onClick={(e) => {
            e.preventDefault();
            toggleDetailsModal(row);
          }}
        >
          <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />
          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            {t("view_details")}
          </UncontrolledTooltip>
        </Link>
      </>
    ),
  },
];

export default ListColumns;
