import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useHistory, useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import toastr from "toastr";
import Select from 'react-select';
import { sideMenus } from "components/VerticalLayout/SidebarContent/ADMIN/sideMenus";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import {
  addOperator,
  updateOperator

} from "store/actions";

//i18n
import { withTranslation } from "react-i18next";

const Operators = ({
  onAddOperator,
  onUpdateOperator,
  error,
  loading,
  ...props
}) => {
  const history = useHistory();
  const [options, setoptions] = useState([]);

  const { id } = useParams();

  const operatorsData = useSelector((store) => store?.Operators);

  const item = operatorsData?.items?.find(it => it._id === id);

  const [fields, setFields] = useState({
    name: "",
    email: "",
    password: "",
    permissions: [],
    country: ""
  });

  const [selectedOption, setSelectedOption] = useState(null);

  if (id && !item) {
    history.push('/operator/list');
  }

  useEffect(() => {
    console.log("sideMenus------>", sideMenus);
    setoptions(sideMenus)
  }, [sideMenus])


  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  
  useEffect(() => {
    if (item) {
      setFields((prev) => ({
        ...prev,
        email: item?.email,
        name: item?.name,
        country: item?.country,
      }));
      let permissions = [];
      item?.permissions.forEach((option) => {
        if(option.route !== "/#" && option.isSelected === true){
          permissions.push(option)
        }
        else if(option.options && option.options.length > 0){
          option.options.forEach((option) => {
            if(option.route !== "/#" && option.isSelected === true){
              permissions.push(option)
            }
            else if(option.options && option.options.length > 0){
              if(option.isSelected === true){
                permissions.push(option)
              }
            }
          });
        }
      });
      setSelectedOption(permissions)
    }
  }, [item]);

  const handleChange = (name, index, field) => (event) => {
    const newValue = event.target.value;

    setFields((prevState) => {
      const updatedFields = { ...prevState };

      if (field) {
        updatedFields[name][index][field] = newValue;
      } else {
        updatedFields[name] = newValue;
      }

      return updatedFields;
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const permissions = [];
    
    options.forEach((option) => {
      if(option.route == "/#" && option.options && option.options.length > 0){
        option.options.forEach((option2) => {
          if(option2.route == "/#" && option2.options && option2.options.length > 0){
            option2.options.forEach((option3) => {
              let match = selectedOption.find((selected) => selected.route == option3.route);
              if (match) {
                option3.isSelected = true;
                option2.isSelected = true;
                option.isSelected = true;
              }
            });
          }
          else{
            let match = selectedOption.find((selected) => selected.route == option2.route);
            if (match) {
              option2.isSelected = true;
              option.isSelected = true;
            }
          }
        });
      }
      else{
        let match = selectedOption.find((selected) => selected.route == option.route);
        if (match) {
          option.isSelected = true;
        }
      }
      permissions.push(option);
    });

    const callback = (response) => {
      if (response.status === "failure") {
        toastr.error(response.message);
      } else {
        history.push('/operator/list')
      }
    }

    let newObj = {
      name: fields?.name,
      email: fields?.email,
      permissions: permissions,
      country: fields?.country,
      _id: id
    }

    if (fields.password) {
      newObj.password = fields.password
    }
    if (id) {
      onUpdateOperator(newObj, callback);
    } 
    else {
      onAddOperator(
        {
          ...fields,
          permissions: permissions
        },
        callback
      );
    }
  };

  console.log("permissions--------->", selectedOption);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm={1} lg={2}></Col>
            <Col sm={10} lg={8}>
              <Breadcrumbs
                title="Operator"
                breadcrumbItem={props.t("Add") + " " + props.t("Operator")}
                breadcrumbItems={[
                  // { title: props.t("Balance"), link: "/add-balance" },
                  { title: id ? "Edit Operator" : "Add Operator" },
                ]}
              />
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Form onSubmit={onSubmit} className="spinner-content">
                        {error && typeof error === "string" ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}

                        <Row>

                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Name")}
                              </Label>

                              <Input
                                id="formrow-firstname-Input"
                                value={fields.name}
                                onChange={handleChange("name")}
                                required
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Email")}
                              </Label>

                              <Input
                                id="formrow-firstname-Input"
                                value={fields.email}
                                onChange={handleChange("email")}
                                required
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("password")}
                              </Label>

                              <Input
                                id="formrow-firstname-Input"
                                value={fields.password}
                                onChange={handleChange("password")}
                                required={id ? false : true}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label>Tabs</Label>

                              <Select
                                value={selectedOption}
                                onChange={handleSelectChange}
                                options={options}
                                isSearchable={true} // Enable search/filter functionality
                                placeholder="Select an option..."
                                isMulti
                                name="options"

                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Country")}
                              </Label>

                              <Input
                                id="formrow-firstname-Input"
                                value={fields.country}
                                onChange={handleChange("country")}
                                required
                              />
                            </FormGroup>
                          </Col>


                        </Row>
                        <div className="mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            {id ? props.t("Update") : props.t("Add")}
                          </button>

                          <button
                            type="button"
                            className="btn outline btn-outline-secondary w-md ml-3"
                            onClick={() => history.goBack()}
                          >
                            <i className="mdi mdi-arrow-left mr-1"></i>
                            {props.t("back")}
                          </button>
                        </div>

                        {loading && (
                          <div className="spinner">
                            <Spinner color="primary" />
                          </div>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm={1} lg={2}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Operators.propTypes = {
  t: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onAddOperator: PropTypes.func,

};

const mapStateToProps = ({ Operators }) => ({
  error: Operators.error,
  loading: Operators.loading,

});

const mapDispatchToProps = (dispatch) => ({

  onAddOperator: (data, callback) => dispatch(addOperator(data, callback)),
  onUpdateOperator: (data, callback) => dispatch(updateOperator(data, callback)),

});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Operators))
);