import { ADD_TOURNAMENT } from "helpers/url_helper";
import {
  TOURNAMENTS_HISTORY_API_FAIL,
  GET_TOURNAMENTS_HISTORY,
  GET_TOURNAMENTS_HISTORY_FAIL,
  GET_TOURNAMENTS_HISTORY_SUCCESS,
  VIEW_TOURNAMENTS_HISTORY,
  VIEW_TOURNAMENTS_HISTORY_SUCCESS,
  VIEW_TOURNAMENTS_HISTORY_FAIL,
} from "./actionTypes";

export const apiFail = (error) => ({
  type: TOURNAMENTS_HISTORY_API_FAIL,
  payload: error,
});

/* Get collections */
export const getTournamentsHistory = (data) => ({
  type: GET_TOURNAMENTS_HISTORY,
  payload: data,
});

export const getTournamentsHistorySuccess = (Tournaments) => ({
  type: GET_TOURNAMENTS_HISTORY_SUCCESS,
  payload: Tournaments,
});

export const getTournamentsHistoryFail = (error) => ({
  type: GET_TOURNAMENTS_HISTORY_FAIL,
  payload: error,
});

export const viewTournamentsHistory = (data) => ({
  console: console.log(data, "actionhistoryDetail"),
  type: VIEW_TOURNAMENTS_HISTORY,
  payload: data,
});

export const viewTournamentsHistorySuccess = (Tournaments) => ({
  type: VIEW_TOURNAMENTS_HISTORY_SUCCESS,
  payload: Tournaments,
});

export const viewTournamentsHistoryFail = (error) => ({
  type: VIEW_TOURNAMENTS_HISTORY_FAIL,
  payload: error,
});
