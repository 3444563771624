import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Spinner,
  Badge,
  Label,
} from "reactstrap";
import moment from "moment";

//Import Images
import profile1 from "assets/images/profile-img.png";

// Breadcrumbs
import Breadcrumbs from "components/Common/Breadcrumb2";

// Actions
import { viewTournament } from "store/actions";
import axios from "axios";

const ViewTournament = (props) => {
  const {
    match: { params },
    onGetTournaments,
    tournamentDetail,
    loading,
  } = props;
  useEffect(() => {
    if (params && params.id) {
      onGetTournaments(params.id);
    }
  }, [params.id]);

  function downloadObjectAsJson(verifyLink) {
    const exportName = "abi.json";
    fetch(verifyLink)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        var dataStr =
          "data:text/json;charset=utf-8," +
          encodeURIComponent(JSON.stringify(data));
        var downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      })
      .catch(function (err) {
        console.warn("Something went wrong.", err);
      });
  }

  console.log(tournamentDetail, "tournamentDetailtournamentDetail view");

  var stillUtc = moment.utc(tournamentDetail?.registration_date).toDate();
  var localRegisterationDate = moment(stillUtc)
    .local()
    .format("YYYY-MM-DD HH:mm:ss");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="spinner-content">
          {loading && (
            <div className="spinner">
              <Spinner color="primary" />
            </div>
          )}

          <Breadcrumbs title="Tournaments" breadcrumbItem="detail" />

          <Row className="justify-content-center">
            <Col sm="10" lg="8">
              <Row>
                <Col>
                  <Card className="overflow-hidden">
                    <CardBody className="pt-0">
                      <Row className="py-3">
                        <Col lg="6" className="my-2">
                          <label
                            htmlFor=""
                            className="form-label d-block fw-bold "
                          >
                            Name
                          </label>
                          <p className="m-0 text-muted py-2 border px-3 rounded">
                            {tournamentDetail?.name}
                          </p>
                        </Col>
                        <Col lg="6" className="my-2">
                          <label
                            htmlFor=""
                            className="form-label d-block fw-bold "
                          >
                            Tournament Type
                          </label>
                          <p className="m-0 text-muted py-2 border px-3 rounded">
                            {tournamentDetail?.tournamentType}
                          </p>
                        </Col>
                        <Col lg="6" className="my-2">
                          <label
                            htmlFor=""
                            className="form-label d-block fw-bold "
                          >
                            Fee Type
                          </label>
                          <span className="text-capitalize font-size-13 badge-soft-success badge badge-secondary px-3 py-1">
                            {tournamentDetail?.fee_type}
                          </span>
                        </Col>
                        {tournamentDetail?.fee_type == "paid" ? (
                          <Col lg="6" className="my-2">
                            <label
                              htmlFor=""
                              className="form-label d-block fw-bold "
                            >
                              Entry Fee
                            </label>
                            <p className="m-0 text-muted py-2 border px-3 rounded">
                              {tournamentDetail?.entry_fee}
                            </p>
                          </Col>
                        ) : (
                          ""
                        )}

                        <Col lg="6" className="my-2">
                          <label
                            htmlFor=""
                            className="form-label d-block fw-bold "
                          >
                            Minimum Player
                          </label>
                          <p className="m-0 text-muted py-2 border px-3 rounded">
                            {tournamentDetail?.minimum_players}
                          </p>
                        </Col>
                        <Col lg="6" className="my-2">
                          <label
                            htmlFor=""
                            className="form-label d-block fw-bold "
                          >
                            Maximum Player
                          </label>
                          <p className="m-0 text-muted py-2 border px-3 rounded">
                            {tournamentDetail?.maximum_players}
                          </p>
                        </Col>
                        <Col lg="6" className="my-2">
                          <label
                            htmlFor=""
                            className="form-label d-block fw-bold "
                          >
                            Start Date
                          </label>
                          <p className="m-0 text-muted py-2 border px-3 rounded">
                            {tournamentDetail?.start_date}
                          </p>
                        </Col>
                        <Col lg="6" className="my-2">
                          <label
                            htmlFor=""
                            className="form-label d-block fw-bold "
                          >
                            Registeration Date
                          </label>
                          <p className="m-0 text-muted py-2 border px-3 rounded">
                            {/* {moment(tournamentDetail?.registration_date).format(
                              "YYYY-MM-DD HH:MM"
                            )} */}
                            {localRegisterationDate}
                          </p>
                        </Col>
                        <Col lg="6" className="my-2">
                          <label
                            htmlFor=""
                            className="form-label d-block fw-bold "
                          >
                            Join Type
                          </label>
                          <p className="m-0 text-muted py-2 border px-3 rounded">
                            {tournamentDetail?.join_type}
                          </p>
                        </Col>
                        <Col md={12} className="my-2">
                          <h4 className="mt-3 font-weight-bold">Price Pool</h4>

                          <Row>
                            {tournamentDetail?.price_pool.map((data, index) => (
                              <Col lg="12" className="" key={index}>
                                <Row className="align-items-end">
                                  <Col lg="6" className="my-1">
                                    <Label> Place</Label>

                                    <p className="m-0 text-muted py-2 border px-3 rounded">
                                      {data?.place}
                                    </p>
                                  </Col>
                                  <Col lg="6" className="my-1">
                                    <Label> Price</Label>
                                    <p className="m-0 text-muted py-2 border px-3 rounded">
                                      {data?.price}
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                            ))}
                          </Row>
                          {/* <Col md="3" className="mt-3">
                                <button
                                  onClick={handleAddPrice}
                                  className="btn btn-primary w-md"
                                >
                                  Add
                                </button>
                              </Col> */}
                        </Col>
                        <Col md={12} className="my-2">
                          <h4 className="mt-3 font-weight-bold">Level Pool</h4>

                          <Row>
                            {tournamentDetail?.level_pool &&
                              tournamentDetail?.level_pool.length > 0 &&
                              tournamentDetail?.level_pool.map(
                                (data, index) => (
                                  <Col lg="12" className="" key={index}>
                                    <Row className="align-items-end">
                                      <Col lg="6" className="my-1">
                                        <Label> Levels</Label>

                                        <p className="m-0 text-muted py-2 border px-3 rounded">
                                          {data?.level}
                                        </p>
                                      </Col>
                                      <Col lg="6" className="my-1">
                                        <Label>Score</Label>
                                        <p className="m-0 text-muted py-2 border px-3 rounded">
                                          {data?.score}
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                )
                              )}
                          </Row>
                          {/* <Col md="3" className="mt-3">
                                <button
                                  onClick={handleAddPrice}
                                  className="btn btn-primary w-md"
                                >
                                  Add
                                </button>
                              </Col> */}
                        </Col>
                        {/* <Col lg="6" className="my-2">
                          <label
                            htmlFor=""
                            className="form-label d-block fw-bold "
                          >
                            End Date
                          </label>
                          <p className="m-0 text-muted py-2 border px-3 rounded">
                            {tournamentDetail?.end_date}
                          </p>
                        </Col> */}
                        {/* <Col lg="6" className="my-2">
                          <label
                            htmlFor=""
                            className="form-label d-block fw-bold "
                          >
                            Start Time
                          </label>
                          <p className="m-0 text-muted py-2 border px-3 rounded">
                            {tournamentDetail?.start_time}
                          </p>
                        </Col> */}
                        {/* <Col lg="6" className="my-2">
                          <label
                            htmlFor=""
                            className="form-label d-block fw-bold "
                          >
                            End Time
                          </label>
                          <p className="m-0 text-muted py-2 border px-3 rounded">
                            {tournamentDetail?.end_time}
                          </p>
                        </Col> */}
                        {/* {tournamentDetail?.price_pool.map((p, index) => (
                          <Col lg="6" className="my-2">
                            <label
                              htmlFor=""
                              className="form-label d-block fw-bold "
                            >
                              Place {p?.place}
                            </label>
                            <p className="m-0 text-muted py-2 border px-3 rounded">
                              {p?.price}
                            </p>
                          </Col>
                        ))} */}
                      </Row>
                      {/* <div className="py-2 mt-4">
                        <h4 className="m-0 fw-bold font-weight-bold">
                          Registration
                        </h4>
                        <Row className="mt-3">
                          <Col lg="6" className="my-2">
                            <label
                              htmlFor=""
                              className="form-label d-block fw-bold "
                            >
                              Start Time
                            </label>
                            <p className="m-0 text-muted py-2 border px-3 rounded">
                              {tournamentDetail?.registration_time}
                            </p>
                          </Col>
                        </Row>
                      </div> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col xl="8"></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ViewTournament.propTypes = {
  tournaments: PropTypes.any,
  date: PropTypes.string,
  onGetTournaments: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = ({ Game }) => ({
  console: console.log(Game, "gameeeeeeeeeeeee"),
  loading: Game.loading,
  // tournaments: Game.tournament,
  tournamentDetail: Game.tournamentDetail?.data,
  date: Game.date,
  error: Game.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetTournaments: (data) => dispatch(viewTournament(data)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ViewTournament))
);
