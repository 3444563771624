import { call, put, takeLatest } from "redux-saga/effects";

// Collection Redux States
import {
  GET_CRYSTAL_PURCHASE_HISTORY,
} from "./actionTypes";

import {
  getCrystalPurchaseHistoryFail,
  getCrystalPurchaseHistorySuccess,
} from "./actions";

import {
  getCrystalPurchaseHistory,
} from "helpers/backend_helper";

function* getCrystalHistorySaga({ payload, callback }) {
  try {
    const response = yield call(getCrystalPurchaseHistory, payload);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(getCrystalPurchaseHistoryFail(response.message));
    }
    yield put(getCrystalPurchaseHistorySuccess(response));
  } catch (error) {
    yield put(getCrystalPurchaseHistoryFail(error));
  }
}

function* CrystalPurchaseHistorySaga() {
  yield takeLatest(GET_CRYSTAL_PURCHASE_HISTORY, getCrystalHistorySaga);
}

export default CrystalPurchaseHistorySaga;