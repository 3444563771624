export const CONTENT_PAGE_API_FAIL = "CONTENT_PAGE_API_FAIL"

// Content Pages
export const GET_CONTENT_PAGES = "GET_CONTENT_PAGES"
export const GET_CONTENT_PAGES_FAIL = "GET_CONTENT_PAGES_FAIL"
export const GET_CONTENT_PAGES_SUCCESS = "GET_CONTENT_PAGES_SUCCESS"

// Add Content Page
export const ADD_CONTENT_PAGE = "ADD_CONTENT_PAGE"
export const ADD_CONTENT_PAGE_FAIL = "ADD_CONTENT_PAGE_FAIL"
export const ADD_CONTENT_PAGE_SUCCESS = "ADD_CONTENT_PAGE_SUCCESS"

// Get Content Page
export const GET_CONTENT_PAGE = "GET_CONTENT_PAGE"
export const GET_CONTENT_PAGE_FAIL = "GET_CONTENT_PAGE_FAIL"
export const GET_CONTENT_PAGE_SUCCESS = "GET_CONTENT_PAGE_SUCCESS"

// update Content Page
export const PUT_CONTENT_PAGE = "PUT_CONTENT_PAGE"
export const PUT_CONTENT_PAGE_FAIL = "PUT_CONTENT_PAGE_FAIL"
export const PUT_CONTENT_PAGE_SUCCESS = "PUT_CONTENT_PAGE_SUCCESS"

// Delete Content Page
export const DELETE_CONTENT_PAGE = "DELETE_CONTENT_PAGE"
export const DELETE_CONTENT_PAGE_FAIL = "DELETE_CONTENT_PAGE_FAIL"
export const DELETE_CONTENT_PAGE_SUCCESS = "DELETE_CONTENT_PAGE_SUCCESS"

// Update Multi Content Page Status
export const PUT_CONTENT_PAGES_STATUS = "PUT_CONTENT_PAGES_STATUS"
export const PUT_CONTENT_PAGES_STATUS_FAIL = "PUT_CONTENT_PAGES_STATUS_FAIL"
export const PUT_CONTENT_PAGES_STATUS_SUCCESS =
  "PUT_CONTENT_PAGES_STATUS_SUCCESS"
