import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";
/* import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import EditorUploadAdapterPlugin from "../../../plugins/ckeditor/UploadAdapterPlugin" */
import CKEditor from "react-ckeditor-component";
import FormButton from "components/Common/FormButtons";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

import { putDailyLimit} from "store/actions";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";
import toastr from "toastr";

const EditLimit = ({
  history,
  error,
  onPutDailyLimit,
  loading,
  ...props
}) => {
  const { id } = useParams();
  const didMountRef = useRef(null);

  const limitItems = useSelector((store) => store?.Settings);

  const item = limitItems?.items?.find(it => it._id === id);

  if(!item){
    history.push('/daily-limit');
  }


  const [fields, setFields] = useState({
    minShipCount: item?.minShipCount,
    maxShipCount: item?.maxShipCount,
    woodenBoxLimit: item?.woodenBoxLimit,
    steelBoxLimit: item?.steelBoxLimit,
    bronzeBoxLimit: item?.bronzeBoxLimit,
    silverBoxLimit: item?.silverBoxLimit,
    goldenBoxLimit: item?.goldenBoxLimit
  });



  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const callback = (res) => {
      if (res.status == "success") {
        toastr.success("Daily limit updated successfully");
        history.goBack();

      } else {
        toastr.error(res.message);
      }
    };
    onPutDailyLimit({ id: item?._id, ...fields }, callback);


  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Edit Daily Limit"
            breadcrumbItem={
              props.t("edit")
            }
            breadcrumbItems={[
              { title: props.t("Edit Daily Limit"), link: "/content-pages" },
            ]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form onSubmit={onSubmit} className="spinner-content">
                    {error && typeof error === "string" ? (
                      <Alert color="danger">{error}</Alert>
                    ) : null}

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Min Ship Count")}
                          </Label>
                          <Input
                            type="number"
                            value={fields?.minShipCount}
                            onChange={(e) => {
                              handleChange("minShipCount")(e);
                            }}
                        
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Max Ship Count")}
                          </Label>
                          <Input
                            type="number"
                            value={fields?.maxShipCount}
                            onChange={(e) => {
                              handleChange("maxShipCount")(e);
                            }}
                          
                          />
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Wooden Box Limit")}
                          </Label>
                          <Input
                            type="number"
                            value={fields?.woodenBoxLimit}
                            onChange={(e) => {
                              handleChange("woodenBoxLimit")(e);
                            }}
                          
                          />
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Steel Box Limit")}
                          </Label>
                          <Input
                            type="number"
                            value={fields?.steelBoxLimit}
                            onChange={(e) => {
                              handleChange("steelBoxLimit")(e);
                            }}
                        
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Bronze Box Limit")}
                          </Label>
                          <Input
                            type="number"
                            value={fields?.bronzeBoxLimit}
                            onChange={(e) => {
                              handleChange("bronzeBoxLimit")(e);
                            }}
                           
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Silver Box Limit")}
                          </Label>
                          <Input
                            type="number"
                            value={fields?.silverBoxLimit}
                            onChange={(e) => {
                              handleChange("silverBoxLimit")(e);
                            }}
                         
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Golden Box Limit")}
                          </Label>
                          <Input
                            type="number"
                            value={fields?.goldenBoxLimit}
                            onChange={(e) => {
                              handleChange("goldenBoxLimit")(e);
                            }}
                          
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormButton
                      goBack={() => history.goBack()}
                    />

                    {loading && (
                      <div className="spinner">
                        <Spinner color="primary" />
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

EditLimit.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
 
  onPutDailyLimit: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  error: Settings.error,
  loading: Settings.loading,
});

const mapDispatchToProps = (dispatch) => ({

  onPutDailyLimit: (data, callback) => dispatch(putDailyLimit(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditLimit))
);
