import React, { useEffect, useState, memo } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

// store
import { getCustomers } from "store/actions"

function SelectUsers(props) {
  const dispatch = useDispatch()
  const { customers } = useSelector(s => s?.customers)

  const [options, setoptions] = useState(customers)
  const [filter, setfilter] = useState({
    orderBy: "date_created_utc",
    order: -1,
    page: 0,
    limit: 20000,
    search: "",
    fields: []
  })

  useEffect(() => {
    let modifiedList = customers.map(dky => ({
      value: dky._id,
      label: `${dky.email} (ID: ${dky.userID})`,
      userId: dky.userID,

    }))
    setoptions(modifiedList)
  }, [customers])

  useEffect(() => {
    dispatch(getCustomers(filter))
  }, [filter])


  return (


    <>
      <Select
        placeholder={props.placeholder || "Select Users"}
        options={options}
        value={props?.value}
        name={props?.name}
        isMulti
        onChange={props.handleChange}
      />
    </>

  )
}

SelectUsers.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ]),
  onChange: PropTypes.func,
}

export default memo(SelectUsers)
