import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";
import toastr from "toastr";
//i18n
import { withTranslation } from "react-i18next";

import { Card, CardBody, Col, Container, Row, Input } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import SearchInput from "components/Common/SearchInput";

import {
  getCustomers,
  getCustomersSuccess,
  removeWalletAddress,
  updateUserStatus,
  updateUserProfile
} from "store/actions";
import UsersColumns from "./Columns";
import UsersConfirmModal from "./ConfirmModal";
import UserConfirmStatusModel from "./ConfirmStatusModal";

const Users = (props) => {
  const {
    history,
    users,
    totalUsers,
    getCustomersSuccess,
    onGetUsers,
    onRemoveWalletAddress,
    onChangeStatus,
    onEditProfile,
    loading,
  } = props;
  let auth = localStorage.getItem("authUser");
  let adminRole = JSON.parse(auth)?.data?.role;
  let country = JSON.parse(auth)?.data?.country;

  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    userId: null,
  });

  const [confirmStatusModal, setConfirmStatusModal] = useState({
    isOpen: false,
    userId: null,
    status: null
  });

  const [filter, setFilter] = useState({
    orderBy: "date_created_utc",
    order: -1,
    page: 0,
    limit: 20,
    search: "",
    fields: [],
    country: ""
  });
  const [searchText, setSearchText] = useState("");
  const [userList, setUsersList] = useState([]);
  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalUsers,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    getCustomersSuccess({ data: [], totalcount: 0 });
    if(adminRole == "SUBADMIN"){
      onGetUsers({ ...filter, country: country });
    }
    else{
      onGetUsers({ ...filter });
    }
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    setUsersList(users || []);
  }, [users]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText.trim());
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (userId) => {
    setConfirmModal((prevState) => ({ isOpen: !prevState.isOpen, userId }));
  };

  const toggleConfirmStatusModal = (userId, status) => {
    setConfirmStatusModal((prevState) => ({ isOpen: !prevState.isOpen, userId, status: status }));
  };

  const toggleShowCommissionReferral = (userId, value) => {
    onEditProfile({ id: userId, showCommissionReferral: value });
    toastr.success("User referral view changed successfully!");
  }

  const removeConfirm = () => {
    const { userId, isOpen } = confirmModal;

    const onComplete = (res) => {
      if (res.status == "success") {
        toastr.success("Wallet Address successfully remove!");
        window.location.reload();
      } else {
        toastr.error("Wallet address not remove!");
      }
    };

    setConfirmModal({ isOpen: !isOpen, userId: null });
    onRemoveWalletAddress({ _id: userId }, onComplete);
  };

  const changeStatus = () => {
    const { userId, isOpen } = confirmStatusModal;

    const onStatusChanged = (res) => {
      if (res.status == "success") {
        toastr.success("User status changed successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } 
      else {
        toastr.error("User status did not change!");
      }
    };

    setConfirmStatusModal({ isOpen: !isOpen, userId: null });
    onChangeStatus({ userId: userId, status: confirmStatusModal?.status === "ban"? "inactive": "active" }, onStatusChanged);
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText.trim() }));
  };

  console.log("value--->",filter?.fields?.[0]?.fieldValue);
  return (
    <React.Fragment>
      <UsersConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />

      <UserConfirmStatusModel
        isOpen={confirmStatusModal.isOpen}
        toggle={toggleConfirmStatusModal}
        onConfirm={changeStatus}
        status={confirmStatusModal.status}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Users"
            breadcrumbItem={props.t("Users")}
            breadcrumbItems={[{ title: props.t("Users") }]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={userList || []}
                        columns={UsersColumns(
                          toggleConfirmModal,
                          toggleConfirmStatusModal,
                          toggleShowCommissionReferral,
                          props.t,
                          adminRole
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4" lg="3">
                                <SearchInput
                                  {...toolkitProps.searchProps}
                                  triggerSearch={onSearch}
                                  placeholder={props.t("Search")}
                                  searchText={searchText}
                                />
                              </Col>

                              <Col sm="3">
                                  <Input
                                    type="select"
                                    value={filter?.fields?.[0]?.fieldValue }
                                    onChange={(e) =>
                                      setFilter((prev) => ({
                                        ...prev,
                                        fields: [{
                                          fieldName:"showCommissionReferral",
                                          fieldValue: e.target.value == "true"? true : e.target.value == "false"? false: ""
                                        }]
                                      }))
                                    }
                                  >
                                    <option value="">Select Commission Referral</option>
                                    <option value={true}>ON</option>
                                  </Input>
                                </Col>

                              <Col sm="2">
                                {!!filter.search || !!filter.fields?.length > 0 && (
                                  <div
                                    className="mr-4 mb-2"
                                    style={{
                                      height: "calc( 1.5em + 0.94rem + 2px)",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFilter((prevState) => ({
                                          ...prevState,
                                          search: "",
                                          fields: [{
                                            fieldName:"showCommissionReferral",
                                            fieldValue: ""
                                          }]
                                        }));
                                        setSearchText("");
                                      }}
                                      className=""
                                    >
                                      {props.t("Clear Filters")}
                                    </Link>
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {/* {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )} */}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Users.propTypes = {
  t: PropTypes.any,
  users: PropTypes.array,
  totalUsers: PropTypes.number,
  getCustomersSuccess: PropTypes.func,
  onGetUsers: PropTypes.func,
  onRemoveWalletAddress: PropTypes.func,
  onChangeStatus: PropTypes.func
};

const mapStateToProps = ({ customers }) => ({
  loading: customers.loading,
  users: customers.customers,
  totalUsers: customers.totalCustomers,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomersSuccess: (data) => dispatch(getCustomersSuccess(data)),
    onChangeStatus: (data, callback) =>
      dispatch(updateUserStatus(data, callback)),
    onRemoveWalletAddress: (data, callback) =>
      dispatch(removeWalletAddress(data, callback)),  
    onGetUsers: (data) => dispatch(getCustomers(data)),
    onEditProfile: (data, callback) => dispatch(updateUserProfile(data,callback))
    
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Users))
);
