import {
  GET_LEADERBOARD,
  GET_LEADERBOARD_SUCCESS,
  GET_LEADERBOARD_FAIL,

  REDUCE_LEADERBOARD_POINTS,
  REDUCE_LEADERBOARD_POINTS_SUCCESS,
  REDUCE_LEADERBOARD_POINTS_FAIL
} from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  leaderboard: [],
  totalCount: 0,
  reducePoint: {}
};


const Leaderboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LEADERBOARD:
      return {
        ...state,
        loading: true,
      };

    case GET_LEADERBOARD_SUCCESS:
      return {
        ...state,
        leaderboard: action.payload.data || [],
        totalCount: action.payload.total || 0,
        loading: false,
        error: "",
      };

    case GET_LEADERBOARD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case REDUCE_LEADERBOARD_POINTS:
      return {
        ...state,
        loading: true,
      };

    case REDUCE_LEADERBOARD_POINTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        reducePoint: action.payload
      };

    case REDUCE_LEADERBOARD_POINTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default Leaderboard;
