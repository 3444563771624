import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import Viewlistmod from "./Viewlistmod";
import ViewBonusListMod from "./viewBonusListMod";
import Confirmationmod from "./Confirmationmod";
import CrewMemberListMod from "./CrewMemberListMod";
import { updateUserProfile } from "store/actions";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";
import toastr from "toastr";
import moment from "moment";

const Profileinfo = (props) => {
  const [viewlist, setviewlist] = useState(false);
  const [viewBonuslist, setviewbonuslist] = useState(false);
  const [confirmmod, setconfirmmod] = useState(false);
  const [isEditUser, setEditUser] = useState(false);
  const [isResetCountUser, setIsResetCountUser] = useState(false);
  const [isResetCount, setIsResetCount] = useState(false);
  const [crewList, setCrewlist]= useState(false);

  const [fields, setFields] = useState({
    username: "",
    treasureBoxCount: 0,
    keyCount: 0,
    adminComment: ""
  });
  const [profileInfo, setProfileInfo] = useState({});

  const { customer, NftList, Settings, onUpdateProfile, bonusHistory, crewMembers } = props;
  console.log("crewMembers", crewMembers)

  useEffect(() => {
    setProfileInfo(customer);
    setFields((prevState) => ({ 
      ...prevState, 
      username: customer?.username, 
      treasureBoxCount: customer?.treasureBoxCount,
      keyCount: customer?.keyCount 
    }));
  }, [customer]);

  const handleEditUserName = (e) => {
    e.preventDefault();
    setEditUser(true);
  }
  
  const handleEditCount = (e) => {
    e.preventDefault();
    setIsResetCountUser(true);
  }

  const handleEditCount2 = (e) => {
    e.preventDefault();
    setIsResetCount(true);
  }
  
  const handleSaveUserName = (e) => {
    e.preventDefault();

    const callback = (res) => {
      if (res.status === "success") {
        toastr.success("Username updated successfully.");
        setEditUser(false)
        setProfileInfo(res?.data)
      } 
      else {
        toastr.error(res.message);
      }

    };
    onUpdateProfile({ username: fields?.username, id: profileInfo?._id }, callback)
  }

  const handleRemoveWallet = (e) => {
    e.preventDefault();

    const callback = (res) => {
      if (res.status === "success") {
        toastr.success("Wallet removed successfully.");
        setconfirmmod(false)
        setProfileInfo(res?.data)
      } 
      else {
        toastr.error(res.message);
      }

    };
    onUpdateProfile({ walletAddress: "", id: profileInfo?._id }, callback)
  }
  

  
  const handleResetCount = (e) => {
    e.preventDefault();

    const callback = (res) => {
      if (res.status === "success") {
        toastr.success("Count updated successfully.");
        setIsResetCountUser(false);
        setIsResetCount(false);
        setFields((prevState) => ({ ...prevState, 
          treasureBoxCount: res?.data?.treasureBoxCount, 
          keyCount: res?.data?.keyCount }));
      } 
      else {
        toastr.error(res.message);
      }

    };
    console.log("fields", fields);
    onUpdateProfile({ 
      treasureBoxCount: parseInt(fields?.treasureBoxCount), 
      keyCount: parseInt(fields?.keyCount), 
      id: profileInfo?._id 
    }, callback)
  }

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };
 
  return (
    <>
      <Viewlistmod NftList={NftList} Settings={Settings} show={viewlist} onhide={() => setviewlist(false)} />
      <ViewBonusListMod BonusList={bonusHistory} Settings={Settings} show={viewBonuslist} onhide={() => setviewbonuslist(false)} />
      <Confirmationmod show={confirmmod} onhide={() => setconfirmmod(false)} onConfirm= {handleRemoveWallet}/>
      <CrewMemberListMod crewMembers={crewMembers} show={crewList} onhide={() => setCrewlist(false)} />
      <section className="profile_info text-start px-5 py-4">
        <ul>
        <li>
            <strong>UserID - </strong>
            <p>
              <a href="javascript:void(0)">{profileInfo?.userID}</a>
            </p>
          </li>
          <li>
            <strong>Email - </strong>
            <p>
              <a href="javascript:void(0)">{profileInfo?.email}</a>
            </p>
          </li>

          <li>
            <strong>User Name - </strong>
            {
              props?.adminRole !== "SUBADMIN" && isEditUser ?
                <Col md={6}>
                  <FormGroup>
                    <Input
                      type="text"
                      id="formrow-firstname-Input"
                      value={fields?.username}
                      onChange={handleChange("username")}
                      required
                    />
                  </FormGroup>
                </Col> :
                <p>{fields?.username ? fields.username: ""}</p>

            }

            {
              props?.adminRole !== "SUBADMIN" && isEditUser  ?
                <Link
                  to="/"
                  className="view_btn"
                  onClick={handleSaveUserName}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z" /></svg>
                </Link> :
                props?.adminRole !== "SUBADMIN" && <Link
                  to="/"
                  className="view_btn"
                  onClick={handleEditUserName}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M5 19h1.4l8.625-8.625l-1.4-1.4L5 17.6V19ZM19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575t1.412.575l1.4 1.4q.575.575.6 1.388t-.55 1.387L19.3 8.925ZM17.85 10.4L7.25 21H3v-4.25l10.6-10.6l4.25 4.25Zm-3.525-.725l-.7-.7l1.4 1.4l-.7-.7Z" /></svg>
                </Link>


            }

          </li>

          <li>
            <strong>Wallet Address - </strong>
            <p>{profileInfo?.walletAddress}</p>
            {props?.adminRole !== "SUBADMIN" && profileInfo?.walletAddress && <Link
              to="/"
              className="view_btn"
              onClick={(e) => {
                e.preventDefault();
                setconfirmmod(true)
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 256 256"
              >
                <path
                  fill="#fff"
                  d="M216 50h-42V40a22 22 0 0 0-22-22h-48a22 22 0 0 0-22 22v10H40a6 6 0 0 0 0 12h10v146a14 14 0 0 0 14 14h128a14 14 0 0 0 14-14V62h10a6 6 0 0 0 0-12ZM94 40a10 10 0 0 1 10-10h48a10 10 0 0 1 10 10v10H94Zm100 168a2 2 0 0 1-2 2H64a2 2 0 0 1-2-2V62h132Zm-84-104v64a6 6 0 0 1-12 0v-64a6 6 0 0 1 12 0Zm48 0v64a6 6 0 0 1-12 0v-64a6 6 0 0 1 12 0Z"
                />
              </svg>
            </Link>}
          </li>

          <li>
            <strong>Total NFTs - </strong>
            <p> {NftList.length}</p>
            {
              NftList.length > 0 &&
              <Link to="/" className="view_btn" onClick={(e) => {
                e.preventDefault();
                setviewlist(true)
              }} >

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="#fff"
                    d="M243.66 126.38c-.34-.76-8.52-18.89-26.83-37.2C199.87 72.22 170.7 52 128 52S56.13 72.22 39.17 89.18c-18.31 18.31-26.49 36.44-26.83 37.2a4.08 4.08 0 0 0 0 3.25c.34.77 8.52 18.89 26.83 37.2c17 17 46.14 37.17 88.83 37.17s71.87-20.21 88.83-37.17c18.31-18.31 26.49-36.43 26.83-37.2a4.08 4.08 0 0 0 0-3.25Zm-32.7 35c-23.07 23-51 34.62-83 34.62s-59.89-11.65-83-34.62A135.71 135.71 0 0 1 20.44 128A135.69 135.69 0 0 1 45 94.62C68.11 71.65 96 60 128 60s59.89 11.65 83 34.62A135.79 135.79 0 0 1 235.56 128A135.71 135.71 0 0 1 211 161.38ZM128 84a44 44 0 1 0 44 44a44.05 44.05 0 0 0-44-44Zm0 80a36 36 0 1 1 36-36a36 36 0 0 1-36 36Z"
                  />
                </svg>
              </Link>
            }

          </li>

          <li>
            <strong>Crew Code ({profileInfo?.crewCode}) - </strong>
            <p>{crewMembers?.length}</p>
            {
              crewMembers?.length > 0 &&
              <Link to="/" className="view_btn" onClick={(e) => {
                e.preventDefault();
                setCrewlist(true)
              }} >

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="#fff"
                    d="M243.66 126.38c-.34-.76-8.52-18.89-26.83-37.2C199.87 72.22 170.7 52 128 52S56.13 72.22 39.17 89.18c-18.31 18.31-26.49 36.44-26.83 37.2a4.08 4.08 0 0 0 0 3.25c.34.77 8.52 18.89 26.83 37.2c17 17 46.14 37.17 88.83 37.17s71.87-20.21 88.83-37.17c18.31-18.31 26.49-36.43 26.83-37.2a4.08 4.08 0 0 0 0-3.25Zm-32.7 35c-23.07 23-51 34.62-83 34.62s-59.89-11.65-83-34.62A135.71 135.71 0 0 1 20.44 128A135.69 135.69 0 0 1 45 94.62C68.11 71.65 96 60 128 60s59.89 11.65 83 34.62A135.79 135.79 0 0 1 235.56 128A135.71 135.71 0 0 1 211 161.38ZM128 84a44 44 0 1 0 44 44a44.05 44.05 0 0 0-44-44Zm0 80a36 36 0 1 1 36-36a36 36 0 0 1-36 36Z"
                  />
                </svg>
              </Link>
            }

          </li>

          <li>
            <strong>Bonus History - </strong>
            <p> {bonusHistory?.length}</p>
            {
              bonusHistory?.length > 0 &&
              <Link to="/" className="view_btn" onClick={(e) => {
                e.preventDefault();
                setviewbonuslist(true)
              }} >

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="#fff"
                    d="M243.66 126.38c-.34-.76-8.52-18.89-26.83-37.2C199.87 72.22 170.7 52 128 52S56.13 72.22 39.17 89.18c-18.31 18.31-26.49 36.44-26.83 37.2a4.08 4.08 0 0 0 0 3.25c.34.77 8.52 18.89 26.83 37.2c17 17 46.14 37.17 88.83 37.17s71.87-20.21 88.83-37.17c18.31-18.31 26.49-36.43 26.83-37.2a4.08 4.08 0 0 0 0-3.25Zm-32.7 35c-23.07 23-51 34.62-83 34.62s-59.89-11.65-83-34.62A135.71 135.71 0 0 1 20.44 128A135.69 135.69 0 0 1 45 94.62C68.11 71.65 96 60 128 60s59.89 11.65 83 34.62A135.79 135.79 0 0 1 235.56 128A135.71 135.71 0 0 1 211 161.38ZM128 84a44 44 0 1 0 44 44a44.05 44.05 0 0 0-44-44Zm0 80a36 36 0 1 1 36-36a36 36 0 0 1-36 36Z"
                  />
                </svg>
              </Link>
            }

          </li>
        </ul>

        <ul className="mt-5">
          <li>
            <strong>Register date - </strong>
            <p>{profileInfo?.date_created_utc ? moment(profileInfo?.date_created_utc).format("DD.MM.YYYY - HH:mm:ss"): ""}</p>
          </li>
          <li>
            <strong>Last login time - </strong>
            <p>{profileInfo?.lastLogin ? moment(profileInfo?.lastLogin).format("DD.MM.YYYY - HH:mm:ss"): ""}</p>
          </li>
          <li>
            <strong>Total Sail Time (in mins)- </strong>
            <p>{profileInfo?.totalSailTime ? profileInfo.totalSailTime: 0}</p>
          </li>
          <li>
            <strong>Total Step Count - </strong>
            <p>{profileInfo?.totalStepCount? profileInfo.totalStepCount: 0}</p>
          </li>
          <li>
            <strong>Total Boxes Opened - </strong>
            <p>{profileInfo?.totalOpenedBoxes ? profileInfo.totalOpenedBoxes: 0}</p>
          </li>
          {/* <li>
            <strong>Total Bosses Defeated - </strong>
            <p>{profileInfo?.totalDefeatedBosses ? profileInfo.totalDefeatedBosses: 0}</p>
          </li> */}
          <li>
            <strong> Treasure box opened count (today) - </strong>
            {
              props?.adminRole !== "SUBADMIN" && isResetCountUser ?
                <Col md={6}>
                  <FormGroup>
                    <Input
                      type="text"
                      id="formrow-firstname-Input"
                      value={fields?.treasureBoxCount}
                      onChange={handleChange("treasureBoxCount")}
                      required
                    />
                  </FormGroup>
                </Col> :
                <p>{fields?.treasureBoxCount ? fields.treasureBoxCount: 0}</p>

            }

            {
              props?.adminRole !== "SUBADMIN" && isResetCountUser ?
                <Link
                  to="/"
                  className="view_btn"
                  onClick={handleResetCount}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z" /></svg>
                </Link> :
                props?.adminRole !== "SUBADMIN" && <Link
                  to="/"
                  className="view_btn"
                  onClick={handleEditCount}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M5 19h1.4l8.625-8.625l-1.4-1.4L5 17.6V19ZM19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575t1.412.575l1.4 1.4q.575.575.6 1.388t-.55 1.387L19.3 8.925ZM17.85 10.4L7.25 21H3v-4.25l10.6-10.6l4.25 4.25Zm-3.525-.725l-.7-.7l1.4 1.4l-.7-.7Z" /></svg>
                </Link>
            }
          </li>

          <li>
            <strong> Key count (today) - </strong>
            {
              props?.adminRole !== "SUBADMIN" && isResetCount ?
                <Col md={6}>
                  <FormGroup>
                    <Input
                      type="text"
                      id="formrow-firstname-Input"
                      value={fields?.keyCount}
                      onChange={handleChange("keyCount")}
                      required
                    />
                  </FormGroup>
                </Col> :
                <p>{fields?.keyCount ? fields.keyCount: 0}</p>

            }

            {
              props?.adminRole !== "SUBADMIN" && isResetCount ?
                <Link
                  to="/"
                  className="view_btn"
                  onClick={handleResetCount}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z" /></svg>
                </Link> :
                props?.adminRole !== "SUBADMIN" && <Link
                  to="/"
                  className="view_btn"
                  onClick={handleEditCount2}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M5 19h1.4l8.625-8.625l-1.4-1.4L5 17.6V19ZM19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575t1.412.575l1.4 1.4q.575.575.6 1.388t-.55 1.387L19.3 8.925ZM17.85 10.4L7.25 21H3v-4.25l10.6-10.6l4.25 4.25Zm-3.525-.725l-.7-.7l1.4 1.4l-.7-.7Z" /></svg>
                </Link>
            }
          </li>
        </ul>
      </section>
    </>
  );
};

export default Profileinfo;
