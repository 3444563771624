import React from "react";
import UserHeader from "components/UserHeader/header";

const HomeProfile = (props) => {
  console.log("props", props)
  return (
    <section className="Dashboardmain_main p-3">
      <UserHeader {...props} />
    </section>
  );
};

export default HomeProfile;
