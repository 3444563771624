import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { Card, CardBody, Col, Container, Row, Spinner, Input } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import { getMobileGameHistory, updateMobileGameHistory, getItems, endMobileGame } from "store/actions";
import ListColumns from "./ListColumns";
import toastr from "toastr";
import ConfirmModal from "./ConfirmModal";
import ConfirmModel2 from "./ConfirmModel2";
import EndModal from "./endModal";

const MobileGameHistory = ({
  GameHistory,
  totalGameHistory,
  onGetGameHistory,
  onUpdateAwardStatus,
  onGetItems,
  onEndGame,
  items,
  totalItems,
  loading,
  ...props
}) => {
  const [filter, setFilter] = useState({
    orderBy: "date_created_utc",
    order: -1,
    page: 1,
    limit: 20,
    search: "",
    status: "All",
    usdtAwardedStatus: "",
    nftAwardedStatus: "",
    weekNo: "",
  });
  const [searchText, setSearchText] = useState("");
  const [GameHistoryList, setGameHistory] = useState([]);
  const [completeMod, setCompleteMod] = useState({ show: false, gameHistory: "" });
  const [transferMod, setTransferMod] = useState({ show: false, gameHistory: "" });
  const [endMod, setEndMod] = useState({ show: false, gameHistory: "" });
  const [item, setItem] = useState({});
  const [itemsList, setItemsList] = useState([]);

  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalGameHistory,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  const itemFilter = {
    orderBy: "create_date",
    order: -1,
    page: 0,
    limit: 2000,
  };

  useEffect(() => {
    onGetGameHistory(filter);
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    setGameHistory(GameHistory || []);
  }, [GameHistory]);

  useEffect(() => {
    onGetItems(itemFilter);
  }, [])

  useEffect(() => {
    setItemsList(items || []);
  }, [items])

  const toggleConfirmModal = useCallback((gameHistory) => {
    setCompleteMod((prevState) => ({ show: !prevState.show, gameHistory: gameHistory }));
  }, []);

  const toggleTransferModal = useCallback((gameHistory) => {
    setTransferMod((prevState) => ({ show: !prevState.show, gameHistory: gameHistory }));
  }, []);

  const toggleEndModal = useCallback((gameHistory) => {
    setEndMod((prevState) => ({ show: !prevState.show, gameHistory: gameHistory }));
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText.trim());
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const onSearch = (event) => {
    event.preventDefault();
    setFilter((prevState) => ({ ...prevState, search: searchText.trim() }));
  };

  const handleChange = (e) => {
    console.log("target------>", e.target.value)
    setItem(e.target.value);
  }

  return (
    <>
      <ConfirmModal
        show={completeMod.show}
        toggle={toggleConfirmModal}
        handleChange={handleChange}
        itemsList={itemsList}
        onConfirm={() => {
          const callback = (res) => {
            setCompleteMod({ show: false, gameHistory: null });
            setItem("");
            if (res.status === "success") {
              toastr.success("History updated successfully.");
              onGetGameHistory(filter);
            }
            else {
              toastr.error(res.message);
            }
          };
          let updateMod = {
            ...completeMod.gameHistory,
            gameHistoryId: completeMod.gameHistory._id,
            nftAwardedStatus: "transferred",
            awardedNft: JSON.parse(item)?._id
          } 
          // console.log("updateMod ---->", updateMod)
          onUpdateAwardStatus(updateMod, callback)
        }}
      />

      <ConfirmModel2
        isOpen={transferMod.show}
        toggle={toggleTransferModal}
        onConfirm={() => {
          const callback = (res) => {
            setTransferMod({ show: false, gameHistory: null });
            if (res.status === "success") {
              toastr.success("History updated successfully.");
              onGetGameHistory(filter);
            }
            else {
              toastr.error(res.message);
            }
          };
          let updateMod = {
            ...transferMod.gameHistory,
            gameHistoryId: transferMod.gameHistory._id,
            usdtAwardedStatus: "transferred",
          } 
          // console.log("updateMod ---->", updateMod)
          onUpdateAwardStatus(updateMod, callback)
        }}
      />

      <EndModal
        isOpen={endMod.show}
        toggle={toggleEndModal}
        onConfirm={() => {
          const callback = (res) => {
            setEndMod({ show: false, gameHistory: null });
            if (res.status === "success") {
              toastr.success("Game ended successfully.");
              onGetGameHistory(filter);
            }
            else {
              toastr.error(res.message);
            }
          };
          let endData = {
            roundId: endMod?.gameHistory?.roundId,
          } 
          // console.log("updateMod ---->", updateMod)
          onEndGame(endData, callback)
        }}
      />

      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Mobile Game History"
              breadcrumbItem={props.t("Mobile Game History")}
              breadcrumbItems={[{ title: props.t("Mobile Game History") }]}
            />

            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          data={GameHistoryList.length > 0 ? GameHistoryList : []}
                          columns={ListColumns(toggleConfirmModal, toggleTransferModal, toggleEndModal, props.t)}
                          bootstrap4
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="2">
                                  <SearchInput
                                    {...toolkitProps.searchProps}
                                    triggerSearch={onSearch}
                                    placeholder={props.t("Search by email, username...")}
                                    searchText={searchText}
                                  />
                                </Col>

                                <Col sm="2">
                                  <Input
                                    type="select"
                                    value={filter.status}
                                    onChange={(e) =>
                                      setFilter((prev) => ({
                                        ...prev,
                                        status: e.target.value,
                                        page: 1
                                      }))
                                    }
                                  >
                                    <option value="">Select Status</option>
                                    <option value="active">Active</option>
                                    <option value="completed">Completed</option>
                                    <option value="ended">Ended</option>
                                  </Input>
                                </Col>
                                <Col sm="2">
                                  <Input
                                    type="select"
                                    value={filter.usdtAwardedStatus}
                                    onChange={(e) =>
                                      setFilter((prev) => ({
                                        ...prev,
                                        usdtAwardedStatus: e.target.value,
                                        page: 1
                                      }))
                                    }
                                  >
                                    <option value="">Select USDT Awarded Status</option>
                                    <option value="pending">Pending</option>
                                    <option value="transferred">Transferred</option>
                                  </Input>
                                </Col>

                                <Col sm="2">
                                  <Input
                                    type="select"
                                    value={filter.nftAwardedStatus}
                                    onChange={(e) =>
                                      setFilter((prev) => ({
                                        ...prev,
                                        nftAwardedStatus: e.target.value,
                                        page: 1
                                      }))
                                    }
                                  >
                                    <option value="">Select NFT Awarded Status</option>
                                    <option value="pending">Pending</option>
                                    <option value="transferred">Transferred</option>
                                  </Input>
                                </Col>

                                <Col sm="2">
                                  <Input
                                    type="select"
                                    value={filter.weekNo}
                                    onChange={(e) =>
                                      setFilter((prev) => ({
                                        ...prev,
                                        weekNo: e.target.value,
                                        page: 1
                                      }))
                                    }
                                  >
                                    <option value="">Select Week</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                    <option value="32">32</option>
                                    <option value="33">13</option>
                                    <option value="34">34</option>
                                    <option value="35">35</option>
                                    <option value="36">36</option>
                                    <option value="37">37</option>
                                    <option value="38">38</option>
                                    <option value="39">39</option>
                                    <option value="40">40</option>
                                    <option value="41">41</option>
                                    <option value="42">42</option>
                                    <option value="43">43</option>
                                    <option value="44">44</option>
                                    <option value="45">45</option>
                                    <option value="46">46</option>
                                    <option value="47">47</option>
                                    <option value="48">48</option>
                                    <option value="49">49</option>
                                    <option value="50">50</option>
                                    <option value="51">51</option>
                                    <option value="52">52</option>
                                  </Input>
                                </Col>
                                <Col sm="2">
                                  {!!filter.search || !!filter.status 
                                  || !!filter.usdtAwardedStatus || !!filter.nftAwardedStatus  ||
                                  !!filter.weekNo ? (
                                    <div
                                      className="mr-4 mb-2"
                                      style={{
                                        height: "calc( 1.5em + 0.94rem + 2px)",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setFilter((prevState) => ({
                                            ...prevState,
                                            search: "",
                                            status: "All",
                                            nftAwardedStatus: "",
                                            usdtAwardedStatus: "",
                                            weekNo: "",
                                            page: 1
                                          }));
                                          setSearchText("");
                                        }}
                                        className=""
                                      >
                                        {props.t("Clear Filters")}
                                      </Link>
                                    </div>
                                  ) : ""}
                                </Col>

                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive spinner-content">
                                    <BootstrapTable
                                      responsive
                                      remote
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table table-centered table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange}
                                      {...paginationTableProps}
                                      defaultSorted={[
                                        {
                                          dataField: "date_created_utc",
                                          order: "desc",
                                        },
                                      ]}
                                      sort={{
                                        sortCaret: (order) =>
                                          order === "asc" ? (
                                            <span className="caret">
                                              <i className="bx bx-caret-up" />
                                            </span>
                                          ) : order === "desc" ? (
                                            <span className="caret">
                                              <i className="bx bx-caret-down" />
                                            </span>
                                          ) : null,
                                      }}
                                    />

                                    {loading && (
                                      <div className="spinner">
                                        <Spinner color="primary" />
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>

  );
};

MobileGameHistory.propTypes = {
  GameHistory: PropTypes.array,
  totalGameHistory: PropTypes.number,
  onGetGameHistory: PropTypes.func,
  items: PropTypes.array,
  totalItems: PropTypes.number,
  onUpdateAwardStatus: PropTypes.func,
  onGetItems: PropTypes.func,
  onEndGame: PropTypes.func
};

const mapStateToProps = ({ GameHistory, items }) => ({
  loading: GameHistory.loading,
  GameHistory: GameHistory.gameHistory,
  totalGameHistory: GameHistory.totalGameHistory,
  items: items.items,
  totalItems: items.totalItems,
});

const mapDispatchToProps = (dispatch) => ({
  onGetGameHistory: (data) => dispatch(getMobileGameHistory(data)),
  onUpdateAwardStatus: (data, callback) => dispatch(updateMobileGameHistory(data, callback)),
  onGetItems: (data) => dispatch(getItems(data)),
  onEndGame: (data, callback) => dispatch(endMobileGame(data,callback))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(MobileGameHistory))
);