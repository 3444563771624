import {
    GET_NOTIFICATION_LIST,
    GET_NOTIFICATION_LIST_SUCCESS,
    GET_NOTIFICATION_LIST_FAIL,
    SEND_NOTIFICATION,
    SEND_NOTIFICATION_FAIL,
    SEND_NOTIFICATION_SUCCESS
} from "./actionTypes";


/* Get notification list*/
export const getNotificationList = (data) => ({
    type: GET_NOTIFICATION_LIST,
    payload: data,
});
  
export const getNotificationListSuccess = (list) => ({
    type: GET_NOTIFICATION_LIST_SUCCESS,
    payload: list,
});
  
export const getNotificationListFail = (error) => ({
    type: GET_NOTIFICATION_LIST_FAIL,
    payload: error,
});

export const sendNotification = (data, callback) => ({
    type: SEND_NOTIFICATION,
    payload: {data, callback},
});
  
export const sendNotificationSuccess = (data, callback) => ({
    type: SEND_NOTIFICATION_SUCCESS,
    payload: {data, callback},
});
  
export const sendNotificationFail = (error) => ({
    type: SEND_NOTIFICATION_FAIL,
    payload: error,
});