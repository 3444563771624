import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Media, Card, CardBody, Col, Container, Row } from "reactstrap";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


//i18n
import { withTranslation } from "react-i18next";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

// web3
import Web3Intraction from "helpers/web3Intraction";

// Store
import { dashboardReports } from "store/actions";

const Dashboard = (props) => {
  let auth = localStorage.getItem("authUser");
  let userRole = JSON.parse(auth)?.data?.role;
  let country = JSON.parse(auth)?.data?.country;
  const [tokens, settokens] = useState(0);
  const [filter, setFilter] = useState("Today");
  const [counts, setCounts] = useState({
    "totalRegisteredCount": 0,
    "totalNFTHolderCount": 0,
    "newRegistrationsPerDay": 0,
    "newRegistrationsPerWeek": 0,
    "newRegistrationsPerMonth": 0,
    "pendingRegistrationsPerDay": 0,
    "pendingRegistrationsPerWeek": 0,
    "pendingRegistrationsPerMonth": 0,
    "dailyActiveUsersPerDay": 0,
    "dailyActiveUsersPerWeek": 0,
    "dailyActiveUsersPerMonth": 0,
    "totalPptEarningPerDay": 0,
    "totalPptEarningPerWeek": 0,
    "totalPptEarningPerMonth": 0,
    "totalCrystalEarningPerDay": 0,
    "totalCrystalEarningPerWeek": 0,
    "totalCrystalEarningPerMonth": 0
  });

  useEffect(() => {
    if(userRole == "SUBADMIN"){
      props.onGetDashboardData({ country: country });
    }
    else{
      props.onGetDashboardData();
    }
  }, []);

  useEffect(() => {
    if (props.settings && JSON.stringify(props?.settings) !== "{}") {
      (async () => {
        const web3Intraction = new Web3Intraction("ethereum", props?.settings);

        console.log(web3Intraction, 46458548789);

        const contract = await web3Intraction?.getContract(
          web3Intraction?.tokenContractSetting?.abi,
          web3Intraction?.tokenContractSetting?.contractAddress
        );

        const supply = await contract?.methods?.totalSupply().call();

        settokens(web3Intraction?.web3?.utils?.fromWei(supply));
      })();
    }
  }, [props.settings]);

  // console.log("filter", filter)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between">

            <Breadcrumbs
              title="Dashboard"
              breadcrumbItem={props?.t("Dashboard")}
              breadcrumbItems={[{ title: props?.t("Dashboard") }]}
            />

            <div className="filtter_dashboard">
              <UncontrolledDropdown>
                <DropdownToggle caret>
                  Filter

                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={e => {
                    e.preventDefault()
                    setFilter("Today")
                  }}>Today</DropdownItem>
                  <DropdownItem onClick={e => {
                    e.preventDefault()
                    setFilter("This Week")
                  }}>This Week</DropdownItem>
                  <DropdownItem onClick={e => {
                    e.preventDefault()
                    setFilter("This Month")
                  }}>This Month</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>

          <Row>

            <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted font-weight-medium">
                        Total Registered Users
                      </p>
                      <h4 className="mb-0">{props?.dashboard?.totalRegisteredCount || 0}</h4>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted font-weight-medium">
                        Total NFT Holders
                      </p>
                      <h4 className="mb-0">{props?.dashboard?.totalNFTHolderCount || 0}</h4>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted font-weight-medium">
                        New Registered Count ({filter})
                      </p>
                      {filter == "Today" ? (
                      <h4 className="mb-0">
                        {props?.dashboard?.newRegistrationsPerDay || 0}
                      </h4>): ""}

                      {filter == "This Week" ? (
                      <h4 className="mb-0">
                        {props?.dashboard?.newRegistrationsPerWeek || 0}
                      </h4>): ""}

                     { filter == "This Month" ? (
                      <h4 className="mb-0">
                        {props?.dashboard?.newRegistrationsPerMonth || 0}
                      </h4>): ""}

                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted font-weight-medium">
                        Active Users ({filter})
                      </p>
                      {filter == "Today" ? (
                      <h4 className="mb-0">
                        {props?.dashboard?.dailyActiveUsersPerDay || 0}
                      </h4>): ""}

                      {filter == "This Week" ? (
                      <h4 className="mb-0">
                        {props?.dashboard?.dailyActiveUsersPerWeek || 0}
                      </h4>): ""}

                     { filter == "This Month" ? (
                      <h4 className="mb-0">
                        {props?.dashboard?.dailyActiveUsersPerMonth || 0}
                      </h4>): ""}

                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                  <Media body>
                      <p className="text-muted font-weight-medium">
                        Total PPT Earning ({filter})
                      </p>
                      {filter == "Today" ? (
                      <h4 className="mb-0">
                        {props?.dashboard?.totalPptEarningPerDay || 0}
                      </h4>): ""}

                      {filter == "This Week" ? (
                      <h4 className="mb-0">
                        {props?.dashboard?.totalPptEarningPerWeek || 0}
                      </h4>): ""}

                     { filter == "This Month" ? (
                      <h4 className="mb-0">
                        {props?.dashboard?.totalPptEarningPerMonth || 0}
                      </h4>): ""}

                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                  <Media body>
                      <p className="text-muted font-weight-medium">
                        Total Crystal Earning ({filter})
                      </p>
                      {filter == "Today" ? (
                      <h4 className="mb-0">
                        {props?.dashboard?.totalCrystalEarningPerDay || 0}
                      </h4>): ""}

                      {filter == "This Week" ? (
                      <h4 className="mb-0">
                        {props?.dashboard?.totalCrystalEarningPerWeek || 0}
                      </h4>): ""}

                     { filter == "This Month" ? (
                      <h4 className="mb-0">
                        {props?.dashboard?.totalCrystalEarningPerMonth || 0}
                      </h4>): ""}

                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted font-weight-medium">
                        Pending Verification Count ({filter})
                      </p>
                      {filter == "Today" ? (
                      <h4 className="mb-0">
                        {props?.dashboard?.pendingRegistrationsPerDay || 0}
                      </h4>): ""}

                      {filter == "This Week" ? (
                      <h4 className="mb-0">
                        {props?.dashboard?.pendingRegistrationsPerWeek || 0}
                      </h4>): ""}

                     { filter == "This Month" ? (
                      <h4 className="mb-0">
                        {props?.dashboard?.pendingRegistrationsPerMonth || 0}
                      </h4>): ""}

                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


Dashboard.propTypes = {};

const mapStateToProps = ({ Settings, Dashboard }) => ({
  settings: Settings?.settings,
  dashboard: Dashboard?.data,
});

const mapDispatchToProps = (dispatch) => ({
  onGetDashboardData: (data) => dispatch(dashboardReports(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dashboard))
);