import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Item Redux States
import {
  GET_ITEMS,
  ADD_ITEM,
  GET_ITEM,
  DEPLOY_ITEM,
  DELETE_ITEM,
  DEPLOY_ITEMS_STATUS,
  BLUE_TICK_NOTIFICATIONS,
  GET_NOTIFICATIONS,
  ADD_BACKGROUND_IMAGE,
  UPDATE_ITEM,
  ADD_CRYSTAL_PACKAGE,
  LIST_CRYSTAL_PACKAGE_PURCHASE,
  TEMP_AIRDROP_NFT,
  UPDATE_ITEM_PRICES,
  ADD_MULTIPLE_ITEMS,
  REMOVE_MULTIPLE_ITEMS
} from "./actionTypes";

import * as CONST from "./actionTypes";
import {
  getItemsFail,
  getItemsSuccess,
  addItemFail,
  addItemSuccess,
  getItemFail,
  getItemSuccess,
  deployItemFail,
  deployItemSuccess,
  deleteItemFail,
  deleteItemSuccess,
  putItemsStatusFail,
  putItemsStatusSuccess,
  getNotificationsFail,
  getNotificationsSuccess,
  addCrystalPackage,
  addCrystalPackageFail, addcrystalPackageSuccess,
  editCrystalPackage, editCrystalPackageFail, editcrystalPackageSuccess,
  listCrystalPackageFail, listCrystalPackage,listCrystalPackageSuccess,
  listCrystalPackagePurchaseFail, listCrystalPackagePurchaseSuccess,
  tempAirdropNftFail,tempAirdropNftSuccess, updateItemsPricesSuccess, updateItemsPricesFail,
  addMultipleItemsFail,addMultipleItemsSuccess, removeMultipleItemsFail, removeMultipleItemsSuccess
  
} from "./actions";

import * as acType from "./actions";

import {
  getItems,
  postItem,
  getItem,
  deployItem,
  addBackgroundImage,
  deleteItem,
  deleteItem2,
  removeMultipleItems,
  putItemsStatus,
  deleteBlog,
  getNotifications,
  putNotifications,
  updateBackgroundImage,
  deleteBackgroundImage,
  viweBackgroundImage,
  listBackgroundImage,
  updateItems,
  postCrystalPackage,
  updateCrystalPackage,
  getCrystalPackage,
  getCrystalPackagePurchaseList,
  tempAirdropNft,
  updateItemsPrices,
  addMultipleItems
} from "helpers/backend_helper";
import { airdropNFT } from "store/actions";

function* fetchItems({ payload }) {
  try {
    const response = yield call(getItems, payload);

    if (response.status == "failure") {
      return yield put(getItemsFail(response.message));
    }

    yield put(getItemsSuccess(response));
  } catch (error) {
    yield put(getItemsFail(error));
  }
}

function* onAddNewItem({ payload: { item, callBack } }) {
  try {
    const response = yield call(postItem, item);

    if (response.status == "failure") {
      window.scrollTo(0, 0);
      callBack && callBack(response);

      return yield put(addItemFail(response.message));
    }

    callBack && callBack(response);

    yield put(addItemSuccess());
  } catch (error) {
    yield put(addItemFail("Internal Error!"));
  }
}

function* onAddBackgroundImage({ payload: { data, callback } }) {
  try {
    const response = yield call(addBackgroundImage, data);

    if (response.status == "failure") {
      window.scrollTo(0, 0);

      callback && callback(response);
      return yield put(acType.addBackgroundImgFail(response.message));
    }
    callback && callback(response);
    yield put(acType.addBackgroundImgSuccess());
  } catch (error) {
    yield put(acType.addBackgroundImgFail("Internal Error!"));
  }
}

function* onUpdateItem({ payload: { item, callback } }) {
  try {
    const response = yield call(updateItems, item);
    if (response.status == "failure") {
      window.scrollTo(0, 0);
      callback && callback(response);
      return yield put(acType.updateItemFail(response.message));
    }
    callback && callback(response);
    yield put(acType.updateItemSuccess());
  } catch (error) {
    yield put(acType.updateItemFail("Internal Error!"));
  }
}

function* fetchBgmImage({ payload: { data, callback } }) {
  try {
    const response = yield call(viweBackgroundImage, data);

    if (response.status == "failure") {
      window.scrollTo(0, 0);
      callback && callback(response);
      return yield put(acType.viewBackgroundImgFail(response.message));
    }
    callback && callback(response);
    yield put(acType.viewBackgroundImgSuccess(response));
  } catch (error) {
    yield put(acType.viewBackgroundImgFail("Internal Error!"));
  }
}

function* putBgmImage({ payload: { data, callback } }) {
  try {
    const response = yield call(updateBackgroundImage, data);

    if (response.status == "failure") {
      window.scrollTo(0, 0);
      callback && callback(response);
      return yield put(acType.putBackgroundImgFail(response.message));
    }
    callback && callback(response);
    yield put(acType.putBackgroundImgSuccess());
  } catch (error) {
    yield put(acType.putBackgroundImgFail("Internal Error!"));
  }
}

function* removeBgmImage({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteBackgroundImage, data);

    if (response.status == "failure") {
      window.scrollTo(0, 0);
      callback && callback(response);
      return yield put(acType.deleteBackgroundImgFail(response.message));
    }
    callback && callback(response);
    yield put(acType.deleteBackgroundImgSuccess());
  } catch (error) {
    yield put(acType.deleteBackgroundImgFail("Internal Error!"));
  }
}

function* getListBgmImage({ payload: { data, callback } }) {
  try {
    const response = yield call(listBackgroundImage, data);

    if (response.status == "failure") {
      window.scrollTo(0, 0);
      callback && callback(response);
      return yield put(acType.listBackgroundImgFail(response.message));
    }
    callback && callback(response);
    yield put(acType.listBackgroundImgSuccess(response));
  } catch (error) {
    yield put(acType.listBackgroundImgFail("Internal Error!"));
  }
}

function* fetchItem({ payload }) {
  try {
    const response = yield call(getItem, payload);

    if (response.status == "failure") {
      return yield put(getItemFail(response.message));
    }

    yield put(getItemSuccess(response.data));
  } catch (error) {
    yield put(getItemFail(error));
  }
}

function* onDeployItem({ payload: { data, callBack } }) {
  try {
    const response = yield call(deployItem, data);

    if (response.status == "failure") {
      callBack && callBack(false, response);

      return yield put(deployItemFail(response.message));
    }

    callBack && callBack(true, response);

    yield put(deployItemSuccess());
  } catch (error) {
    yield put(deployItemFail("Internal Error!"));
  }
}

function* onDeleteItem({ payload: { data, callback, type } }) {
  try {
    let response;
    if (type == "itemId") {
      response = yield call(deleteItem2, data);
    } else if (type) {
      response = yield call(deleteItem, data);
    } else {
      response = yield call(deleteBlog, data);
    }
    if (response.status == "failure") {
      callback && callback(response);

      return yield put(deleteItemFail(response.message));
    }

    yield put(deleteItemSuccess());
    callback && callback(response);
  } catch (error) {
    yield put(deleteItemFail("Internal Error!"));
  }
}

function* onDeleteItemsSaga({ payload: { data, callback } }) {
  try {
    let response = yield call(removeMultipleItems, data);
    if (response.status == "failure") {
      return yield put(removeMultipleItemsFail(response.message));
    }
    yield put(removeMultipleItemsSuccess());
    callback && callback(response);
  } catch (error) {
    yield put(removeMultipleItemsFail("Internal Error!"));
  }
}

function* onPutItemsStatus({ payload: { data, callback } }) {
  try {
    const response = yield call(putItemsStatus, data);

    if (response.status == "failure") {
      return yield put(putItemsStatusFail(response.message));
    }

    yield put(putItemsStatusSuccess());
    callback && callback();
  } catch (error) {
    yield put(putItemsStatusFail("Internal Error!"));
  }
}

function* onGetNotifications({ payload: { data, callback } }) {
  try {
    const response = yield call(getNotifications, data);

    if (response.status == "failure") {
      return yield put(getNotificationsFail(response.message));
    }

    yield put(getNotificationsSuccess(response));
    callback && callback(response);
  } catch (error) {
    yield put(getNotificationsFail("Internal Error!"));
  }
}


function* onPostCrystalPackage({ payload: { data, callback } }) {
  try {
    const response = yield call(postCrystalPackage, data);

    if (response.status == "failure") {
      return yield put(addCrystalPackageFail(response.message));
    }

    yield put(addcrystalPackageSuccess());
    callback && callback(response);
  } catch (error) {
    yield put(addCrystalPackageFail("Internal Error!"));
  }
}



function* onUpdateCrystalPackage({ payload: { data, callback } }) {
  try {
    const response = yield call(updateCrystalPackage, data);

    if (response.status == "failure") {
      return yield put(editCrystalPackageFail(response.message));
    }

    yield put(editcrystalPackageSuccess());
    callback && callback(response);
  } catch (error) {
    yield put(editCrystalPackageFail("Internal Error!"));
  }
}

function* fetchPackages({ payload }) {
  try {
    const response = yield call(getCrystalPackage, payload);

    if (response.status == "failure") {
      return yield put(listCrystalPackageFail(response.message));
    }

    yield put(listCrystalPackageSuccess(response));
  } catch (error) {
    yield put(listCrystalPackageFail(error));
  }
}

function* fetchPackagePurchases({ payload }) {
  try {
    const response = yield call(getCrystalPackagePurchaseList, payload);

    if (response.status == "failure") {
      return yield put(listCrystalPackagePurchaseFail(response.message));
    }

    yield put(listCrystalPackagePurchaseSuccess(response));
  } catch (error) {
    yield put(listCrystalPackagePurchaseFail(error));
  }
}

function* tempAirdropNftSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(tempAirdropNft, data);

    if (response.status == "failure") {
      callback && callback(response);
      return yield put(acType.tempAirdropNftFail(response.message));
    }
    callback && callback(response);
    yield put(acType.tempAirdropNftSuccess(response));
  } catch (error) {
    yield put(acType.tempAirdropNftFail("Internal Error!"));
  }
}

function* updateSelectedNFTPricesSaga({ payload: { data, callback }  }) {
  try {
    const response = yield call(updateItemsPrices, data);

    if (response.status == "failure") {
      return yield put(updateItemsPricesFail(response.message));
    }
    callback && callback(response);
    yield put(updateItemsPricesSuccess(response));
  } catch (error) {
    yield put(updateItemsPricesFail(error));
  }
}

function* addMultipleItemsSaga({ payload: { data, callback }  }) {
  try {
    const response = yield call(addMultipleItems, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(addMultipleItemsFail(response.message));
    }
    yield put(addMultipleItemsSuccess(response));
  } catch (error) {
    callback && callback(error);
    yield put(addMultipleItemsFail(error));
  }
}

function* onPutNotifications({ payload: { data, callback } }) {
  try {
    const response = yield call(putNotifications, data);
    callback && callback(response);
  } catch (error) {}
}
function* ItemsSaga() {
  yield takeEvery(GET_ITEMS, fetchItems);
  yield takeEvery(ADD_BACKGROUND_IMAGE, onAddBackgroundImage);
  yield takeEvery(UPDATE_ITEM, onUpdateItem);
  yield takeEvery(CONST.VIEW_BACKGROUND_IMAGE, fetchBgmImage);
  yield takeEvery(CONST.DELETE_BACKGROUND_IMAGE, removeBgmImage);
  yield takeEvery(CONST.GET_LIST_BACKGROUND_IMAGE, getListBgmImage);
  yield takeEvery(CONST.PUT_BACKGROUND_IMAGE, putBgmImage);
  yield takeEvery(ADD_ITEM, onAddNewItem);
  yield takeEvery(GET_ITEM, fetchItem);
  yield takeEvery(BLUE_TICK_NOTIFICATIONS, onPutNotifications);
  yield takeEvery(DEPLOY_ITEM, onDeployItem);
  yield takeEvery(DELETE_ITEM, onDeleteItem);
  yield takeEvery(REMOVE_MULTIPLE_ITEMS, onDeleteItemsSaga);
  yield takeEvery(DEPLOY_ITEMS_STATUS, onPutItemsStatus);
  yield takeEvery(GET_NOTIFICATIONS, onGetNotifications);
  yield takeEvery(CONST.ADD_CRYSTAL_PACKAGE, onPostCrystalPackage);
  yield takeEvery(CONST.UPDATE_CRYSTAL_PACKAGE, onUpdateCrystalPackage);
  yield takeEvery(CONST.LIST_CRYSTAL_PACKAGE, fetchPackages);
  yield takeEvery(CONST.LIST_CRYSTAL_PACKAGE_PURCHASE, fetchPackagePurchases);
  yield takeEvery(TEMP_AIRDROP_NFT, tempAirdropNftSaga);
  yield takeEvery(UPDATE_ITEM_PRICES, updateSelectedNFTPricesSaga);
  yield takeLatest(ADD_MULTIPLE_ITEMS, addMultipleItemsSaga)

}

export default ItemsSaga;
