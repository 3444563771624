import React, { useState } from "react"
import { connect } from "react-redux"

// import images
import gracerySvg from "assets/images/Grocery.svg"
import foodSvg from "assets/images/Food.svg"
import medicineSvg from "assets/images/Medicine.svg"
import deliverySvg from "assets/images/Delivery.svg"

const Market = props => {
  const { setactiveTab, storeTypes, fields, handleChange, apiError } = props

  const [markets, setmarkets] = useState([])

  const handleMarketChange = market => {
    handleChange("storeType")({
      target: { value: { ...fields?.storeType, [market]: "SINGLE" } },
    })

    if (markets?.includes(market)) {
      const _index = markets.findIndex(item => item === market)
      setmarkets(prevState => [
        ...prevState.slice(0, _index),
        ...prevState.slice(_index + 1),
      ])
      return
    }

    setmarkets(prevState => [...prevState, market])
  }

  function handleNext() {
    if (markets?.length <= 0) {
      window.scrollTo(0, 0)
      return apiError("Please select atleast one Marketplace!")
    }

    setactiveTab(3)
  }

  return (
    <div className="tab">
      <div className="row">
        <div className="col-lg-12">
          <h1>Create your Go marketplace and start building today</h1>
        </div>

        <div className="col-lg-12">
          <div className="input-group">
            <label className="flex">
              Marketplace type <a href="#">Learn more</a>
            </label>

            <ul className="invisible-checkboxes">
              {storeTypes?.map((store, key) => {
                return (
                  <li key={`_storetypes_${key}`}>
                    <div className="marketplacelist">
                      <input
                        type="checkbox"
                        id={`r1-${key}`}
                        onClick={() => handleMarketChange(store?.storeType)}
                      />

                      <label className="checkbox-alias" for={`r1-${key}`}>
                        <div
                          className={`topslider${markets?.includes(store?.storeType)
                            ? " topslideactive"
                            : ""
                            }`}
                        >
                          <img src={foodSvg} alt="" />
                          <h3
                            className={
                              markets?.includes(store?.storeType)
                                ? "activecolor"
                                : ""
                            }
                          >
                            {store?.storeType?.toLowerCase()}
                          </h3>
                        </div>

                        <div
                          className="hidden-list"
                          style={{
                            display: markets?.includes(store?.storeType)
                              ? "block"
                              : "none",
                          }}
                        >
                          <div className="inline-flex">
                            <div className="cd-switch">
                              <div className="switch">
                                <div
                                  className={
                                    fields?.storeType[store?.storeType] ===
                                      "SINGLE"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <input
                                    type="radio"
                                    name={`choice${key + 1}`}
                                    id={`yes${key + 1}`}
                                    checked={
                                      fields?.storeType[store?.storeType] ===
                                      "SINGLE"
                                    }
                                    onChange={() => {
                                      handleChange("storeType")({
                                        target: {
                                          value: {
                                            ...fields?.storeType,
                                            [store?.storeType]: "SINGLE",
                                          },
                                        },
                                      })
                                    }}
                                  />
                                  <label for={`yes${key + 1}`}>
                                    Single Vendor
                                  </label>

                                  {fields?.storeType[store?.storeType] ===
                                    "SINGLE" && (
                                      <span className="switchFilter"></span>
                                    )}
                                </div>

                                <div
                                  className={
                                    fields?.storeType[store?.storeType] ===
                                      "AGGREAGATOR"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <input
                                    type="radio"
                                    name={`choice${key + 1}`}
                                    id={`no${key + 1}`}
                                    checked={
                                      fields?.storeType[store?.storeType] ===
                                      "AGGREAGATOR"
                                    }
                                    onChange={() => {
                                      handleChange("storeType")({
                                        target: {
                                          value: {
                                            ...fields?.storeType,
                                            [store?.storeType]: "AGGREAGATOR",
                                          },
                                        },
                                      })
                                    }}
                                  />
                                  <label for={`no${key + 1}`}>Aggregator</label>
                                  {fields?.storeType[store?.storeType] ===
                                    "AGGREAGATOR" && (
                                      <span className="switchFilter"></span>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </li>
                )
              })}
            </ul>

            <p>You can change the marketplace type later.</p>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="flex-btn">
            <button
              type="button"
              className="themebtnblack fullwidthbtn"
              onClick={() => setactiveTab(1)}
            >
              Back
            </button>

            <button
              type="button"
              className="themebtn fullwidthbtn"
              onClick={handleNext}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ Settings }) => ({
  loading: Settings.loading,
  storeTypes: Settings.storeTypes,
})

export default connect(mapStateToProps)(Market)
