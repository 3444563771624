import { Contract, ethers, ContractFactory, utils } from "ethers";


class Web3IntractionEth {
  constructor(networkUrl, provider, settings) {
    this.contractAbi = JSON.stringify(settings);
    if (provider) {
      this.PROVIDER = new ethers.providers.Web3Provider(
        provider,
        networkUrl
          ? { name: networkUrl.chainName, chainId: Number(networkUrl.chainId) }
          : "any"
      );

      this.SIGNER = this.PROVIDER.getSigner();
    } else if (networkUrl) {
      this.PROVIDER = new ethers.providers.JsonRpcProvider(
        networkUrl.url,
        networkUrl
          ? { name: networkUrl.chainName, chainId: Number(networkUrl.chainId) }
          : "any"
      );

      this.SIGNER = this.PROVIDER;
    }

    this.settings = settings;
    this.networkUrl = networkUrl;
  }

  /**
   * Get contract from abi and address
   *
   * @param {string} abi - ABI JSON
   * @param {string} address - Contract Address
   *
   * @returns {object} Contract
   */
  getContract = (abi, address) => {
    try {
      let contract = new Contract(address, JSON.parse(abi), this.SIGNER);
      return contract;
    } catch (error) {
      console.log("error", error);
      return null;
    }
  };

  /**
   * Deploy contract.
   *
   * @param {string} abi abi data
   * @param {string} bytecode bytecode data
   * @param {function} callback Callback function
   *
   * @returns {Promise} Object (Transaction Hash, Contract Address) in Success or Error in Fail
   */
  deployContract = (abi, bytecode, callback) =>
    new Promise(async (resolve, reject) => {


      // console.log(abi, bytecode,"<=====abi, bytecode,")
      try {
        const factory = new ContractFactory(
          JSON.parse(abi),
          bytecode,
          this.SIGNER
        );
        const options = {
          // gasLimit: 3000000,
        };

        const contract = await factory.deploy(options);
        await contract.deployTransaction.wait();

        callback && callback(null, contract);
        resolve(contract);
      } catch (error) {
        console.log("error", error);
        if (error.code == "ACTION_REJECTED") {
          callback && callback("User reject the request");
          reject("User reject the request");
        } else {
          callback && callback(error.data?.message || error.message || error);
          reject(error.data?.message || error.message || error);
        }
      }
    });

  /**
   * Deploy collection contract TronLink.
   *
   * @param {string} userWallet Current user wallet address
   * @param {object} collectionData Collection Details (ie. abi, bytecode)
   * @param {function} callback Callback function
   *
   * @returns {Promise} Object (Transaction Hash, Contract Address) in Success or Error in Fail
   */
  deployTronLinkContract = (abi, bytecode, callback) => {
    return new Promise(async (resolve, reject) => {
      //set up transaction parameters
      try {
        let tronweb = window.tronWeb;
        const l_args = {
          abi: JSON.parse(abi),
          bytecode: bytecode,
          feeLimit: 1e10,
          callValue: 0,
          userFeePercentage: 100,
          originEnergyLimit: 1e7,
        };

        let balance = await tronweb.trx.getBalance(tronweb.defaultAddress.hex);
        console.log(balance, "balance");
        console.log(1e9, "balance");
        if (balance < 1e9) {
          reject("Insufficient balance");
          return;
        }

        let tx = await tronweb.transactionBuilder.createSmartContract(
          l_args,
          tronweb.defaultAddress.hex
        );

        let signedTx = await tronweb.trx.sign(tx);
        let receipt = await tronweb.trx.sendRawTransaction(signedTx);

        console.log(receipt, "receipt");
        callback && callback(null, { ...receipt, address: receipt.transaction.contract_address, transactionHash: receipt.txid });
        resolve({ ...receipt, address: receipt.transaction.contract_address, transactionHash: receipt.txid });
      } catch (error) {
        console.log("error", error);
        if (error.code == "ACTION_REJECTED") {
          callback && callback("User reject the request");
          reject("User reject the request");
        } else {
          callback && callback(error.data?.message || error.message || error);
          reject(error.data?.message || error.message || error);
        }
      }
    });
  };

  /**
   * Set Pre Sale Config.
   *

   * @param {object} params  Details (ie. _SaleStart, _SaleEnd, _ICB_IN_DOLLOR)
   * @param {string} contractAddress Contract deploy address
   * @param {function} callback Callback function
   *
   * @returns {Promise} Object (Transaction Hash, Contract Address) in Success or Error in Fail
   */
  preSaleConfig = (params, abi, contractAddress, callback) =>
    new Promise(async (resolve, reject) => {
      try {
        let contract = this.getContract(JSON.stringify(abi), contractAddress);
        //Get connected wallet and contract address and match same or not
        const userWallet = await this.SIGNER.getAddress();
        const contractOwner = await contract.owner();
        if (contractOwner != userWallet) {
          reject("Connected wallet address is not contract owner");
          return;
        }

        let transaction = await contract.preSaleConfig(
          params.saleStart,
          params.saleEnd,
          params.icbInDollar,
          {
            gasLimit: 3000000,
          }
        );

        let receipt = await transaction?.wait();

        resolve(receipt);
      } catch (error) {
        if (error.code == "ACTION_REJECTED") {
          callback && callback("User reject the request");
          reject("User reject the request");
        } else {
          callback && callback(error.data?.message || error.message || error);
          reject(error.data?.message || error.message || error);
        }
      }
    });

  /**
   * Set Private Sale Config.
   *

   * @param {object} params  Details (ie. _SaleStart, _SaleEnd, _ICB_IN_DOLLOR)
   * @param {string} contractAddress Contract deploy address
   * @param {function} callback Callback function
   *
   * @returns {Promise} Object (Transaction Hash, Contract Address) in Success or Error in Fail
   */
  privateSaleConfig = (params, abi, contractAddress, callback) =>
    new Promise(async (resolve, reject) => {
      try {
        let contract = this.getContract(JSON.stringify(abi), contractAddress);

        let transaction = await contract.privateSaleConfig(
          params.saleStart,
          params.saleEnd,
          params.icbInDollar,
          {
            gasLimit: 3000000,
          }
        );

        let receipt = await transaction?.wait();

        resolve(receipt);
      } catch (error) {
        if (error.code == "ACTION_REJECTED") {
          callback && callback("User reject the request");
          reject("User reject the request");
        } else {
          callback && callback(error.data?.message || error.message || error);
          reject(error.data?.message || error.message || error);
        }
      }
    });

  /**
   * Set Public Sale Config.
   *

   * @param {object} params  Details (ie. _SaleStart, _ICB_IN_DOLLOR)
   * @param {string} contractAddress Contract deploy address
   * @param {function} callback Callback function
   *
   * @returns {Promise} Object (Transaction Hash, Contract Address) in Success or Error in Fail
   */
  publicSaleConfig = (params, abi, contractAddress, callback) =>
    new Promise(async (resolve, reject) => {
      try {
        let contract = this.getContract(JSON.stringify(abi), contractAddress);

        let transaction = await contract.publicSaleConfig(
          params.saleStart,
          params.saleEnd,
          params.icbInDollar,
          {
            gasLimit: 3000000,
          }
        );

        let receipt = await transaction?.wait();

        resolve(receipt);
      } catch (error) {
        if (error.code == "ACTION_REJECTED") {
          callback && callback("User reject the request");
          reject("User reject the request");
        } else {
          callback && callback(error.data?.message || error.message || error);
          reject(error.data?.message || error.message || error);
        }
      }
    });

  /**
   *  Add user for whitelist.
   *

   * @param {object} addressArr  address array
   * @param {string} contractAddress Contract deploy address
   * @param {function} callback Callback function
   *
   * @returns {Promise} Object (Transaction Hash, Contract Address) in Success or Error in Fail
   */
  addUser = (addressArr, abi, contractAddress, callback) =>
    new Promise(async (resolve, reject) => {
      console.log(addressArr, contractAddress, "<===data");
      try {
        let contract = this.getContract(JSON.stringify(abi), contractAddress);
        console.log(contract, "<=====contract");
        let transaction = await contract.addUser(addressArr, {
          gasLimit: 3000000,
        });

        let receipt = await transaction?.wait();

        resolve(receipt);
      } catch (error) {
        if (error.code == "ACTION_REJECTED") {
          callback && callback("User reject the request");
          reject("User reject the request");
        } else {
          callback && callback(error.data?.message || error.message || error);
          reject(error.data?.message || error.message || error);
        }
      }
    });

  /**
   *  Add user for whitelist.
   *

   * @param {object} addressArr  address array
   * @param {string} contractAddress Contract deploy address
   * @param {function} callback Callback function
   *
   * @returns {Promise} Object (Transaction Hash, Contract Address) in Success or Error in Fail
   */
  EndSaleClearWhiteList = async (addressArr, abi, contractAddress, callback) =>
    new Promise(async (resolve, reject) => {
      try {
        let contract = this.getContract(JSON.stringify(abi), contractAddress);

        let transaction = await contract.EndSaleClearWhiteList(addressArr, {
          gasLimit: 3000000,
        });

        let receipt = await transaction?.wait();

        resolve(receipt);
      } catch (error) {
        if (error.code == "ACTION_REJECTED") {
          callback && callback("User reject the request");
          reject("User reject the request");
        } else {
          callback && callback(error.data?.message || error.message || error);
          reject(error.data?.message || error.message || error);
        }
      }
    });

  /**
   *  Validate Contract Address
   *
   * @param {object} address  contract address
   * @returns {Promise} Boolean
   */
  isContractAddress = (address) =>
    new Promise(async (resolve, reject) => {
      try {
        const code = await this.PROVIDER.getCode(address);
        resolve(code !== "0x");
      } catch (error) {
        reject(error.data?.message || error.message || error);
      }
    });

  /**
   * Update Max Trade
   *
   * @param {object} params  Details (ie. _SaleStart, _ICB_IN_DOLLOR)
   * @param {string} contractAddress Contract deploy address
   * @param {function} callback Callback function
   *
   * @returns {Promise} Object (Transaction Hash, Contract Address) in Success or Error in Fail
   */
  updateMaxTrade = (params, abi, contractAddress, callback) =>
    new Promise(async (resolve, reject) => {
      try {
        console.log(params, abi, contractAddress, "<====dat");
        let contract = this.getContract(JSON.stringify(abi), contractAddress);
        console.log(contract, "<===contract");
        let transaction = await contract.UpdateMaxTrade(
          params.maxTradePerDay,
          params.maxQtyPerSale,
          {
            gasLimit: 3000000,
          }
        );

        let receipt = await transaction?.wait();

        resolve(receipt);
      } catch (error) {
        if (error.code == "ACTION_REJECTED") {
          callback && callback("User reject the request");
          reject("User reject the request");
        } else {
          callback && callback(error.data?.message || error.message || error);
          reject(error.data?.message || error.message || error);
        }
      }
    });
}

export default Web3IntractionEth;
