import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import Dropzone from "react-dropzone";
import { isObject } from "lodash";

import { Row, Col, FormGroup, FormText, Label, Input } from "reactstrap";
import Instructions from "./Instructions";

// Actions
import { uploadFile } from "store/actions";

// Images
import logo_placeholder from "assets/images/logo-placeholder.png";



const GeneralSettings = (props) => {
  const { fields, handleChange, onUploadFile } = props;


  const [logoselectedFiles, setlogoselectedFiles] = useState([]);

 

  // alert("yaa");
  return (
    <React.Fragment>
      <Row>
        <Col lg={4}>
          <Instructions t={props.t} />
        </Col>

        <Col lg={8}>
          <Row>
            

            <Col md={6}>
              <FormGroup>
                <Label>Level 1</Label>

                <Input
                  type="number"
                  value={fields?.level1}
                  onChange={(e) => {
                    handleChange("level1")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 2</Label>

                <Input
                  type="number"
                  value={fields?.level2}
                  onChange={(e) => {
                    handleChange("level2")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 3</Label>

                <Input
                  type="number"
                  value={fields?.level3}
                  onChange={(e) => {
                    handleChange("level3")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Level 4</Label>

                <Input
                  type="number"
                  value={fields?.level4}
                  onChange={(e) => {
                    handleChange("level4")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Level 5</Label>

                <Input
                  type="number"
                  value={fields?.level5}
                  onChange={(e) => {
                    handleChange("level5")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
           
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

GeneralSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneralSettings)
);
