import {
  ADD_TOURNAMENT,
  REMOVE_TOURNAMENTS,
  UPDATE_TOURNAMENT,
  VIEW_TOURNAMENT,
} from "helpers/url_helper";
import {
  TOURNAMENTS_HISTORY_API_FAIL,
  GET_TOURNAMENTS_HISTORY,
  GET_TOURNAMENTS_HISTORY_SUCCESS,
  GET_TOURNAMENTS_HISTORY_FAIL,
  VIEW_TOURNAMENTS_HISTORY,
  VIEW_TOURNAMENTS_HISTORY_SUCCESS,
  VIEW_TOURNAMENTS_HISTORY_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  billingProducts: [],
  totalBillingProducts: 0,
  error: "",
  loading: false,
  tournamentHistoryDetail: [],
  billingProduct: {},
  tournamentsHistory: [],
  totaltournamentsHistory: 0,
  collection: {},
  date: "",
};

const TournamentsHistory = (state = INIT_STATE, action) => {
  // console.log(action.payload, "SSS");
  switch (action.type) {
    /* Get Collections */
    case GET_TOURNAMENTS_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case GET_TOURNAMENTS_HISTORY_SUCCESS:
      return {
        ...state,
        tournamentsHistory: action.payload.data || [],
        totaltournamentsHistory: action.payload.total || 0,
        loading: false,
        error: "",
      };

    case GET_TOURNAMENTS_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    /* VIEW tournament */
    case VIEW_TOURNAMENTS_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case VIEW_TOURNAMENTS_HISTORY_SUCCESS:
      return {
        ...state,
        tournamentHistoryDetail: action.payload,
        loading: false,
        error: "",
      };

    case VIEW_TOURNAMENTS_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default TournamentsHistory;
