import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import toastr from "toastr";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "components/Common/Breadcrumb2";
import { airDropList } from "store/actions";
import ListColumns, { selectRow } from "./ListColumns";
import ConfirmModal from "./ConfirmModal";
import SearchInput from "components/Common/SearchInput";

const AllAirDropList = ({
  accessLevel,
  history,
  airDropListData,
  totalCount,
  onGetAirDropPlotsList,
  loading,
  ...props
}) => {
  const { settings } = useSelector(state => state.Settings)
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  });

  const [selected, setSelected] = useState([]);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    itemId: null,
  });

  const [filter, setFilter] = useState({
    page: 0,
    limit: 20,
    orderBy: "date_created_utc",
    status: "created",
    order: -1,
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
    search: ""
  });
  const [searchText, setSearchText] = useState("");
  const [itemsList, setItemsList] = useState([]);
  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalCount,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canBlock: true,
      };
      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
      canDelete: false,
      canBlock: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;

        case "DELETE":
          data.canDelete = item.value;
          break;

        case "BLOCK":
          data.canBlock = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    onGetAirDropPlotsList(filter);
    setItemsList([]);
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    setItemsList(airDropListData || []);
  }, [airDropListData]);

  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText.trim());
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "create_date";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (itemId) => {
    const itemIds = itemId ? itemId : selected;

    console.log(itemIds, "itemIdsitemIds");
    setConfirmModal((prevState) => ({
      isOpen: !prevState.isOpen,
      itemIds,
    }));
  };

  const removeConfirm = () => {
    const { itemIds, isOpen } = confirmModal;
    const onComplete = () => {
      toastr.success("Successfully Deleted");
      window?.location?.reload();
    };

    setConfirmModal({ isOpen: !isOpen, itemIds: null });
  };

  const onSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected((prevSelected) => [...prevSelected, row._id]);
    } else {
      setSelected((prevSelected) => prevSelected.filter((x) => x !== row._id));
    }
  };

  const onSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r._id);
    if (isSelect) {
      setSelected(ids);
    } else {
      setSelected([]);
    }
  };


  const onSearch = (event) => {
    event.preventDefault();
    setFilter((prevState) => ({ ...prevState, search: searchText.trim() }));
  };
  // console.log(itemsList, "itemsList")
  return (
    <React.Fragment>
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />

      <div className="page-content pl-0">
        <Container fluid className="pl-0">
          <Breadcrumbs
            title="Air Drop Nft List"
            breadcrumbItem={props.t("Air Drop Nft List")}
            breadcrumbItems={[{ title: props.t("Air Drop Nft List") }]}
          />

          <Row>
            <Col xs="12" className="pl-0">
              <Card>
                <CardBody style={{ padding: 0 }}>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={itemsList || []}
                        columns={ListColumns(
                          history,
                          null,
                          toggleConfirmModal,
                          accesses,
                          props.t,
                          settings
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4" lg="3">
                                <SearchInput
                                  {...toolkitProps.searchProps}
                                  triggerSearch={onSearch}
                                  placeholder={props.t("Search")}
                                  searchText={searchText}
                                />
                              </Col>
                              <Col sm="2">
                                {!!filter.fields.fieldName ||
                                  (!!filter.search && (
                                    <div
                                      className="mr-4 mb-2"
                                      style={{
                                        height: "calc( 1.5em + 0.94rem + 2px)",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setFilter((prevState) => ({
                                            ...prevState,
                                            fields: {
                                              fieldName: "",
                                              fieldValue: "",
                                            },
                                            search: "",
                                          }));
                                          setSearchText("");
                                        }}
                                        className=""
                                      >
                                        {props.t("clear_filters")}
                                      </Link>
                                    </div>
                                  ))}
                              </Col>
                            </Row>

                            {(accesses.canEdit || accesses.canDelete) && (
                              <AnimateHeight
                                duration={500}
                                height={selected.length > 0 ? "auto" : 0}
                              >
                                <Row className="pb-2">
                                  {accesses.canEdit && <Col sm="2"></Col>}

                                  <Col></Col>
                                </Row>
                              </AnimateHeight>
                            )}

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  // selectRow={selectRow({
                                  //   selected,
                                  //   onSelect,
                                  //   onSelectAll,
                                  // })}
                                  />

                                  {/* {!loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )} */}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AllAirDropList.propTypes = {
  onGetAirDropPlotsList: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  loading: Settings.loading,
  airDropListData: Settings.airDropList,
  totalCount: Settings.totalCount,
});

const mapDispatchToProps = (dispatch) => ({
  onGetAirDropPlotsList: (data, callback) =>
    dispatch(airDropList(data, callback)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(AllAirDropList))
);
