import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { Card, CardBody, Col, Container, Row, Spinner, Input } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import { getNotificationList } from "store/actions";
import ListColumns from "./ListColumns";

const NotificationsList = ({
  notifications,
  total,
  onGetNotificationList,
  loading,
  ...props
}) => {
  const [filter, setFilter] = useState({
    orderBy: "createdAt",
    order: -1,
    page: 1,
    limit: 20,
    search: "",
    type: "primary",
    notifyType: ""
  });
  const [searchText, setSearchText] = useState("");
  const [notificationsList, setNotifications] = useState([]);
  const pageOptions = {
    sizePerPage: 20,
    totalSize: total,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    onGetNotificationList(filter);
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    setNotifications(notifications || []);
  }, [notifications]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText.trim());
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "createdAt";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const onSearch = (event) => {
    event.preventDefault();
    setFilter((prevState) => ({ ...prevState, search: searchText.trim() }));
  };

  console.log("notifications -------->", notifications);
  console.log(" notificationsList ------>", notificationsList);
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Manage Notifications"
              breadcrumbItem={props.t("Manage Notifications")}
              breadcrumbItems={[{ title: props.t("Manage Notifications") }]}
            />

            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          data={notificationsList.length > 0 ? notificationsList : []}
                          columns={ListColumns( props.t)}
                          bootstrap4
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="3">
                                  <SearchInput
                                    {...toolkitProps.searchProps}
                                    triggerSearch={onSearch}
                                    placeholder={props.t("Search by title, message...")}
                                    searchText={searchText}
                                  />
                                </Col>

                                <Col sm="3">
                                  <Input
                                    type="select"
                                    value={filter.type}
                                    onChange={(e) =>
                                      setFilter((prev) => ({
                                        ...prev,
                                        type: e.target.value,
                                      }))
                                    }
                                  >
                                    <option value="">Select Type</option>
                                    <option value="system">System</option>
                                    <option value="primary">Primary</option>
                                  </Input>
                                </Col>

                                <Col sm="3">
                                  <Input
                                    type="select"
                                    value={filter.notifyType}
                                    onChange={(e) =>
                                      setFilter((prev) => ({
                                        ...prev,
                                        notifyType: e.target.value,
                                      }))
                                    }
                                  >
                                    <option value="">Select Notify Type</option>
                                    <option value="selected">Selected</option>
                                    <option value="all">All</option>
                                  </Input>
                                </Col>

                                <Col sm="2">
                                  {!!filter.search || !!filter.type || !!filter.notifyType ? (
                                    <div
                                      className="mr-4 mb-2"
                                      style={{
                                        height: "calc( 1.5em + 0.94rem + 2px)",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setFilter((prevState) => ({
                                            ...prevState,
                                            search: "",
                                            type: "",
                                            userType: ""
                                          }));
                                          setSearchText("");
                                        }}
                                        className=""
                                      >
                                        {props.t("Clear Filters")}
                                      </Link>
                                    </div>
                                  ) : ""}
                                </Col>

                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive spinner-content">
                                    <BootstrapTable
                                      responsive
                                      remote
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table table-centered table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange}
                                      {...paginationTableProps}
                                      defaultSorted={[
                                        {
                                          dataField: "createdAt",
                                          order: "desc",
                                        },
                                      ]}
                                      sort={{
                                        sortCaret: (order) =>
                                          order === "asc" ? (
                                            <span className="caret">
                                              <i className="bx bx-caret-up" />
                                            </span>
                                          ) : order === "desc" ? (
                                            <span className="caret">
                                              <i className="bx bx-caret-down" />
                                            </span>
                                          ) : null,
                                      }}
                                    />

                                    {loading && (
                                      <div className="spinner">
                                        <Spinner color="primary" />
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>

  );
};

NotificationsList.propTypes = {
  notifications: PropTypes.array,
  total: PropTypes.number,
  onGetNotificationList: PropTypes.func,
};

const mapStateToProps = ({ Notifications }) => ({
  loading: Notifications.loading,
  notifications: Notifications.notifications,
  total: Notifications.total,
});

const mapDispatchToProps = (dispatch) => ({
  onGetNotificationList: (data) => { dispatch(getNotificationList(data))}
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(NotificationsList))
);