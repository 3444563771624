import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  REMOVE_WALLET_ADDRESS,
  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAIL
} from "./actionTypes"

export const editProfile = (user, history) => {
  return {
    type: EDIT_PROFILE,
    payload: { user, history },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const putChangePwd = (data, callback) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { data, callback },
  }
}

export const putChangePwdSuccess = msg => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: msg,
  }
}

export const putChangePwdError = error => {
  return {
    type: CHANGE_PASSWORD_FAIL,
    payload: error,
  }
}

export const removeWalletAddress = (data, callback) => {
  return {
    type: REMOVE_WALLET_ADDRESS,
    payload: { data, callback },
  }
}

export const removeWalletAddressSuccess = msg => {
  return {
    type: REMOVE_WALLET_ADDRESS_SUCCESS,
    payload: msg,
  }
}

export const removeWalletAddressError = error => {
  return {
    type: REMOVE_WALLET_ADDRESS_FAIL,
    payload: error,
  }
}

export const updateUserStatus = (data, callback) => {
  return {
    type: UPDATE_USER_STATUS,
    payload: { data, callback },
  }
}

export const updateUserStatusSuccess = msg => {
  return {
    type: UPDATE_USER_STATUS_SUCCESS,
    payload: msg,
  }
}

export const updateUserStatusError = error => {
  return {
    type: UPDATE_USER_STATUS_FAIL,
    payload: error,
  }
}