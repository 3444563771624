export const FD_CATEGORY_API_FAIL = "FD_CATEGORY_API_FAIL"

// Categorys
export const GET_FD_CATEGORIES = "GET_FD_CATEGORIES"
export const GET_FD_CATEGORIES_FAIL = "GET_FD_CATEGORIES_FAIL"
export const GET_FD_CATEGORIES_SUCCESS = "GET_FD_CATEGORIES_SUCCESS"

// Add Category
export const ADD_FD_CATEGORY = "ADD_FD_CATEGORY"
export const ADD_FD_CATEGORY_FAIL = "ADD_FD_CATEGORY_FAIL"
export const ADD_FD_CATEGORY_SUCCESS = "ADD_FD_CATEGORY_SUCCESS"

// Get Category
export const GET_FD_CATEGORY = "GET_FD_CATEGORY"
export const GET_FD_CATEGORY_FAIL = "GET_FD_CATEGORY_FAIL"
export const GET_FD_CATEGORY_SUCCESS = "GET_FD_CATEGORY_SUCCESS"

// update Category
export const PUT_FD_CATEGORY = "PUT_FD_CATEGORY"
export const PUT_FD_CATEGORY_FAIL = "PUT_FD_CATEGORY_FAIL"
export const PUT_FD_CATEGORY_SUCCESS = "PUT_FD_CATEGORY_SUCCESS"

// Delete Category
export const DELETE_FD_CATEGORY = "DELETE_FD_CATEGORY"
export const DELETE_FD_CATEGORY_FAIL = "DELETE_FD_CATEGORY_FAIL"
export const DELETE_FD_CATEGORY_SUCCESS = "DELETE_FD_CATEGORY_SUCCESS"

// Update Multi Category Status
export const PUT_FD_CATEGORIES_STATUS = "PUT_FD_CATEGORIES_STATUS"
export const PUT_FD_CATEGORIES_STATUS_FAIL = "PUT_FD_CATEGORIES_STATUS_FAIL"
export const PUT_FD_CATEGORIES_STATUS_SUCCESS =
  "PUT_FD_CATEGORIES_STATUS_SUCCESS"

// Update Category Sorting
export const PUT_FD_CATEGORY_SORT = "PUT_FD_CATEGORY_SORT"
export const PUT_FD_CATEGORY_SORT_FAIL = "PUT_FD_CATEGORY_SORT_FAIL"
export const PUT_FD_CATEGORY_SORT_SUCCESS = "PUT_FD_CATEGORY_SORT_SUCCESS"

// Export FdCategories
export const EXPORT_FD_CATEGORIES = "EXPORT_FD_CATEGORIES"
export const EXPORT_FD_CATEGORIES_FAIL = "EXPORT_FD_CATEGORIES_FAIL"
export const EXPORT_FD_CATEGORIES_SUCCESS = "EXPORT_FD_CATEGORIES_SUCCESS"
