import {
    GET_IP_HISTORY,
    GET_IP_HISTORY_SUCCESS,
    GET_IP_HISTORY_FAIL,
} from "./actionTypes";


const INIT_STATE = {
    error: "",
    loading: false,
    ipHistory: [],
    totalHistory: 0
};

const IPHistory = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_IP_HISTORY:
            return {
                ...state,
                loading: true,
            };

        case GET_IP_HISTORY_SUCCESS:
            return {
                ...state,
                ipHistory: action.payload.data || [],
                totalHistory: action.payload.total || 0,
                loading: false,
                error: "",
            };

        case GET_IP_HISTORY_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export default IPHistory;