export const ACTIVITY_API_FAIL = "ACTIVITY_API_FAIL";

// ACTIVITYs
export const GET_ACTIVITIES = "GET_ACTIVITIES";
export const GET_ACTIVITIES_FAIL = "GET_ACTIVITIES_FAIL";
export const GET_ACTIVITIES_SUCCESS = "GET_ACTIVITIES_SUCCESS";

// Add ACTIVITY
export const ADD_ACTIVITY = "ADD_ACTIVITY";
export const ADD_ACTIVITY_FAIL = "ADD_ACTIVITY_FAIL";
export const ADD_ACTIVITY_SUCCESS = "ADD_ACTIVITY_SUCCESS";

// Get ACTIVITY
export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_ACTIVITY_FAIL = "GET_ACTIVITY_FAIL";
export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";

// update ACTIVITY
export const PUT_ACTIVITY = "PUT_ACTIVITY";
export const PUT_ACTIVITY_FAIL = "PUT_ACTIVITY_FAIL";
export const PUT_ACTIVITY_SUCCESS = "PUT_ACTIVITY_SUCCESS";

// Delete ACTIVITY
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const DELETE_ACTIVITY_FAIL = "DELETE_ACTIVITY_FAIL";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";

// Update Multi ACTIVITY Status
export const PUT_ACTIVITIES_STATUS = "PUT_ACTIVITIES_STATUS";
export const PUT_ACTIVITIES_STATUS_FAIL = "PUT_ACTIVITIES_STATUS_FAIL";
export const PUT_ACTIVITIES_STATUS_SUCCESS = "PUT_ACTIVITIES_STATUS_SUCCESS";


// Airdrop NFT
export const AIRDROP_NFT = "AIRDROP_NFT";
export const AIRDROP_NFT_FAIL = "AIRDROP_NFT_FAIL";
export const AIRDROP_NFT_SUCCESS = "AIRDROP_NFT_SUCCESS";

// Cancel NFT Sale
export const CANCEL_NFT_SALE = "CANCEL_NFT_SALE";
export const CANCEL_NFT_SALE_FAIL = "CANCEL_NFT_SALE_FAIL";
export const CANCEL_NFT_SALE_SUCCESS = "CANCEL_NFT_SALE_SUCCESS";