import * as TYPES from "./actionTypes";

const INIT_STATE = {
  stakes: [],
  totalStakes: 0,
  error: "",
  loading: false,
  stats: {},
};

const Stakes = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Stakes */
    case TYPES.GET_STAKES:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_STAKES_SUCCESS:
      return {
        ...state,
        stakes: action.payload.data || [],
        totalStakes: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case TYPES.GET_STAKES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get Stakes END */

    /* Stats */
    case TYPES.GET_STAKE_STATS:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_STAKE_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        stats: action.payload.data,
      };

    case TYPES.GET_STAKE_STATS_FAIL:
      return {
        ...state,
        loading: false,
      };
    /* Stats END */

    default:
      return state;
  }
};

export default Stakes;
