import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Collection Redux States
import {
    GET_IP_HISTORY,
} from "./actionTypes";

import {
    getIPHistoryFail,
    getIPHistorySuccess,
} from "./actions";

import {
    postGetIPHistory,
} from "helpers/backend_helper";


function* getIPHistorySaga({ payload }) {
    try {
        const response = yield call(postGetIPHistory, payload);
        if (response.status == "failure") {
            return yield put(getIPHistoryFail(response.message));
        }
        yield put(getIPHistorySuccess(response));
    } catch (error) {
        yield put(getIPHistoryFail(error));
    }
}

function* IPHistorySaga() {
    yield takeLatest(GET_IP_HISTORY, getIPHistorySaga);
}

export default IPHistorySaga;