import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { connect, useDispatch, useSelector } from "react-redux";

//i18n
import { withTranslation } from "react-i18next";
import { getItems, getPromoCodeUsers, getSettings } from "store/actions";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import NftModel from "./NftModel";
import toastr from "toastr";

const ConfirmModal = (props) => {
  const {
    isOpen,
    toggle,
    promoCodeId,
    onGetPromoCodeUsers,
    promoCodeUsers,
    onGetItems,
    items,
  } = props;
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.Settings);
  const [promoCodeUsersList, setPromoCodeUsersList] = useState([]);
  const [item, setItem] = useState([]);
  const [checkNft, setCheckNft] = useState(false);
  const [nftListModal, setNftListModal] = useState({
    isOpen: false,
    promoCodeId: null,
  });
  const [filter, setFilter] = useState({
    orderBy: "create_date",
    order: -1,
    page: 0,
    limit: 20,
    search: "",
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
  });
  useEffect(() => {
    dispatch(getSettings());
  }, []);
  useEffect(() => {
    onGetPromoCodeUsers({ promoCodeId: promoCodeId });
  }, [promoCodeId]);

  useEffect(() => {
    setPromoCodeUsersList(promoCodeUsers);
  }, [promoCodeUsers]);

  const getItem = (userId) => (e) => {
    e.preventDefault();
    setCheckNft(true);
    if (userId != "") {
      onGetItems({ ...filter, current_owner: userId });
    } else {
      toastr.error("No nft found!!");
    }
  };
  useEffect(() => {
    setItem(items);
    if (checkNft) {
      if (items.length > 0) {
        setNftListModal((prevState) => ({ isOpen: !prevState.isOpen, items }));
      } else {
        toastr.error("No nft found!!");
      }
    }
  }, [items]);
  const toggleNftModel = (e) => {
    setNftListModal((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  return (
    <>
      <NftModel
        isOpen={nftListModal.isOpen}
        toggle={toggleNftModel}
        items={nftListModal.items || ""}
        Settings={settings || {}}
      />
      <Modal
        size="lg"
        isOpen={isOpen}
        autoFocus={true}
        centered={true}
        toggle={toggle}
        scrollable="true"
      >
        <div className="modal-content remove-confirm position-relative">
          <div className="d-flex justify-content-end">
            <a href="javascript:void(0)" onClick={toggle} className="closebtn">
              <svg width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6L6.4 19Z"
                />
              </svg>
            </a>
          </div>
          <ModalBody>
            <Table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Email</th>
                  <th>Date</th>
                  <th>No. of NFT</th>
                  <th>Show NFT</th>
                </tr>
              </thead>
              <tbody>
                {promoCodeUsersList &&
                  promoCodeUsersList?.map((list, idx) => {
                    return (
                      <tr>
                        <td>{idx + 1}</td>
                        <td>{list.email}</td>
                        <td>
                          {moment(list.date_created_utc).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </td>
                        <td>{list?.itemList?.length}</td>
                        <td>
                          <a
                            href="#"
                            onClick={getItem(list?.walletAddress)}
                            className="text-secondary"
                          >
                            <i className="far fa-eye mr-1" />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};

ConfirmModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};
const mapStateToProps = ({ promoCodes, items }) => ({
  promoCodeUsers: promoCodes.promoCodeUsers,
  items: items.items,
});

const mapDispatchToProps = (dispatch) => ({
  onGetPromoCodeUsers: (data, callback) =>
    dispatch(getPromoCodeUsers(data, callback)),
  onGetItems: (data) => dispatch(getItems(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConfirmModal))
);
// export default withTranslation()(ConfirmModal);
