import { call, put, takeEvery } from "redux-saga/effects";

// Collection Redux States
import {
  GET_TOURNAMENTS_HISTORY,
  VIEW_TOURNAMENTS_HISTORY,
} from "./actionTypes";
import {
  getTournamentsHistorySuccess,
  getTournamentsHistoryFail,
  viewTournamentsHistoryFail,
  viewTournamentsHistorySuccess,
} from "./actions";

import {
  postGetTournament,
  postGetTournamentHistory,
  postViewTournamentHistory,
} from "helpers/backend_helper";

function* getTournamentHistorySaga({ payload, callback }) {
  try {
    const response = yield call(postGetTournamentHistory, payload);
    console.log(response, "all listrtttt");
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(getTournamentsHistoryFail(response.message));
    }
    yield put(getTournamentsHistorySuccess(response));
  } catch (error) {
    yield put(getTournamentsHistoryFail(error));
  }
}

function* viewTournamentHistorySaga({ payload, callback }) {
  console.log(payload, "viewpayloadSagaHistory");
  try {
    const response = yield call(postViewTournamentHistory, payload);
    console.log(response, "all listrtttt");
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(viewTournamentsHistoryFail(response.message));
    }
    yield put(viewTournamentsHistorySuccess(response));
  } catch (error) {
    yield put(getTournamentsHistoryFail(error));
  }
}

function* GameHistorySaga() {
  yield takeEvery(GET_TOURNAMENTS_HISTORY, getTournamentHistorySaga);
  yield takeEvery(VIEW_TOURNAMENTS_HISTORY, viewTournamentHistorySaga);
}

export default GameHistorySaga;
