import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import toastr from "toastr";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, useLocation } from "react-router-dom";

import {
  Media,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Button,
  Label,
  Container,
  Card,
  CardBody,
  Spinner,
  Alert,
  CardTitle,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import {
  getSettings,
  postCompileContractGov,
  postDeployContractGov,
} from "store/actions";
// web3
import Web3Intraction from "helpers/web3Intraction";

//
import Actions from "./Actions";

const TokenManagement = (props) => {
  const { onGetSettings, onPostDeployContractGov, loading, error } = props;
  // const { isPPG } = useParams();

  const [details, setdetails] = useState({ tokens: 0, sold: 0, unsold: 0 });
  const [fields, setFields] = useState({
    token: "",
    name: "",
    symbol: "",
    initialSupply: "",
    contractAddress: [],
  });

  let location = useLocation();

  const settings = useSelector((state) => state?.Settings?.settings);
  const latestWork = useSelector(
    (state) => state?.Settings?.settings?.tokenPpg?.contractAddress
  );

  const contractAddressData = useSelector(
    (state) => state?.Settings?.settings?.tokenPpg?.contractAddress
  );

  const tokenGov = useSelector((state) => state?.Settings?.settings?.tokenGov);

  useEffect(() => {
    onGetSettings();
  }, []);

  console.log(latestWork, 546456564);
  useEffect(() => {
    if (props.settings && props.settings.blockchain) {
      setFields({
        initialSupply: latestWork?.filter(
          (da) => da?.type == settings?.latestDeployNetwork
        ),
        contractAddress: tokenGov?.contractAddress?.filter(
          (da) => da?.type == settings?.latestDeployNetwork
        ),
      });
    }
  }, [latestWork, tokenGov]);

  useEffect(() => {
    if (props.settings && JSON.stringify(props.settings) !== "{}") {
      (async () => {
        const web3Intraction = new Web3Intraction(
          fields.initialSupply[0]?.type,
          props.settings,
          "getGovernanceToken"
        );
        const tokenDetails = await web3Intraction.getTokenInfoGov();
        console.log(tokenDetails, "tokenDetails");
        // setdetails(tokenDetails);
      })();
    }
  }, [props.settings]);

  const submitCompileGov = (evt) => {
    evt.preventDefault();

    props.onPostCompileContractGov(
      {
        type: [fields.initialSupply[0]?.type],
        token: fields.initialSupply[0]?.testnet,
      },
      (response) => {
        downloadSolidity(response);
        downloadSolidityJson(response);
      }
    );
  };

  const downloadSolidity = (response) => {
    let blob = new Blob([response.data.source], {
      type: "text/plain;charset=utf-8",
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Governance.sol`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
    URL.revokeObjectURL(url);
  };

  console.log(fields, 54645);
  const downloadSolidityJson = (response) => {
    const json = JSON.stringify(response.data.solidityConf, null, 2);
    const blob = new Blob([json], { type: "application/json" });

    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = "PiratesPets.json";
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  function deployGov(item, blockchainNetworkMode) {
    try {
      onPostDeployContractGov({
        ...item,
        tokenGov: tokenGov,
        blockchainNetworkMode,
      });
    } catch {
      throw new Error("Some Error Occurred!!");
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Settings" breadcrumbItem="Token Management" />
          <Card>
            <CardBody className="spinner-content">
              <CardTitle>Compile Governance Contract</CardTitle>
              <Col className="d-flex pl-0">
                {loading && <Spinner color="primary" className="" />}
                <Button
                  type="submit"
                  className="buttoncolor ml-0"
                  disabled={loading}
                  onClick={submitCompileGov}
                >
                  Compile
                </Button>
              </Col>
              <hr className="my-3" />

              {error && typeof error === "string" ? (
                <Alert color="danger">{error}</Alert>
              ) : null}

              {loading && (
                <div className="spinner">
                  <Spinner color="primary" className="mr-2" />
                </div>
              )}
              <CardTitle>Deploy Contract </CardTitle>

              <Form>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Contract Address (PPG)</Label>

                      <Input
                        value={fields.initialSupply[0]?.testnet}
                        // onChange={}
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col></Col>
                </Row>
              </Form>
            </CardBody>
          </Card>

          <Card>
            <CardBody className="spinner-content">
              {error && typeof error === "string" ? (
                <Alert color="danger">{error}</Alert>
              ) : null}

              {loading && (
                <div className="spinner">
                  <Spinner color="primary" className="mr-2" />
                </div>
              )}

              {fields?.contractAddress?.length > 0
                ? fields?.contractAddress.map((item, index) => (
                    <React.Fragment key={`idx-${item._id}-${index}`}>
                      <Row>
                        <Col xs={12}>
                          <Label>{item.type} Address</Label>
                        </Col>

                        <Col sm={12} md={6}>
                          <Label>Mainnet</Label>
                          <Button
                            color="success"
                            className="ml-2 mt-2"
                            onClick={() => deployGov(item, "mainnet")}
                          >
                            Deploy
                          </Button>

                          <h4>{item.mainnet || "N/A"}</h4>
                        </Col>

                        <Col sm={12} md={6}>
                          <Label>Testnet</Label>
                          <Button
                            color="success"
                            className="ml-2 mt-2"
                            onClick={() => deployGov(item, "testnet")}
                          >
                            Deploy
                          </Button>

                          <h4>{item.testnet || "N/A"}</h4>
                        </Col>
                      </Row>
                      <Actions item={item} details={details} />
                    </React.Fragment>
                  ))
                : fields?.initialSupply &&
                  fields?.initialSupply?.map((item, index) => {
                    return (
                      <>
                        <Row>
                          <Col xs={12}>
                            <Label>{item?.type} Address</Label>
                          </Col>
                          <Col sm={12} md={6}>
                            <Label>Mainnet</Label>
                            <Button
                              color="success"
                              className="ml-2 mt-2"
                              onClick={() => deployGov(item, "mainnet")}
                            >
                              Deploy
                            </Button>

                            <h4> {"N/A"}</h4>
                          </Col>

                          <Col sm={12} md={6}>
                            <Label>Testnet</Label>
                            <Button
                              color="success"
                              className="ml-2 mt-2"
                              onClick={() => deployGov(item, "testnet")}
                            >
                              Deploy
                            </Button>

                            <h4>{item?.testnet || "N/A"}</h4>
                          </Col>
                        </Row>
                        <hr className="my-3" />
                        {true && <Actions item={item} details={details} />}
                      </>
                    );
                  })}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

TokenManagement.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onPostCompileContract: (data, callback) =>
    dispatch(postCompileContract(data, callback)),
  onPostDeployContract: (data, callback) =>
    dispatch(postDeployContract(data, callback)),
  onPostCompileContractPpg: (data, callback) =>
    dispatch(postCompileContractPpg(data, callback)),
  onPostCompileContractGov: (data, callback) =>
    dispatch(postCompileContractGov(data, callback)),
  onPostDeployContractPpg: (data, callback) =>
    dispatch(postDeployContractPpg(data, callback)),
  onPostDeployContractGov: (data, callback) =>
    dispatch(postDeployContractGov(data, callback)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(TokenManagement))
);
