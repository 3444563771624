import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Row, Col, FormGroup, Label, Input } from "reactstrap"
import Instructions from "./Instructions"

const SupportSettings = (props) => {
  const { fields, handleChange } = props

  return (
    <React.Fragment>
      <Row>
        <Col lg={4}>
          <Instructions t={props.t} />
        </Col>

        <Col lg={8}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Email</Label>

                <Input
                  type="text"
                  value={fields.email}
                  onChange={handleChange("email")}
                />
              </FormGroup>
            </Col>

            {/* <Col md={6}>
              <FormGroup>
                <Label>Mobile Number</Label>

                <Input
                  type="text"
                  value={fields.mobileNumber}
                  onChange={handleChange("mobileNumber")}
                />
              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup>
                <Label>Address</Label>

                <Input
                  type="textarea"
                  value={fields.address}
                  onChange={handleChange("address")}
                  rows={4}
                />
              </FormGroup>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}

SupportSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
}

const mapStateToProps = ({}) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SupportSettings),
)
