import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const CustomersColumns = (toggleConfirmModal, toggleConfirmStatusModal, toggleShowCommissionReferral, t, adminRole) => [
  {
    dataField: "userID",
    text: t("userID"),
    sort: true,
    formatter: (_, row) => row.userID || "--",
  },
  {
    dataField: "name",
    text: t("Name"),
    sort: true,
    formatter: (_, row) => row.username || "--",
  },
  {
    dataField: "walletAddress",
    text: t("Wallet Address"),
  },
  {
    dataField: "email",
    text: t("Email"),
    sort: true,
    formatter: (_, row) => (row.email ? row.email : "-"),
  },
  {
    dataField: "isNftHolder",
    text: t("NFT"),
    sort: true,
    formatter: (_, row) => (
      <Badge
        className={`text-capitalize font-size-13 badge-soft- ${row.isNftHolder === true ? "nft" : (row?.isPirateHolder === true || row?.isShipHolder === true) ? "one-nft" : "non-nft"}`}
        color={row.badgeClass}>
        {t(row.isNftHolder === true ? "Yes" : (row?.isPirateHolder === true || row?.isShipHolder === true) ? "Yes" : "No")}
      </Badge>
    ),
  },
  {
    dataField: "walletBalance",
    text: t("Wallet Balance"),
    sort: true,
    formatter: (_, row) => (row.walletBalance ? row.walletBalance?.toFixed(2) : "0"),
  },
  {
    dataField: "promoCode",
    text: t("Promo Code"),
    sort: true,
    formatter: (_, row) => (row.promoCode ? row.promoCode : "-"),
  },
  {
    dataField: "country",
    text: t("Country"),
    sort: true,
    formatter: (_, row) => (row.country ? row.country : "-"),
  },
  (adminRole !== "SUBADMIN" &&{
    dataField: "userIpHistories",
    text: t("Ip Addresses"),
    sort: true,
    formatter: (_, row) => {
      const maxLimit = 5; // Change this value to set your desired maximum limit
      if (row.userIpHistories?.length > 0) {
        const uniqueIPs = [...new Set(row.userIpHistories.map(history => history.ipAddress))];
        const truncatedIPs = uniqueIPs.slice(0, maxLimit);
        const displayIPs = truncatedIPs.join(', ');
        if (uniqueIPs.length > maxLimit) {
          return displayIPs + '...';
        } else {
          return displayIPs;
        }
      } else {
        return "-";
      }
    }

  }),
  (adminRole !== "SUBADMIN" &&{
    dataField: "showCommissionReferral",
    text: t("Show Commission Referral"),
    sort: true,
    formatter: (cell, row) => (
      <BootstrapSwitchButton
        checked={cell}
        onlabel="On"
        offlabel="Off"
        onChange={(checked) => toggleShowCommissionReferral(row._id, checked)}
      />
    ),
  }),
  {
    text: t("Registered At"),
    dataField: "createdAt",
    sort: true,
    formatter: (_, row) =>
      row.date_created_utc ? (
        <>
          {moment(row.date_created_utc).local().format("YYYY-MM-DD HH:mm:ss")}
          {/* moment(row.date_created_utc).utcOffset(330).format("DD MMM YYYY LT") */}
        </>
      ) : (
        "-"
      ),
  },
  {
    text: t("Last Login At"),
    dataField: "lastLogin",
    sort: true,
    formatter: (_, row) =>
      row.lastLogin ? (
        <>
          {moment(row.lastLogin).local().format("YYYY-MM-DD HH:mm:ss")}
          {/* moment(row.lastLogin).utcOffset(330).format("DD MMM YYYY LT") */}
        </>
      ) : (
        "-"
      ),
  },
   {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <Link to={`/users/profile/${row._id}`} className="mr-3 text-secondary">
          <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />
          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            {t("View")} {t("Details")}
          </UncontrolledTooltip>
        </Link>

        { adminRole !== "SUBADMIN" && row.status && row.status == "active" && (
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              toggleConfirmStatusModal(row._id, "ban");
            }}
            className="text-secondary"
          >
            <i class="fa fa-ban  mr-3" aria-hidden="true" id={`inactive-${row._id}-tooltip`} />
            <UncontrolledTooltip
              placement="top"
              target={`inactive-${row._id}-tooltip`}
            >
              {t("Ban User")}
            </UncontrolledTooltip>
          </Link>
        )}
        { adminRole !== "SUBADMIN" && row.status && row.status == "inactive" && (
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              toggleConfirmStatusModal(row._id, "unban");
            }}
            className="text-secondary"
          >
            <i class="fa fa-check  mr-3" aria-hidden="true" id={`active-${row._id}-tooltip`} />
            <UncontrolledTooltip
              placement="top"
              target={`active-${row._id}-tooltip`}
            >
              {t("Unban User")}
            </UncontrolledTooltip>
          </Link>
        )}
        {/* {row.walletAddress && row.walletAddress != "" && (
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              toggleConfirmModal(row._id);
            }}
            className="text-secondary"
          >
            <i
              className="fas fa-trash-alt mr-3"
              id={`delete-${row._id}-tooltip`}
            />
            <UncontrolledTooltip
              placement="top"
              target={`delete-${row._id}-tooltip`}
            >
              {t("Remove wallet address")}
            </UncontrolledTooltip>
          </Link>
        )} */}

      </>
    ),
  },
];

export default CustomersColumns;
