import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";

//i18n38476
import { withTranslation } from "react-i18next";

import { getTournamentsHistory } from "store/actions";
import ListColumns from "./ListColumns";
import toastr from "toastr";

const TournamentHistoryList = ({
  TournamentsHistory,
  accessLevel,
  history,
  collections,
  totalTournaments,
  onGetCollections,
  onDeleteTournament,
  loading,
  ...props
}) => {
  const { id } = useParams();
  const [filter, setFilter] = useState({
    orderBy: "date_created_utc",
    order: -1,
    page: 1,
    limit: 20,
    search: "",
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
    user_id: id
  });
  const [TournamentList, setTournamentList] = useState([]);
  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalTournaments,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    onGetCollections(filter);
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    setTournamentList(TournamentsHistory || []);
  }, [TournamentsHistory]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
   
    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };


  return (
    <React.Fragment>
      <div className="page-content p-0">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={TournamentList.length > 0 ? TournamentList : []}
                        columns={ListColumns( props.t)}
                        bootstrap4
                     
                      >
                        {(toolkitProps) => (
                          <React.Fragment>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

// GamesList.propTypes = {
//   collections: PropTypes.array,
//   totalCollections: PropTypes.number,
//   onGetCollections: PropTypes.func,
//   onDeleteCollection: PropTypes.func,
//   onPutCollectionsStatus: PropTypes.func,
// };

const mapStateToProps = ({ TournamentHistory }) => ({
  loading: TournamentHistory.loading,
  TournamentsHistory: TournamentHistory.tournamentsHistory,
  totalTournaments: TournamentHistory.totaltournamentsHistory,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCollections: (data) => dispatch(getTournamentsHistory(data)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(TournamentHistoryList))
);
