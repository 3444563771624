import React, { useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Button
} from "reactstrap"
import { useTranslation } from "react-i18next"

//store
import {
  sendNotification,
} from "store/actions"

// /helper
import { NOTIFICATION_SEND_TYPE } from "../../../helpers/contants"

//Components
import Breadcrumbs from "components/Common/Breadcrumb";
import UserSelectors from "components/Select/User";
import toastr from "toastr";

const SendNotification = props => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch();
  const { loading } = useSelector(s => s.File)
  const history = useHistory();
  const [fields, setFields] = useState({
    title_en: "",
    title_ja: "",
    title_ru: "",
    message_en: "",
    message_ja: "",
    message_ru: "",
    notifyType: "all",
    type: "primary",
    userIds: []
  })

  const handleChange = (name, type) => event => {
    console.log(name, type, event);
    setFields(prevState => ({
      ...prevState,
      [name]: type == "selector" ? event : event.target.value,
    }))

  }

  const onSubmit = event => {
    console.log("onSubmit called ----->");
    event.preventDefault()
    if (loading) return;
    if (fields.notifyType === 'all') {
      fields.userIds = [];
    }
    else {
      fields.userIds = fields.userIds?.map((userId) => { return userId.value })
    }
    let data = {
      ...fields
    }
    const callback = (err, response) => {
      console.log("callback response ----->", response, err);
      if (err) {
        toastr.error(err)
      } 
      else {
        toastr.success(response.message)
        setFields({
          title_en: "",
          title_ja: "",
          title_ru: "",
          message_en: "",
          message_ja: "",
          message_ru: "",
          notifyType: "all",
          type: "primary",
          userIds: []
        })
        history.push("/manage-notifications")
      }
    }
    console.log("data ----->", data);
    dispatch(sendNotification(data, callback))
  }
  console.log("NOTIFICATION_SEND_TYPE", NOTIFICATION_SEND_TYPE);
  console.log("fields ------>", fields);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm={12} lg={12}>
              <Breadcrumbs
                title="Send Notification"
                breadcrumbItem={"Send Notification"}
                breadcrumbItems={[
                  { title: "Notifications", link: "/manage-notifications" },
                  { title: "Send Notification" }
                ]}
              />

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Form onSubmit={onSubmit} className="spinner-content">
                        <Row>

                          <Col md={6}>
                            <FormGroup>
                              <Label>{i18n.t("Title (en)")}</Label>

                              <Input
                                type="text"
                                className="form-control"
                                value={fields.title_en}
                                onChange={handleChange("title_en")}
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label>{i18n.t("Message (en)")}</Label>

                              <Input
                                type="text"
                                className="form-control"
                                value={fields.message_en}
                                onChange={handleChange("message_en")}
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label>{i18n.t("Title (ja)")}</Label>

                              <Input
                                type="text"
                                className="form-control"
                                value={fields.title_ja}
                                onChange={handleChange("title_ja")}
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label>{i18n.t("Message (ja)")}</Label>

                              <Input
                                type="text"
                                className="form-control"
                                value={fields.message_ja}
                                onChange={handleChange("message_ja")}
                              />
                            </FormGroup>
                          </Col>


                          <Col md={6}>
                            <FormGroup>
                              <Label>{i18n.t("Title (ru)")}</Label>

                              <Input
                                type="text"
                                className="form-control"
                                value={fields.title_ru}
                                onChange={handleChange("title_ru")}
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label>{i18n.t("Message (ru)")}</Label>

                              <Input
                                type="text"
                                className="form-control"
                                value={fields.message_ru}
                                onChange={handleChange("message_ru")}
                              />
                            </FormGroup>
                          </Col>

                          {/* <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {i18n.t("Type")}
                              </Label>
                              <Input
                                type="select"
                                value={fields.type}
                                onChange={handleChange("type")}
                                required
                              >
                                {" "}
                                {Object.entries(NOTIFICATION_SEND_TYPE).map(
                                  ([key, value], index) => (
                                    <option value={key} key={index}>
                                      {value}
                                    </option>
                                  )
                                )}{" "} 
                              </Input>
                            </FormGroup>
                          </Col> */}

                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {i18n.t("Notify Type")}
                              </Label>
                              <Input
                                type="select"
                                value={fields.notifyType}
                                onChange={handleChange("notifyType")}
                                required
                              >
                                {" "}
                                {Object.entries(NOTIFICATION_SEND_TYPE).map(
                                  ([key, value], index) => (
                                    <option value={key} key={index}>
                                      {value}
                                    </option>
                                  )
                                )}{" "}
                              </Input>
                            </FormGroup>
                          </Col>

                          {fields.notifyType == "selected" && (
                            <Col md={6}>
                              <FormGroup>
                                <Label>{i18n.t("selectUser")}</Label>
                                <UserSelectors
                                  value={fields.label}
                                  handleChange={handleChange("userIds", "selector")}
                                />
                              </FormGroup>
                            </Col>

                          )}
                        </Row>

                        <div className="mt-4">
                          <Button
                            type="button"
                            outline color="secondary"
                            onClick={onSubmit}
                          >
                            Submit
                          </Button>
                        </div>

                        {loading && (
                          <div className="spinner">
                            <Spinner color="primary" />
                          </div>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SendNotification
