import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { Card, CardBody, Col, Container, Row, Spinner, Input, Button } from "reactstrap";
import moment from 'moment';
//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import { getBalanceHistory } from "store/actions";
import ListColumns from "./ListColumns";


const RewardsHistoryList = ({
    BalanceHistory,
    totalBalance,
    onGetBalanceHistory,
    loading,
    ...props
}) => {
    const [filter, setFilter] = useState({
        orderBy: "date_created_utc",
        order: -1,
        page: 1,
        limit: 20,
        search: "",
        start_date: "",
        end_date: "",
        payment: "rewards",
        fields: [
            {
                fieldName: "",
                fieldValue: "",
            },
        ],
    });
    const [searchText, setSearchText] = useState("");
    const [BalanceList, setBalanceList] = useState([]);
    const pageOptions = {
        sizePerPage: 20,
        totalSize: totalBalance,
        custom: true,
        onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
    };

    useEffect(() => {
        onGetBalanceHistory(filter);
    }, [JSON.stringify(filter)]);

    // useEffect(() => {
    //   setBalanceList(BalanceHistory || []);
    // }, [BalanceHistory]);

    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
        if (type === "search") {
            setSearchText(searchText.trim());
        }

        if (type === "sort") {
            if (sortField == "createdAt") {
                sortField = "date_created_utc";
            }

            if (sortOrder == "desc") {
                sortOrder = -1;
            } else if (sortOrder == "asc") {
                sortOrder = 1;
            }

            setFilter((prevState) => ({
                ...prevState,
                orderBy: sortField,
                order: sortOrder,
            }));
        }
    };

    const onSearch = (event) => {
        event.preventDefault();
        setFilter((prevState) => ({ ...prevState, search: searchText.trim() }));
    };

    console.log(BalanceHistory, "=======> Rewards");
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Reward History"
                        breadcrumbItem={props.t("Reward History")}
                        breadcrumbItems={[{ title: props.t("Reward History") }]}
                    />

                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="_id"
                                                data={BalanceHistory.length > 0 ? BalanceHistory : []}
                                                columns={ListColumns(props.t)}
                                                bootstrap4
                                                search
                                            >
                                                {(toolkitProps) => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col sm="4">
                                                                <SearchInput
                                                                    {...toolkitProps.searchProps}
                                                                    triggerSearch={onSearch}
                                                                    placeholder={props.t("Search by email and username...")}
                                                                    searchText={searchText}
                                                                />
                                                            </Col>

                                                            <Col sm="2">
                                                                <div className="row mx-2">
                                                                    <label for="start">Start date:</label>
                                                                    <input
                                                                        type="date"
                                                                        id="start"
                                                                        name="reward-start"
                                                                        value={filter.start_date}
                                                                        onChange={(e) => setFilter((pre) => ({
                                                                            ...pre,
                                                                            start_date: moment(e.target.value).format('YYYY-MM-DD'),
                                                                        }))}
                                                                        min="2023-01-01"
                                                                        max={moment().format('YYYY-MM-DD')}
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col sm="2">
                                                                <div className="row mx-2">
                                                                    <label for="start">End date:</label>
                                                                    <input
                                                                        type="date"
                                                                        id="end"
                                                                        name="reward-end"
                                                                        value={filter.end_date}
                                                                        onChange={(e) => setFilter((pre) => ({
                                                                            ...pre,
                                                                            end_date: moment(e.target.value).format('YYYY-MM-DD'),
                                                                        }))}
                                                                        min="2023-01-01"
                                                                        max={moment().format('YYYY-MM-DD')}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            {/* <Col sm="2">
                                                                <Button
                                                                    type="submit"
                                                                    className="buttoncolor"
                                                                    disabled={loading}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setFilter((prevState) => ({
                                                                            ...prevState,
                                                                            start_date: "",
                                                                            end_date: ""
                                                                        }));
                                                                        setSearchText("");
                                                                    }}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            </Col> */}

                                                            <Col sm="2">
                                                                {!!filter.search || !!filter.end_date || !!filter.start_date ?(
                                                                    <div
                                                                        className="mr-4 mb-2"
                                                                        style={{
                                                                            height: "calc( 1.5em + 0.94rem + 2px)",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                        }}
                                                                    >
                                                                        <Link
                                                                            to="#"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                setFilter((prevState) => ({
                                                                                    ...prevState,
                                                                                    search: "",
                                                                                    payment: ""
                                                                                }));
                                                                                setSearchText("");
                                                                            }}
                                                                            className=""
                                                                        >
                                                                            {props.t("Clear Filters")}
                                                                        </Link>
                                                                    </div>
                                                                ):""}
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive spinner-content">
                                                                    <BootstrapTable
                                                                        responsive
                                                                        remote
                                                                        bordered={false}
                                                                        striped={false}
                                                                        classes={
                                                                            "table table-centered table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        onTableChange={handleTableChange}
                                                                        {...paginationTableProps}
                                                                        defaultSorted={[
                                                                            {
                                                                                dataField: "createdAt",
                                                                                order: "desc",
                                                                            },
                                                                        ]}
                                                                        sort={{
                                                                            sortCaret: (order) =>
                                                                                order === "asc" ? (
                                                                                    <span className="caret">
                                                                                        <i className="bx bx-caret-up" />
                                                                                    </span>
                                                                                ) : order === "desc" ? (
                                                                                    <span className="caret">
                                                                                        <i className="bx bx-caret-down" />
                                                                                    </span>
                                                                                ) : null,
                                                                        }}
                                                                    />

                                                                    {loading && (
                                                                        <div className="spinner">
                                                                            <Spinner color="primary" />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

RewardsHistoryList.propTypes = {
    BalanceHistory: PropTypes.array,
    totalBalance: PropTypes.number,
    onGetBalanceHistory: PropTypes.func,
};

const mapStateToProps = ({ AddBalance }) => ({
    loading: AddBalance?.loading,
    BalanceHistory: AddBalance?.balanceHistory,
    totalBalance: AddBalance?.totalBalance,
});

const mapDispatchToProps = (dispatch) => ({
    onGetBalanceHistory: (data) => dispatch(getBalanceHistory(data)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RewardsHistoryList))
);
