import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import Instructions from "./Instructions";

const Leaderboard = (props) => {
  const { fields, handleChange } = props;

  return (
    <React.Fragment>
      <Row>
        <Col lg={4}>
          <Instructions t={props.t} />
        </Col>

        <Col lg={8}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Status</Label>

                <div className="status-switch square-switch">
                  <input
                    type="checkbox"
                    id="square-switch-status"
                    switch="none"
                    checked={fields?.status == "active"}
                    onChange={() => {
                      const value =
                        fields?.status == "active" ? "inactive" : "active";

                      handleChange("status")({ target: { value } });
                    }}
                  />
                  <label
                    htmlFor="square-switch-status"
                    data-on-label={"active"}
                    data-off-label={"inactive"}
                  />
                </div>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Week Count</Label>

                <Input
                  type="number"
                  value={fields?.weekCount}
                  onChange={(e) => {
                    handleChange("weekCount")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>

          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

Leaderboard.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({ }) => ({});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Leaderboard)
);
