import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import { BLOCKCHAIN_CURRENCY_CODE } from "helpers/contants";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
    <input type="checkbox" className="custom-control-input" {...rest} />
    <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (history, toggleConfirmModal, toggleConfirmModal2, accesses, t) => [
  {
    dataField: "name",
    text: t("Name"),
    // formatter: (_, row) =>
    //   ![undefined, null].includes(row?.copy)
    //     ? row.name + " " + row?.copy
    //     : row.name,
  },
  {
    dataField: "price",
    text: t("Price"),
    formatter: (_, row) =>
      parseFloat(Number(row.price).toFixed(8)) +
      " " +
      BLOCKCHAIN_CURRENCY_CODE[row.blockchain],
  },
  // {
  //   dataField: "current_owner.name",
  //   text: t("Current Owner"),
  //   formatter: (_, row) => (
  //     <Link to={`/users/profile/${row.current_owner?._id}`}>
  //       {row.current_owner?.username}
  //     </Link>
  //   ),
  // },

  {
    dataField: "author_id.walletAddress",
    text: t("Author Address"),
  },
  {
    dataField: "tokenId",
    text: t("Token Id"),
    formatter: (_, row) => row.token_id,
  },
  {
    dataField: "current_owner.walletAddress",
    text: t("Current Owner Address"),
    formatter: (_, row) =>
      row?.current_owner?.walletAddress
        ?
        <Link to={`/users/profile/${row?.current_owner?._id}`}>
          {row.current_owner.walletAddress}
        </Link>
        : null
  },
  {
    dataField: "visible",
    text: t("Visible"),
    formatter: (cellContent, row) => (
      <Badge
        className={
          "text-capitalize font-size-13 badge-soft-" +
          (row.visible == "yes"
            ? "success"
            : row.visible == "no"
              ? "danger"
              : "danger")
        }
        color={row.badgeClass}
      >
        {row.visible}
      </Badge>
    ),
  },
  {
    dataField: "status",
    text: t("Mint Status"),
    formatter: (cellContent, row) => (
      <Badge
        className={
          "text-capitalize font-size-13 badge-soft-" +
          (row.receiptStatus == "success"
            ? "success"
            : row.receiptStatus == "process"
            ? "process"
            : row.receiptStatus == "reject"
              ? "warning"
              : "danger")
        }
        color={row.badgeClass}
      >
        {t(row.receiptStatus)}
      </Badge>
    ),
  },
  {
    text: t("Created At"),
    dataField: "create_date",
    sort: true,
    formatter: (_, row) => moment(row.create_date).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        {row.receiptStatus != "success" && (
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              toggleConfirmModal(row);
            }}
            className="text-secondary"
          >
            <i
              className="fas fa-trash-alt mr-1"
              id={`delete-${row._id}-tooltip`}
            />
            <UncontrolledTooltip
              placement="top"
              target={`delete-${row._id}-tooltip`}
            >
              {t("delete")}
            </UncontrolledTooltip>
          </Link>
        )}
        <Link
          to={`/adminitems/view/${row._id}`}
          className="mr-2 text-secondary"
        >
          <i className="far fa-eye mr-1" id={`view-${row._id}-tooltip`} />

          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            View
          </UncontrolledTooltip>
        </Link>{" "}
        {
          row.receiptStatus == "pending" &&
          <Link to={`/items/change/${row._id}`} className="mr-2 text-secondary">
          <i className="far fa-edit mr-1" id={`view-${row._id}-tooltip`} />

          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            Preview
          </UncontrolledTooltip>
        </Link>
        }
        {row.receiptStatus == "pending" && (
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              toggleConfirmModal2(row);
            }}
            className="text-secondary"
          >
            <i
              className="far fa-edit mr-1"
              id={`airdrop-${row._id}-tooltip`}
            />
            <UncontrolledTooltip
              placement="top"
              target={`airdrop-${row._id}-tooltip`}
            >
              {t("Temp Airdrop")}
            </UncontrolledTooltip>
          </Link>
        )}
      </>
    ),
  },
];

export default ListColumns;
