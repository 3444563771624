import { call, put, takeLatest, takeEvery } from "redux-saga/effects";

// Collection Redux States
import {
    GET_LANGUAGE_LIST,
} from "./actionTypes";

import {
    getLanguageListFail,
    getLanguageListSuccess,
} from "./actions";

import {
    postGetLanguageList,
} from "helpers/backend_helper";
  

function* getLanguageSaga({ payload, callback }) {
    try {
      const response = yield call(postGetLanguageList, payload);
      callback && callback(response);
      if (response.status == "failure") {
        return yield put(getLanguageListFail(response.message));
      }
      yield put(getLanguageListSuccess(response));
    } 
    catch (error) {
      yield put(getLanguageListFail(error));
    }
}


function* LanguageSaga() {
  yield takeEvery(GET_LANGUAGE_LIST, getLanguageSaga);
}
  
export default LanguageSaga;