import PropTypes from "prop-types";
import React, { useState,useEffect } from "react";
import Lightmode from "components/Lightmode";

import { connect, useSelector } from "react-redux";
// import { Row, Col } from "reactstrap"

import { Link } from "react-router-dom";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logoLightPng from "../../assets/images/logo.png";
import logoDark from "../../assets/images/logo.png";
import mini_logoDark from "../../assets/images/mini-logo.png";
import logodark from "../../assets/images/logo.png";
import logodark2x from "../../assets/images/logoopen.png";
import logoheader from "../../assets/images/logo.png";
import logoheader2x from "../../assets/images/logoopen.png";

import {
  getNetworkUrl,
} from "helpers/contants";
//i18n
import { withTranslation } from "react-i18next";
import { walletHooks } from "helpers/Wallet/walletHooks";
// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";

import { ROLES } from "helpers/contants";

const Header = (props) => {
  const { settings } = useSelector(state => state.Settings)
  // console.log("props", settings, props.getSettings)
  const wallet = walletHooks();
  /* const [search, setsearch] = useState(false)
  const [megaMenu, setmegaMenu] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false) */

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
// console.log("wallet?.isActive",wallet?.isActive)
  const onConnectWallet = async (e) => {
    e.preventDefault();
    // e.preventDefault();
    const networkUrl = getNetworkUrl("BNB", settings);
    wallet.connectWallet();
    await wallet.switchChain(networkUrl.chainId);
  }
  useEffect(()=>{
    (async()=>{
   console.log("settings",settings)

      if(JSON.stringify(settings) !== "{}"){
        const networkUrl = getNetworkUrl("BNB", settings);

        wallet.connectWallet();
        //   chainId,
    // chainName,
    // nativeCurrency,
    // rpcUrls,
    // blockExplorerUrls,
        // await wallet.switchChain({networkUrl});
      }
    })(); 
  },[settings]);
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu);
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    }
  }
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div id="site-logo" className="clearfix">
              <div id="site-logo-inner">
                {/* -----------> */}
                <Link to="/dashboard" className="logo logo-dark">
                  <span className="logo-sm">
                    <img
                      id="logo_header"
                      src={logodark}
                      alt=""
                      height="19"
                    // alt="nft-gaming"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      id="logo_header"
                      src={logodark}
                      alt=""
                      height="33"
                    // style={{
                    //   width: "150px",
                    //   height: "50%",
                    //   padding: "1px 36px 3px 42px",
                    // }}
                    // alt="nft-gaming"
                    />
                  </span>
                </Link>

                {/* <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img id="logo_header" src={logodark} alt="" height="22" alt="nft-gaming" />
                </span>
                <span className="logo-lg">
                  <img id="logo_header" src={logodark} alt="" height="50" width="100%" alt="nft-gaming" />
                </span>
              </Link> */}
                {/* ------ */}
                {/* <Link to="/" rel="home" className="main-logo">
                    <img
                      className="logo-dark"
                      id="logo_header"
                      src={logodark}
                      srcSet={`${logodark2x}`}
                      alt="nft-gaming"
                    />
                    <img
                      className="logo-light"
                      id="logo_header"
                      src={logoheader}
                      srcSet={`${logoheader2x}`}
                      alt="nft-gaming"
                    />
                  </Link> */}
              </div>
            </div>
            {/* <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={mini_logoDark} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" style={{ width: "65%" }} />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightPng} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLightPng} alt="" height="50" width="100%" />
                </span>
              </Link>
            </div> */}

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>

          <div className="d-flex align-items-center">
            {/* {props?.role !== ROLES.vendor && <LanguageDropdown />} */}

            {/* <div className="dropdown d-none d-lg-inline-block ml-1">
              <Link to="/settings">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i class="fas fa-language"></i>
              </button>
              </Link>
            </div> */}
            <div className="p-2">
              {!wallet?.isActive ? (
                <button onClick={onConnectWallet} className="badge badge-success p-2" style={{ "cursor": "pointer" }}>
                  Connect Metamask
                </button>
              ) : (
                <button className="badge badge-success p-2" style={{ "cursor": "pointer" }}>
                  Metamask Connected
                </button>
              )}
              {/* <div className="metamask-button d-flex align-items-center">
              {!wallet?.isActive ? (
                <span className="badge badge-success p-2" onClick={onConnectWallet}>
                  Connect metamask
                </span>
              ) : (
                <span className="badge badge-success p-2">
                  Metamask Connected
                </span>
              )}
            </div> */}
            </div>
            <div className="btnbottom position-relative">
              <div className="mide_div">
                <Lightmode />
              </div>
            </div>

            <div className="dropdown d-none d-lg-inline-block ml-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            {/* {props?.role !== ROLES.vendor && <NotificationDropdown />} */}
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
    role: state?.Login?.user?.role || "",
    storeID: state?.Settings?.settings?.slug || "#",
  };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
