import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});
const paymentValues = {
  "addByAdmin": "Added By Admin",
  "reduceByAdmin": "Reduced By Admin",
  "winning": "Winning",
  "others": "Others",
  "purchased": "Purchased Crystals",
  "withdraw": "Withdraw",
  "blocked": "Blocked",
  "unblocked": "Unblocked",
  "sailRewards": "Sail Rewards",
  "rewards": "Rewards",
  "commission": "Commission", 
  "others":"Others",
  "buyNFT":"Purchased NFT",
  "buyEnergy":"Purchased Energy",
  "mobileGame":"LP Game",
  "nftLevelUpgrade":"NFT Level Upgrade",
  "repairNft":"NFT Repair"
}

const typeValues = {
  "credit": "Credit",
  "debit": "Debit"
}
const categoryValues = {
  "ppt": "PPT",
  "crystal": "CRY"
}

const ListColumns = (t) => [
  {
    dataField: "user_id.email",
    text: t("Email"),
    formatter: (_, row) => (row.user_id?.email ? row.user_id?.email : "-"),
  },
  {
    dataField: "user_id.username",
    text: t("Username"),
    formatter: (_, row) => (row.user_id?.username ? row.user_id?.username : "-"),
  },

  {
    dataField: "payment",
    text: t("Payment"),
    formatter: (_, row) => (row.payment ? paymentValues[row.payment] : "-"),
  },
  {
    dataField: "type",
    text: t("Type"),
    formatter: (_, row) => (row.type ? typeValues[row.type] : "-"),
  },
  {
    text: t("Amount"),
    dataField: "amount",
    formatter: (_, row) => (row.amount ? row.amount.toFixed(2) : "0"),
  },
  {
    dataField: "prevBalance",
    text: t("Previous Balance"),
    formatter: (cellContent, row) => (
      row.prevBalance ? <>
        {row.prevBalance?.toFixed(2)}
        <Badge className={`m-1 text-capitalize font-size-13 badge-soft- ${row?.category === "crystal" ? "cry" : "ppt"}`} >
          {t(categoryValues[row?.category])}
        </Badge>
      </> : "0"
    ),
  },
  
  {
    dataField: "newBalance",
    text: t("New Balance"),
    formatter: (cellContent, row) => (
      row.newBalance ? <>
        {row.newBalance?.toFixed(2)}
        <Badge className={`m-1 text-capitalize font-size-13 badge-soft- ${row?.category === "crystal" ? "cry" : "ppt"}`} >
          {t(categoryValues[row?.category])}
        </Badge>
      </> : "0"
    ),
  },
  {
    text: t("matchID"),
    dataField: "MatchID",
    formatter: (_, row) => (row.matchID ? row.matchID : "-"),
  },
  {
    text: t("Created At"),
    dataField: "date_created_utc",
    sort: true,
    formatter: (_, row) => moment(row.date_created_utc).format("YYYY-MM-DD HH:mm:ss"),
  },

];

export default ListColumns;
