import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import toastr from "toastr";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, useLocation } from "react-router-dom";

import {
  Media,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Button,
  Label,
  Container,
  Card,
  CardBody,
  Spinner,
  Alert,
  CardTitle,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import {
  getSettings,
  postCompileContract,
  postDeployContract,
  postCompileContractPpg,
  postDeployContractPpg,
} from "store/actions";
// web3
import Web3Intraction from "helpers/web3Intraction";

//
import Actions from "./Actions";

const BLOCKCHAIN_CONTRACT = (blockchain, contractAddress) => {
  return blockchain?.map((item) => {
    let contract = contractAddress?.find(
      (address) => address.type === item.type
    );

    if (!contract) {
      return {
        label: item.label,
        type: item.type,
        mainnet: "",
        testnet: "",
      };
    }

    contract.label = item.label;
    return contract;
  });
};

const TokenManagement = (props) => {
  const {
    onGetSettings,
    onPostDeployContractPpg,
    onPostDeployContract,
    loading,
    error,
  } = props;
  // const { isPPG } = useParams();

  const [details, setdetails] = useState({ tokens: 0, sold: 0, unsold: 0 });
  const [fields, setFields] = useState({
    name: "",
    symbol: "",
    initialSupply: "",
    contractAddress: [],
  });
  let location = useLocation();

  useEffect(() => {
    onGetSettings();
  }, []);
  useEffect(() => {
    if (props.settings && props.settings.blockchain) {
      setFields({
        name: location?.pathname?.includes("ppg")
          ? props.settings.tokenPpg?.name
          : props.settings.token?.name,
        symbol: location?.pathname?.includes("ppg")
          ? props.settings.tokenPpg?.symbol
          : props.settings.token?.symbol,
        initialSupply: location?.pathname?.includes("ppg")
          ? props.settings.tokenPpg?.initialSupply
          : props.settings.token?.initialSupply,
        contractAddress: BLOCKCHAIN_CONTRACT(
          props.settings.blockchain,
          location?.pathname?.includes("ppg")
            ? props.settings.tokenPpg?.contractAddress
            : props.settings.token?.contractAddress
        ),
      });
    }
  }, [JSON.stringify(props?.settings)]);

  useEffect(() => {
    if (props.settings && JSON.stringify(props.settings) !== "{}") {
      (async () => {
        const web3Intraction = new Web3Intraction("MATIC", props.settings);
        const tokenDetails = await web3Intraction.getTokenInfo();

        setdetails(tokenDetails);
      })();
    }
  }, [props.settings]);

  const handleChange = (name) => (event) => {
    const { value } = event.target;

    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  const submitCompile = (evt) => {
    evt.preventDefault();

    props.onPostCompileContract({ type: "polygon", ...fields }, (response) => {
      downloadSolidity(response);
      downloadSolidityJson(response);
    });
  };
  const submitCompilePpg = (evt) => {
    evt.preventDefault();

    props.onPostCompileContractPpg(
      { type: "polygon", ...fields },
      (response) => {
        downloadSolidity(response, "ppg");
        downloadSolidityJson(response);
      }
    );
  };

  const downloadSolidity = (response, type) => {
    let blob = new Blob([response.data.source], {
      type: "text/plain;charset=utf-8",
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    if (type == "ppg") {
      link.setAttribute("download", `PPG.sol`);
    } else {
      link.setAttribute("download", `PPT.sol`);
    }

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const downloadSolidityJson = (response) => {
    const json = JSON.stringify(response.data.solidityConf, null, 2);
    const blob = new Blob([json], { type: "application/json" });

    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = "PiratesPets.json";
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  function deploy(item, blockchainNetworkMode) {
    try {
      onPostDeployContract({
        ...item,
        blockchainNetworkMode,
      });
    } catch {
      throw new Error("Some Error Occurred!!");
    }
  }

  function deployPpg(item, blockchainNetworkMode) {
    // console.log("test");
    try {
      onPostDeployContractPpg({
        ...item,
        blockchainNetworkMode,
      });
    } catch {
      throw new Error("Some Error Occurred!!");
    }
  }
  // console.log(isPPG, "isPPG");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Settings" breadcrumbItem="Token Management" />

          <Row>
            <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted font-weight-medium">
                        Total Tokens
                      </p>
                      <h4 className="mb-0">
                        {props.settings.token?.initialSupply}
                      </h4>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted font-weight-medium">
                        Sold Tokens
                      </p>
                      <h4 className="mb-0">{details.sold}</h4>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted font-weight-medium">
                        Unsold Tokens
                      </p>
                      <h4 className="mb-0">{details.unsold}</h4>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody className="spinner-content">
              <CardTitle>Compile Token Contract</CardTitle>

              {error && typeof error === "string" ? (
                <Alert color="danger">{error}</Alert>
              ) : null}

              {loading && (
                <div className="spinner">
                  <Spinner color="primary" className="mr-2" />
                </div>
              )}

              <Form
                onSubmit={
                  location?.pathname?.includes("ppg")
                    ? submitCompilePpg
                    : submitCompile
                }
              >
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Name</Label>

                      <Input
                        value={fields.name}
                        onChange={(e) => {
                          handleChange("name")(e);
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup>
                      <Label>Symbol</Label>

                      <Input
                        value={fields.symbol}
                        onChange={(e) => {
                          handleChange("symbol")(e);
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup>
                      <Label>Initial Supply</Label>

                      <Input
                        value={fields.initialSupply}
                        onChange={(e) => {
                          handleChange("initialSupply")(e);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <hr className="my-3" />

                <Row>
                  <Col></Col>

                  <Col className="d-flex justify-content-end">
                    {loading && <Spinner color="primary" className="mr-2" />}
                    <Button
                      type="submit"
                      className="buttoncolor"
                      disabled={loading}
                    >
                      Compile
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>

          <Card>
            <CardBody className="spinner-content">
              {error && typeof error === "string" ? (
                <Alert color="danger">{error}</Alert>
              ) : null}

              {loading && (
                <div className="spinner">
                  <Spinner color="primary" className="mr-2" />
                </div>
              )}

              {fields.contractAddress.map((item, index) => (
                <React.Fragment key={`idx-${item._id}-${index}`}>
                  <Row>
                    <Col xs={12}>
                      <Label>{item.label} Address</Label>
                    </Col>

                    <Col sm={12} md={6}>
                      <Label>Mainnet</Label>
                      <Button
                        color="success"
                        className="ml-2 mt-2"
                        onClick={() => deploy(item, "mainnet")}
                      >
                        Deploy
                      </Button>
                      <h4>{item.mainnet || "N/A"}</h4>
                    </Col>

                    <Col sm={12} md={6}>
                      <Label>Testnet</Label>
                      <Button
                        color="success"
                        className="ml-2 mt-2"
                        onClick={
                          location?.pathname?.includes("ppg")
                            ? () => deployPpg(item, "testnet")
                            : () => deploy(item, "testnet")
                        }
                      >
                        Deploy
                      </Button>

                      <h4>{item.testnet || "N/A"}</h4>
                    </Col>
                  </Row>

                  {/* <hr className="my-3" />
                  {!location?.pathname?.includes("ppg") && (
                    <Actions item={item} details={details} />
                  )} */}
                </React.Fragment>
              ))}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

TokenManagement.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onPostCompileContract: (data, callback) =>
    dispatch(postCompileContract(data, callback)),
  onPostDeployContract: (data, callback) =>
    dispatch(postDeployContract(data, callback)),
  onPostCompileContractPpg: (data, callback) =>
    dispatch(postCompileContractPpg(data, callback)),
  onPostDeployContractPpg: (data, callback) =>
    dispatch(postDeployContractPpg(data, callback)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(TokenManagement))
);
