import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import { ACTIVITY_TYPE, BLOCKCHAIN_CURRENCY_CODE } from "helpers/contants";
const STATUS = {
  "pending": "Pending",
  "success": "Success",
  "cancelled": "Cancelled"
}
export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (history, toggleConfirmModal, toggleCancelModal, t, settings, adminRole) => [
  {
    dataField: "history_type",
    text: t("Type"),
    formatter: (_, row) => {
      return (
        <>
          <p>{row?.history_type || "----"}</p>
        </>
      );
    },
  },
  {
    dataField: "from.email",
    text: t("Email"),
    formatter: (_, row) => {
      return (
        <>
          <p>{row?.from?.email || "----"}</p>
        </>
      );
    },
  },

  {
    dataField: "from.username",
    text: t("Username"),
    formatter: (_, row) => {
      return (
        <>
          <p>{row?.from?.username || "----"}</p>
        </>
      );
    },
  },
  {
    dataField: "from.walletAddress",
    text: t("WalletAddress"),
    formatter: (_, row) => {
      return (
        <>
          <p>{row?.from?.walletAddress || "----"}</p>
        </>
      );
    },
  },
  {
    dataField: "item.name",
    text: t("Item Name"),
    formatter: (_, row) => {
      return (
        <>
          <p>{row?.item?.name || "----"}</p>
        </>
      );
    },
  },
  {
    dataField: "transferStatus",
    text: t("Transfer Status"),
    formatter: (_, row) => {
      return (
        <Badge
          className={ "text-capitalize font-size-13 badge-soft-" + (row?.transferStatus == "success"
            ? "success"
            : row?.transferStatus == "pending"
              ? "warning"
              : "danger")}
          color={row.badgeClass}
        >
          {t(STATUS[row?.transferStatus])}
        </Badge>
      )
    },
  },
  {
    text: t("Created Date"),
    dataField: "date_created_utc",
    sort: true,
    formatter: (_, row) => (
      <>
        {/* moment(row.created_date).format("DD MMM YYYY hh:mm A"), */}
        {moment(row.created_date).local().format("YYYY-MM-DD HH:mm:ss")}
      </>
    ),
  },
  {
    dataField: "transaction_hash",
    text: t("TnxHash"),
    formatter: (_, row) => {
      return (
        <>
          <p>
            <a
              href={
                settings?.blockchainNetworkMode == "mainnet"
                  ? `https://bscscan.com/tx/${row?.transaction_hash}`
                  : `https://testnet.bscscan.com/tx/${row?.transaction_hash}`
              }
              target={"_blank"}
            >
              {row?.transaction_hash || "-"}
            </a>
          </p>
        </>
      );
    },
  },
  (adminRole !== "SUBADMIN" && { 
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <Link to="#"
          onClick={(e) => {
            e.preventDefault();
            toggleConfirmModal(row?.item, row?.from?.walletAddress);
          }} className="mr-2 text-secondary">
          <i className="far fa-edit mr-1" id={`airdrop-${row._id}-tooltip`} />

          <UncontrolledTooltip
            placement="top"
            target={`airdrop-${row._id}-tooltip`}
          >
            Airdrop
          </UncontrolledTooltip>
        </Link>


          {row?.transferStatus === "pending"  && 
          <Link to="#"
          onClick={(e) => {
            e.preventDefault();
            toggleCancelModal(row?.item_id);
          }} className="mr-2 text-secondary">
          <i className="fas fa-ban mr-1" id={`cancel-${row._id}-tooltip`} />

          <UncontrolledTooltip
            placement="top"
            target={`cancel-${row._id}-tooltip`}
          >
            Cancel Sale
          </UncontrolledTooltip>
        </Link>}

      </>
    ),
  }),
];

export default ListColumns;
