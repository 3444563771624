import {
    GET_LANGUAGE_LIST,
    GET_LANGUAGE_LIST_SUCCESS,
    GET_LANGUAGE_LIST_FAIL,
} from "./actionTypes";


const INIT_STATE = {
    error: "",
    loading: false,
    languages: [],
    total: 0,
};


const Languages = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_LANGUAGE_LIST:
        return {
          ...state,
          loading: true,
        };
  
      case GET_LANGUAGE_LIST_SUCCESS:
        return {
          ...state,
          languages: action.payload.data || [],
          total: action.payload.total || 0,
          loading: false,
          error: "",
        };
  
      case GET_LANGUAGE_LIST_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
    
        default:
        return state;
  }
};

export default Languages;