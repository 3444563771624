import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";

import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Row,
    Spinner,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import {
    getPendingWithdraw,
    withdrawStatus

} from "store/actions";
import ListColumns, { selectRow } from "./ListColumns";
import ConfirmModal from "./ConfirmModal";
import RejectModal from "./RejectModal";


import toastr from "toastr";
const WithdrawList = ({
    accessLevel,
    history,
    items,
    totalItems,
    onGetPendingWithdraw,
    onGetWithrawStatus,
    onDeleteItem,
    onPutItemsStatus,
    loading,
    onDeployItem,
    ...props
}) => {
    const [searchText, setSearchText] = useState("");

    const dispatch = useDispatch();
    const categories = useSelector((store) => store?.Categoryes?.fdCategories);
    const [accesses, setaccesses] = useState({
        canAdd: false,
        canEdit: false,
        canDelete: false,
        canBlock: false,
    });
    const [selected, setSelected] = useState([]);

    const [filter, setFilter] = useState({
        page: 0,
        limit: 20,
        filter: "pending",
        user_id: "",
        search: ""
    });

    const [confirmModal, setConfirmModal] = useState({
        isOpen: false,
        withdrawId: null,
    });

    const [rejectModal, setRejectModal] = useState({
        isOpen: false,
        withdrawId: null,
    });

    const [itemsList, setItemsList] = useState([]);

    const pageOptions = {
        sizePerPage: 20,
        totalSize: totalItems,
        custom: true,
        onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
    };


    // useEffect(() => {
    //   onGetPendingWithdraw(filter);
    // }, []);


    useEffect(() => {
        onGetPendingWithdraw(filter);
    }, [JSON.stringify(filter)]);

    useEffect(() => {
        setItemsList(items || []);
    }, [items]);

    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { searchText, sortField, sortOrder, searchToken }) => {

        if (type === "search") {
            setSearchText(searchText.trim());
        }
        if (type === "sort") {
            if (sortField == "createdAt") {
                sortField = "create_date";
            }

            if (sortOrder == "desc") {
                sortOrder = -1;
            } else if (sortOrder == "asc") {
                sortOrder = 1;
            }

            setFilter((prevState) => ({
                ...prevState,
                orderBy: sortField,
                order: sortOrder,
            }));
        }
    };



    const removeConfirm = () => {
        const { withdrawId, isOpen } = confirmModal;
        const callback = (res) => {
            if (res.status == "success") {
                toastr.success("Withdraw request approved successfully!");
                window.location.reload();
            } else {
                toastr.error("Withdraw request not approved!");
            }
        };
        setConfirmModal({ isOpen: !isOpen, withdrawId: null });
        onGetWithrawStatus({
            withdrawRequestId: withdrawId,
            status: "confirmed"
        }, callback);
    };


    const onReject = () => {
        const { withdrawId, isOpen } = rejectModal;
        const callback = (res) => {
            console.log("mohittttttttttttttt", res)
            if (res.status == "success") {
                toastr.success("Withdraw request cancelled successfully!");
                window.location.reload();
            } else {
                toastr.error("Withdraw request not cancelled!");
            }
        };
        setRejectModal({ isOpen: !isOpen, withdrawId: null });
        onGetWithrawStatus({
            withdrawRequestId: withdrawId,
            status: "rejected"
        }, callback);
    };

    const toggleConfirmModal = (withdrawId) => {
        setConfirmModal((prevState) => ({ isOpen: !prevState.isOpen, withdrawId }));
    };


    const toggleRejectModal = (withdrawId) => {
        setRejectModal((prevState) => ({ isOpen: !prevState.isOpen, withdrawId }));
    };


    const onSearch = (event) => {
        event.preventDefault();

        setFilter((prevState) => ({ ...prevState, search: searchText.trim() }));
    };
    return (
        <React.Fragment>

            <ConfirmModal
                isOpen={confirmModal.isOpen}
                toggle={toggleConfirmModal}
                onConfirm={removeConfirm}
            />

            <RejectModal
                isOpen={rejectModal.isOpen}
                toggle={toggleRejectModal}
                onConfirm={onReject}
            />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Pending Withdraw"
                        breadcrumbItem={props.t("Withdraw Pending Requests")}
                        breadcrumbItems={[{ title: props.t("Withdraw Pending Requests") }]}
                    />

                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="_id"
                                                data={itemsList || []}
                                                columns={ListColumns(
                                                    toggleConfirmModal,
                                                    toggleRejectModal,
                                                    props.t,
                                                    "text"
                                                )}
                                                bootstrap4
                                                search

                                            >
                                                {(toolkitProps) => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">

                                                            <Row className="mb-2">
                                                                <Col md="4" lg="3">
                                                                    <SearchInput
                                                                        {...toolkitProps.searchProps}
                                                                        triggerSearch={onSearch}
                                                                        placeholder={props.t("Search")}
                                                                        searchText={searchText}
                                                                    />
                                                                </Col>

                                                                <Col sm="2">
                                                                    {!!filter.search && (
                                                                        <div
                                                                            className="mr-4 mb-2"
                                                                            style={{
                                                                                height: "calc( 1.5em + 0.94rem + 2px)",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <Link
                                                                                to="#"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    setFilter((prevState) => ({
                                                                                        ...prevState,
                                                                                        search: "",
                                                                                    }));
                                                                                    setSearchText("");
                                                                                }}
                                                                                className=""
                                                                            >
                                                                                {props.t("Clear Filters")}
                                                                            </Link>
                                                                        </div>
                                                                    )}
                                                                </Col>
                                                            </Row>

                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive spinner-content">
                                                                    <BootstrapTable
                                                                        responsive
                                                                        remote
                                                                        bordered={false}
                                                                        striped={false}
                                                                        classes={
                                                                            "table table-centered table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        onTableChange={handleTableChange}
                                                                        {...paginationTableProps}
                                                                        defaultSorted={[
                                                                            {
                                                                                dataField: "createdAt",
                                                                                order: "desc",
                                                                            },
                                                                        ]}
                                                                        sort={{
                                                                            sortCaret: (order) =>
                                                                                order === "asc" ? (
                                                                                    <span className="caret">
                                                                                        <i className="bx bx-caret-up" />
                                                                                    </span>
                                                                                ) : order === "desc" ? (
                                                                                    <span className="caret">
                                                                                        <i className="bx bx-caret-down" />
                                                                                    </span>
                                                                                ) : null,
                                                                        }}
                                                                    />

                                                                    {loading && (
                                                                        <div className="spinner">
                                                                            <Spinner color="primary" />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

WithdrawList.propTypes = {
    items: PropTypes.array,
    totalItems: PropTypes.number,
    onGetPendingWithdraw: PropTypes.func,
    onGetWithrawStatus: PropTypes.func,
    loading: PropTypes.bool,

};

const mapStateToProps = ({ Settings }) => ({
    items: Settings.items,
    totalItems: Settings.totalItems,
    loading: Settings.loading,

});

const mapDispatchToProps = (dispatch) => ({
    onGetPendingWithdraw: (data) => dispatch(getPendingWithdraw(data)),
    onGetWithrawStatus: (data, callback) => dispatch(withdrawStatus(data, callback)),

});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WithdrawList))
);
