import * as CONST from "./actionTypes";
/* Get Crew Members */
export const getCrewMembers = (data) => ({
    type: CONST.GET_CREW_MEMBERS,
    payload: data,
});

export const getCrewMembersSuccess = (crewMembers) => ({
    type: CONST.GET_CREW_MEMBERS_SUCCESS,
    payload: crewMembers,
});

export const getCrewMembersFail = (error) => ({
    type: CONST.GET_CREW_MEMBERS_FAIL,
    payload: error,
});

/* Get Referral Claim history */
export const getReferralClaimHistory = (data) => ({
    type: CONST.GET_REFERRAL_CLAIM_HISTORY,
    payload: data,
});

export const getReferralClaimHistorySuccess = (crewMembers) => ({
    type: CONST.GET_REFERRAL_CLAIM_HISTORY_SUCCESS,
    payload: crewMembers,
});

export const getReferralClaimHistoryFail = (error) => ({
    type: CONST.GET_REFERRAL_CLAIM_HISTORY_FAIL,
    payload: error,
});

/* update Referral Claim history */
export const updateReferralClaimHistory = (data, callback) => ({
    type: CONST.UPDATE_REFERRAL_CLAIM_HISTORY,
    payload: { data, callback},
});

export const updateReferralClaimHistorySuccess = (data, callback) => ({
    type: CONST.UPDATE_REFERRAL_CLAIM_HISTORY_SUCCESS,
    payload: { data, callback},
});

export const updateReferralClaimHistoryFail = (error) => ({
    type: CONST.UPDATE_REFERRAL_CLAIM_HISTORY_FAIL,
    payload: error,
});