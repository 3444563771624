import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import toastr from "toastr";
import Select from 'react-select';

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import {
  addBalance,
  getCustomers,
  removeBalance,
  getUserBalance,
} from "store/actions";

//i18n
import { withTranslation } from "react-i18next";

const RemoveBalance = ({
  onGetUsers,
  onGetBalance,
  error,
  loading,
  collection,
  onGetTournament,
  tournamentDetail,
  onUploadFile,
  users,
  userBalance,
  ...props
}) => {
  const history = useHistory();
  const [options, setoptions] = useState([]);

  const [fields, setFields] = useState({
    amount: "",
    user_id: "",
  });

  const [filter, setFilter] = useState({
    orderBy: "email",
    order: 1,
    page: 0,
    limit: 50000,
    search: "",
    role: "USER"
  });


  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    onGetUsers({ ...filter });
  }, [JSON.stringify(filter)]);

  const [userList, setUsersList] = useState([]);



  //options dropdown manage
  useEffect(() => {
    setoptions(userList.map((val) => {
      return { value: val._id, label: val.email }
    }))
  }, [userList])

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onGetBalance({ "user_id": selectedOption?.value });
  };

  useEffect(() => {
    setUsersList(users || []);
  }, [users]);

  const handleChange = (name, index, field) => (event) => {
    const newValue = event.target.value;

    setFields((prevState) => {
      const updatedFields = { ...prevState };
      if (field) {
        updatedFields[name][index][field] = newValue;
      } else {
        updatedFields[name] = newValue;
      }

      return updatedFields;
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (fields.amount > userBalance) {
      return toastr.error("Not Enough Balance");
    }
    if (fields.amount && userBalance == 0 ) {
      return toastr.error("Low User Balance");
    }
    if (loading) {
      return;
    } else {
      const callback = (response) => {
        if (response.status === "failure") {
          toastr.error(response.message);
        } else {
          toastr.success(response.message);
          setFields((prevState) => ({ ...prevState, amount: "", user_id: "" }));
          setSelectedOption({})
          history.push("/remove-balance");
        }
      };
      props.onRemoveBalance(
        {
          ...fields,
          user_id: selectedOption?.value
        },
        callback
      );
    }
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm={1} lg={2}></Col>
            <Col sm={10} lg={8}>
              <Breadcrumbs
                title="Games"
                breadcrumbItem={props.t("Remove") + " " + props.t("Balance")}
                breadcrumbItems={[
                  // { title: props.t("Balance"), link: "/add-balance" },
                  { title: props.t("Remove Balance") },
                ]}
              />
              {
                selectedOption?.value && <h5>Current Balance: {userBalance}</h5>
              }

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Form onSubmit={onSubmit} className="spinner-content">
                        {error && typeof error === "string" ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}

                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label>User</Label>
                              {/* <Input
                                type="select"
                                value={fields.user_id}
                                onChange={handleChange("user_id")}
                                required
                              >
                                <option value={""}>Select User</option>
                                {userList &&
                                  userList.length > 0 &&
                                  userList.map((data, id) => (
                                    <option value={data._id}>
                                      {data.email}
                                    </option>
                                  ))}
                              </Input> */}
                              <Select
                                value={selectedOption}
                                onChange={handleSelectChange}
                                options={options}
                                isSearchable={true} // Enable search/filter functionality
                                placeholder="Select an option..."
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Amount")}
                              </Label>

                              <Input
                                type="number"
                                id="formrow-firstname-Input"
                                value={fields.amount}
                                onChange={handleChange("amount")}
                                required
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <div className="mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            {props.t("Remove")}
                          </button>

                          <button
                            type="button"
                            className="btn outline btn-outline-secondary w-md ml-3"
                            onClick={() => history.goBack()}
                          >
                            <i className="mdi mdi-arrow-left mr-1"></i>
                            {props.t("back")}
                          </button>
                        </div>

                        {loading && (
                          <div className="spinner">
                            <Spinner color="primary" />
                          </div>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm={1} lg={2}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

RemoveBalance.propTypes = {
  t: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onRemoveBalance: PropTypes.func,
  onUpdateGame: PropTypes.func,
  onGetTournament: PropTypes.func,
};

const mapStateToProps = ({ Game, customers, AddBalance }) => ({
  error: Game.error,
  loading: Game.loading,
  // tournamentDetail: Game.tournamentDetail?.data,
  users: customers.customers,
  userBalance: AddBalance?.balanceHistory?.amount
});

const mapDispatchToProps = (dispatch) => ({
  onRemoveBalance: (data, history) => { dispatch(removeBalance(data, history)); },

  onGetUsers: (data) => { dispatch(getCustomers(data)); },
  onGetBalance: (data) => { dispatch(getUserBalance(data)); },


});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RemoveBalance))
);
