import {
  ITEM_API_FAILED,
  GET_ITEMS,
  GET_ITEMS_FAIL,
  GET_ITEMS_SUCCESS,
  ADD_ITEM,
  ADD_ITEM_FAIL,
  ADD_ITEM_SUCCESS,
  GET_ITEM,
  GET_ITEM_FAIL,
  GET_ITEM_SUCCESS,
  DEPLOY_ITEM,
  DEPLOY_ITEM_FAIL,
  DEPLOY_ITEM_SUCCESS,
  DELETE_ITEM,
  DELETE_ITEM_FAIL,
  DELETE_ITEM_SUCCESS,
  BLUE_TICK_NOTIFICATIONS,
  DEPLOY_ITEMS_STATUS,
  DEPLOY_ITEMS_STATUS_FAIL,
  DEPLOY_ITEMS_STATUS_SUCCESS,
  UPDATE_ITEM,
  UPDATE_ITEM_FAIL,
  UPDATE_ITEM_SUCCESS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_SUCCESS,
  TEMP_AIRDROP_NFT,
  TEMP_AIRDROP_NFT_SUCCESS,
  TEMP_AIRDROP_NFT_FAIL,
  ADD_MULTIPLE_ITEMS,
  ADD_MULTIPLE_ITEMS_FAIL,
  ADD_MULTIPLE_ITEMS_SUCCESS,
  UPDATE_ITEM_PRICES,
  UPDATE_ITEM_PRICES_FAIL,
  UPDATE_ITEM_PRICES_SUCCESS,
  REMOVE_MULTIPLE_ITEMS,
  REMOVE_MULTIPLE_ITEMS_SUCCESS,
  REMOVE_MULTIPLE_ITEMS_FAIL
} from "./actionTypes";

import * as CONST from "./actionTypes";

// export const apiFailedCustom = (error) => ({
//   type: ITEM_API_FAILED,
//   payload: error,
// });

/* Get items */
export const getItems = (data) => ({
  type: GET_ITEMS,
  payload: data,
});

export const getItemsSuccess = (items) => ({
  type: GET_ITEMS_SUCCESS,
  payload: items,
});

export const getItemsFail = (error) => ({
  type: GET_ITEMS_FAIL,
  payload: error,
});
/* Get Items END */

/* Add Item */
export const addItem = (item, callBack) => ({
  type: ADD_ITEM,
  payload: { item, callBack },
});

export const addItemFail = (error) => ({
  type: ADD_ITEM_FAIL,
  payload: error,
});

export const addItemSuccess = () => ({
  type: ADD_ITEM_SUCCESS,
});
/* Add Item END */

/* update Item */
export const updateItem = (item, callback) => {
  return {
    type: UPDATE_ITEM,
    payload: { item, callback },
  };
};
/* update Item */
export const resetItem = () => {
  return {
    type: CONST.RESET_VIEW_ITEM,
    payload: {},
  };
};

export const updateItemFail = (error) => ({
  type: UPDATE_ITEM_FAIL,
  payload: error,
});

export const updateItemSuccess = () => ({
  type: UPDATE_ITEM_SUCCESS,
});
/* Update Item END */

/* Get Item */
export const getItem = (id) => ({
  type: GET_ITEM,
  payload: id,
});

export const getItemFail = (error) => ({
  type: GET_ITEM_FAIL,
  payload: error,
});

export const getItemSuccess = (item) => ({
  type: GET_ITEM_SUCCESS,
  payload: item,
});
/* Get Item END */

/* Update Item */
export const deployItem = (data, callBack) => ({
  type: DEPLOY_ITEM,
  payload: { data, callBack },
});

export const deployItemFail = (error) => ({
  type: DEPLOY_ITEM_FAIL,
  payload: error,
});

export const deployItemSuccess = () => ({
  type: DEPLOY_ITEM_SUCCESS,
});
/* Update Item END */

/* Delete Item */
export const deleteItem = (data, callback, type) => ({
  type: DELETE_ITEM,
  payload: { data, callback, type },
});

export const deleteItemFail = (error) => ({
  type: DELETE_ITEM_FAIL,
  payload: error,
});

export const deleteItemSuccess = () => ({
  type: DELETE_ITEM_SUCCESS,
});
/* Delete Faz END */

/* Update Multi Items Status */
export const putItemsStatus = (data, callback) => ({
  type: DEPLOY_ITEMS_STATUS,
  payload: { data, callback },
});

export const putItemsStatusFail = (error) => ({
  type: DEPLOY_ITEMS_STATUS_FAIL,
  payload: error,
});

export const putItemsStatusSuccess = () => ({
  type: DEPLOY_ITEMS_STATUS_SUCCESS,
});
/* Update Multi Items Status END */

/* Get Notifications */
export const getNotifications = (data, callback) => ({
  type: GET_NOTIFICATIONS,
  payload: { data, callback },
});

export const getNotificationsFail = (error) => ({
  type: GET_NOTIFICATIONS_FAIL,
  payload: error,
});

export const getNotificationsSuccess = (data) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: data,
});
/* Get Notifications END */

export const putNotifications = (data, callback) => ({
  type: BLUE_TICK_NOTIFICATIONS,
  payload: { data, callback },
});

/* BACKGROUND IMAGE START */
export const addBackgroundImg = (data, callback) => ({
  type: CONST.ADD_BACKGROUND_IMAGE,
  payload: { data, callback },
});

export const addBackgroundImgFail = (error) => ({
  type: CONST.ADD_BACKGROUND_IMAGE_FAIL,
  payload: error,
});

export const addBackgroundImgSuccess = (data) => ({
  type: CONST.ADD_BACKGROUND_IMAGE_SUCCESS,
  payload: data,
});

export const listBackgroundImg = (data, callback) => ({
  type: CONST.GET_LIST_BACKGROUND_IMAGE,
  payload: { data, callback },
});

export const listBackgroundImgFail = (error) => ({
  type: CONST.GET_LIST_BACKGROUND_IMAGE_FAIL,
  payload: error,
});

export const listBackgroundImgSuccess = (data) => ({
  type: CONST.GET_LIST_BACKGROUND_IMAGE_SUCCESS,
  payload: data,
});

export const viewBackgroundImg = (data, callback) => ({
  type: CONST.VIEW_BACKGROUND_IMAGE,
  payload: { data, callback },
});

export const viewBackgroundImgFail = (error) => ({
  type: CONST.VIEW_BACKGROUND_IMAGE_FAIL,
  payload: error,
});

export const viewBackgroundImgSuccess = (data) => ({
  type: CONST.VIEW_BACKGROUND_IMAGE_SUCCESS,
  payload: data,
});

export const deleteBackgroundImg = (data, callback) => ({
  type: CONST.DELETE_BACKGROUND_IMAGE,
  payload: { data, callback },
});

export const deleteBackgroundImgFail = (error) => ({
  type: CONST.DELETE_BACKGROUND_IMAGE_FAIL,
  payload: error,
});

export const deleteBackgroundImgSuccess = (data) => ({
  type: CONST.DELETE_BACKGROUND_IMAGE_SUCCESS,
  payload: data,
});

export const putBackgroundImg = (data, callback) => ({
  type: CONST.PUT_BACKGROUND_IMAGE,
  payload: { data, callback },
});

export const putBackgroundImgFail = (error) => ({
  type: CONST.PUT_BACKGROUND_IMAGE_FAIL,
  payload: error,
});

export const putBackgroundImgSuccess = (data) => ({
  type: CONST.PUT_BACKGROUND_IMAGE_SUCCESS,
  payload: data,
});



export const addCrystalPackage = (data, callback) => ({
  type: CONST.ADD_CRYSTAL_PACKAGE,
  payload: { data, callback },
});

export const addCrystalPackageFail = (error) => ({
  type: CONST.ADD_CRYSTAL_PACKAGE_FAIL,
  payload: error,
});

export const addcrystalPackageSuccess = (data) => ({
  type: CONST.ADD_CRYSTAL_PACKAGE_SUCCESS,
  payload: data,
});

export const editCrystalPackage = (data, callback) => ({
  type: CONST.UPDATE_CRYSTAL_PACKAGE,
  payload: { data, callback },
});

export const editCrystalPackageFail = (error) => ({
  type: CONST.UPDATE_CRYSTAL_PACKAGE_FAIL,
  payload: error,
});

export const editcrystalPackageSuccess = (data) => ({
  type: CONST.UPDATE_CRYSTAL_PACKAGE_SUCCESS,
  payload: data,
});


export const listCrystalPackage = (data) => ({
  type: CONST.LIST_CRYSTAL_PACKAGE,
  payload:  data ,
});

export const listCrystalPackageFail = (error) => ({
  type: CONST.LIST_CRYSTAL_PACKAGE_FAIL,
  payload: error,
});

export const listCrystalPackageSuccess = (data) => ({
  type: CONST.LIST_CRYSTAL_PACKAGE_SUCCESS,
  payload: data,
});


export const listCrystalPackagePurchase = (data) => ({
  type: CONST.LIST_CRYSTAL_PACKAGE_PURCHASE,
  payload:  data ,
});

export const listCrystalPackagePurchaseFail = (error) => ({
  type: CONST.LIST_CRYSTAL_PACKAGE_PURCHASE_FAIL,
  payload: error,
});

export const listCrystalPackagePurchaseSuccess = (data) => ({
  type: CONST.LIST_CRYSTAL_PACKAGE_PURCHASE_SUCCESS,
  payload: data,
});


export const tempAirdropNft = (data, callback) => ({
  type: TEMP_AIRDROP_NFT,
  payload:  {data, callback}
});

export const tempAirdropNftFail = (error) => ({
  type: TEMP_AIRDROP_NFT_FAIL,
  payload: error,
});

export const tempAirdropNftSuccess = (data) => ({
  type: TEMP_AIRDROP_NFT_SUCCESS,
  payload: data,
});

//update multiple item prices
export const updateItemsPrices = (data, callback) => ({
  type: UPDATE_ITEM_PRICES,
  payload: {data, callback}
});

export const updateItemsPricesFail = (error) => ({
  type: UPDATE_ITEM_PRICES_FAIL,
  payload: error,
});

export const updateItemsPricesSuccess = (data,callback) => ({
  type: UPDATE_ITEM_PRICES_SUCCESS,
  payload:  {data, callback}
});

//add multiple items
export const addMultipleItems = (data, callback) => ({
  type: ADD_MULTIPLE_ITEMS,
  payload: {data, callback}
});

export const addMultipleItemsFail = (error) => ({
  type: ADD_MULTIPLE_ITEMS_FAIL,
  payload: error,
});

export const addMultipleItemsSuccess = (data,callback) => ({
  type: ADD_MULTIPLE_ITEMS_SUCCESS,
  payload:  {data, callback}
});


//remove multiple items
export const removeMultipleItems = (data, callback) => ({
  type: REMOVE_MULTIPLE_ITEMS,
  payload: {data, callback}
});

export const removeMultipleItemsFail = (error) => ({
  type: REMOVE_MULTIPLE_ITEMS_FAIL,
  payload: error,
});

export const removeMultipleItemsSuccess = (data,callback) => ({
  type: REMOVE_MULTIPLE_ITEMS_SUCCESS,
  payload:  {data, callback}
});