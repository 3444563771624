import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { Card, CardBody, Col, Container, Row, Spinner, Input } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import { getBonusHistory, updateBonusHistory } from "store/actions";
import ListColumns from "./ListColumns";
import toastr from "toastr";
import ConfirmModal from "./ConfirmModal";
import RejectModal from "./RejectModal";

const BonusHistoryList = ({
  BonusHistory,
  accessLevel,
  history,
  collections,
  totalBonusHistory,
  onGetBonusHistory,
  onUpdateBonusStatus,
  loading,
  ...props
}) => {
  const [filter, setFilter] = useState({
    orderBy: "date_created_utc",
    order: -1,
    page: 1,
    limit: 20,
    search: "",
    status: "",
  });
  const [searchText, setSearchText] = useState("");
  const [BonusHistoryList, setBonusHistory] = useState([]);
  const [completeMod, setCompleteMod] = useState({ show: false, bonusHistoryId: "" });
  const [rejectMod, setRejectMod] = useState({ show: false, bonusHistoryId: "" });
  const [transactionHash, setTransactionHash] = useState("");

  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalBonusHistory,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    onGetBonusHistory(filter);
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    setBonusHistory(BonusHistory || []);
  }, [BonusHistory]);


  const handleChange = (e) => {
    console.log("targe", e?.target?.value);
    setTransactionHash(e.target.value);
  }

  const toggleConfirmModal = useCallback((bonusHistoryId) => {
    setCompleteMod((prevState) => ({ show: !prevState.show, bonusHistoryId: bonusHistoryId }));
  }, []);

  const toggleRejectModal = useCallback((bonusHistoryId) => {
    setRejectMod((prevState) => ({ show: !prevState.show, bonusHistoryId: bonusHistoryId }));
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText.trim());
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const onSearch = (event) => {
    event.preventDefault();
    setFilter((prevState) => ({ ...prevState, search: searchText.trim() }));
  };

  return (
    <>
      <ConfirmModal
        show={completeMod.show}
        toggle={toggleConfirmModal}
        handleChange={handleChange}
        onConfirm={() => {
          const callback = (res) => {
            setCompleteMod({ show: false, bonusHistoryId: "" });
            setTransactionHash("");
            if (res.status === "success") {
              toastr.success("Status updated successfully.");
              onGetBonusHistory(filter);
            }
            else {
              toastr.error(res.message);
            }
          };
          onUpdateBonusStatus({
            bonusHistoryId: completeMod?.bonusHistoryId,
            status: "completed",
            transactionHash: transactionHash
          }, callback)
        }}
      />

      <RejectModal
        isOpen={rejectMod.show}
        toggle={toggleRejectModal}
        onConfirm={() => {
          const callback = (res) => {
            console.log("callback", res);
            setRejectMod({ show: false, bonusHistoryId: "" });
            if (res.status === "success") {
              toastr.success("Status updated successfully.");
              onGetBonusHistory(filter);
            }
            else {
              toastr.error(res.message);
            }
          };
          console.log("historyId", rejectMod?.bonusHistoryId)
          onUpdateBonusStatus({
            bonusHistoryId: rejectMod?.bonusHistoryId,
            status: "rejected"
          }, callback)
        }}
      />

      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Bonus History"
              breadcrumbItem={props.t("Bonus History")}
              breadcrumbItems={[{ title: props.t("Bonus History") }]}
            />

            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          data={BonusHistoryList.length > 0 ? BonusHistoryList : []}
                          columns={ListColumns(toggleConfirmModal, toggleRejectModal, props.t)}
                          bootstrap4
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="3">
                                  <SearchInput
                                    {...toolkitProps.searchProps}
                                    triggerSearch={onSearch}
                                    placeholder={props.t("Search by email, username...")}
                                    searchText={searchText}
                                  />
                                </Col>

                                <Col sm="3">
                                  <Input
                                    type="select"
                                    value={filter.status}
                                    onChange={(e) =>
                                      setFilter((prev) => ({
                                        ...prev,
                                        status: e.target.value,
                                        filter: "",
                                      }))
                                    }
                                  >
                                    <option value="">Select Status</option>
                                    <option value="pending">Pending</option>
                                    <option value="completed">Completed</option>
                                    <option value="rejected">Rejected</option>
                                  </Input>
                                </Col>
                                <Col sm="2">
                                  {!!filter.search || !!filter.status ? (
                                    <div
                                      className="mr-4 mb-2"
                                      style={{
                                        height: "calc( 1.5em + 0.94rem + 2px)",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setFilter((prevState) => ({
                                            ...prevState,
                                            search: "",
                                            status: "",
                                            filter: "all"
                                          }));
                                          setSearchText("");
                                        }}
                                        className=""
                                      >
                                        {props.t("Clear Filters")}
                                      </Link>
                                    </div>
                                  ) : ""}
                                </Col>

                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive spinner-content">
                                    <BootstrapTable
                                      responsive
                                      remote
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table table-centered table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange}
                                      {...paginationTableProps}
                                      defaultSorted={[
                                        {
                                          dataField: "date_created_utc",
                                          order: "desc",
                                        },
                                      ]}
                                      sort={{
                                        sortCaret: (order) =>
                                          order === "asc" ? (
                                            <span className="caret">
                                              <i className="bx bx-caret-up" />
                                            </span>
                                          ) : order === "desc" ? (
                                            <span className="caret">
                                              <i className="bx bx-caret-down" />
                                            </span>
                                          ) : null,
                                      }}
                                    />

                                    {loading && (
                                      <div className="spinner">
                                        <Spinner color="primary" />
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>

  );
};

BonusHistoryList.propTypes = {
  BonusHistory: PropTypes.array,
  totalBonusHistory: PropTypes.number,
  onGetBonusHistory: PropTypes.func,
};

const mapStateToProps = ({ BonusHistory }) => ({
  loading: BonusHistory.loading,
  BonusHistory: BonusHistory.bonusHistory,
  totalBonusHistory: BonusHistory.totalBonusHistory,
});

const mapDispatchToProps = (dispatch) => ({
  onGetBonusHistory: (data) => dispatch(getBonusHistory(data)),
  onUpdateBonusStatus: (data, callback) => dispatch(updateBonusHistory(data, callback))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(BonusHistoryList))
);