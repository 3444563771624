import {
  PROMO_CODE_API_FAILED,
  GET_PROMO_CODES,
  GET_PROMO_CODES_FAIL,
  GET_PROMO_CODES_SUCCESS,
  ADD_PROMO_CODE,
  ADD_PROMO_CODE_FAIL,
  ADD_PROMO_CODE_SUCCESS,
  GET_PROMO_CODE,
  GET_PROMO_CODE_FAIL,
  GET_PROMO_CODE_SUCCESS,
  PUT_PROMO_CODE,
  PUT_PROMO_CODE_FAIL,
  PUT_PROMO_CODE_SUCCESS,
  DELETE_PROMO_CODE,
  DELETE_PROMO_CODE_FAIL,
  DELETE_PROMO_CODE_SUCCESS,
  PUT_PROMO_CODES_STATUS,
  PUT_PROMO_CODES_STATUS_FAIL,
  PUT_PROMO_CODES_STATUS_SUCCESS,
  GET_PROMO_CODE_USERS,
  GET_PROMO_CODE_USERS_FAIL,
  GET_PROMO_CODE_USERS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  promocodes: [],
  totalPromoCodes: 0,
  error: "",
  loading: false,
  promocode: {},
  promoCodeUsers: []
}

const PromoCodes = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get PromoCodes */
    case GET_PROMO_CODES:
      return {
        ...state,
        loading: true,
      }

    case GET_PROMO_CODES_SUCCESS:
      return {
        ...state,
        promocodes: action.payload.data || [],
        totalPromoCodes: action.payload.totalCount || 0,
        loading: false,
        error: "",
      }

    case GET_PROMO_CODES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Get PromoCodes END */

    /* Add PromoCode */
    case ADD_PROMO_CODE:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case ADD_PROMO_CODE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case ADD_PROMO_CODE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Add PromoCode END */

    /* Get PromoCode */
    case GET_PROMO_CODE:
      return {
        ...state,
        error: "",
        loading: true,
        promocode: {},
      }

    case GET_PROMO_CODE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        promocode: {},
      }

    case GET_PROMO_CODE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        promocode: action.payload,
      }
    /* Add PromoCode END */

    /* Update PromoCode */
    case PUT_PROMO_CODE:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case PUT_PROMO_CODE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case PUT_PROMO_CODE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Update PromoCode END */

    /* Delete PromoCode */
    case DELETE_PROMO_CODE:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case DELETE_PROMO_CODE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Delete PromoCode END */
    
    /* get   promo code */
    case GET_PROMO_CODE_USERS:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case GET_PROMO_CODE_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PROMO_CODE_USERS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        promoCodeUsers: action.payload.data
      }
    /* get   promo code END */
    case PROMO_CODE_API_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default PromoCodes
