import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { isEmpty } from "lodash";
import moment from "moment";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import Nft3d from "common/nftUpload/index";

//Import actions
import { viewBackgroundImg } from "store/actions";

const ViewItem = (props) => {
  const {
    match: { params },
    onGetItem,
    item,
    error,
    loading,
  } = props;

  useEffect(() => {
    if (params && params.id) {
      onGetItem(params.id);
    }
  }, [params]);

  return (
    <React.Fragment>
      {loading && (
        <div className="page-content">
          <Spinner color="primary" />
        </div>
      )}
      {error && <Redirect to="/collections" />}
      {!error && !isEmpty(item) && !loading && (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Items" breadcrumbItem="Item Details" />
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="12">
                        <div className="mt-4 mt-xl-3">
                          <h4 className="mt-1 mb-3 text-primary">
                            {item?.name}
                          </h4>
                          <Row>
                            <Col md={6}>
                              {item?.image_url?.search(".glb") !== -1 ||
                              item?.image_url?.search(".gltf") !== -1 ? (
                                <>
                                  <Nft3d src={item?.image_url} />
                                </>
                              ) : item?.image_url?.search(".wav") !== -1 ? (
                                <Col xl={8} lg={6} md={4} sm={4} xs={6}>
                                  <audio
                                    style={{ width: 185 }}
                                    src={item?.image_url}
                                    alt="Item"
                                  />
                                </Col>
                              ) : item?.image_url?.search(".webm") !== -1 ? (
                                <>
                                  {item?.image_url && (
                                    <Col xl={8} lg={6} md={4} sm={4} xs={6}>
                                      <video
                                        style={{ width: 185 }}
                                        controls
                                        autoPlay={true}
                                        loop
                                        alt="Item"
                                      >
                                        <source
                                          src={item?.image_url}
                                          type="video/webm"
                                        />
                                        <source
                                          src={item?.image_url}
                                          type="video/mp4"
                                        />
                                        <source
                                          src={item?.image_url}
                                          type="video/ogg"
                                        />
                                      </video>
                                    </Col>
                                  )}
                                </>
                              ) : (
                                <Col xl={8} lg={6} md={4} sm={4} xs={6}>
                                  <CardImg
                                    style={{ width: 185 }}
                                    src={item?.image_url}
                                    alt="Item"
                                  />
                                </Col>
                              )}
                            </Col>
                            <Col md={6}>
                              {item?.thumb?.link && (
                                <CardImg
                                  style={{ width: 185 }}
                                  src={item?.thumb?.link}
                                  alt="Item"
                                />
                              )}
                            </Col>
                          </Row>

                          <p className="text-muted mt-2">
                            Price - {item?.price}
                          </p>

                          {item?.isRentSale && (
                            <p className="text-muted mt-2">
                              Rent price per day - {item?.rent_price_perday}
                            </p>
                          )}

                          {item?.enableReserve && (
                            <p className="text-muted mt-2">
                              Auction Reserve Price- {item?.reservePrice}
                            </p>
                          )}
                          <p className="text-muted">
                            Description - {item?.description}
                          </p>
                          <p className="text-muted">
                            Created On -
                            {moment(item?.create_date).format("lll")}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        <Card className="border border-dark p-5">
                          <CardTitle>Author</CardTitle>
                          <CardText>
                            <p className="mt-1 mb-3 text-primary">
                              {item?.author_id?.name}
                            </p>

                            {item?.author_id?.walletAddress && (
                              <a
                                href={
                                  item?.blockchain === "polygon"
                                    ? `https://mumbai.polygonscan.com/address/` +
                                      item?.author_id?.walletAddress
                                    : item?.blockchain === "BNB"
                                    ? `https://bscscan.com/address/` +
                                      item?.author_id?.walletAddress
                                    : ""
                                }
                                target="_blank"
                              >
                                <p className="mt-1 mb-3 text-primary">
                                  {item?.author_id?.walletAddress}
                                </p>
                              </a>
                            )}
                          </CardText>
                        </Card>
                      </Col>
                      <Col sm="4">
                        <Card className="border border-dark p-5">
                          <CardTitle>Collection</CardTitle>
                          <CardText>
                            <p className="text-primary">
                              Name - {item?.collection_id?.name}
                            </p>
                            <p className="text-primary">
                              Symbol - {item?.collection_id?.symbol}
                            </p>
                            {item?.collection_id?.image && (
                              <CardImg
                                width="100%"
                                src={item.collection_id?.image?.link}
                                alt="Collection"
                              />
                            )}
                          </CardText>
                        </Card>
                      </Col>
                      <Col sm="4">
                        <Card className="border border-dark p-5">
                          <CardTitle>Current Owner</CardTitle>
                          <CardText>
                            {console.log(item, "getLinkdata")}
                            <p className="mt-1 mb-3 text-primary">
                              {item?.current_owner?.name}
                            </p>
                            <a
                              href={
                                item?.blockchain === "polygon"
                                  ? `https://mumbai.polygonscan.com/address/` +
                                    item?.current_owner?.walletAddress
                                  : item?.blockchain === "BNB"
                                  ? `https://bscscan.com/address/` +
                                    item?.current_owner?.walletAddress
                                  : ""
                              }
                              target="_blank"
                            >
                              <p className="mt-1 mb-3 text-primary">
                                {item?.current_owner?.walletAddress}
                              </p>
                            </a>
                          </CardText>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

ViewItem.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  item: PropTypes.any,
  onGetItem: PropTypes.func,
};

const mapStateToProps = ({ items }) => ({
  error: items.error,
  loading: items.loading,
  item: items.item,
});

const mapDispatchToProps = (dispatch) => ({
  onGetItem: (id) => dispatch(viewBackgroundImg(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewItem);
