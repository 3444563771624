import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import * as API from "helpers/backend_helper";

function* registerUserEmail({ payload: { user, callback } }) {
  try {
    if (!user.policy) {
      window.scrollTo(0, 0);
      return yield put(
        ACTIONS.registerUserEmailFailed("Please agree terms and condition!")
      );
    }
    const response = yield call(API.postRegisterEmail, user);

    if (response.status == "failure") {
      window.scrollTo(0, 0);
      return yield put(ACTIONS.registerUserEmailFailed(response.message));
    }

    callback && callback();
    yield put(ACTIONS.registerUserEmailSuccessful(response));
  } catch (error) {
    console.log(error);
    yield put(ACTIONS.registerUserEmailFailed("Internal Server Error"));
  }
}

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    if (!user.policy) {
      window.scrollTo(0, 0);
      return yield put(
        ACTIONS.registerUserFailed("Please agree terms and condition!")
      );
    }
    const response = yield call(API.postRegister, user);

    if (response.status == "failure") {
      window.scrollTo(0, 0);
      return yield put(ACTIONS.registerUserFailed(response.message));
    }

    history.replace("/");
    yield put(ACTIONS.registerUserSuccessful(response));
  } catch (error) {
    console.log(error);
    yield put(ACTIONS.registerUserFailed("Internal Server Error"));
  }
}

export function* watchUserRegister() {
  yield takeEvery(TYPES.REGISTER_USER_EMAIL, registerUserEmail);
  yield takeEvery(TYPES.REGISTER_USER, registerUser);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
