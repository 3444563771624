import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { getSettings, putSettings } from "store/actions";

import {
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Button,
  FormText,
  Label,
  Input,
  UncontrolledTooltip,
  Container,
  Card,
  CardBody,
  Spinner,
  Alert,
} from "reactstrap";
import Instructions from "./Instructions";

// Actions
import { uploadFile } from "store/actions";

import Breadcrumbs from "components/Common/Breadcrumb";
import { acceptOnlyNumber } from "helpers/contants";

const RewardsManagement = (props) => {
  const { onGetSettings, onPutSettings, loading, error } = props;

  const [fields, setFields] = useState({
    stepsRewards: {
      number_of_steps: 0,
      pptTokens: 0,
      ppgTokens: 0,
      governanceTokens: 0,
    },
  });

  useEffect(() => {
    onGetSettings();
  }, []);

  useEffect(() => {
    if (props.settings && props.settings.rewardsSettings) {
      setFields((prevState) => ({
        ...prevState,
        stepsRewards: props.settings?.rewardsSettings?.stepsRewards || {},
      }));
    }
  }, [JSON.stringify(props?.settings)]);

  const handleStepsRewardsChange = (reward) => (event) => {
    const { value, name } = event.target;

    setFields((prevFields) => ({
      ...prevFields,
      [reward]: { ...prevFields[reward], [name]: value },
    }));
  };

  function submit(e) {
    e.preventDefault();
    console.log("kjdjbfjdsjbdbgj");
    try {
      onPutSettings({
        rewardsSettings: {
          ...props.settings.rewardsSettings,
          stepsRewards: fields.stepsRewards,
        },
        _id: props.settings._id,
      });
    } catch {
      throw new Error("Some Error Occurred!!");
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Settings" breadcrumbItem="Rewards Settings" />
          <Row>
            <Card>
              <CardBody className="spinner-content">
                <Col md={12}>
                  <Form onSubmit={submit}>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <Label>Number Of Steps</Label>

                          <Input
                            type="number"
                            min={0}
                            name="number_of_steps"
                            value={fields.stepsRewards.number_of_steps}
                            onKeyDown={acceptOnlyNumber}
                            onChange={(e) => {
                              handleStepsRewardsChange("stepsRewards")(e);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label>PPT Tokens</Label>

                          <Input
                            type="text"
                            name="pptTokens"
                            value={fields.stepsRewards.pptTokens}
                            onKeyDown={acceptOnlyNumber}
                            onChange={(e) => {
                              handleStepsRewardsChange("stepsRewards")(e);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label>PPG Tokens</Label>

                          <Input
                            type="text"
                            name="ppgTokens"
                            value={fields.stepsRewards.ppgTokens}
                            onKeyDown={acceptOnlyNumber}
                            onChange={(e) => {
                              handleStepsRewardsChange("stepsRewards")(e);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr className="my-3" />

                    <Row>
                      <Col></Col>

                      <Col className="d-flex justify-content-end">
                        {loading && (
                          <Spinner color="primary" className="mr-2" />
                        )}
                        <Button
                          className="buttoncolor"
                          disabled={loading}
                          type="submit"
                        >
                          Save Changes
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

RewardsManagement.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
  onGetSettings: () => dispatch(getSettings()),
  onPutSettings: (data) => dispatch(putSettings(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RewardsManagement)
);
