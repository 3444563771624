import {
    GET_LANGUAGE_LIST,
    GET_LANGUAGE_LIST_SUCCESS,
    GET_LANGUAGE_LIST_FAIL,
} from "./actionTypes";


/* Get language list*/
export const getLanguageList = (data) => ({
    type: GET_LANGUAGE_LIST,
    payload: data,
});
  
export const getLanguageListSuccess = (list) => ({
    type: GET_LANGUAGE_LIST_SUCCESS,
    payload: list,
});
  
export const getLanguageListFail = (error) => ({
    type: GET_LANGUAGE_LIST_FAIL,
    payload: error,
});