import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const paymentValues = {
  "addByAdmin": "Added By Admin",
  "reduceByAdmin": "Reduced By Admin",
  "winning": "Winning",
  "others": "Others",
  "purchased": "Purchased Crystals",
  "withdraw": "Withdraw",
  "blocked": "Blocked",
  "unblocked": "Unblocked",
  "sailRewards": "Sail Rewards",
  "rewards": "Rewards",
  "commission": "Commission", 
  "others":"Others",
  "buyNFT":"Purchased NFT",
  "buyEnergy":"Purchased Energy",
  "mobileGame":"LP Game",
  "nftLevelUpgrade":"NFT Level Upgrade",
  "repairNft":"NFT Repair"
}

const ListColumns = (t) => [
  {
    dataField: "user_id.email",
    text: t("Email"),
    formatter: (_, row) => (row.user_id?.email ? row.user_id?.email : "-"),
  },
  {
    dataField: "user_id.username",
    text: t("Username"),
    formatter: (_, row) => (row.user_id?.username ? row.user_id?.username : "-"),
  },
  {
    dataField: "prevBalance",
    text: t("Previous Balance"),
    formatter: (_, row) => (row.prevBalance?  row.prevBalance : "-"),
  },
  {
    dataField: "payment",
    text: t("Payment"),
    formatter: (_, row) => (row.payment? paymentValues[row.payment] : "-"),
  },
  {
    dataField: "type",
    text: t("Type"),
    formatter: (_, row) => (row.type?  row.type : "-"),
  },
  {
    text: t("Amount"),
    dataField: "amount",
    formatter: (_, row) => (row.amount?  row.amount : "0"),
  },
  {
    text: t("matchID"),
    dataField: "MatchID",
    formatter: (_, row) => (row.matchID?  row.matchID : "-"),
  },
  {
    dataField: "newBalance",
    text: t("New Balance"),
    formatter: (_, row) => (row.newBalance?  row.newBalance : "-"),
  },
  {
    text: t("Created At"),
    dataField: "date_created_utc",
    sort: true,
    formatter: (_, row) => moment(row.date_created_utc).format("YYYY-MM-DD HH:mm:ss"),
  },
  // {
  //   isDummyField: true,
  //   text: t("Action"),
  //   dataField: "action",
  //   formatter: (_, row) => (
  //     <>
  //       <Link
  //         to={`/match-history/view/${row._id}`}
  //         className="mr-3 text-secondary"
  //       >
  //         <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />

  //         <UncontrolledTooltip
  //           placement="top"
  //           target={`view-${row._id}-tooltip`}
  //         >
  //           View
  //         </UncontrolledTooltip>
  //       </Link>
  //     </>
  //   ),
  // },
];

export default ListColumns;
