import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (toggleMatchWinHistory, toggleReduce, t) => [{
    dataField: "Number",
    text: t("Position"),
    formatter: (_, row) => (row.position ? row.position : "-"),
  },
  {
    dataField: "user_id.username",
    text: t("Username"),
    formatter: (_, row) => (row.user_id?.username ? row.user_id?.username : "-"),
  },
  {
    dataField: "user_id.email",
    text: t("Email"),
    formatter: (_, row) => (row.user_id?.email ? row.user_id?.email : "-"),
  },
  
  {
    text: t("Points"),
    dataField: "winnerPoints",
    formatter: (_, row) => (row.winnerPoints?  row.winnerPoints : "0"),
  },
  // {
  //   text: t("Created At"),
  //   dataField: "date_created_utc",
  //   sort: true,
  //   formatter: (_, row) => moment(row.date_created_utc).format("YYYY-MM-DD HH:mm:ss"),
  // },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
       <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              toggleMatchWinHistory(row.user_id?._id);
            }}
            className="text-secondary"
          >
            <i
              className="fas fa-eye mr-3"
              id={`view-${row.user_id?._id}-tooltip`}
            />
            <UncontrolledTooltip
              placement="top"
              target={`view-${row.user_id?._id}-tooltip`}
            >
              {t("view")}
            </UncontrolledTooltip>
          </Link>

          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              toggleReduce(row.user_id?._id);
            }}
            className="text-secondary"
          >
            <i
              className="fas fa-minus mr-3"
              id={`reduce-${row.user_id?._id}-tooltip`}
            />
            <UncontrolledTooltip
              placement="top"
              target={`reduce-${row.user_id?._id}-tooltip`}
            >
              {t("Reduce")}
            </UncontrolledTooltip>
          </Link>

      </>
    ),
  },
];

export default ListColumns;
