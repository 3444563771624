import React, { useRef } from "react"
import { connect } from "react-redux"

import {
  LANGUAGES,
  COUNTRY_OPTIONS,
  CURRENCY_OPTIONS,
  TIMEZONES,
} from "helpers/contants"

import { registerStoreName } from "store/actions"

const CreateMarket = props => {
  const {
    setactiveTab,
    fields,
    handleChange,
    storeNameFailed,
    storeNameLoading,
  } = props

  const storeNameTimeout = useRef(null)

  const onStoreNameChange = e => {
    if (storeNameTimeout.current) {
      clearTimeout(storeNameTimeout.current)
      storeNameTimeout.current = null
    }

    storeNameTimeout.current = setTimeout(() => {
      props?.registerStoreName({ storeName: e?.target?.value })
    }, 1000)

    handleChange("storeName")(e)
  }

  return (
    <div className="tab">
      <div className="row">
        <div className="col-lg-12">
          <h1>Create your Go marketplace and start building today</h1>
        </div>

        <div className="col-lg-12">
          <div className="input-group has-validation position-relative">
            <label>Store name</label>
            <input
              type="text"
              value={fields?.storeName}
              required
              onChange={onStoreNameChange}
            />
            <div class="invalid-tooltip">{storeNameFailed}</div>

            <p>You can change the marketplace type later.</p>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="input-group">
            <label>Country</label>
            <select
              value={`${fields?.country}-${fields?.countryCode}`}
              onChange={e => {
                const { value } = e.target
                const values = value?.split("-")

                handleChange("country")({
                  target: { value: values[0] },
                })
                handleChange("countryCode")({
                  target: { value: values[1] },
                })
              }}
            >
              <option value="">Select Country</option>
              {COUNTRY_OPTIONS?.map(option => (
                <option value={`${option?.label}-${option?.countryCode}`}>
                  {option?.label}
                </option>
              ))}
            </select>

            <p>You can change the marketplace type later.</p>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="input-group">
            <label>Language</label>

            <select
              value={`${fields?.language?.code}-${fields?.language?.name}`}
              onChange={e => {
                const { value } = e.target
                const values = value?.split("-")

                handleChange("language")({
                  target: { value: { code: values[0], name: values[1] } },
                })
              }}
            >
              {LANGUAGES?.map(language => (
                <option value={`${language.value}-${language.label}`}>
                  {language.label}
                </option>
              ))}
            </select>
            <p>You can change the marketplace type later.</p>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="input-group">
            <label>Currency</label>

            <select
              value={`${fields?.currency?.code}-${fields?.currency?.sign}`}
              onChange={e => {
                const { value } = e.target
                const values = value?.split("-")

                handleChange("currency")({
                  target: { value: { code: values[0], sign: values[1] } },
                })
              }}
            >
              <option value="">Select Currency</option>
              {CURRENCY_OPTIONS?.map(currency => (
                <option value={`${currency.value}-${currency.label}`}>
                  {currency.label} {currency.value}
                </option>
              ))}
            </select>
            <p>You can change the marketplace type later.</p>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="input-group">
            <label>Timezone</label>

            <select
              value={fields?.timezone}
              onChange={handleChange("timezone")}
            >
              {TIMEZONES?.map(timezone => (
                <option value={timezone.value}>{timezone.label}</option>
              ))}
            </select>
            <p>You can change the marketplace type later.</p>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="flex-btn">
            <button
              type="button"
              className="themebtnblack fullwidthbtn"
              onClick={() => setactiveTab(2)}
            >
              Back
            </button>

            <button type="submit" className="themebtn fullwidthbtn">
              Create my marketplace!
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ Register }) => ({
  storeNameFailed: Register.storeNameFailed,
  storeNameLoading: Register.storeNameLoading,
})

export default connect(mapStateToProps, { registerStoreName })(CreateMarket)
