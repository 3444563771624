import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, Label, FormGroup, Input, Col } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

const ConfirmModal = (props) => {
  const { isOpen, toggle, onConfirm, name, type } = props;

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      autoFocus={true}
      centered={true}
      toggle={toggle}
      scrollable={"true"}
    >
      <div className="modal-content remove-confirm">
        <ModalBody>
          <div className="modal_form text-center">
          <h3 className="m-3"><strong>Temporary Airdrop NFT</strong></h3>
            <h6 className="mt-3"><strong>Please enter user's wallet address below:</strong></h6>
            <Col className="mt-3 text-center" lg={12}>
                  <FormGroup>
                    {/* <Label for="walletaddress">Wallet Address</Label> */}
                    <Input
                      type="text"
                      name="walletaddress"
                      id="walletaddress" 
                      onChange={(e)=> props.handleChange(e)}
                    />
                  </FormGroup>
                </Col>
            </div>


        

          <div className="delete-btns">
            <Button type="button" outline color="secondary" onClick={toggle}>
              {props.t("no_cancel")}
            </Button>

            <Button type="button" color="primary" onClick={onConfirm}>
            
              {props.t("Yes, Airdrop it")}
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default withTranslation()(ConfirmModal);
