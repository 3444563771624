import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { Card, CardBody, Col, Container, Row, Spinner, Input } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import { getMatchHistory, endMatch } from "store/actions";
import ListColumns from "./ListColumns";
import toastr from "toastr";


const MatchHistoryList = ({
    MatchHistory,
    accessLevel,
    history,
    collections,
    totalMatchHistory,
    onGetMatchHistory,
    onEndMatch,
    onDeleteTournament,
    loading,
    ...props
}) => {
    const { id } = useParams();
    const [filter, setFilter] = useState({
        orderBy: "date_created_utc",
        order: -1,
        page: 1,
        limit: 20,
        search: "",
        status: "",
        filter: "all",
        fields: [
            {
                fieldName: "",
                fieldValue: "",
            },
        ],
        user_id: id
    });
    const [MatchList, setMatchList] = useState([]);
    const pageOptions = {
        sizePerPage: 20,
        totalSize: totalMatchHistory,
        custom: true,
        onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
    };
    console.log("pageOptions", pageOptions)

    useEffect(() => {
        onGetMatchHistory(filter);
    }, [JSON.stringify(filter)]);

    useEffect(() => {
        setMatchList(MatchHistory || []);
    }, [MatchHistory]);

    console.log("MatchList", MatchList)

    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { sortField, sortOrder }) => {
    
        if (type === "sort") {
            if (sortField == "createdAt") {
                sortField = "date_created_utc";
            }

            if (sortOrder == "desc") {
                sortOrder = -1;
            } else if (sortOrder == "asc") {
                sortOrder = 1;
            }

            setFilter((prevState) => ({
                ...prevState,
                orderBy: sortField,
                order: sortOrder,
            }));
        }
    };

    return (
        <React.Fragment>
            <div className="page-content p-0">
                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="_id"
                                                data={MatchList.length > 0 ? MatchList : []}
                                                columns={ListColumns(props.t)}
                                                bootstrap4
                                            >
                                                {(toolkitProps) => (
                                                    <React.Fragment>

                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive spinner-content">
                                                                    <BootstrapTable
                                                                        responsive
                                                                        remote
                                                                        bordered={false}
                                                                        striped={false}
                                                                        classes={
                                                                            "table table-centered table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        onTableChange={handleTableChange}
                                                                        {...paginationTableProps}
                                                                        defaultSorted={[
                                                                            {
                                                                                dataField: "date_created_utc",
                                                                                order: "desc",
                                                                            },
                                                                        ]}
                                                                        sort={{
                                                                            sortCaret: (order) =>
                                                                                order === "asc" ? (
                                                                                    <span className="caret">
                                                                                        <i className="bx bx-caret-up" />
                                                                                    </span>
                                                                                ) : order === "desc" ? (
                                                                                    <span className="caret">
                                                                                        <i className="bx bx-caret-down" />
                                                                                    </span>
                                                                                ) : null,
                                                                        }}
                                                                    />

                                                                    {loading && (
                                                                        <div className="spinner">
                                                                            <Spinner color="primary" />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

MatchHistoryList.propTypes = {
    MatchHistory: PropTypes.array,
    totalMatchHistory: PropTypes.number,
    onGetMatchHistory: PropTypes.func,
    onEndMatch: PropTypes.func,
    onDeleteCollection: PropTypes.func,
    onPutCollectionsStatus: PropTypes.func,
};

const mapStateToProps = ({ MatchHistory }) => ({
    loading: MatchHistory.loading,
    MatchHistory: MatchHistory.matchHistory,
    totalMatchHistory: MatchHistory.totalMatchHistory,
});

const mapDispatchToProps = (dispatch) => ({
    onGetMatchHistory: (data) => dispatch(getMatchHistory(data)),
    onEndMatch: (data) => dispatch(endMatch(data)),

});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation()(MatchHistoryList))
);
