import * as CONST from "./actionTypes";

const INIT_STATE = {
  logs: [],
  totalLogs: 0,
  error: "",
  keys_error: "",
  loading: false,
  keys_loading: false,
};

const Logs = (state = INIT_STATE, action) => {
  switch (action.type) {

    /* Get Logs */
    case CONST.GET_LOGS:
      return {
        ...state,
        loading: true,
      };

    case CONST.GET_LOGS_SUCCESS:
      return {
        ...state,
        logs: action.payload.data || [],
        totalLogs: action.payload.totalCount || 0,
        loading: false,
      };

    case CONST.GET_LOGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

  
    default:
      return state;
  }
};

export default Logs;
