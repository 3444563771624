import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Card, CardBody, Spinner, Alert } from "reactstrap";

// Actions
import { getSettings, postDeployContract } from "store/actions";
import Breadcrumbs from "components/Common/Breadcrumb";
import Actions from "./Actions";

const BLOCKCHAIN_CONTRACT = (blockchain, contractAddress) => {
  return blockchain?.map((item) => {
    let contract = contractAddress?.find(
      (address) => address.type === item.type
    );

    if (!contract) {
      return {
        label: item.label,
        type: item.type,
        mainnet: "",
        testnet: "",
      };
    }

    contract.label = item.label;
    return contract;
  });
};

const ContractSettings = (props) => {
  const {
    onGetSettings,
    onPostDeployContract,
    loading,
    error,
    onputEmailDrop,
  } = props;

  const dispatch = useDispatch();
  const [fields, setFields] = useState({
    contractAddress: [],
    tokenAddress: [],
    toAddresses: [],
    tokenIds: [],
  });
  const getSetting = useCallback(() => {
    onGetSettings();
  }, []);

  useEffect(() => {
    // getSetting();
  }, []);

  useEffect(() => {
    if (props.settings && props.settings.blockchain) {
      setFields({
        contractAddress: BLOCKCHAIN_CONTRACT(
          props.settings.blockchain,
          props.settings.airdrop?.contractAddress
        ),
        tokenAddress: [],
        toAddresses: [],
        tokenIds: [],
      });
    }
  }, [props?.settings]);

  return (
    <React.Fragment>
      <div className="page-content spinner-content">
        <Container fluid>
          <Breadcrumbs title="Settings" breadcrumbItem="Air drop" />

          {error && typeof error === "string" ? (
            <Alert color="danger">{error}</Alert>
          ) : null}

          {/* {loading && (
            <div className="spinner">
              <Spinner color="primary" className="mr-2" />
            </div>
          )} */}

          {fields.contractAddress.map((item, index) => (
            <React.Fragment key={`idx-${item._id}-${index}`}>
              <Card>
                <CardBody className="spinner-content">
                  <Actions item={item} />
                </CardBody>
              </Card>
            </React.Fragment>
          ))}
        </Container>
      </div>
    </React.Fragment>
  );
};

ContractSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onPostDeployContract: (data, callback) =>
    dispatch(postDeployContract(data, callback)),
  // onputEmailDrop: (data, callback) => dispatch(putEmailDrop(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContractSettings)
);
