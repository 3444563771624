import {
  ADD_OPERATORS,
  ADD_OPERATORS_FAIL,
  ADD_OPERATORS_SUCCESS,
  GET_OPERATORS,
  GET_OPERATORS_FAIL,
  GET_OPERATORS_SUCCESS,
  REMOVE_OPERATORS,
  REMOVE_OPERATORS_FAIL,
  REMOVE_OPERATORS_SUCCESS,
  UPDATE_OPERATORS,
  UPDATE_OPERATORS_FAIL,
  UPDATE_OPERATORS_SUCCESS,

} from "./actionTypes";



export const addOperator = (data, callback) => ({
  type: ADD_OPERATORS,
  payload: {data, callback}
});

export const addOperatorSuccess = (data) => ({
  type: ADD_OPERATORS_SUCCESS,
  payload: data,
});

export const addOperatorFail = (error) => ({
  type: ADD_OPERATORS_FAIL,
  payload: error,
});

export const getOperator = (data) => ({
  type: GET_OPERATORS,
  payload: data,
});

export const getOperatorSuccess = (operators) => ({
  type: GET_OPERATORS_SUCCESS,
  payload: operators,
});

export const getOperatorFail = (error) => ({
  type: GET_OPERATORS_FAIL,
  payload: error,
});


export const updateOperator = (data, callback) => ({
  type: UPDATE_OPERATORS,
  payload: {data, callback}
});

export const updateperatorSuccess = (data) => ({
  type: UPDATE_OPERATORS_SUCCESS,
  payload: data,
});

export const updateOperatorFail = (error) => ({
  type: UPDATE_OPERATORS_FAIL,
  payload: error,
});


export const removeOperator = (data, onComplete) => ({
  type: REMOVE_OPERATORS,
  payload: {data, onComplete}
});

export const removeOperatorSuccess = (data) => ({
  type: REMOVE_OPERATORS_SUCCESS,
  payload: data,
});

export const removeOperatorFail = (error) => ({
  type: REMOVE_OPERATORS_FAIL,
  payload: error,
});

