import { ADD_TOURNAMENT } from "helpers/url_helper";
import {
  TOURNAMENTS_API_FAIL,
  GET_TOURNAMENTS,
  GET_TOURNAMENTS_FAIL,
  GET_TOURNAMENTS_SUCCESS,
  ADD_TOURNAMENT_SUCCESS,
  ADD_TOURNAMENT_FAIL,
  REMOVE_TOURNAMENT,
  REMOVE_TOURNAMENT_SUCCESS,
  REMOVE_TOURNAMENT_FAIL,
  VIEW_TOURNAMENT,
  VIEW_TOURNAMENT_SUCCESS,
  VIEW_TOURNAMENT_FAIL,
  x,
  UPDATE_TOURNAMENT,
  UPDATE_TOURNAMENT_SUCCESS,
  UPDATE_TOURNAMENT_FAIL,
} from "./actionTypes";

export const apiFail = (error) => ({
  type: TOURNAMENTS_API_FAIL,
  payload: error,
});

/* Get collections */
export const getTournaments = (data) => ({
  type: GET_TOURNAMENTS,
  payload: data,
});

export const getTournamentsSuccess = (Tournaments) => ({
  type: GET_TOURNAMENTS_SUCCESS,
  payload: Tournaments,
});

export const getTournamentsFail = (error) => ({
  type: GET_TOURNAMENTS_FAIL,
  payload: error,
});

/* Add tournament */
export const addTournament = (data, callback) => ({
  type: ADD_TOURNAMENT,
  payload: data,
  callback,
});

export const addTournamentSuccess = (Tournaments) => ({
  type: ADD_TOURNAMENT_SUCCESS,
  payload: Tournaments,
});

export const addTournamentFail = (error) => ({
  type: ADD_TOURNAMENT_FAIL,
  payload: error,
});

/* update tournament */
export const updateTournament = (data, callback) => ({
  type: UPDATE_TOURNAMENT,
  payload: data,
  callback,
});

export const updateTournamentSuccess = (Tournaments) => ({
  type: UPDATE_TOURNAMENT_SUCCESS,
  payload: Tournaments,
});

export const updateTournamentFail = (error) => ({
  type: UPDATE_TOURNAMENT_FAIL,
  payload: error,
});

/* remove tournament */
export const removeTournament = (data, callback) => {
  console.log("Inside removeTournament action creator:", data);
  return {
    type: REMOVE_TOURNAMENT,
    payload: data,
    callback,
  };
};

export const removeTournamentSuccess = (Tournaments) => ({
  type: REMOVE_TOURNAMENT_SUCCESS,
  payload: Tournaments,
});

export const removeTournamentFail = (error) => ({
  type: REMOVE_TOURNAMENT_FAIL,
  payload: error,
});

/* view tournament */
export const viewTournament = (data, callback) => {
  console.log("Inside removeTournament action creator:", data);
  return {
    type: VIEW_TOURNAMENT,
    payload: data,
    callback,
  };
};

export const viewTournamentSuccess = (Tournaments) => ({
  type: VIEW_TOURNAMENT_SUCCESS,
  payload: Tournaments,
});

export const viewTournamentFail = (error) => ({
  type: VIEW_TOURNAMENT_FAIL,
  payload: error,
});
