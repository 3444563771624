import { call, put, takeEvery } from "redux-saga/effects";

// Collection Redux States
import {
  GET_TOURNAMENTS,
  REMOVE_TOURNAMENT,
  VIEW_TOURNAMENT,
  UPDATE_TOURNAMENT,
  // ADD_TOURNAMENT,
} from "./actionTypes";
import {
  addTournamentFail,
  addTournamentSuccess,
  getTournamentsSuccess,
  getTournamentsFail,
  removeTournamentFail,
  removeTournamentSuccess,
  viewTournamentFail,
  viewTournamentSuccess,
  updateTournamentFail,
  updateTournamentSuccess,
} from "./actions";

import {
  postAddTournament,
  postGetTournament,
  postRemoveTournament,
  getViewTournament,
  postUpdateTournament,
} from "helpers/backend_helper";
import { ADD_TOURNAMENT, REMOVE_TOURNAMENTS } from "helpers/url_helper";

function* addTournamentSaga({ payload, callback }) {
  try {
    const response = yield call(postAddTournament, payload);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(addTournamentFail(response.message));
    }
    yield put(addTournamentSuccess(response));
  } catch (error) {
    yield put(addTournamentFail(error));
  }
}

function* getTournamentSaga({ payload, callback }) {
  try {
    const response = yield call(postGetTournament, payload);
    //console.log(response, "all listrtttt");
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(getTournamentsFail(response.message));
    }
    yield put(getTournamentsSuccess(response));
  } catch (error) {
    yield put(getTournamentsFail(error));
  }
}

function* removeTournamentSaga({ payload, callback }) {
  try {
    const response = yield call(postRemoveTournament, payload);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(removeTournamentFail(response.message));
    }
    yield put(removeTournamentSuccess(response));
  } catch (error) {
    //console.log(error, "delete error");
    yield put(removeTournamentFail(error));
  }
}

function* viewTournamentSaga({ payload, callback }) {
  try {
    const response = yield call(getViewTournament, payload);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(viewTournamentFail(response.message));
    }
    yield put(viewTournamentSuccess(response));
  } catch (error) {
    //console.log(error, "catch error asdf ");
    yield put(viewTournamentFail(error));
  }
}
function* updateTournamentSaga({ payload, callback }) {
  try {
    const response = yield call(postUpdateTournament, payload);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(updateTournamentFail(response.message));
    }
    yield put(updateTournamentSuccess(response));
  } catch (error) {
    //console.log(error, "catch error asdf ");
    yield put(updateTournamentFail(error));
  }
}

function* GameSaga() {
  yield takeEvery(ADD_TOURNAMENT, addTournamentSaga);
  yield takeEvery(GET_TOURNAMENTS, getTournamentSaga);
  yield takeEvery(REMOVE_TOURNAMENT, removeTournamentSaga);
  yield takeEvery(VIEW_TOURNAMENT, viewTournamentSaga);
  yield takeEvery(UPDATE_TOURNAMENT, updateTournamentSaga);
}

export default GameSaga;
