import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardTitle, CardText } from "reactstrap";
import moment from "moment";

//i18n
import { withTranslation } from "react-i18next";

// web3
import Web3Intraction from "helpers/web3Intraction";

const TIME_VIEW = (timmer) => {
  let _timmer = [];

  timmer.years > 0 ? _timmer.push(timmer.years + "Years") : null;
  timmer.months > 0 ? _timmer.push(timmer.months + "Month") : null;
  timmer.days > 0 ? _timmer.push(timmer.days + "Day") : null;

  if (timmer.months <= 1) {
    _timmer.push(timmer.hours + "Hour");
  }

  if (timmer.months <= 0 && timmer.days <= 1) {
    _timmer.push(timmer.minutes + "Minute");

    if (timmer.days < 1 && timmer.hours < 24) {
      _timmer.push(timmer.seconds + "Seconds");
    }
  }

  return <>{_timmer.join(" ")}</>;
};

const DepositDetails = (props) => {
  const [depositInfo, setdepositInfo] = useState({});
  const [timeleft, settimeleft] = useState({
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  useEffect(() => {
    if (
      props.walletAddress &&
      props.settings &&
      JSON.stringify(props.settings) !== "{}"
    ) {
      (async () => {
        const web3Intraction = new Web3Intraction("ethereum", props.settings);
        const _depositInfo = await web3Intraction.getDepositInfo(
          props.walletAddress
        );

        if (_depositInfo.stake) {
          let endTime = moment(_depositInfo.minHoldTime, "X");
          if (_depositInfo.isMinComplete) {
            endTime = moment(_depositInfo.maxHoldTime, "X");
          }

          const nowTime = moment();

          const duration = moment.duration(endTime.diff(nowTime));

          let years = duration.years();
          let months = duration.months();
          let days = duration.days();
          let hours = duration.hours();
          let minutes = duration.minutes();
          let seconds = duration.seconds();

          settimeleft({ years, months, days, hours, minutes, seconds });
        }

        setdepositInfo(_depositInfo);
      })();
    }
  }, [props.walletAddress, props.settings]);

  return (
    <Card className="border border-dark p-5">
      <CardTitle>Stakes</CardTitle>
      <CardText>
        <p className="mt-1 mb-3">
          Wallet: <span className="text-primary">{props.walletAddress}</span>
        </p>
        {!depositInfo.stake && (
          <p className="mt-1 mb-3">You did not stake token</p>
        )}
        {!!depositInfo.stake && (
          <p className="mt-1 mb-3">
            Total Stake {depositInfo.stake}
            {props.settings?.token?.symbol}
          </p>
        )}
        {!!depositInfo.stake && (
          <p className="mt-1 mb-3">
            Rewards you will get on withdraw {depositInfo.rewards}
            {props.settings?.token?.symbol}
          </p>
        )}
        {!!depositInfo.stake && !depositInfo.isMinComplete && (
          <p className="mt-1 mb-3">Lockin period left {TIME_VIEW(timeleft)}</p>
        )}
        {!!depositInfo.stake && depositInfo.isMinComplete && (
          <p className="mt-1 mb-3">
            Wait for {TIME_VIEW(timeleft)} to get {depositInfo.maxReward}%
            reward
          </p>
        )}
      </CardText>
    </Card>
  );
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(DepositDetails))
);
