import React, { useEffect, useState, useMemo } from "react";
import toastr from "toastr";

export const WalletContext = React.createContext();
const WalletProvider = ({ children }) => {
  const [state, setstate] = useState({
    isActive: false,
    account: "",
    loading: false,
    chainId: null
  });
  const connectWallet = async () => {
    if (window.ethereum) {
      // Do something
      setstate((prev) => ({ ...prev, loading: true }));
      try {
        let account = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        setstate((prev) => ({
          ...prev,
          loading: false,
          account: account[0],
          isActive: true,
          chainId: chainId
        }));
      } catch (error) {
        setstate((prev) => ({ ...prev, loading: false }));
        toastr.error(error?.message);
      }
    } else {
      toastr.error("install metamask extension!!");
    }
  };
  useEffect(() => {
    if (window.ethereum) {
      // Add the event listener when the component mounts
      // window.ethereum.on('chainChanged', async function(res){
      //  console.log("chainChanged",res)
      // });


      window.ethereum.on('chainChanged', function (chainId) {
        console.log("chainID", chainId);
        setstate((prev) => ({
          ...prev,
          chainID: chainId
        }));
      });

      window.ethereum.on("accountsChanged", function (accounts) {
        // console.log("accountchange",accounts)
        // Time to reload your interface with accounts[0]!
        setstate((prev) => ({
          ...prev,
          loading: false,
          account: accounts[0],
          isActive: accounts?.length ? true : false,
        }));
      });
    }
  }, [window.ethereum]);

  async function switchChain(data) {
    try {
      // Example of using the function
      let chainId = ('0x' + (parseInt(data.networkUrl.chainId)).toString(16));
      if (state.chainId !== chainId) {
        const chainParams = {
          chainId: ('0x' + (parseInt(data.networkUrl.chainId)).toString(16)), // Use the hexadecimal representation of 97
          chainName: data.networkUrl.chainName,
          nativeCurrency: data.networkUrl.nativeCurrency,
          rpcUrls: [data.networkUrl.url],
          blockExplorerUrls: data.networkUrl.blockExplorerUrls
        };
        const dt = await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            chainParams
          ],
        });
        // Switch to the added chain
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${data.networkUrl.chainId.toString(16)}` }],
        });

        // Check if the chain switch was successful
        const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });

        if (parseInt(currentChainId, 16) === data.networkUrl.chainId) {
          console.log(`Switched to chain ${data.networkUrl.chainId}`);
        } else {
          console.error('Failed to switch to the specified chain');
        }
      }
    } catch (error) {
      console.error('Error switching chain:', error);
    }
  }


  const values = useMemo(
    () => ({
      connectWallet,
      switchChain,
      ...state,
    }),
    [state]
  );

  return (
    <>
      <WalletContext.Provider value={values}>{children}</WalletContext.Provider>
    </>
  );
};

export default WalletProvider;
