import { call, put, takeEvery } from "redux-saga/effects";

// Collection Redux States
import {
  GET_SAIL_HISTORY,
  VIEW_SAIL_HISTORY,
} from "./actionTypes";

import {
  getSailHistoryFail,
  getSailHistorySuccess,
  viewSailHistoryFail,
  viewSailHistorySuccess,
} from "./actions";

import {
  postGetSailHistory,
  viewSailHistory,
} from "helpers/backend_helper";

function* getSailHistorySaga({ payload, callback }) {
  try {
    const response = yield call(postGetSailHistory, payload);
    console.log(response, "all listrtttt");
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(getSailHistoryFail(response.message));
    }
    yield put(getSailHistorySuccess(response));
  } catch (error) {
    yield put(getSailHistoryFail(error));
  }
}

function* viewSailHistorySaga({ payload, callback }) {
  try {
    const response = yield call(postViewSailHistory, payload);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(viewSailHistoryFail(response.message));
    }
    yield put(viewSailHistorySuccess(response));
  } catch (error) {
    yield put(viewSailHistoryFail(error));
  }
}

function* SailHistorySaga() {
  yield takeEvery(GET_SAIL_HISTORY, getSailHistorySaga);
  yield takeEvery(VIEW_SAIL_HISTORY, viewSailHistorySaga);
}

export default SailHistorySaga;