import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams, useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import toastr from "toastr";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import { addTournament, viewTournament, updateTournament } from "store/actions";

//i18n
import { withTranslation } from "react-i18next";
import moment from "moment";

const AddGame = ({
  error,
  loading,
  collection,
  onGetTournament,
  tournamentDetail,
  onUploadFile,
  ...props
}) => {
  const history = useHistory();
  console.log(history, 741);
  const { id } = useParams();

  const didMountRef = useRef();

  const timezone = moment.tz.guess();
  console.log(timezone, "timmeZoone");

  const [fields, setFields] = useState({
    name: "",
    fee_type: "",
    entry_fee: "0",
    minimum_players: "",
    maximum_players: "",
    // start_date: new Date(),
    start_date: "",
    // end_date: "",
    // start_time: "",
    // end_time: "",
    registration_date: "",
    // registration_time: "",
    timezone: timezone,
    join_type: "",
    price_pool: [
      {
        place: "1",
        price: "0",
      },
      {
        place: "2",
        price: "0",
      },
      {
        place: "3-4",
        price: "0",
      },
      {
        place: "5-8",
        price: "0",
      },
      {
        place: "9-16",
        price: "0",
      },
      {
        place: "17-32",
        price: "0",
      },
      {
        place: "33-64",
        price: "0",
      },
    ],
    level_pool: [
      {
        level: "Final",
        score: "1",
      },
      {
        level: "1/2",
        score: "1",
      },
      {
        level: "1/4",
        score: "1",
      },
      {
        level: "1/8",
        score: "1",
      },
      {
        level: "1/16",
        score: "1",
      },
      {
        level: "1/32",
        score: "1",
      },
      {
        level: "1/64",
        score: "1",
      },
      {
        level: "1/128",
        score: "1",
      },
      {
        level: "1/256",
        score: "1",
      },
      {
        level: "1/512",
        score: "1",
      },
    ],
    tournamentType: "",
  });
  console.log(fields, tournamentDetail, "setFieldssssss test joint");
  // const [prices, setPrices] = useState({ addons: [{ price: "", place: "" }] });
  // console.log(prices, "pricesprices");
  // const [price, setPrice] = useState();

  // const handlePriceChange = (e, i) => {
  //   const { name, value } = e.target;
  //   setPrices((pre) => ({
  //     ...pre,
  //     addons: [
  //       ...pre.addons.slice(0, i),
  //       {
  //         ...pre.addons[i],
  //         [name]: value,
  //       },

  //       ...pre.addons.slice(i + 1),
  //     ],
  //   }));
  // };
  const handleAddPrice = (e) => {
    e.preventDefault();
    setPrices((pre) => ({
      ...pre,
      addons: [...pre.addons, { price: "", place: "" }],
    }));
    // setPrice({ priceOne: "" });
  };

  const handleRemove = (index) => {
    const newData = prices.filter((_, i) => i !== index);
    setPrices(newData);
  };
  // const [price, setPrice] = useState({
  //   priceOne: "",
  //   priceTwo: "",
  //   priceThree: "",
  // });

  const [selectedFiles, setselectedFiles] = useState([]);

  useEffect(() => {
    if (id) {
      onGetTournament(id);
    }
  }, [id]);

  useEffect(() => {
    if (didMountRef.current) {
      try {
        setFields({
          name: tournamentDetail?.name || "",
          tournamentType: tournamentDetail?.tournamentType || "",
          join_type: tournamentDetail?.join_type || "",
          fee_type: tournamentDetail?.fee_type,
          entry_fee: tournamentDetail?.entry_fee,
          minimum_players: tournamentDetail?.minimum_players || "",
          maximum_players: tournamentDetail?.maximum_players || "",
          start_date: tournamentDetail?.start_date || "",
          registration_date: tournamentDetail?.registration_date || "",
          price_pool: tournamentDetail?.price_pool || [],
          level_pool: tournamentDetail?.level_pool || [],
        });

        if (tournamentDetail?.image) {
          setselectedFiles({ preview: collection.image.link });
        }
      } catch (err) {
        console.log("parsing error ", err);
      }
    } else didMountRef.current = true;
  }, [JSON.stringify(tournamentDetail)]);

  const uploadFileSuccess = (response) => {
    setFields((prevState) => ({ ...prevState, image: response.data._id }));
  };

  function handleAcceptedFiles(files) {
    onUploadFile({ image: files[0] }, uploadFileSuccess);

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setselectedFiles(files);
  }

  // const handlePriceChange = (e, i) => {
  //   const { name, value } = e.target;
  //   setPrices((pre) => ({
  //     ...pre,
  //     addons: [
  //       ...pre.addons.slice(0, i),
  //       {
  //         ...pre.addons[i],
  //         [name]: value,
  //       },

  //       ...pre.addons.slice(i + 1),
  //     ],
  //   }));
  // };

  const handleChange = (name, index, field) => (event) => {
    const newValue = event.target.value;

    setFields((prevState) => {
      const updatedFields = { ...prevState };

      if (field) {
        updatedFields[name][index][field] = newValue;
      } else {
        updatedFields[name] = newValue;
      }

      return updatedFields;
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    console.log("running");

    if (loading) {
      return;
    } else {
      // Parse the start and end times to compare them
      const startTime = new Date(`${fields.start_date} ${fields.start_time}`);
      const endTime = new Date(`${fields.end_date} ${fields.end_time}`);

      if (startTime.getTime() >= endTime.getTime()) {
        return toastr.error(
          "End time cannot be before or equal to start time."
        );
      } else if (
        Number(fields.minimum_players) <= 1 ||
        Number(fields.minimum_players) >= Number(fields.maximum_players)
      ) {
        return toastr.error(
          "Please add a minimum player count greater than 1 and less than the maximum player count."
        );
      } else if (Number(fields.maximum_players) <= 0) {
        return toastr.error(
          "Please add a maximum player count greater than minimum player."
        );
      }
      const callback = (response) => {
        if (response.status === "failure") {
          toastr.error(response.message);
        } else {
          toastr.success(response.message);
          history.push("/games");
        }
      };

      if (id) {
        console.log(id, "id");
        props.onUpdateGame(
          {
            ...fields,
            _id: id,
          },
          callback
        );
      } else {
        props.onAddGame(
          {
            ...fields,
            // price_pool: prices,
          },
          callback
        );
      }
    }
  };

  console.log(fields.price_pool, "=====> field change update");

  const goBack = () => {
    console.log("back");
    history.push("/games");
  };

  console.log(tournamentDetail, "update -=======>>>>>>>> detail");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm={1} lg={2}></Col>
            <Col sm={10} lg={8}>
              <Breadcrumbs
                title="Games"
                breadcrumbItem={props.t("Add") + " " + props.t("Games")}
                breadcrumbItems={[
                  { title: props.t("Games"), link: "/games" },
                  { title: props.t(id ? "Edit" : "Add") },
                ]}
              />

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Form onSubmit={onSubmit} className="spinner-content">
                        {error && typeof error === "string" ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}

                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Name")}
                              </Label>

                              <Input
                                type="text"
                                id="formrow-firstname-Input"
                                value={fields.name}
                                onChange={handleChange("name")}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label>Tournament Type</Label>
                              <Input
                                type="select"
                                value={fields.tournamentType}
                                onChange={handleChange("tournamentType")}
                                required
                              >
                                <option value="">Type</option>
                                <option value="paid">Battleship</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("Fee Type")}</Label>
                              <Input
                                type="select"
                                value={fields.fee_type}
                                onChange={handleChange("fee_type")}
                                required
                              >
                                <option value="">Type</option>
                                <option value="paid">Paid</option>
                                <option value="free">Free</option>
                              </Input>
                            </FormGroup>
                          </Col>

                          {fields.fee_type == "paid" ? (
                            <Col md={6}>
                              <FormGroup>
                                <Label for="formrow-firstname-Input">
                                  {props.t("Entry Fee")}
                                </Label>

                                <Input
                                  type="number"
                                  id="formrow-entry_fee-Input"
                                  value={fields.entry_fee}
                                  onChange={handleChange("entry_fee")}
                                  required
                                />
                              </FormGroup>
                            </Col>
                          ) : (
                            ""
                          )}

                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-Min-player-Input">
                                {props.t("Minimum Player")}
                              </Label>

                              <Input
                                type="number"
                                id="formrow-min-player-Input"
                                value={fields.minimum_players}
                                onChange={handleChange("minimum_players")}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Maximum Player")}
                              </Label>

                              <Input
                                type="number"
                                id="formrow-max-player-Input"
                                value={fields.maximum_players}
                                onChange={handleChange("maximum_players")}
                                min={fields?.minimum_players}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Start Date")}
                              </Label>

                              <Input
                                type="datetime-local"
                                id="formrow-Start-date-Input"
                                value={fields.start_date}
                                onChange={handleChange("start_date")}
                                required
                                min={new Date().toISOString().split("T")[0]}
                              />
                            </FormGroup>
                          </Col>
                          {/* <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("End Date")}
                              </Label>

                              <Input
                                type="date"
                                id="formrow-End-date-Input"
                                value={fields.end_date}
                                onChange={handleChange("end_date")}
                                min={fields.start_date}
                                required
                              />
                            </FormGroup>
                          </Col> */}
                          {/* <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Start Time")}
                              </Label>

                              <Input
                                type="time"
                                id="formrow-Start-time-Input"
                                value={fields.start_time}
                                onChange={handleChange("start_time")}
                              />
                            </FormGroup>
                          </Col> */}
                          {/* <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("End Time")}
                              </Label>

                              <Input
                                type="time"
                                id="formrow-End-time-Input"
                                value={fields.end_time}
                                onChange={handleChange("end_time")}
                                required
                              />
                            </FormGroup>
                          </Col> */}
                          <Col lg="6">
                            <Label className="">Registeration Date</Label>
                            <Input
                              type="datetime-local"
                              name="registration_date"
                              id="formrow-registration_date-Input"
                              value={fields.registration_date}
                              onChange={handleChange("registration_date")}
                              required
                            />
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label>Join Type</Label>
                              <Input
                                type="select"
                                value={fields.join_type}
                                onChange={handleChange("join_type")}
                                required
                              >
                                <option value="">Type</option>
                                <option value="everyone">Everyone</option>
                                <option value="nftHolder">NFT Holder</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col md={12} className="my-2">
                            <h4 className="mt-3 font-weight-bold">
                              Price Pool
                            </h4>
                            {/* <Row>
                                  {[...Array(3)].map((_, index) => (
                                    <Col lg="12" className="" key={index}>
                                      <Row className="align-items-end">
                                        <Col lg="6" className="my-2">
                                          <Label> Place</Label>
                                          <input
                                            name="place"
                                            readOnly
                                            onChange={(e) =>
                                              handlePriceChange(e, index)
                                            }
                                            type="text"
                                            value={Number(
                                              prices.addons[index]?.place +
                                                (index + 1)
                                            )}
                                            className="form-control"
                                          />
                                        </Col>
                                        <Col lg="6" className="my-2">
                                          <Label> Price</Label>
                                          <input
                                            name="price"
                                            value={
                                              prices.addons[index]?.price || ""
                                            }
                                            onChange={(e) =>
                                              handlePriceChange(e, index)
                                            }
                                            type="number"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  ))}
                                </Row> */}
                            <Row>
                              {fields?.price_pool &&
                                fields?.price_pool?.length >= 0 &&
                                fields.price_pool?.map((data, index) => (
                                  <Col lg="12" className="" key={index}>
                                    <Row className="align-items-end">
                                      <Col lg="6" className="my-1">
                                        <Label> Place</Label>
                                        <input
                                          name="place"
                                          readOnly
                                          onChange={handleChange(
                                            "price_pool",
                                            index,
                                            "place"
                                          )}
                                          type="text"
                                          value={data?.place}
                                          className="form-control"
                                        />
                                      </Col>
                                      <Col lg="6" className="my-1">
                                        <Label> Price</Label>
                                        <input
                                          name="price"
                                          onChange={handleChange(
                                            "price_pool",
                                            index,
                                            "price"
                                          )}
                                          value={data.price}
                                          type="number"
                                          className="form-control"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                ))}
                            </Row>
                            {/* <Col md="3" className="mt-3">
                                <button
                                  onClick={handleAddPrice}
                                  className="btn btn-primary w-md"
                                >
                                  Add
                                </button>
                              </Col> */}
                          </Col>
                          <Col lg="12" className="my-2">
                            <h4 className="mt-3 font-weight-bold">
                              Level Pool
                            </h4>
                            <Row>
                              {fields?.level_pool &&
                                fields?.level_pool?.length >= 0 &&
                                fields?.level_pool?.map((data, index) => (
                                  <Col lg="12" className="" key={index}>
                                    <Row className="align-items-end">
                                      <Col lg="6" className="my-1">
                                        <Label> Level</Label>
                                        <input
                                          name="level"
                                          readOnly
                                          onChange={handleChange(
                                            "level_pool",
                                            index,
                                            "level"
                                          )}
                                          type="text"
                                          value={data?.level}
                                          className="form-control"
                                        />
                                      </Col>
                                      <Col lg="6" className="my-1">
                                        <Label>Score</Label>
                                        <input
                                          name="score"
                                          onChange={handleChange(
                                            "level_pool",
                                            index,
                                            "score"
                                          )}
                                          value={data?.score}
                                          type="number"
                                          className="form-control"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                ))}
                            </Row>
                          </Col>

                          {/* <Col lg="6">
                            <Label className="">Start Time</Label>
                            <Input
                              type="time"
                              // name="registration_date"
                              id="formrow-registration_time-Input"
                              value={fields.registration_time}
                              onChange={handleChange("registration_time")}
                              required
                            />
                          </Col> */}
                        </Row>
                        <div className="mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            {props.t(id ? "Edit" : "Add")}
                          </button>

                          <button
                            type="button"
                            className="btn outline btn-outline-secondary w-md ml-3"
                            onClick={() => history.goBack()}
                          >
                            <i className="mdi mdi-arrow-left mr-1"></i>
                            {props.t("back")}
                          </button>
                        </div>

                        {loading && (
                          <div className="spinner">
                            <Spinner color="primary" />
                          </div>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm={1} lg={2}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddGame.propTypes = {
  t: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onAddGame: PropTypes.func,
  onUpdateGame: PropTypes.func,
  onGetTournament: PropTypes.func,
};

const mapStateToProps = ({ Game }) => ({
  error: Game.error,
  loading: Game.loading,
  tournamentDetail: Game.tournamentDetail?.data,
});

const mapDispatchToProps = (dispatch) => ({
  onAddGame: (data, history) => dispatch(addTournament(data, history)),
  onUpdateGame: (data, history) => dispatch(updateTournament(data, history)),
  onGetTournament: (id, callback) => dispatch(viewTournament(id, callback)),
  // onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
  // onRemoveFile: (data) => dispatch(removeFile(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddGame))
);
