import {
  CONTENT_PAGE_API_FAIL,
  GET_CONTENT_PAGES,
  GET_CONTENT_PAGES_FAIL,
  GET_CONTENT_PAGES_SUCCESS,
  ADD_CONTENT_PAGE,
  ADD_CONTENT_PAGE_FAIL,
  ADD_CONTENT_PAGE_SUCCESS,
  GET_CONTENT_PAGE,
  GET_CONTENT_PAGE_FAIL,
  GET_CONTENT_PAGE_SUCCESS,
  PUT_CONTENT_PAGE,
  PUT_CONTENT_PAGE_FAIL,
  PUT_CONTENT_PAGE_SUCCESS,
  DELETE_CONTENT_PAGE,
  DELETE_CONTENT_PAGE_FAIL,
  DELETE_CONTENT_PAGE_SUCCESS,
  PUT_CONTENT_PAGES_STATUS,
  PUT_CONTENT_PAGES_STATUS_FAIL,
  PUT_CONTENT_PAGES_STATUS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  contentPages: [],
  totalContentPages: 0,
  error: "",
  loading: false,
  contentPage: {},
}

const ContentPages = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get ContentPages */
    case GET_CONTENT_PAGES:
      return {
        ...state,
        loading: true,
      }

    case GET_CONTENT_PAGES_SUCCESS:
      console.log("action.payload", action.payload)
      return {
        ...state,
        contentPages: action.payload.data || [],
        totalContentPages: action.payload.totalCount || 0,
        loading: false,
        error: "",
      }

    case GET_CONTENT_PAGES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Get ContentPages END */

    /* Add ContentPage */
    case ADD_CONTENT_PAGE:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case ADD_CONTENT_PAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case ADD_CONTENT_PAGE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Add ContentPage END */

    /* Get ContentPage */
    case GET_CONTENT_PAGE:
      return {
        ...state,
        error: "",
        loading: true,
        contentPage: {},
      }

    case GET_CONTENT_PAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        contentPage: {},
      }

    case GET_CONTENT_PAGE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        contentPage: action.payload,
      }
    /* Add ContentPage END */

    /* Update ContentPage */
    case PUT_CONTENT_PAGE:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case PUT_CONTENT_PAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case PUT_CONTENT_PAGE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Update ContentPage END */

    /* Delete ContentPage */
    case DELETE_CONTENT_PAGE:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case DELETE_CONTENT_PAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_CONTENT_PAGE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Delete ContentPage END */

    /* Update ContentPages Status */
    case PUT_CONTENT_PAGES_STATUS:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case PUT_CONTENT_PAGES_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case PUT_CONTENT_PAGES_STATUS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Update ContentPages Status END */

    case CONTENT_PAGE_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default ContentPages
