import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import WalletProvider from "helpers/Wallet/wallet"
import store from "./store";

if (process.env.NODE_ENV !== "development") {
  console.log = () => { };
  console.error = () => { };
  console.warn = () => { };
  console.info = () => { };
}
if (process.env.NODE_ENV === "development") {
  console.error = () => { };
  console.warn = () => { };
  console.info = () => { };
}

const app = (
  <WalletProvider>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </WalletProvider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
