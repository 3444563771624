import {
  ADD_TOURNAMENT,
  REMOVE_TOURNAMENTS,
  UPDATE_TOURNAMENT,
  VIEW_TOURNAMENT,
} from "helpers/url_helper";
import {
  TOURNAMENTS_API_FAIL,
  GET_TOURNAMENTS,
  GET_TOURNAMENTS_FAIL,
  GET_TOURNAMENTS_SUCCESS,
  ADD_TOURNAMENT_SUCCESS,
  ADD_TOURNAMENT_FAIL,
  REMOVE_TOURNAMENT_SUCCESS,
  REMOVE_TOURNAMENT_FAIL,
  VIEW_TOURNAMENT_SUCCESS,
  VIEW_TOURNAMENT_FAIL,
  UPDATE_TOURNAMENT_SUCCESS,
  UPDATE_TOURNAMENT_FAIL
} from "./actionTypes";

const INIT_STATE = {
  billingProducts: [],
  totalBillingProducts: 0,
  error: "",
  loading: false,
  tournamentDetail: [],
  billingProduct: {},
  tournaments: [],
  totaltournaments: 0,
  collection: {},
  date: "",
};

const Tournaments = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Collections */
    case GET_TOURNAMENTS:
      return {
        ...state,
        loading: true,
      };

    case GET_TOURNAMENTS_SUCCESS:
      return {
        ...state,
        tournaments: action.payload.data || [],
        totaltournaments: action.payload.total || 0,
        loading: false,
        error: "",
      };

    case GET_TOURNAMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    /* add tournament */
    case ADD_TOURNAMENT:
      return {
        ...state,
        loading: true,
      };

    case ADD_TOURNAMENT_SUCCESS:
      return {
        ...state,
        tournaments: action.payload.data || [],
        totaltournaments: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case ADD_TOURNAMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    /* update tournament */
    case UPDATE_TOURNAMENT:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_TOURNAMENT_SUCCESS:
      return {
        ...state,
        tournaments: action.payload.data || [],
        totaltournaments: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case UPDATE_TOURNAMENT_FAIL:

    /* REMOVE tournament */
    case REMOVE_TOURNAMENTS:
      return {
        ...state,
        loading: true,
      };

    case REMOVE_TOURNAMENT_SUCCESS:
      return {
        ...state,
        tournaments: action.payload.data || [],
        totaltournaments: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case REMOVE_TOURNAMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    /* VIEW tournament */
    case VIEW_TOURNAMENT:
      return {
        ...state,
        loading: true,
      };

    case VIEW_TOURNAMENT_SUCCESS:
      return {
        ...state,
        tournamentDetail: action.payload,
        loading: false,
        error: "",
      };

    case VIEW_TOURNAMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default Tournaments;
