import {
    GET_NFT_LVL_UPGRADE_HISTORY,
    GET_NFT_LVL_UPGRADE_HISTORY_SUCCESS,
    GET_NFT_LVL_UPGRADE_HISTORY_FAIL,
} from "./actionTypes";


const INIT_STATE = {
    error: "",
    loading: false,
    upgradeHistory: [],
    totalHistory: 0,
};


const NFTLevelHistory = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_NFT_LVL_UPGRADE_HISTORY:
        return {
          ...state,
          loading: true,
        };
  
      case GET_NFT_LVL_UPGRADE_HISTORY_SUCCESS:
        return {
          ...state,
          upgradeHistory: action.payload.data || [],
          totalHistory: action.payload.total || 0,
          loading: false,
          error: "",
        };
  
      case GET_NFT_LVL_UPGRADE_HISTORY_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
    
        default:
        return state;
  }
};

export default NFTLevelHistory;