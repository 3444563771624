import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import Dropzone from "react-dropzone";
import { isObject } from "lodash";

import { Row, Col, FormGroup, FormText, Label, Input } from "reactstrap";
import Instructions from "./Instructions";

// Actions
import { uploadFile } from "store/actions";

// Images
import logo_placeholder from "assets/images/logo-placeholder.png";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const GeneralSettings = (props) => {
  const { fields, handleChange, onUploadFile } = props;

  const [logoselectedFiles, setlogoselectedFiles] = useState([]);

  useEffect(() => {
    if (isObject(fields?.logo) && !!fields?.logo?.link) {
      setlogoselectedFiles([{ preview: fields?.logo?.link }]);
    }
  }, [JSON.stringify(fields?.logo)]);

  const uploadFileSuccess = (name) => (response) => {
    const { _id } = response.data;

    handleChange(name)({ target: { value: _id } });
  };

  const handleAcceptedFiles = (name) => (_files) => {
    const files = _files?.filter((file) => file.size < 5242880);

    if (files.length < _files.length) {
      return toastr.error("Maximum upload file size: 5MB");
    }

    onUploadFile({ image: files[0] }, uploadFileSuccess(name));

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    if (name === "logo") {
      setlogoselectedFiles(files);
    }
  };
  // alert("yaa");
  return (
    <React.Fragment>
      <Row>
        <Col lg={4}>
          <Instructions t={props.t} />
        </Col>

        <Col lg={8}>
          <Row>
            {/* <Col md={6}>
              <FormGroup>
                <Label>Name</Label>

                <Input
                  type="text"
                  value={fields.name}
                  onChange={(e) => {
                    handleChange("name")(e);
                  }}
                />
              </FormGroup>
            </Col> */}

            {/* <Col md={6}>
              <FormGroup>
                <Label>Admin Commission</Label>

                <Input
                  type="number"
                  value={fields.adminCommission}
                  onChange={(e) => {
                    handleChange("adminCommission")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col> */}
            <Col md={6}>
              <FormGroup>
                <Label>Promo Code</Label>

                <div className="status-switch square-switch">
                  <input
                    type="checkbox"
                    id="square-switch1"
                    switch="none"
                    checked={fields.promoCode == "active"}
                    onChange={() => {
                      const value =
                        fields.promoCode == "active" ? "inactive" : "active";

                      handleChange("promoCode")({ target: { value } });
                    }}
                  />
                  <label
                    htmlFor="square-switch1"
                    data-on-label={"active"}
                    data-off-label={"inactive"}
                  />
                </div>
              </FormGroup>
            </Col>
            {/* <Col md={6}>
              <FormGroup>
                <Label>Logo</Label>

                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles("logo")(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => {
                    const imageFile = logoselectedFiles[0];

                    return (
                      <div
                        className="dropzone-single-image avatar-xl border"
                        {...getRootProps()}
                      >
                        <input
                          {...getInputProps()}
                          id="formrow-profile-image-Input"
                          multiple={false}
                        />

                        <img
                          className="rounded avatar-xl"
                          alt={
                            !!imageFile && imageFile.name
                              ? imageFile.name
                              : "hlc"
                          }
                          src={
                            !!imageFile ? imageFile.preview : logo_placeholder
                          }
                        />

                        <div className="edit">
                          <i className="bx bx-pencil"></i>
                        </div>
                      </div>
                    );
                  }}
                </Dropzone>

                <FormText>Max. upload file size: 5MB</FormText>
              </FormGroup>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

GeneralSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneralSettings)
);
