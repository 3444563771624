import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import toastr from "toastr";

import {
  Row,
  Col,
  Button,
  Label,
  Collapse,
  InputGroup,
  Input,
  Form,
  FormGroup,
  FormText,
  Spinner,
} from "reactstrap";

// Actions
import * as ACTIONS from "store/actions";

// Web3
import Web3Intraction from "helpers/web3Intraction";

const Actions = (props) => {
  const [collapse, setcollapse] = useState(false);
  const [fields, setFields] = useState({
    minHoldTime: "",
    minReward: "",
    maxHoldTime: "",
    maxReward: "",
  });
  const [loading, setloading] = useState(false);

  useEffect(() => {
    setFields(props.details);
  }, [props.details]);

  const handleChange = (name) => (event) => {
    const { value } = event.target;

    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  const onSubmit = (type) => async (evt) => {
    evt.preventDefault();

    try {
      setloading(true);
      const web3Intraction = new Web3Intraction("MATIC", props.settings);
      await web3Intraction.setTokenInfo(type, fields);
      setloading(false);
    } catch (err) {
      setloading(false);
      toastr.error(err?.message || err);
    }
  };

  return (
    <React.Fragment>
      <Button
        color="success"
        className="w-100"
        onClick={() => setcollapse((prevState) => !prevState)}
      >
        {collapse ? "Hide" : "Show"} Action
      </Button>

      <Collapse isOpen={collapse} className="spinner-content">
        {loading && (
          <div className="spinner">
            <Spinner color="primary" />
          </div>
        )}

        <Row>
          <Col md={6} className="mt-3">
            <Form onSubmit={onSubmit("minHoldTime")}>
              <FormGroup>
                <Label>Min Hold Time</Label>

                <InputGroup>
                  <Input
                    onChange={handleChange("minHoldTime")}
                    value={fields.minHoldTime}
                    min={0}
                    required
                  />

                  <div className="input-group-append">
                    <span className="input-group-text">Days</span>
                  </div>
                </InputGroup>

                <FormText>
                  Current min hold time: {fields.minHoldTime || 0} days
                </FormText>
              </FormGroup>

              <Button type="submit" color="success">
                Set Min hold time
              </Button>
            </Form>
          </Col>

          <Col md={6} className="mt-3">
            <Form onSubmit={onSubmit("maxHoldTime")}>
              <FormGroup>
                <Label>Max Hold Time</Label>

                <InputGroup>
                  <Input
                    onChange={handleChange("maxHoldTime")}
                    value={fields.maxHoldTime}
                    min={0}
                    required
                  />

                  <div className="input-group-append">
                    <span className="input-group-text">Days</span>
                  </div>
                </InputGroup>

                <FormText>
                  Current max hold time: {fields.maxHoldTime || 0} days
                </FormText>
              </FormGroup>

              <Button type="submit" color="success">
                Set Max hold time
              </Button>
            </Form>
          </Col>

          <Col md={6} className="mt-3">
            <Form onSubmit={onSubmit("minReward")}>
              <FormGroup>
                <Label>Min Reward</Label>

                <InputGroup>
                  <Input
                    onChange={handleChange("minReward")}
                    value={fields.minReward}
                    min={0}
                    max={100}
                    required
                  />

                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </InputGroup>

                <FormText>
                  Current min reward: {fields.minReward || 0}%
                </FormText>
              </FormGroup>

              <Button type="submit" color="success">
                Set Min reward
              </Button>
            </Form>
          </Col>

          <Col md={6} className="mt-3">
            <Form onSubmit={onSubmit("maxReward")}>
              <FormGroup>
                <Label>Max Reward</Label>

                <InputGroup>
                  <Input
                    onChange={handleChange("maxReward")}
                    value={fields.maxReward}
                    min={0}
                    required
                  />

                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </InputGroup>

                <FormText>
                  Current max reward: {fields.maxReward || 0}%
                </FormText>
              </FormGroup>

              <Button type="submit" color="success">
                Set Max reward
              </Button>
            </Form>
          </Col>
        </Row>
      </Collapse>
    </React.Fragment>
  );
};

Actions.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
});

const mapDispatchToProps = (dispatch) => ({
  /* onPostAirdropAction: (data, callback) =>
    dispatch(ACTIONS.postAirdropAction(data, callback)), */
  onGetSettings: () => dispatch(ACTIONS.getSettings()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Actions)
);
