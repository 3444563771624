import React, { useState, useEffect } from "react";

const Lightmode = (...props) => {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true" ? true : false
  );

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem("darkMode", newDarkMode);
  };

  useEffect(() => {
    const bodyElement = document.body;
    if (darkMode) {
      bodyElement.classList.add("light-mode");
      bodyElement.classList.remove("dark-mode");
    } else {
      bodyElement.classList.add("dark-mode");
      bodyElement.classList.remove("light-mode");
    }
  }, [darkMode]);

  return (
    <div className="toggle-theme-wrapper d-flex align-items-center justify-content-between gap-2">
      <p className="text_lightdaark">{} Mode</p>
      <label className="switch">
        <input type="checkbox" checked={darkMode} onChange={toggleDarkMode} />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default Lightmode;
