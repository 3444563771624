import * as TYPES from "./actionTypes";

/* Get Global Settings */
export const getSettings = () => ({
  type: TYPES.GET_SETTINGS,
});

export const getSettingsSuccess = (data) => ({
  type: TYPES.GET_SETTINGS_SUCCESS,
  payload: data,
});

export const getSettingsFail = (error) => ({
  type: TYPES.GET_SETTINGS_FAIL,
  payload: error,
});
/* Get Global Settings END */

/* Update Global Settings */
export const putSettings = (data) => ({
  type: TYPES.PUT_SETTINGS,
  payload: data,
});

export const putSettingsSuccess = (data) => ({
  type: TYPES.PUT_SETTINGS_SUCCESS,
  payload: data,
});

export const putSettingsFail = (error) => ({
  type: TYPES.PUT_SETTINGS_FAIL,
  payload: error,
});
/* Update Global Settings END */

/* Get Access List */
export const getAccessList = () => ({
  type: TYPES.GET_ACCESS_LIST,
});

export const getAccessListSuccess = (data) => ({
  type: TYPES.GET_ACCESS_LIST_SUCCESS,
  payload: data,
});

export const getAccessListFail = (error) => ({
  type: TYPES.GET_ACCESS_LIST_FAIL,
  payload: error,
});
/* Get Access List END */

/* Get Store Types */
export const getStoreTypes = () => ({
  type: TYPES.GET_STORE_TYPES,
});

export const getStoreTypesSuccess = (data) => ({
  type: TYPES.GET_STORE_TYPES_SUCCESS,
  payload: data,
});

export const getStoreTypesFail = (error) => ({
  type: TYPES.GET_STORE_TYPES_FAIL,
  payload: error,
});
/* Get Store Types END */

/* Get Store Started */
export const getStarted = () => ({
  type: TYPES.GET_STARTED,
});

export const getStartedSuccess = (data) => ({
  type: TYPES.GET_STARTED_SUCCESS,
  payload: data,
});

export const getStartedFail = (error) => ({
  type: TYPES.GET_STARTED_FAIL,
  payload: error,
});
/* Get Store Started END */

/* Post Contract Deploy */
export const postDeployContract = (data, callback) => ({
  type: TYPES.POST_CONTRACT_DEPLOY,
  payload: { data, callback },
});

export const postDeployContractSuccess = (data) => ({
  type: TYPES.POST_CONTRACT_DEPLOY_SUCCESS,
  payload: data,
});

export const postDeployContractFail = (error) => ({
  type: TYPES.POST_CONTRACT_DEPLOY_FAIL,
  payload: error,
});
/* Post Contract Deploy */

/* Post Contract Deploy For multisend */
export const postDeployContractForMultisend = (data, callback) => ({
  type: TYPES.POST_CONTRACT_DEPLOY_MULTISEND,
  payload: { data, callback },
});

export const postDeployContractForMultisendSuccess = (data) => ({
  type: TYPES.POST_CONTRACT_DEPLOY_MULTISEND_SUCCESS,
  payload: data,
});

export const postDeployContractForMultisendFail = (error) => ({
  type: TYPES.POST_CONTRACT_DEPLOY_MULTISEND_FAIL,
  payload: error,
});
/* Post Contract Deploy For Multisend */
/* Post Contract Deploy */
export const postDeployContractPpg = (data, callback) => ({
  type: TYPES.POST_CONTRACT_DEPLOY_PPG,
  payload: { data, callback },
});

export const postDeployContractSuccessPpg = (data) => ({
  type: TYPES.POST_CONTRACT_DEPLOY_SUCCESS_PPG,
  payload: data,
});

export const postDeployContractFailPpg = (error) => ({
  type: TYPES.POST_CONTRACT_DEPLOY_FAIL_PPG,
  payload: error,
});
/* Post Contract Deploy */
/* Post Contract Deploy */
export const postDeployContractGov = (data, callback) => ({
  type: TYPES.POST_CONTRACT_DEPLOY_GOV,
  payload: { data, callback },
});

export const postDeployContractSuccessGov = (data) => ({
  type: TYPES.POST_CONTRACT_DEPLOY_SUCCESS_GOV,
  payload: data,
});

export const postDeployContractFailGov = (error) => ({
  type: TYPES.POST_CONTRACT_DEPLOY_FAIL_GOV,
  payload: error,
});
/* Post Contract Deploy */

/* Post Compile Contract */
export const postCompileContract = (data, callback) => ({
  type: TYPES.POST_COMPILE_CONTRACT,
  payload: { data, callback },
});

export const postCompileContractSuccess = (data) => ({
  type: TYPES.POST_COMPILE_CONTRACT_SUCCESS,
  payload: data,
});

export const postCompileContractFail = (error) => ({
  type: TYPES.POST_COMPILE_CONTRACT_FAIL,
  payload: error,
});
/* Post Compile Contract */

export const postCompileContractPpg = (data, callback) => {
  return {
    type: TYPES.POST_COMPILE_CONTRACT_PPG,
    payload: { data, callback },
  };
};

export const postCompileContractSuccessPpg = (data) => ({
  type: TYPES.POST_COMPILE_CONTRACT_SUCCESS_PPG,
  payload: data,
});

export const postCompileContractFailPpg = (error) => ({
  type: TYPES.POST_COMPILE_CONTRACT_FAIL_PPG,
  payload: error,
});

export const postCompileContractGov = (data, callback) => {
  return {
    type: TYPES.POST_COMPILE_CONTRACT_GOV,
    payload: { data, callback },
  };
};

export const postCompileContractSuccessGov = (data) => ({
  type: TYPES.POST_COMPILE_CONTRACT_SUCCESS_GOV,
  payload: data,
});

export const postCompileContractFailGov = (error) => ({
  type: TYPES.POST_COMPILE_CONTRACT_FAIL_GOV,
  payload: error,
});

/* Air drop Settings */
export const airDrop = (data, callback) => ({
  type: TYPES.AIR_DROP,
  payload: { data, callback },
});
export const airDropV2 = (data, callback) => ({
  type: TYPES.AIR_DROP_V2,
  payload: { data, callback },
});

export const airDropSuccess = (data) => ({
  type: TYPES.AIR_DROP_SUCCESS,
  payload: data,
});

export const airDropFail = (error) => ({
  type: TYPES.AIR_DROP_FAIL,
  payload: error,
});

export const airDropList = (data) => ({
  type: TYPES.AIR_DROP_LIST,
  payload: data,
});

export const airDropListSuccess = (data) => ({
  type: TYPES.AIR_DROP_LIST_SUCCESS,
  payload: data,
});

export const airDropListFail = (error) => ({
  type: TYPES.AIR_DROP_LIST_FAIL,
  payload: error,
});
/* Air Settings END */


/* Mobile App Settings */

export const putMobileSettings = (data) => ({
  type: TYPES.PUT_MOBILE_SETTINGS,
  payload: data,
});

export const putMobileSettingsSuccess = (data) => ({
  type: TYPES.PUT_MOBILE_SETTINGS_SUCCESS,
  payload: data,
});

export const putMobileSettingsFail = (error) => ({
  type: TYPES.PUT_MOBILE_SETTINGS_FAIL,
  payload: error,
});


export const getDailyLimit = (data) => ({
  type: TYPES.GET_DAILY_LIMIT,
  payload: data,
});

export const getDailyLimitSuccess = (data) => ({
  type: TYPES.GET_DAILY_LIMIT_SUCCESS,
  payload: data,
});

export const getDailyLimitFail = (error) => ({
  type: TYPES.GET_DAILY_LIMIT_FAIL,
  payload: error,
});


export const putDailyLimit = (data, callback) => ({
  type: TYPES.PUT_DAILY_LIMIT,
  payload: {data, callback},
});

export const putDailyLimitSuccess = (data) => ({
  type: TYPES.PUT_DAILY_LIMIT_SUCCESS,
  payload: data,
});

export const putDailyLimitFail = (error) => ({
  type: TYPES.PUT_DAILY_LIMIT_FAIL,
  payload: error,
});

export const getPendingWithdraw = (data) => ({
  type: TYPES.GET_PENDING_WITHDRAW,
  payload: data,
});

export const getPendingWithdrawSuccess = (data) => ({
  type: TYPES.GET_PENDING_WITHDRAW_SUCCESS,
  payload: data,
});

export const getPendingWithdrawFail = (error) => ({
  type: TYPES.GET_PENDING_WITHDRAW_FAIL,
  payload: error,
});


export const getWithdrawHistory = (data) => ({
  type: TYPES.GET_WITHDRAW_HISTORY,
  payload: data,
});

export const getWithdrawHistorySuccess = (data) => ({
  type: TYPES.GET_WITHDRAW_HISTORY_SUCCESS,
  payload: data,
});

export const getWithdrawHistoryFail = (error) => ({
  type: TYPES.GET_WITHDRAW_HISTORY_FAIL,
  payload: error,
});

export const withdrawStatus = (data, callback) => ({
  type: TYPES.WITHDRAW_STATUS,
  payload: {data, callback},
});

export const withrawStatusSuccess = (data) => ({
  type: TYPES.WITHDRAW_STATUS_SUCCESS,
  payload: data,
});

export const withdrawStatusFail = (error) => ({
  type: TYPES.WITHDRAW_STATUS_FAIL,
  payload: error,
});
