import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, Label, FormGroup, Input, Col } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

const PriceModal = (props) => {
  const { isOpen, toggle, onConfirm, items, handleChange, onClose } = props;
  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      autoFocus={true}
      centered={true}
      toggle={toggle}
      items={items}
      scrollable={"true"}
    >
      <div className="modal-content remove-confirm">
        <ModalBody>
            <div className="modal_form text-center">
                <h3 className="m-3"><strong>Update Price for Selected NFT's</strong></h3>
                <h6 className="mt-3"><strong>Please enter NFT's prices below:</strong></h6>
                <Col className="mt-3 text-center" lg={12}>
                    <ul className="mt-3"> 
                        {items?.map((item, index) => (
                            <li className= "mt-5 row flex" key={index}>
                            <span className="text-white text-bold col-4 text-left">{item.name}</span>  
                            <Input
                              className="col-8"
                                type="number"
                                id="formrow-price-Input"
                                value={item.price}
                                onChange={(e) => handleChange(item, index)(e)}
                                required
                              />
                            </li>
                        ))}
                    </ul>
                </Col>
            </div>

          <div className="delete-btns mt-5">
            <Button type="button" outline color="secondary" onClick={onClose}>
              {props.t("no_cancel")}
            </Button>

            <Button type="button" color="primary" onClick={onConfirm}>
            
              {props.t("Yes, Update it")}
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

PriceModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default withTranslation()(PriceModal);
