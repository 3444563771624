import * as CONST from "./actionTypes";


/* Get Logs */
export const getLogs = (data) => ({
  type: CONST.GET_LOGS,
  payload: data,
});

export const getLogsSuccess = (logs) => ({
  type: CONST.GET_LOGS_SUCCESS,
  payload: logs,
});

export const getLogsFail = (error) => ({
  type: CONST.GET_LOGS_FAIL,
  payload: error,
});
