import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import {
  viewTournament,
  getTournaments,
  removeTournament,
} from "store/actions";
import ListColumns from "./ListColumns";
import ConfirmModal from "./ConfirmModal";
import DeployConfirmModal from "./DeployConfirmModal";
import toastr from "toastr";

const GamesList = ({
  Tournaments,
  accessLevel,
  history,
  collections,
  totalTournaments,
  onGetCollections,
  onDeleteTournament,
  loading,
  ...props
}) => {
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    tournmentId: null,
  });
  const [deployConfirmModal, setdeployConfirmModal] = useState({
    isOpen: false,
    collectiond: null,
  });
  const [filter, setFilter] = useState({
    orderBy: "date_created_utc",
    order: -1,
    page: 1,
    limit: 20,
    search: "",
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
    status: ["created","active","inactive", "cancel", "complete"]
  });
  const [searchText, setSearchText] = useState("");
  const [TournamentList, setTournamentList] = useState([]);
  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalTournaments,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    onGetCollections(filter);
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    setTournamentList(Tournaments || []);
  }, [Tournaments]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText.trim());
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (tournmentId) => {
    setConfirmModal((prevState) => ({
      isOpen: !prevState.isOpen,
      tournmentId,
    }));
  };

  const toggleDeployConfirmModal = (collection) => {
    setdeployConfirmModal((prevState) => ({
      isOpen: !prevState.isOpen,
      collection,
    }));
  };

  const onDeploySuccess = (res) => {
    if (res.status == "failure") {
      toggleDeployConfirmModal();
      toastr.error(res.message);
      return;
    }
    toggleDeployConfirmModal();

    onGetCollections(filter);
  };

  const removeConfirm = () => {
    const { tournmentId, isOpen } = confirmModal;

    const callback = () => {
      setTimeout(() => {
        toastr.success("Remove tournament successfully!");
        onGetCollections(filter);
      }, 3000);
    };

    setConfirmModal({ isOpen: !isOpen, tournmentId: null });
    onDeleteTournament({ _id: tournmentId }, callback);
  };

  const onSearch = (event) => {
    event.preventDefault();
    setFilter((prevState) => ({ ...prevState, search: searchText.trim() }));
  };

  //console.log(Tournaments, "=======> Tournaments");

  return (
    <React.Fragment>
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />
      <DeployConfirmModal
        {...deployConfirmModal}
        toggle={toggleDeployConfirmModal}
        onSuccess={onDeploySuccess}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Tournaments"
            breadcrumbItem={props.t("Tournaments")}
            breadcrumbItems={[{ title: props.t("Tournaments") }]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={TournamentList.length > 0 ? TournamentList : []}
                        columns={ListColumns(toggleConfirmModal, props.t)}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="3">
                                <SearchInput
                                  {...toolkitProps.searchProps}
                                  triggerSearch={onSearch}
                                  placeholder={props.t("Search by name,Fee type")}
                                  searchText={searchText}
                                />
                              </Col>

                              <Col sm="2">
                                {!!filter.search && (
                                  <div
                                    className="mr-4 mb-2"
                                    style={{
                                      height: "calc( 1.5em + 0.94rem + 2px)",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFilter((prevState) => ({
                                          ...prevState,
                                          search: "",
                                        }));
                                        setSearchText("");
                                      }}
                                      className=""
                                    >
                                      {props.t("Clear Filters")}
                                    </Link>
                                  </div>
                                )}
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

// GamesList.propTypes = {
//   collections: PropTypes.array,
//   totalCollections: PropTypes.number,
//   onGetCollections: PropTypes.func,
//   onDeleteCollection: PropTypes.func,
//   onPutCollectionsStatus: PropTypes.func,
// };

const mapStateToProps = ({ Game }) => ({
  loading: Game.loading,
  Tournaments: Game.tournaments,
  totalTournaments: Game.totaltournaments,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCollections: (data) => dispatch(getTournaments(data)),
  onDeleteTournament: (data, callback) =>
    dispatch(removeTournament(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GamesList))
);
