import { ADD_TOURNAMENT } from "helpers/url_helper";
import {
  ADD_BALANCE,
  ADD_BALANCE_SUCCESS,
  ADD_BALANCE_FAIL,
  REMOVE_BALANCE,
  REMOVE_BALANCE_SUCCESS,
   REMOVE_BALANCE_FAIL,
  BALANCE_API_FAIL,
  GET_BALANCE_HISTORY,
  GET_BALANCE_HISTORY_SUCCESS,
  GET_BALANCE_HISTORY_FAIL,
  GET_USER_BALANCE,
  GET_USER_BALANCE_SUCCESS,
  GET_USER_BALANCE_FAIL,
  GET_ACTIVITY_HISTORY,
  GET_ACTIVITY_HISTORY_SUCCESS,
  GET_ACTIVITY_HISTORY_FAIL
} from "./actionTypes";

export const apiFail = (error) => ({
  type: BALANCE_API_FAIL_API_FAIL,
  payload: error,
});

/* add balance */
export const addBalance = (data, callback) => ({
  console: console.log(data, "dataaddBalance"),
  type: ADD_BALANCE,
  payload: data,
  callback,
});

export const addBalanceSuccess = (Tournaments) => ({
  type: ADD_BALANCE_SUCCESS,
  payload: Tournaments,
});

export const addBalanceFail = (error) => ({
  type: ADD_BALANCE_FAIL,
  payload: error,
});

/* remove balance */
export const removeBalance = (data, callback) => ({
  type: REMOVE_BALANCE,
  payload: data,  callback,
});

export const removeBalanceSuccess = (Tournaments) => ({
  type: REMOVE_BALANCE_SUCCESS,
  payload: Tournaments,
});

export const removeBalanceFail = (error) => ({
  type: REMOVE_BALANCE_FAIL,
  payload: error,
});

// get user balance

export const getUserBalance = (data) => ({
  type: GET_USER_BALANCE,
  payload: data,
});

export const getUserBalanceSuccess = (matches) => ({
  type: GET_USER_BALANCE_SUCCESS,
  payload: matches,
});

export const getUserBalanceFail = (error) => ({
  type: GET_USER_BALANCE_FAIL,
  payload: error,
});


// get balance history

export const getBalanceHistory = (data) => ({
  type: GET_BALANCE_HISTORY,
  payload: data,
});

export const getBalanceHistorySuccess = (matches) => ({
  type: GET_BALANCE_HISTORY_SUCCESS,
  payload: matches,
});

export const getBalanceHistoryFail = (error) => ({
  type: GET_BALANCE_HISTORY_FAIL,
  payload: error,
});


export const getActivityHistory = (data) => ({
  type: GET_ACTIVITY_HISTORY,
  payload: data,
});

export const getActivityHistorySuccess = (activities) => ({
  type: GET_ACTIVITY_HISTORY_SUCCESS,
  payload: activities,
});

export const getActivityHistoryFail = (error) => ({
  type: GET_ACTIVITY_HISTORY_FAIL,
  payload: error,
});
