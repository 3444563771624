import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { Card, CardBody, Col, Container, Row, Spinner, Input } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import { getMobileGameLeaderboardHistory, updateMobileGameLeaderboardPoints } from "store/actions";
import ListColumns from "./ListColumns";
import toastr from "toastr";
import ConfirmModal from "./ConfirmModal";

const MobileGameLeaderboardHistory = ({
  GameLeaderboardHistory,
  totalGameLeaderboardHistory,
  onGetGameLeaderboardHistory,
  onUpdateLeaderboardPoints,
  loading,
  ...props
}) => {
  const [filter, setFilter] = useState({
    orderBy: "points",
    order: -1,
    page: 1,
    limit: 20,
    search: "",
    weekNo: "",
  });
  const [searchText, setSearchText] = useState("");
  const [GameLeaderboardHistoryList, setGameLeaderboardHistory] = useState([]);
  const [completeMod, setCompleteMod] = useState({ show: false, leaderboardId: "", points: 0 });

  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalGameLeaderboardHistory,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    onGetGameLeaderboardHistory(filter);
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    setGameLeaderboardHistory(GameLeaderboardHistory || []);
  }, [GameLeaderboardHistory]);


  const toggleConfirmModal = useCallback((leaderboardId, points) => {
    setCompleteMod((prevState) => ({ show: !prevState.show, leaderboardId: leaderboardId , points: points}));
  }, []);

  const handleChange = (e) => {
    console.log("targe", e?.target?.value);
    setCompleteMod((prevState) => ({ ...prevState, points: Number(e?.target?.value) }));
  }

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText.trim());
    }

    if (type === "sort") {
      // if (sortField == "createdAt") {
      //   sortField = "date_created_utc";
      // }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } 
      else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const onSearch = (event) => {
    event.preventDefault();
    setFilter((prevState) => ({ ...prevState, search: searchText.trim() }));
  };

  return (
    <>
       <ConfirmModal
        show={completeMod.show}
        toggle={toggleConfirmModal}
        handleChange={handleChange}
        points={completeMod.points}
        onConfirm={() => {
          const callback = (res) => {
            setCompleteMod({ show: false, leaderboardId: "", points : 0 });
            if (res.status === "success") {
              toastr.success("Leaderboard updated successfully.");
              onGetGameLeaderboardHistory(filter);
            }
            else {
              toastr.error(res.message);
            }
          };
          onUpdateLeaderboardPoints({
            leaderboardId: completeMod?.leaderboardId,
            points: Number(completeMod?.points),
          }, callback)
        }}
      />


      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Mobile Game Leaderboard History"
              breadcrumbItem={props.t("Mobile Game Leaderboard History")}
              breadcrumbItems={[{ title: props.t("Mobile Game Leaderboard History") }]}
            />

            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          data={GameLeaderboardHistoryList.length > 0 ? GameLeaderboardHistoryList : []}
                          columns={ListColumns(toggleConfirmModal, props.t)}
                          bootstrap4
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="3">
                                  <SearchInput
                                    {...toolkitProps.searchProps}
                                    triggerSearch={onSearch}
                                    placeholder={props.t("Search by email, username...")}
                                    searchText={searchText}
                                  />
                                </Col>

                                <Col sm="3">
                                  <Input
                                    type="select"
                                    value={filter.weekNo}
                                    onChange={(e) =>
                                      setFilter((prev) => ({
                                        ...prev,
                                        weekNo: e.target.value,
                                      }))
                                    }
                                  >
                                    <option value="">Select Week</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                    <option value="32">32</option>
                                    <option value="33">13</option>
                                    <option value="34">34</option>
                                    <option value="35">35</option>
                                    <option value="36">36</option>
                                    <option value="37">37</option>
                                    <option value="38">38</option>
                                    <option value="39">39</option>
                                    <option value="40">40</option>
                                    <option value="41">41</option>
                                    <option value="42">42</option>
                                    <option value="43">43</option>
                                    <option value="44">44</option>
                                    <option value="45">45</option>
                                    <option value="46">46</option>
                                    <option value="47">47</option>
                                    <option value="48">48</option>
                                    <option value="49">49</option>
                                    <option value="50">50</option>
                                    <option value="51">51</option>
                                    <option value="52">52</option>
                                  </Input>
                                </Col>
                                <Col sm="2">
                                  {!!filter.search || !!filter.weekNo ? (
                                    <div
                                      className="mr-4 mb-2"
                                      style={{
                                        height: "calc( 1.5em + 0.94rem + 2px)",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setFilter((prevState) => ({
                                            ...prevState,
                                            search: "",
                                            weekNo: ""
                                          }));
                                          setSearchText("");
                                        }}
                                        className=""
                                      >
                                        {props.t("Clear Filters")}
                                      </Link>
                                    </div>
                                  ) : ""}
                                </Col>

                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive spinner-content">
                                    <BootstrapTable
                                      responsive
                                      remote
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table table-centered table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange}
                                      {...paginationTableProps}
                                      defaultSorted={[
                                        // {
                                        //   dataField: "points",
                                        //   order: "desc",
                                        // },
                                      ]}
                                      sort={{
                                        sortCaret: (order) =>
                                          order === "asc" ? (
                                            <span className="caret">
                                              <i className="bx bx-caret-up" />
                                            </span>
                                          ) : order === "desc" ? (
                                            <span className="caret">
                                              <i className="bx bx-caret-down" />
                                            </span>
                                          ) : null,
                                      }}
                                    />

                                    {loading && (
                                      <div className="spinner">
                                        <Spinner color="primary" />
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>

  );
};

MobileGameLeaderboardHistory.propTypes = {
  GameLeaderboardHistory: PropTypes.array,
  totalGameLeaderboardHistory: PropTypes.number,
  onGetGameLeaderboardHistory: PropTypes.func,
  onUpdateLeaderboardPoints: PropTypes.func,
};

const mapStateToProps = ({ GameHistory }) => ({
  loading: GameHistory.loading,
  GameLeaderboardHistory: GameHistory.gameLeaderboardHistory,
  totalGameLeaderboardHistory: GameHistory.totalGameLeaderboardHistory,
});

const mapDispatchToProps = (dispatch) => ({
  onGetGameLeaderboardHistory: (data) => dispatch(getMobileGameLeaderboardHistory(data)),
  onUpdateLeaderboardPoints: (data, callback) => dispatch(updateMobileGameLeaderboardPoints(data, callback))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(MobileGameLeaderboardHistory))
);