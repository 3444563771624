import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const CustomersColumns = (t) => [
  {
    dataField: "crewCode",
    text: t("Crew Code"),
    sort: true,
    formatter: (_, row) => row.crewCode || "--",
  },
  {
    dataField: "email",
    text: t("Email"),
    sort: true,
    formatter: (_, row) => (row.email ? row.email : "-"),
  },
  {
    dataField: "username",
    text: t("Username"),
    sort: true,
    formatter: (_, row) => (row.username ? row.username : "-"),
  },

  {
    dataField: "used",
    text: t("Used"),
    sort: true,
    formatter: (_, row) => (row.crewMembersCount ? row.crewMembersCount : "0"),
  },
  {
    text: t("Created At"),
    dataField: "createdAt",
    sort: true,
    formatter: (_, row) =>
      row.date_created_utc ? (
        <>
          {moment(row.date_created_utc).local().format("YYYY-MM-DD HH:mm:ss")}
        </>
      ) : (
        "-"
      ),
  },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        {row.crewMembersCount > 0 && (
          <Link
            to={`/crew-codes/${row._id}`}
            className="text-secondary"
          >
            <i
              className="fas fa-eye mr-3"
              id={`view-${row._id}-tooltip`}
            />
            <UncontrolledTooltip
              placement="top"
              target={`view-${row._id}-tooltip`}
            >
              {t("Crew Members List")}
            </UncontrolledTooltip>
          </Link>
        )}

      </>
    ),
  },
];

export default CustomersColumns;
