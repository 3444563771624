import * as TYPES from "./actionTypes";

export const apiFail = (error) => ({
  type: TYPES.DOCUMENT_API_FAIL,
  payload: error,
});

/* Get documents */
export const getDocuments = (data) => ({
  type: TYPES.GET_DOCUMENTS,
  payload: data,
});

export const getDocumentsSuccess = (documents) => ({
  type: TYPES.GET_DOCUMENTS_SUCCESS,
  payload: documents,
});

export const getDocumentsFail = (error) => ({
  type: TYPES.GET_DOCUMENTS_FAIL,
  payload: error,
});
/* Get Documents END */

/* Add Document */
export const addDocument = (document, history) => ({
  type: TYPES.ADD_DOCUMENT,
  payload: { document, history },
});

export const addDocumentFail = (error) => ({
  type: TYPES.ADD_DOCUMENT_FAIL,
  payload: error,
});

export const addDocumentSuccess = () => ({
  type: TYPES.ADD_DOCUMENT_SUCCESS,
});
/* Add Document END */

/* Get Document */
export const getDocument = (id) => ({
  type: TYPES.GET_DOCUMENT,
  payload: id,
});

export const getDocumentFail = (error) => ({
  type: TYPES.GET_DOCUMENT_FAIL,
  payload: error,
});

export const getDocumentSuccess = (document) => ({
  type: TYPES.GET_DOCUMENT_SUCCESS,
  payload: document,
});
/* Get Document END */

/* Update Document */
export const putDocument = (data, history) => ({
  type: TYPES.PUT_DOCUMENT,
  payload: { data, history },
});

export const putDocumentFail = (error) => ({
  type: TYPES.PUT_DOCUMENT_FAIL,
  payload: error,
});

export const putDocumentSuccess = () => ({
  type: TYPES.PUT_DOCUMENT_SUCCESS,
});
/* Update Document END */

/* Delete Document */
export const deleteDocument = (data, callback) => ({
  type: TYPES.DELETE_DOCUMENT,
  payload: { data, callback },
});

export const deleteDocumentFail = (error) => ({
  type: TYPES.DELETE_DOCUMENT_FAIL,
  payload: error,
});

export const deleteDocumentSuccess = () => ({
  type: TYPES.DELETE_DOCUMENT_SUCCESS,
});
/* Delete Faz END */
