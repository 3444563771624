import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects";
import toastr from "toastr";
// Customer Redux States
import { GET_LOGS,} from "./actionTypes";
import {
  getLogsFail,
  getLogsSuccess,

} from "./actions";

import {
  getLogs,
 
} from "helpers/backend_helper";

import * as actType from "./actions";

toastr.options = {
  newestOnTop: true,
  progressBar: true,
};

function* fetchLogs({ payload }) {
  try {
    const response = yield call(getLogs, payload);

    if (response.status == "failure") {
      return yield put(getLogsFail(response.message));
    }

    yield put(getLogsSuccess(response));
  } catch (error) {
    yield put(getLogsFail(error));
  }
}


function* customersSaga() {
  yield takeLatest(GET_LOGS, fetchLogs);

}

export default customersSaga;
