import {
    GET_NFT_REPAIR_HISTORY,
    GET_NFT_REPAIR_HISTORY_SUCCESS,
    GET_NFT_REPAIR_HISTORY_FAIL,
} from "./actionTypes";


/* Get NFT lvl upgrade history */
export const getNftRepairHistory = (data) => ({
    type: GET_NFT_REPAIR_HISTORY,
    payload: data,
});
  
export const getNftRepairHistorySuccess = (history) => ({
    type: GET_NFT_REPAIR_HISTORY_SUCCESS,
    payload: history,
});
  
export const getNftRepairHistoryFail = (error) => ({
    type: GET_NFT_REPAIR_HISTORY_FAIL,
    payload: error,
});