import React from "react"


const SignupForm = props => {
  const {
    setactiveTab,
    fields,
    handleChange,
    registerUserEmail,
    apiError,
  } = props

  const submitNext = () => {
    if (!fields?.email) {
      window.scrollTo(0, 0)
      return apiError("Email is required!")
    }
    if (!fields?.firstName) {
      window.scrollTo(0, 0)
      return apiError("First Name is required!")
    }
    if (!fields?.mobileNumber) {
      window.scrollTo(0, 0)
      return apiError("Mobile Number is required!")
    }
    if (!fields?.password) {
      window.scrollTo(0, 0)
      return apiError("Password is required!")
    }

    registerUserEmail(fields, () => {
      setactiveTab(2)
    })
  }

  return (
    <div className="tab">
      <div className="row">
        <div className="col-lg-12">
          <h1>First, let's get your account set up for the Go marketplace.</h1>
        </div>

        <div className="col-lg-12">
          <div className="input-group">
            <label>Your email</label>
            <input
              required
              type="text"
              placeholder="Your email"
              value={fields?.email}
              onChange={handleChange("email")}
            />
          </div>
        </div>

        <div className="col-lg-6 nopaddright">
          <div className="input-group">
            <label>First Name</label>
            <input
              required
              type="text"
              placeholder="First Name"
              value={fields?.firstName}
              onChange={handleChange("firstName")}
            />
          </div>
        </div>

        <div className="col-lg-6 nopaddleft">
          <div className="input-group">
            <label>Last Name</label>
            <input
              required
              type="text"
              placeholder="last Name"
              name="lastName"
              value={fields?.lastName}
              onChange={handleChange("lastName")}
            />
          </div>
        </div>

        <div className="col-lg-12">
          <div className="input-group">
            <label>Mobile Number</label>
            <input
              required
              type="text"
              placeholder="Mobile Number"
              value={fields?.mobileNumber}
              onChange={handleChange("mobileNumber")}
            />
          </div>
        </div>

        <div className="col-lg-12">
          <div className="input-group">
            <label>Password</label>
            <input
              required
              type="password"
              placeholder="Enter Password"
              value={fields?.password}
              onChange={handleChange("password")}
            />
          </div>
        </div>

        <div className="col-lg-12">
          <div className="custom-control custom-checkbox mb-3">
            <input
              required
              type="checkbox"
              className="custom-control-input"
              id="customCheck"
              name="example1"
              checked={fields?.policy}
              onChange={e => {
                handleChange("policy")({ target: { value: !fields?.policy } })
              }}
            />

            <label className="custom-control-label" for="customCheck">
              By creating account you agree to our{" "}
              <a href="#" className="colortextlink">
                terms and condition
              </a>
            </label>
          </div>
        </div>

        <div className="col-lg-12">
          <button
            type="button"
            className="themebtn fullwidthbtn"
            onClick={submitNext}
          >
            Next: Marketplace details
          </button>
        </div>
      </div>
    </div>
  )
}

export default SignupForm
