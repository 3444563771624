import { call, put, takeEvery } from "redux-saga/effects";

// Collection Redux States
import {
 ADD_OPERATORS, ADD_OPERATORS_FAIL, ADD_OPERATORS_SUCCESS, GET_OPERATORS, REMOVE_OPERATORS, UPDATE_OPERATORS, 
} from "./actionTypes";
import {
 addOperator,
 addOperatorFail,
 addOperatorSuccess,
 getOperatorFail,
 getOperatorSuccess,
 updateperatorSuccess,
 updateOperatorFail,
 removeOperatorFail, 
 removeOperatorSuccess
} from "./actions";
import toastr from "toastr";

import {

  postAddOperator,getOperatorList,updateOperator,removeOperator
} from "helpers/backend_helper";


function* addOperatorSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(postAddOperator, data);
    callback(response);
    if (response.status == "success") {
      toastr.success(response.message);
     return yield put(addOperatorSuccess(response));
    }else{
      return yield put(addOperatorFail(response.message));
    }
  } catch (error) {
    yield put(addOperatorFail(error));
  }
}


function* fetchOperators({ payload }) {
  try {
    const response = yield call(getOperatorList, payload);

    if (response.status == "failure") {
      return yield put(getOperatorFail(response.message));
    }

    yield put(getOperatorSuccess(response));
  } catch (error) {
    yield put(getOperatorFail(error));
  }
}



function* updateOperatorSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(updateOperator, data);
    callback(response);
    if (response.status == "success") {
      toastr.success(response.message);
     return yield put(updateperatorSuccess(response));
    }else{
      return yield put(updateOperatorFail(response.message));
    }
  } catch (error) {
    yield put(updateOperatorFail(error));
  }
}


function* removeOperatorSaga({ payload: { data, onComplete } }) {
  try {
    const response = yield call(removeOperator, data);
    onComplete(response);
    if (response.status == "success") {
      toastr.success(response.message);
     return yield put(removeOperatorSuccess(response));
    }else{
      return yield put(removeOperatorFail(response.message));
    }
  } catch (error) {
    yield put(removeOperatorFail(error));
  }
}


function* OperatorSaga() {
  yield takeEvery(ADD_OPERATORS, addOperatorSaga);
  yield takeEvery(GET_OPERATORS, fetchOperators);

  yield takeEvery(UPDATE_OPERATORS, updateOperatorSaga);
  yield takeEvery(REMOVE_OPERATORS, removeOperatorSaga);

}

export default OperatorSaga;
