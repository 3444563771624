import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PrivateLvlPoints from "./Private-lvl-Points";
import MainShipLvlPoints from "./Main-Ship-lvl-Points";
import RestShipLvlPoints from "./Rest-Ship-lvl-Points";
import PrivateLvlCrystals from "./Private-lvl-Crystals";
import MiniGame from "./Mini-Game";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Alert,
} from "reactstrap";

// Actions
import { getSettings, putMobileSettings } from "store/actions";

// Other Settings
import GeneralSettings from "./General";


const Settings = (props) => {
  const { onGetSettings, onPutSettings, loading, error } = props;

  const [fields, setFields] = useState({

    adminCommission: props.settings?.minigameSetting?.adminCommission,

    mobileAppSetting: props.settings.mobileAppSetting || {
      isUnderMaintenance: false,
      stepCountToAwardKey: 0,
      treasureBoxOpenRadius: 0,
      mapTreasureDistance: 0,
      mapMonsterDistance: 0,
      appBuildNumber: "1.0.0",
      androidAppVersion: "",
      iosAppVersion: "",
      maxLimitForNonNFTUser: 0,
      crystalCountForNonNFTUser: 0,
      maintenanceMessage: "",
      nextSailStartTime: 0,
      bnbToCrystalExchangeRate: 0,
      isMarketplaceUnderMaintenance: false,
      isWalletBuyUnderMaintenance: false,
      referralUserDayCount: 0,
      referralUserDate: "",
      referralBonusPercentage: 0,
      referralTreasureboxCount: 0,
      referralEnergyAmount: 0,
      pirateLvlCrystals: {
        level1: 0,
        level2: 0,
        level3: 0,
        level4: 0,
        level5: 0
      },
      pirateLvlPoints: {
        level1: 0,
        level2: 0,
        level3: 0,
        level4: 0,
        level5: 0
      },
      mainShipLvlPoints: {
        level1Points: 0,
        level1SecondPoints: 0,
        level2Points: 0,
        level2SecondPoints: 0,
        level3Points: 0,
        level3SecondPoints: 0,
        level4Points: 0,
        level4SecondPoints: 0,
        level5Points: 0,
        level5SecondPoints: 0,
        level6Points: 0,
        level6SecondPoints: 0,
        level7Points: 0,
        level7SecondPoints: 0,
        level8Points: 0,
        level8SecondPoints: 0,
        level9Points: 0,
        level9SecondPoints: 0,
        level10Points: 0,
        level10SecondPoints: 0,
        level11Points: 0,
        level11SecondPoints: 0,
        level12Points: 0,
        level12SecondPoints: 0,
        level13Points: 0,
        level13SecondPoints: 0,
        level14Points: 0,
        level14SecondPoints: 0,
        level15Points: 0,
        level15SecondPoints: 0,
        level16Points: 0,
        level16SecondPoints: 0,
        level17Points: 0,
        level17SecondPoints: 0,
        level18Points: 0,
        level18SecondPoints: 0,
        level19Points: 0,
        level19SecondPoints: 0,
        level20Points: 0,
        level20SecondPoints: 0,
        level21Points: 0,
        level21SecondPoints: 0,
        level22Points: 0,
        level22SecondPoints: 0,
        level23Points: 0,
        level23SecondPoints: 0,
        level24Points: 0,
        level24SecondPoints: 0,
        level25Points: 0,
        level25SecondPoints: 0,
        level26Points: 0,
        level26SecondPoints: 0,
        level27Points: 0,
        level27SecondPoints: 0,
        level28Points: 0,
        level28SecondPoints: 0,
        level29Points: 0,
        level29SecondPoints: 0,
        level30Points: 0,
        level30SecondPoints: 0,
        level31Points: 0,
        level31SecondPoints: 0,
        level32Points: 0,
        level32SecondPoints: 0,
        level33Points: 0,
        level33SecondPoints: 0,
        level34Points: 0,
        level34SecondPoints: 0,
        level35SecondPoints: 0,
        level35Points: 0,
        level36Points: 0,
        level36SecondPoints: 0,
        level37Points: 0,
        level37SecondPoints: 0,
        level38Points: 0,
        level38SecondPoints: 0,
        level39Points: 0,
        level39SecondPoints: 0,
        level40Points: 0,
        level40SecondPoints: 0,
        level41Points: 0,
        level41SecondPoints: 0,
        level42Points: 0,
        level42SecondPoints: 0,
        level43Points: 0,
        level43SecondPoints: 0,
        level44Points: 0,
        level44SecondPoints: 0,
        level45Points: 0,
        level45SecondPoints: 0,
        level46Points: 0,
        level46SecondPoints: 0,
        level47Points: 0,
        level47SecondPoints: 0,
        level48Points: 0,
        level48SecondPoints: 0,
        level49Points: 0,
        level49SecondPoints: 0
      },
      restShipLvlPoints: {
        level1Points: 0,
        level2Points: 0,
        level3Points: 0,
        level4Points: 0,
        level5Points: 0,
        level6Points: 0,
        level7Points: 0,
        level8Points: 0,
        level9Points: 0,
        level10Points: 0,
        level11Points: 0,
        level12Points: 0,
        level13Points: 0,
        level14Points: 0,
        level15Points: 0,
        level16Points: 0,
        level17Points: 0,
        level18Points: 0,
        level19Points: 0,
        level20Points: 0,
        level21Points: 0,
        level22Points: 0,
        level23Points: 0,
        level24Points: 0,
        level25Points: 0,
        level26Points: 0,
        level27Points: 0,
        level28Points: 0,
        level29Points: 0,
        level30Points: 0,
        level31Points: 0,
        level32Points: 0,
        level33Points: 0,
        level34Points: 0,
        level35Points: 0,
        level36Points: 0,
        level37Points: 0,
        level38Points: 0,
        level39Points: 0,
        level40Points: 0,
        level41Points: 0,
        level42Points: 0,
        level43Points: 0,
        level44Points: 0,
        level45Points: 0,
        level46Points: 0,
        level47Points: 0,
        level48Points: 0,
        level49Points: 0
      },
      minigameSettings: {
        maxEnergy: 0,
        crystalPurchaseAmountFor1Energy: 0,
        pptPurchaseAmountFor1Energy: 0,
        leaderboardPointfor10thLine: 0,
        level1PirateDailyEnergy: 0,
        level2PirateDailyEnergy: 0,
        level3PirateDailyEnergy: 0,
        level4PirateDailyEnergy: 0,
        level5PirateDailyEnergy: 0,
        minLine1: 0,
        maxLine1: 0,
        bombCountLine1: 0,
        minLine2: 0,
        maxLine2: 0,
        bombCountLine2: 0,
        minLine3: 0,
        maxLine3: 0,
        bombCountLine3: 0,
        minLine4: 0,
        maxLine4: 0,
        bombCountLine4: 0,
        minLine5: 0,
        maxLine5: 0,
        bombCountLine5: 0,
        minLine6: 0,
        maxLine6: 0,
        bombCountLine6: 0,
        minLine7: 0,
        maxLine7: 0,
        bombCountLine7: 0,
        minLine8: 0,
        maxLine8: 0,
        bombCountLine8: 0,
        minLine9: 0,
        maxLine9: 0,
        bombCountLine9: 0,
        nftCountLine9: 0,
        minLine10: 0,
        maxLine10: 0,
        bombCountLine10: 0,
        nftCountLine10: 0,
        usdtCountLine9: 0,
        usdtCountLine10: 0,
        usdtValueLine9: 0,
        usdtValueLine10: 0,
        leaderboardPrizeForRank1: 0,
        leaderboardPrizeTypeForRank1: "",
        leaderboardPrizeForRank2: 0,
        leaderboardPrizeTypeForRank2: "",
        leaderboardPrizeForRank3: 0,
        leaderboardPrizeTypeForRank3: "",
        leaderboardPrizeForRank4: 0,
        leaderboardPrizeTypeForRank4: "",
        leaderboardPrizeForRank5: 0,
        leaderboardPrizeTypeForRank5: "",
        leaderboardPrizeForRank6: 0,
        leaderboardPrizeTypeForRank6: "",
        leaderboardPrizeForRank7: 0,
        leaderboardPrizeTypeForRank7: "",
        leaderboardPrizeForRank8: 0,
        leaderboardPrizeTypeForRank8: "",
        leaderboardPrizeForRank9: 0,
        leaderboardPrizeTypeForRank9: "",
        leaderboardPrizeForRank10:  0,
        leaderboardPrizeTypeForRank10: "",
        leaderboardStatus:""
      },
    },
    minigameLeaderboard: props.settings.minigameLeaderboard || {
      status: "active",
      weekCount: 1
    }

  });

  useEffect(() => {
    onGetSettings();
  }, []);

  useEffect(() => {
    if (props.settings) {
      setFields((prevState) => ({
        ...prevState,
        "adminCommission": props.settings.minigameSetting?.adminCommission,
        mobileAppSetting: props.settings.mobileAppSetting,
      }));
    }
  }, [JSON.stringify(props?.settings)]);

  const handleChange = (name1) => (name) => (event) => {
    const { value } = event.target;
    setFields((prevFields) => ({ ...prevFields, [name1]: { ...prevFields[name1], [name]: value } }));

  };

  const handleInnerChange = (parent, name) => (name2) => (event) => {
    const { value } = event.target;
    setFields((prevState) => ({ ...prevState, [parent]: { ...prevState[parent], [name]: { ...prevState[parent][name], [name2]: value } } }));
  };


  function submit() {
    onPutSettings({
      mobileAppSetting: fields.mobileAppSetting,
    });
  }


  return (
    <div className="page-content">
      <Container fluid>
        {/* <Breadcrumbs title="Settings" breadcrumbItem="Settings" /> */}

        <Row>
          <Col sm={1} lg={2}></Col>

          <Col sm={10} lg={8}>
            <Card>
              <CardBody className="spinner-content">
                {error && typeof error === "string" ? (
                  <Alert color="danger">{error}</Alert>
                ) : null}

                {loading && <div className="spinner"></div>}

                <GeneralSettings fields={fields?.mobileAppSetting} handleChange={handleChange('mobileAppSetting')} />

                <hr className="my-3" />

                <PrivateLvlCrystals
                  fields={fields.mobileAppSetting?.pirateLvlCrystals}
                  handleChange={handleInnerChange('mobileAppSetting', "pirateLvlCrystals")}
                />
                <hr className="my-3" />

                <PrivateLvlPoints
                  fields={fields.mobileAppSetting?.pirateLvlPoints}
                  handleChange={handleInnerChange('mobileAppSetting', "pirateLvlPoints")}
                />

                <hr className="my-3" />

                <MainShipLvlPoints
                  fields={fields.mobileAppSetting?.mainShipLvlPoints}
                  handleChange={handleInnerChange('mobileAppSetting', "mainShipLvlPoints")}
                />

                <hr className="my-3" />

                <RestShipLvlPoints
                  fields={fields.mobileAppSetting?.restShipLvlPoints}
                  handleChange={handleInnerChange('mobileAppSetting', "restShipLvlPoints")}
                />

                <Row>
                  <Col></Col>

                  <Col className="d-flex justify-content-end">
                    {loading && <Spinner color="primary" className="mr-2" />}

                    <Button className="buttoncolor" onClick={submit}>
                      Save Changes
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col sm={1} lg={2}></Col>
        </Row>
      </Container>
    </div>
  );
};

Settings.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  onGetSettings: PropTypes.func,
  onPutSettings: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onPutSettings: (data) => dispatch(putMobileSettings(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
);
