import React from "react";
import HomeProfile from "./index";

export default function Dashboardtheme(props) {
  return (
    <>
     
      <HomeProfile {...props}/>
    </>
  );
}
