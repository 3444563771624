import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
const STATUS = {
  "requested":"Requested", 
  "transferred":"Transferred",
  "rejected":"Rejected"
}
export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (toggleConfirmModal, toggleRejectModal, t) => [
  {
    dataField: "user.username",
    text: t("Username"),
    formatter: (_, row) => (row.user?.username ? row.user?.username : "-"),
  },
  {
    dataField: "user.email",
    text: t("Email"),
    formatter: (_, row) => (row.user?.email ? row.user?.email : "-"),
  },
  {
    dataField: "itemName",
    text: t("Requested NFT"),
    formatter: (_, row) => (row.itemName ? row.itemName : "-"),
  },
  {
    dataField: "itemLevel",
    text: t("Requested NFT level"),
    formatter: (_, row) => (row.itemLevel ? row.itemLevel : "-"),
  },
  {
    dataField: "item.name",
    text: t("Transferred NFT"),
    formatter: (_, row) => (row.item?.name ? row.item?.name : "-"),
  },
  {
    dataField: "status",
    text: t("Status"),
    formatter: (cellContent, row) => (
      <Badge
        className={
          `text-capitalize font-size-13 badge-soft-${row.status}`
        }
        color={row.badgeClass}
      >
        {t(STATUS[row.status])}
      </Badge>
    ),
  },
  {
    dataField: "date_created_utc",
    text: t("Created Date"),
    sort: true,
    sortValue: (_, row) =>row?.date_created_utc,
    formatter: (_, row) => (row?.date_created_utc ? moment(row?.date_created_utc).format("YYYY-MM-DD HH:mm:ss") : "--"),
  },
  {
    dataField: "transferredAt",
    text: t("Tranfer Date"),
    sort: true,
    sortValue: (_, row) =>row?.transferredAt,
    formatter: (_, row) => (row?.transferredAt ? moment(row?.transferredAt).format("YYYY-MM-DD HH:mm:ss") : "--"),
  },
  // {
  //   dataField: "transactionHash",
  //   text: t("TransactionHash"),
  //   formatter: (_, row) => (row.transactionHash ? row.transactionHash : "-"),
  // },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
      {row?.status == "requested" && (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            toggleConfirmModal(row?._id);
          }}
          className="mr-3 text-secondary"
        >
          <i className="fa fa-check mr-3" id={`transferred-${row._id}-tooltip`} />

          <UncontrolledTooltip
            placement="top"
            target={`transferred-${row._id}-tooltip`}
          >
            Mark Transferred
          </UncontrolledTooltip>
        </Link>
        )}

        {row?.status == "requested" && (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            toggleRejectModal(row?._id);
          }}
          className="mr-3 text-secondary"
        >
          <i className="fa fa-ban mr-3" id={`reject-${row._id}-tooltip`} />

          <UncontrolledTooltip
            placement="top"
            target={`reject-${row._id}-tooltip`}
          >
            Mark Rejected
          </UncontrolledTooltip>
        </Link>
        )}
      </>
    ),
  },
];

export default ListColumns;