import { all, fork } from "redux-saga/effects";

import Alert from "./Alert/saga";

//public
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import Register from "./auth/register/saga";
import Settings from "./Settings/saga";
import Customers from "./customers/saga";

// File
import File from "./File/saga";

import StakeSaga from "./stake/saga";

// Faqs
import documents from "./document/saga";

// Content Page
import content_page from "./contentpage/saga";
import Collection from "./collection/saga";
import ActivitiesSaga from "./activity/saga";
// Faqs
import faqs from "./faq/saga";

//nft
import Items from "./items/saga";
import CategoryesSaga from "./categoryes/saga";
import LogsSaga from "./logs/saga";


import promoCodes from "./promo/saga";
import GameSaga from "./game/saga";
import GameHistorySaga from "./gameHistory/saga";
import MatchHistorySaga from "./matchHistory/saga";
import BalanceSaga from "./AddBalance/saga";
import LeaderboardSaga from "./leaderboard/saga";

import Operator from "./operators/saga";


import SailHistory from "./sailHistory/saga";
import CrewCodeSaga from "./crewCode/saga";
import DashboardSaga from "./dashboard/saga";
import BonusHistorySaga from "./bonusHistory/saga";
import CrystalPurchaseSaga from "./crystalPuchase/saga";
import MobilegameHistorySaga from "./mobilegameHistory/saga";

import NftLvlUpgradeHistorySaga from "./nftLvlUpgradeHistory/saga";
import NftRepairHistorySaga from "./nftRepairHistory/saga";

import LanguageSaga from "./language/saga";
import NotificationSaga  from "./notification/saga";
import IPHistorySaga from "./ipHistory/saga";

export default function* rootSaga() {
  yield all([
    fork(Alert),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    Register(),
    LayoutSaga(),
    Settings(),
    content_page(),
    fork(StakeSaga),
    fork(Customers),
    fork(File),
    fork(documents),
    fork(Collection),
    fork(ActivitiesSaga),
    fork(faqs),
    fork(Items),
    fork(CategoryesSaga),
    fork(promoCodes),
    fork(GameSaga),
    fork(GameHistorySaga),
    fork(MatchHistorySaga),
    fork(BalanceSaga),
    fork(LeaderboardSaga),
    fork(Operator),
    fork(LogsSaga),
    fork(SailHistory),
    fork(DashboardSaga),
    fork(CrewCodeSaga),
    fork(BonusHistorySaga),
    fork(CrystalPurchaseSaga),
    fork(MobilegameHistorySaga),
    fork(NftLvlUpgradeHistorySaga),
    fork(NftRepairHistorySaga),
    fork(LanguageSaga),
    fork(NotificationSaga),
    fork(IPHistorySaga)
  ]);
}
