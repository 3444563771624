import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import { compileDeployCollectionUpdt as postDeployCollectionUpdt } from "store/actions";
//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import { getCollections, deleteCollection } from "store/actions";
import ListColumns from "./ListColumns";
import ConfirmModal from "./ConfirmModal";
import DeployConfirmModal from "./DeployConfirmModal";
import toastr from "toastr";
import Web3IntractionEth from "helpers/web3IntractionEth";
import { walletHooks } from "helpers/Wallet/walletHooks";
import {
  getNetworkUrl,
} from "helpers/contants";
const Games = ({
  accessLevel,
  history,
  collections,
  totalCollections,
  onGetCollections,
  onDeleteCollection,
  loading,
  ...props
}) => {
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    collectionId: null,
  });
  const { settings } = useSelector((state) => state?.Settings);
  const wallet = walletHooks();
  const [deployConfirmModal, setdeployConfirmModal] = useState({
    isOpen: false,
    collectiond: null,
  });
  const [filter, setFilter] = useState({
    orderBy: "date_created_utc",
    order: -1,
    page: 0,
    limit: 20,
    search: "",
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
  });
  const [searchText, setSearchText] = useState("");
  const [collectionsList, setCollectionsList] = useState([]);
  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalCollections,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    onGetCollections(filter);
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    setCollectionsList(collections || []);
  }, [collections]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText.trim());
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (collectionId) => {
    setConfirmModal((prevState) => ({
      isOpen: !prevState.isOpen,
      collectionId,
    }));
  };

  const toggleDeployConfirmModal = (collection) => {
    setdeployConfirmModal((prevState) => ({
      isOpen: !prevState.isOpen,
      collection,
    }));
  };

  const onDeploySuccess = async (res) => {
    if (res.status == "failure") {
      toggleDeployConfirmModal();
      toastr.error(res.message);
      return;
    }
    // toastr.success("done")

    try {
      // const provider = await wallet.connectWallet();
      const provider = window.ethereum;
      const networkUrl = await getNetworkUrl("BNB", settings);
      wallet.connectWallet();
      await wallet.switchChain({ networkUrl });
      // await wallet.switchChain({ networkUrl });
      console.log("deploy ------> ", networkUrl, provider)
      const web3IntractionEth = new Web3IntractionEth(networkUrl, provider, settings);
      let receipt = await web3IntractionEth.deployContract(
        JSON.stringify(res.data.compiledData.abi),
        res.data.compiledData.bytecode
      );
      // console.log("receipt", receipt)
      props.onCompileDeployCollectionUpdt(
        {
          collectionId: res.data.collectionId,
          // address: "0x6bdbbcbc18f0789293598e4194d5ce83688b5e77",
          // transactionHash: "0x57063cceaad351e9f685eb94bd9e86f3380abb33bfc870dabf9763f9c50e2383"
        
          address: receipt.address,
          transactionHash: receipt.deployTransaction.hash
        },
        async (res) => {
          if (res.status == "failure") {
            toggleDeployConfirmModal();
            toastr.error(res.message);
            return;
          }
          toastr.success(res.message);
          toggleDeployConfirmModal();

          onGetCollections(filter);
        }
      )
    } catch (e) {
      console.log("error", e)
      toggleDeployConfirmModal();

      onGetCollections(filter);
    }

    // receipt = await web3IntractionEth.deployContract(
    //   JSON.stringify(item[blockchainNetworkMode].abi),
    //   item[blockchainNetworkMode].bytecode
    // );

    // const contract = await web3Intraction?.getContract(
    //   parseData?.collection_id?.contractAbi,
    //   parseData?.collection_id?.contractAddress
    // );
    // toggleDeployConfirmModal();

    // onGetCollections(filter);
  };

  const removeConfirm = () => {
    const { collectionId, isOpen } = confirmModal;

    const onComplete = () => {
      onGetCollections(filter);
    };

    setConfirmModal({ isOpen: !isOpen, collectionId: null });
    onDeleteCollection({ _id: collectionId }, onComplete);
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText.trim() }));
  };

  return (
    <React.Fragment>
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />
      <DeployConfirmModal
        {...deployConfirmModal}
        toggle={toggleDeployConfirmModal}
        onSuccess={onDeploySuccess}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Collections"
            breadcrumbItem={props.t("collections")}
            breadcrumbItems={[{ title: props.t("collections") }]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={collectionsList || []}
                        columns={ListColumns(toggleDeployConfirmModal, props.t)}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="3">
                                <SearchInput
                                  {...toolkitProps.searchProps}
                                  triggerSearch={onSearch}
                                  placeholder={props.t("Search")}
                                  searchText={searchText}
                                />
                              </Col>

                              <Col sm="2">
                                {!!filter.search && (
                                  <div
                                    className="mr-4 mb-2"
                                    style={{
                                      height: "calc( 1.5em + 0.94rem + 2px)",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFilter((prevState) => ({
                                          ...prevState,
                                          search: "",
                                        }));
                                        setSearchText("");
                                      }}
                                      className=""
                                    >
                                      {props.t("Clear Filters")}
                                    </Link>
                                  </div>
                                )}
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Games.propTypes = {
  collections: PropTypes.array,
  totalCollections: PropTypes.number,
  onGetCollections: PropTypes.func,
  onDeleteCollection: PropTypes.func,
  onPutCollectionsStatus: PropTypes.func,
};

const mapStateToProps = ({ collections }) => ({
  loading: collections.loading,
  collections: collections.collections,
  totalCollections: collections.totalCollections,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCollections: (data) => dispatch(getCollections(data)),
  onCompileDeployCollectionUpdt : (data, callback)=>dispatch(postDeployCollectionUpdt(data, callback)),
  onDeleteCollection: (data, callback) =>
    dispatch(deleteCollection(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Games))
);
