import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

const ConfirmModal = (props) => {

  const { isOpen, toggle, onConfirm, toAddress, internalLoader, item, closePopup } = props;
  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      autoFocus={true}
      centered={true}
      toggle={toggle}
      scrollable={"true"}
      toAddress={toAddress}
      item={item}
      internalLoader={internalLoader}
      closePopup={closePopup}
      backdrop={"static"}
    >
      <div className="modal-content remove-confirm">
        <ModalBody>
          <div className="infoimg">
            <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M256,0C114.497,0,0,114.507,0,256c0,141.503,114.507,256,256,256c141.503,0,256-114.507,256-256    C512,114.497,397.493,0,256,0z M256,472c-119.393,0-216-96.615-216-216c0-119.393,96.615-216,216-216    c119.393,0,216,96.615,216,216C472,375.393,375.385,472,256,472z"
                fill="#f8c886"
              />
              <path
                d="M256,128.877c-11.046,0-20,8.954-20,20V277.67c0,11.046,8.954,20,20,20s20-8.954,20-20V148.877    C276,137.831,267.046,128.877,256,128.877z"
                fill="#f8c886"
              />
              <circle cx="256" cy="349.16" r="27" fill="#f8c886" />
            </svg>
          </div>

          <h4>{props.t("Are you sure you want to airdrop this NFT ?")}</h4>

          <h4>{`Item Name: ${item? JSON.parse(item)?.name: ""}`}</h4>

          <h4>{`To Address: ${toAddress}`}</h4>

          <div className="delete-btns">
            <Button type="button" outline color="secondary" onClick={closePopup}>
              {props.t("no_cancel")}
            </Button>

            {internalLoader ?
            <>
            
              <button
                type="button"
                disabled={internalLoader}
                className="btn btn-primary w-md"
              >
                {props.t("Confirm")}
              </button>

              <p>Please Wait...</p>
            </>
            : 
            <button
              type="button"
              className="btn btn-primary w-md"
              onClick={onConfirm}
            >
              {props.t("Confirm")}
            </button>
            }
            {/* <Button type="button" color="primary" onClick={onConfirm}>
              {props.t("Confirm")}
            </Button> */}
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default withTranslation()(ConfirmModal);
