import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
const STATUS = {
  "created":"open", 
  "inprogress":"In Progress", 
  "completed":"Completed", 
  "ended":"No Result"
}
export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (t) => [
  {
    dataField: "matchID",
    text: t("Match Id"),
    // formatter: (_, row) => (row.winner?.username ? row.winner?.username : "-"),
  },
  {
    dataField: "creator.username",
    text: t("Creator"),
    formatter: (_, row) => (row.creator?.username ? row.creator?.username : "-"),
  },
  {
    dataField: "winner.username",
    text: t("Winner"),
    formatter: (_, row) => (row.winner?.username ? row.winner?.username : "-"),
  },
  {
    dataField: "looser.username",
    text: t("Loser"),
 
    formatter: (_, row) => (row.looser?.username ? row.looser?.username : "-"),
  },
  {
    dataField: "betamount",
    text: t("Bet Amount"),
    formatter: (_, row) => (row?.betamount ? row?.betamount : 0),
  },
  {
    text: t("Total Round"),
    dataField: "totalRound",
  },
  {
    text: t("Won Amount"),
    dataField: "winAmount",
    formatter: (_, row) => (row?.winAmount ? row?.winAmount : "-"),
  },
  
  {
    dataField: "status",
    text: t("Status"),
    formatter: (cellContent, row) => (
      <Badge
        className={
          "text-capitalize font-size-13 badge-soft-" 
        }
        color={row.badgeClass}
      >
        {t(STATUS[row.status])}
      </Badge>
    ),
  },
  {
    dataField: "end_date_utc",
    text: t("End Date"),
    sort: true,
    sortValue: (_, row) =>row?.end_date_utc,
    formatter: (_, row) => (row?.end_date_utc ? moment(row?.end_date_utc).format("YYYY-MM-DD HH:mm:ss") : "--"),
  },

  // {
  //   isDummyField: true,
  //   text: t("Action"),
  //   dataField: "action",
  //   formatter: (_, row) => (
  //     <>
  //       <Link
  //         to={`/match-history/view/${row._id}`}
  //         className="mr-3 text-secondary"
  //       >
  //         <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />

  //         <UncontrolledTooltip
  //           placement="top"
  //           target={`view-${row._id}-tooltip`}
  //         >
  //           View
  //         </UncontrolledTooltip>
  //       </Link>
  //       {
  //         row.status == "created" ||  row.status == "inprogress" ?
  //       <Link
  //         to='#'
  //         onClick={(e) => {
  //           e.preventDefault();
  //           toggleConfirmModal(row);
  //         }}
  //         className="mr-3 text-secondary"
  //       >
  //         <i className="fas fa fa-ban mr-3" id={`delete-${row._id}-tooltip`} />

  //         <UncontrolledTooltip
  //           placement="top"
  //           target={`delete-${row._id}-tooltip`}
  //         >
  //           End-Match
  //         </UncontrolledTooltip>
  //       </Link> :""
  // }
  //     </>
  //   ),
  // },
];

export default ListColumns;
