import { call, put, takeEvery } from "redux-saga/effects";

// Document Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import * as API from "helpers/backend_helper";

function* fetchDocuments({ payload }) {
  try {
    const response = yield call(API.getDocuments, payload);

    if (response.status == "failure") {
      return yield put(ACTIONS.getDocumentsFail(response.message));
    }

    yield put(ACTIONS.getDocumentsSuccess(response));
  } catch (error) {
    console.log("error", error);
    yield put(ACTIONS.getDocumentsFail(error));
  }
}

function* onAddNewDocument({ payload: { document, history } }) {
  try {
    const response = yield call(API.postDocument, document);

    if (response.status == "failure") {
      window.scrollTo(0, 0);

      return yield put(ACTIONS.addDocumentFail(response.message));
    }

    history && history.replace("/documents");

    yield put(ACTIONS.addDocumentSuccess());
  } catch (error) {
    yield put(ACTIONS.addDocumentFail("Internal Error!"));
  }
}

function* fetchDocument({ payload }) {
  try {
    const response = yield call(API.getDocument, payload);

    if (response.status == "failure") {
      return yield put(ACTIONS.getDocumentFail(response.message));
    }

    yield put(ACTIONS.getDocumentSuccess(response.data));
  } catch (error) {
    yield put(ACTIONS.getDocumentFail(error));
  }
}

function* onPutDocument({ payload: { data, history } }) {
  try {
    const response = yield call(API.putDocument, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.putDocumentFail(response.message));
    }

    history && history.replace("/documents");

    yield put(ACTIONS.putDocumentSuccess());
  } catch (error) {
    yield put(ACTIONS.putDocumentFail("Internal Error!"));
  }
}

function* onDeleteDocument({ payload: { data, callback } }) {
  try {
    const response = yield call(API.deleteDocument, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.deleteDocumentFail(response.message));
    }

    yield put(ACTIONS.deleteDocumentSuccess());
    callback && callback();
  } catch (error) {
    yield put(ACTIONS.deleteDocumentFail("Internal Error!"));
  }
}

function* DocumentsSaga() {
  yield takeEvery(TYPES.GET_DOCUMENTS, fetchDocuments);
  yield takeEvery(TYPES.ADD_DOCUMENT, onAddNewDocument);
  yield takeEvery(TYPES.GET_DOCUMENT, fetchDocument);
  yield takeEvery(TYPES.PUT_DOCUMENT, onPutDocument);
  yield takeEvery(TYPES.DELETE_DOCUMENT, onDeleteDocument);
}

export default DocumentsSaga;
