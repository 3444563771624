import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { Card, CardBody, Col, Container, Row, Spinner, Input } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import { getMatchHistory, endMatch } from "store/actions";
import ListColumns from "./ListColumns";
import DeployConfirmModal from "./DeployConfirmModal";
import toastr from "toastr";
import ConfirmModal from "./ConfirmModal";

const MatchHistoryList = ({
  MatchHistory,
  accessLevel,
  history,
  collections,
  totalMatchHistory,
  onGetMatchHistory,
  onEndMatch,
  onDeleteTournament,
  loading,
  ...props
}) => {
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    tournmentId: null,
  });
  const [deployConfirmModal, setdeployConfirmModal] = useState({
    isOpen: false,
    collectiond: null,
  });
  const [filter, setFilter] = useState({
    orderBy: "date_created_utc",
    order: -1,
    page: 1,
    limit: 20,
    search: "",
    status: "",
    filter: "all",
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
  });
  const [searchText, setSearchText] = useState("");
  const [MatchList, setMatchList] = useState([]);
  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalMatchHistory,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };
  console.log("pageOptions", pageOptions)

  useEffect(() => {
    onGetMatchHistory(filter);
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    setMatchList(MatchHistory || []);
  }, [MatchHistory]);

  console.log("MatchList",MatchList)

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText.trim());
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (tournmentId) => {
    setConfirmModal((prevState) => ({
      isOpen: !prevState.isOpen,
      tournmentId,
    }));
  };

  const toggleDeployConfirmModal = (collection) => {
    setdeployConfirmModal((prevState) => ({
      isOpen: !prevState.isOpen,
      collection,
    }));
  };

  const onDeploySuccess = (res) => {
    if (res.status == "failure") {
      toggleDeployConfirmModal();
      toastr.error(res.message);
      return;
    }
    toggleDeployConfirmModal();

    onGetMatchHistory(filter);
  };

  const removeConfirm = () => {
    const { tournmentId, isOpen } = confirmModal;

    // const onComplete = () => {
    //   onGetMatchHistory(filter);
    // };


    const onComplete = (response) => {
      // if (response.status === "failure") {
      //   toastr.error(response.message);
      // } else {
      //   onGetMatchHistory(filter);
      //   toastr.success("End match successfully.", {
      //     duration: 5000,
      //   });
      // }
    };

    setConfirmModal({ isOpen: !isOpen, tournmentId: null });
    onEndMatch({ match_id: tournmentId?._id }, onComplete);
  };


  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText.trim() }));
  };

  return (
    <React.Fragment>
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />
      <DeployConfirmModal
        {...deployConfirmModal}
        toggle={toggleDeployConfirmModal}
        onSuccess={onDeploySuccess}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Match History"
            breadcrumbItem={props.t("Match History")}
            breadcrumbItems={[{ title: props.t("Match History") }]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={MatchList.length > 0 ? MatchList : []}
                        columns={ListColumns(toggleConfirmModal, props.t)}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="3">
                                <SearchInput
                                  {...toolkitProps.searchProps}
                                  triggerSearch={onSearch}
                                  placeholder={props.t("Search by email, match, betamount")}
                                  searchText={searchText}
                                />
                              </Col>

                              <Col sm="3">
                                <Input
                                  type="select"
                                  value={filter.status}
                                  onChange={(e) =>
                                    setFilter((prev) => ({
                                      ...prev,
                                      status: e.target.value,
                                      filter: "",
                                    }))
                                  }
                                >
                                  <option value="">Select Status</option>
                                  <option value="created">Open</option>
                                  <option value="inprogress">In Progress</option>
                                  <option value="completed">Completed</option>
                                  <option value="ended">No Result</option>
                                </Input>
                              </Col>
                              <Col sm="2">
                                {!!filter.search || !!filter.status ? (
                                  <div
                                    className="mr-4 mb-2"
                                    style={{
                                      height: "calc( 1.5em + 0.94rem + 2px)",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFilter((prevState) => ({
                                          ...prevState,
                                          search: "",
                                          status: "",
                                          filter: "all"
                                        }));
                                        setSearchText("");
                                      }}
                                      className=""
                                    >
                                      {props.t("Clear Filters")}
                                    </Link>
                                  </div>
                                ):""}
                              </Col>
                             
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "date_created_utc",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

MatchHistoryList.propTypes = {
  MatchHistory: PropTypes.array,
  totalMatchHistory: PropTypes.number,
  onGetMatchHistory: PropTypes.func,
  onEndMatch:PropTypes.func,
  onDeleteCollection: PropTypes.func,
  onPutCollectionsStatus: PropTypes.func,
};

const mapStateToProps = ({ MatchHistory }) => ({
  loading: MatchHistory.loading,
  MatchHistory: MatchHistory.matchHistory,
  totalMatchHistory: MatchHistory.totalMatchHistory,
});

const mapDispatchToProps = (dispatch) => ({
  onGetMatchHistory: (data) => dispatch(getMatchHistory(data)),
  onEndMatch: (data) => dispatch(endMatch(data)),

});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(MatchHistoryList))
);
