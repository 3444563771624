import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (toggleConfirmModal, t) => [
  {
    dataField: "name",
    text: t("Name"),
  },
  {
    dataField: "winner_id.email",
    text: t("Winner"),
  },
  {
    dataField: "looser_id.email",
    text: t("Loser"),
  },
  {
    dataField: "fee_type",
    text: t("Fee Type"),
  },
  {
    text: t("Total Round"),
    dataField: "total_rounds",
  },
  {
    text: t("Entry Fee"),
    dataField: "entry_fee",
  },

  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <Link
          to={`/tournament-history/view/${row._id}`}
          className="mr-3 text-secondary"
        >
          <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />

          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            View
          </UncontrolledTooltip>
        </Link>
      </>
    ),
  },
];

export default ListColumns;
