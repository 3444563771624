import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  FormGroup,
  Label,
  Spinner,
  Button,
} from "reactstrap";
import { isEmpty } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import actions
import { getCustomer, changeStatus, getSettings } from "store/actions";

//
import DepositDetails from "./DepositDetails";
import NftModel from "./NftModel";

const ViewCustomer = (props) => {
  const [state, setstate] = useState({ status: "active" });
  const [nftListModal, setNftListModal] = useState({
    isOpen: false,
    items: null,
  });
  const {
    match: { params },
    onGetCustomer,
    customer,
    NftList,
    error,
    loading,
  } = props;

  const dispatch = useDispatch();
  const { settings } = useSelector(state => state.Settings)
  useEffect(() => {
    if (params && params.id) {
      onGetCustomer(params.id);
    }
  }, [params]);

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (!customer) return;
    setstate((prev) => ({ ...prev, status: customer?.status }));
  }, [customer]);

  const handleChange = (e) => {
    const { checked } = e.target;
    setstate((prev) => ({ ...prev, status: checked ? "blocked" : "active" }));
    dispatch(
      changeStatus({
        status: checked ? "blocked" : "active",
        userId: params.id,
      })
    );
  };
  const handleClick = (e) => {
    e.preventDefault();
    if (NftList.length > 0) {
      setNftListModal((prevState) => ({ isOpen: !prevState.isOpen, NftList }));
    }
  }
  const toggleNftModel = (e) => {
    setNftListModal((prevState) => ({ isOpen: !prevState.isOpen }));
  }
  return (
    <React.Fragment>
      {loading && (
        <div className="page-content">
          <Spinner color="primary" />
        </div>
      )}

      <NftModel
        isOpen={nftListModal.isOpen}
        toggle={toggleNftModel}
        NftList={nftListModal.NftList || ""}
        Settings={settings || {}}
      />

      {!loading && (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Users" breadcrumbItem="User Details" />

            <Card>
              <CardBody>
                <Row>
                  <Col xl="6">
                    <div className="mt-4 mt-xl-3">
                      <h4 className="text-primary">{customer.role}</h4>
                      <p className="mt-1 mb-3 font-weight-bold">
                        Name -{" "}
                        <span className="text-muted">{customer.username}</span>
                      </p>

                      <p className="mt-1 mb-3 font-weight-bold">
                        Email -{" "}
                        <span className="text-muted">{customer.email}</span>
                      </p>

                      <p className="mt-1 mb-3 font-weight-bold">
                        Wallet address -{" "}
                        <span className="text-muted">
                          {customer.walletAddress}
                        </span>
                      </p>

                      <p className="mt-1 mb-3 font-weight-bold">
                        Total NFTs -{" "}
                        <span className="text-muted">
                          {NftList.length}
                          {NftList.length > 0 && <Button className="ml-5 btn btn-success" onClick={handleClick}>View NFTs</Button>}
                        </span>
                      </p>
                    </div>
                  </Col>
                  <FormGroup>
                    <Label className="text-capitalize">Status</Label>

                    <div className="status-switch square-switch">
                      <input
                        className="switcher"
                        type="checkbox"
                        id={`blockchain-status`}
                        switch="none"
                        checked={state.status == "blocked"}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor={`blockchain-status`}
                        data-on-label="Blocked"
                        data-off-label="Active"
                      />
                    </div>
                  </FormGroup>
                </Row>

                <Row>
                  {customer.wallets &&
                    customer.wallets.map((walletAddress) => (
                      <Col sm="4">
                        <DepositDetails walletAddress={walletAddress} />
                      </Col>
                    ))}
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

ViewCustomer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  customer: PropTypes.any,
  onGetCustomer: PropTypes.func,
};

const mapStateToProps = ({ customers }) => ({
  error: customers.error,
  loading: customers.loading,
  customer: customers.customer,
  NftList: customers.NftList,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCustomer: (id) => dispatch(getCustomer(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomer);
