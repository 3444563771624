import {
    GET_LEADERBOARD,
    GET_LEADERBOARD_SUCCESS,
    GET_LEADERBOARD_FAIL,

    REDUCE_LEADERBOARD_POINTS,
    REDUCE_LEADERBOARD_POINTS_SUCCESS,
    REDUCE_LEADERBOARD_POINTS_FAIL
} from "./actionTypes";

export const getLeaderboard = (data) => ({
    type: GET_LEADERBOARD,
    payload: data,
});

export const getLeaderboardSuccess = (leaders) => ({
    type: GET_LEADERBOARD_SUCCESS,
    payload: leaders,
});

export const getLeaderboardFail = (error) => ({
    type: GET_LEADERBOARD_FAIL,
    payload: error,
});

export const reduceLeaderboardPoints = (data, callback) => ({
    type: REDUCE_LEADERBOARD_POINTS,
    payload: { data, callback },
});

export const reduceLeaderboardPointsSuccess = (response) => ({
    type: REDUCE_LEADERBOARD_POINTS_SUCCESS,
    payload: response,
});

export const reduceLeaderboardPointsFail = (error) => ({
    type: REDUCE_LEADERBOARD_POINTS_FAIL,
    payload: error,
});