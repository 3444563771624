import axios from "axios";
import { post, del, get, put } from "./api_helper";
import * as url from "./url_helper";
import authHeader from "./jwt-token-access/auth-token-header";
import { gameGet, gamePost } from "./game_api_helper";

import { mobileGet, mobilePost, mobilePut } from "./mobile_api_helper";
import E from "react-script";


// Login Method
export const postLogin = (data) => post(url.POST_LOGIN, data);
export const changeStatus = (data) => post(url.CHANGE_STATUS, data);

// Register
export const postRegisterEmail = (data) => post(url.POST_REGISTER_EMAIL, data);
export const postRegister = (data) => post(url.POST_REGISTER, data);

// Forgot Password
export const postForgetPwd = (data) => post(url.POST_FORGOT_PASSWORD, data);
export const postResetPwd = (data) => post(url.POST_RESET_PASSWORD, data);

// Profile
export const getCurrentUser = () => get(url.GET_CURRENT_USER);
export const putProfile = (data) => post(url.PUT_PROFILE, data);
export const putChangePassword = (data) => post(url.CHANGE_PASSWORD, data);
export const removeWalletAddress = (data) => post(url.REMOVE_WALLET_ADDRESS, data);
export const updateUserStatus = (data) => post(url.UPDATE_USER_STATUS, data);

// File
export const postFile = (data) => {
  const formData = new FormData();

  Object.entries(data).map(([key, value]) => formData.append(key, value));

  const config = {
    headers: { ...authHeader(), "content-type": "multipart/form-data" },
  };

  return post(url.POST_FILE, formData, config);
};
// File
export const postItemFile = (data) => {
  const formData = new FormData();

  Object.entries(data).map(([key, value]) => formData.append(key, value));

  const config = {
    headers: { ...authHeader(), "content-type": "multipart/form-data" },
  };

  return post(url.POST_ITEM_FILE, formData, config);
};

export const postMultipleFile = (data) => {
  try{
    console.log("data ----->",data);
    let formData = new FormData();
    
    Object.entries(data).forEach(([key, files]) => {
      if (Array.isArray(files)) {
        files.forEach((file, index) => {
          formData.append(`${key}_${index}`, file); // Append each file with a unique key
        });
      } else {
        formData.append(key, files); // Append single file
      }
    });
  
    const config = {
      headers: { ...authHeader(), "content-type": "multipart/form-data" },
    };
    console.log("formData ------>", formData);
    return post(url.POST_MULTIPLE_FILE, formData, config);
  }
  catch(err){
    console.log(err);
  }
};
export const postRemoveFile = (data) => post(url.POST_REMOVE_FILE, data);

// Customers
export const getCustomers = (data) => post(url.GET_USERS, data);
export const getCustomer = (id) => get(url.VIEW_USER + "/" + id);

export const updateProfile = (data) => put(url.POST_UPDATE_PROFILE + data?.id , data);

export const getLogs = (data) => post(url.GET_LOGS, data);


// Customers
export const airDrop = (data) => post(url.AIR_DROP, data);
export const airDropV2 = (data) => post(url.AIR_DROP_V2, data);
export const airDropList = (data) => post(url.AIR_DROP_LIST, data);

// Settings
export const getSettings = () => get(url.GET_SETTINGS);
export const putSettings = (data) => post(url.PUT_SETTINGS, data);
export const postDeployContract = (data) =>
  post(url.POST_CONTRACT_DEPLOY, data);
export const postDeployContractForMultisend = (data) =>
  post(url.POST_CONTRACT_DEPLOY_MULTISEND, data);
export const postCompileContract = (data) =>
  post(url.POST_COMPILE_CONTRACT, data);
export const postDeployContractPpg = (data) =>
  post(url.POST_CONTRACT_DEPLOY_PPG, data);
export const postDeployContractGov = (data) =>
  post(url.POST_CONTRACT_DEPLOY_GOV, data);
export const postCompileContractPpg = (data) =>
  post(url.POST_COMPILE_CONTRACT_PPG, data);
export const postCompileContractGov = (data) =>
  post(url.POST_COMPILE_CONTRACT_GOV, data);

// Stake
export const getStakes = (data) => post(url.GET_STAKES, data);
export const getStakeStats = (data) => post(url.GET_STAKE_STATS, data);

//Mobile Settings


export const putMobileSettings = (data) => mobilePost(url.PUT_MOBILE_SETTINGS, data);


// Documents
export const getDocuments = (data) => post(url.GET_DOCUMENTS, data);
export const postDocument = (data) => post(url.ADD_DOCUMENT, data);
export const getDocument = (id) => get(url.GET_DOCUMENT + "/" + id);
export const putDocument = (data) => post(url.PUT_DOCUMENT, data);
export const deleteDocument = (data) => post(url.DELETE_DOCUMENT, data);

// Content Page
export const getContentPages = (data) => post(url.GET_CONTENT_PAGES, data);
export const postContentPage = (data) => post(url.ADD_CONTENT_PAGE, data);
export const getContentPage = (id) => get(url.GET_CONTENT_PAGE + "/" + id);
export const putContentPage = (data) => post(url.PUT_CONTENT_PAGE, data);
export const deleteContentPage = (data) => post(url.DELETE_CONTENT_PAGE, data);
export const putContentPagesStatus = (data) =>
  post(url.PUT_CONTENT_PAGES_STATUS, data);

//collections
export const getCollections = (data) => post(url.GET_COLLECTIONS, data);
export const postCollection = (data) => post(url.POST_COLLECTION, data);
export const putCollection = (data) => post(url.PUT_COLLECTION, data);
export const deleteCollection = (data) => post(url.DELETE_COLLECTION, data);
export const getCollection = (id) => get(url.GET_COLLECTION + "/" + id);
export const putCollectionsStatus = (data) =>
  post(url.PUT_COLLECTION_STATUS, data);
export const updateStoreCollection = (data) =>
  post(url.UPDATE_STORE_COLLECTION, data);
export const putStoreCollectionDiscount = (data) =>
  post(url.PUT_COLLECTION_DISCOUNT, data);

export const compileDeployCollection = (data) =>
  post(url.COMPILE_DEPLOY_COLLECTIONS, data);


  export const compileDeployCollectionV2 = (data) =>
  post(url.COMPILE_DEPLOY_COLLECTIONS_V2 , data);



  export const compileDeployCollectionUpdt = (data) =>
  post(url.COMPILE_DEPLOY_COLLECTIONS_UPDT , data);

// Activities
export const getActivities = (data) => post(url.GET_ACTIVITIES, data);

// Airdrop for crystal bought
export const airdropNFTForCrystal = (data) => post(url.AIR_DROP_V2_CRYSTAL, data);

// Faqs
export const getFaqs = (data) => post(url.GET_FAQS, data);
export const postFaq = (data) => post(url.ADD_FAQ, data);
export const getFaq = (id) => get(url.GET_FAQ + "/" + id);
export const putFaq = (data) => post(url.PUT_FAQ, data);
export const deleteFaq = (data) => post(url.DELETE_FAQ, data);
export const putFaqsStatus = (data) => post(url.PUT_FAQS_STATUS, data);

// Promo Codes
export const getPromoCodes = (data) => post(url.GET_PROMO_CODES, data);
export const postPromoCode = (data) => post(url.ADD_PROMO_CODE, data);
export const getPromoCode = (id) => get(url.GET_PROMO_CODE + "/" + id);
export const putPromoCode = (data) => post(url.PUT_PROMO_CODE, data);
export const deletePromoCode = (data) => post(url.DELETE_PROMO_CODE, data);
export const getPromoCodeUsers = (data) => post(url.GET_PROMO_CODE_USERS, data);

// Items
export const getItems = (data) => post(url.GET_ITEMS, data);
export const updateItems = (data) => post(url.UPDATE_NFT, data);
export const addBackgroundImage = (data) =>
  post(url.ADD_BACKGROUND_IMAGE, data);
export const viweBackgroundImage = (data) =>
  get(url.VIEW_BACKGROUND_IMAGE + data);

export const listBackgroundImage = (data) => get(url.LIST_BACKGROUND_IMAGE);

export const updateBackgroundImage = (data) =>
  post(url.UPDATE_BACKGROUND_IMAGE, data);

export const deleteBackgroundImage = (data) =>
  post(url.DELETE_BACKGROUND_IMAGE, data);

export const postItem = (data) => post(url.POST_ITEM, data);
export const deployItem = (data) => post(url.DEPLOY_ITEM, data);
export const deleteItem = (data) => post(url.DELETE_ITEM, data);
export const deleteItem2 = (data) => post(url.DELETE_ITEM_2 + data);
export const removeMultipleItems = (data) => post(url.REMOVE_MULTIPLE_ITEMS, data);
export const deleteBlog = (data) => post(url.DELETE_BLOG, data);
export const getItem = (id) => get(url.GET_ITEM + "/" + id);
export const putItemsStatus = (data) => post(url.PUT_ITEM_STATUS, data);
export const getNotifications = (data) => post(url.GET_NOTIFICATIONS, data);

export const putNotifications = (data) => post(url.PUT_NOTIFICATIONS, data);

//categories
export const getFdCategories = (data) => post(url.API_CATEGORY, data);

export const postFdCategory = (storeType, data) =>
  post(url.API_CATEGORY + url.ADD_FD_CATEGORY, data);

export const getFdCategory = (id) =>
  get(url.API_CATEGORY + url.GET_FD_CATEGORY + "/" + id);

// export const getFdCategory = (id) => get(url.VIEW_USER + "/" + id)
export const putFdCategory = (data) =>
  post(url.API_CATEGORY + url.PUT_FD_CATEGORY, data);

export const deleteFdCategory = (storeType, data) =>
  post(url.API_CATEGORY + url.DELETE_FD_CATEGORY, data);

export const putFdCategoriesStatus = (storeType, data) =>
  post(url.PUT_FD_CATEGORIES_STATUS, data);

export const putFdCategorySort = (data) => post(url.PUT_FD_CATEGORY_SORT, data);

export const exportFdCategories = (storeType, id) =>
  get(urlHelper(url.EXPORT_FD_CATEGORIES, storeType, id));

// TOURNAMENT

export const postAddTournament = (data) => gamePost(url.ADD_TOURNAMENT, data);
export const postGetTournament = (data) => gamePost(url.GET_TOURNAMENTS, data);
export const postRemoveTournament = ({ _id }) => {
  gameGet(url.REMOVE_TOURNAMENTS + _id);
};
export const getViewTournament = (data) => gameGet(url.VIEW_TOURNAMENT + data);
export const postUpdateTournament = (data) =>
  gamePost(url.UPDATE_TOURNAMENT + data._id, data);

// TOURNAMENT HISTORY
export const postGetTournamentHistory = (data) =>
  gamePost(url.GET_TOURNAMENTS_HISTORY, data);
export const postViewTournamentHistory = (_id) =>
  gameGet(url.VIEW_TOURNAMENTS_HISTORY + _id);

// MATCH HISTORY
export const postGetMatchHistory = (data) =>
  gamePost(url.GET_MATCH_HISTORY, data);
export const postViewMatchtHistory = (_id) =>gameGet(url.VIEW_MATCH_HISTORY + _id);

// balance
export const postAddBalance = (data) => gamePost(url.ADD_BALANCE, data);
export const postRemoveBalance = (data) => gamePost(url.REMOVE_BALANCE, data);
export const postGetUserBalance = (data) => gamePost(url.GET_USER_BALANCE, data);

export const postGetBalanceHistory = (data) => gamePost(url.GET_BALANCE_HISTORY, data);


export const postEndMatch = (data) => gamePost(url.END_MATCH, data);

export const getLeaderBoard = (data) => gamePost(url.LEADER_BOARD, data)
export const reduceLeaderboardPoints = (data) => gamePost(url.reduceLeaderboardPoints, data)
//Mobile App History

export const postDailyLimit = (data) => mobilePost(url.POST_DAILY_LIMIT, data);

export const postEditDailyLimit = (data) => mobilePut(url.POST_EDIT_DAILY_LIMIT + data?.id , data);

export const postPendingWithdrawList = (data) => gamePost(url.POST_PENDING_WITHDRAWS, data);
export const postWithdrawHistory = (data) => gamePost(url.POST_WITHDRAWS_HISTORY, data);

export const postWithdrawStatus = (data) => gamePost(url.POST_WITHDRAW_STATUS, data);


export const postCrystalPackage = (data) => gamePost(url.CREATE_CRYSTAL_PACKAGE, data);
export const updateCrystalPackage = (data) => gamePost(url.UPDATE_CRYSTAL_PACKAGE, data);
export const getCrystalPackage = (data) => gamePost(url.GET_CRYSTAL_PACKAGE, data);
export const getCrystalPackagePurchaseList = (data) => gamePost(url.GET_CRYSTAL_PACKAGE_PURCHASE_LIST, data);

export const postAddOperator = (data) => post(url.ADD_OPERATOR, data);
export const getOperatorList = (data) => post(url.GET_OPERATOR, data);


export const updateOperator = (data) => put(url.UPDATE_OPERATOR + data?._id , data);
export const removeOperator = (data) => del(url.REMOVE_OPERATOR + data?._id , data);


export const postGetSailHistory = (data) => mobilePost(url.GET_SAIL_HISTORY, data);
export const viewSailHistory = (data) => mobileGet(url.VIEW_SAIL_HISTORY + data?._id, data);

export const getDashboard = (data) =>  get(url.GET_DASHBOARD_DATA);

export const getDashboardWithCountry = (data) =>  get(url.GET_DASHBOARD_DATA + "?country=" + data?.country);

export const postGetActivityHistory = (data) => post(url.GET_USER_ACTIVITIES, data);

export const getCrewMembers = (data) => mobilePost(url.GET_CREW_MEMBERS, data);

export const postGetBonusHistory = (data) => mobilePost(url.GET_BONUS_HISTORY, data);
export const updateBonusHistory = (data) => mobilePost(url.UPDATE_BONUS_HISTORY, data);


export const getCrystalPurchaseHistory = (data) => mobilePost(url.GET_CRYSTAL_PURCHASE_HISTORY, data);
export const postGetMobileGameHistory = (data) => mobilePost(url.GET_MOBILE_GAME_HISTORY, data);
export const getMobileGameDetail = (data) => mobileGet(url.GET_MOBILE_GAME_DETAIL + data);
export const postGetMobileGameEnergyHistory = (data) => mobilePost(url.GET_MOBILE_GAME_ENERGY_HISTORY, data);
export const postGetMobileGameLeaderboardHistory = (data) => mobilePost(url.GET_MOBILE_GAME_LEADERBOARD_HISTORY, data);
export const updateLeaderboardPoint = (data) => mobilePost(url.UPDATE_LEADERBOARD_POINTS, data);
export const updateMobileGameHistory = (data) => mobilePost(url.UPDATE_GAME_HISTORY, data);
export const getReferralClaimHistory = (data) => mobilePost(url.GET_REFERRAL_CLAIM_HISTORY, data);
export const updateReferralClaimHistory = (data) => mobilePost(url.UPDATE_REFERRAL_CLAIM_HISTORY, data);

export const postGetNFTRepairHistory = (data) => mobilePost(url.NFT_REPAIR_HISTORY, data);

export const postGetNFTLvlUpgradeHistory = (data) => mobilePost(url.NFT_LVL_UPGRADE_HISTORY, data);

export const tempAirdropNft = (data) => mobilePost(url.TEMP_AIRDROP_NFT, data);

export const postGetLanguageList = (data) => mobilePost(url.GET_LANGUAGE_LIST,data);

export const endMobileGame = (data) => mobilePost(url.END_MOBILE_GAME,data);

export const cancelNFTSale = (data) => mobilePost(url.CANCEL_NFT_SALE, data);

export const updateItemsPrices = (data) => post(url.UPDATE_SELECTED_ITEM_PRICES, data);

export const addMultipleItems = (data) => post(url.ADD_MULTIPLE_ITEMS, data);

export const postGetNotificationList = (data) => mobilePost(url.GET_NOTIFICATIONS_LIST, data);

export const postGetIPHistory = (data) => mobilePost(url.GET_IP_HISTORY, data);

export const postSendNotification = (data) => mobilePost(url.SEND_NOTIFICATION, data);