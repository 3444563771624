import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch} from "react-redux";
import { withRouter, Link, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationListStandalone, PaginationProvider, } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { withTranslation } from "react-i18next";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import SearchInput from "components/Common/SearchInput";
import { getCrewMembers,getSettings } from "store/actions";
import UsersColumns from "./CrewMemberColumns";
import ViewlistModal from "./Viewlistmod";
import toastr from "toastr";

const CrewCodes = (props) => {
    const {  onGetCrewMembers,  crewMembers,  totalCrewMembers,  NftList } = props;
    // const dispatch = useDispatch();
    const { _id } = useParams();
    const { settings } = useSelector(state => state.Settings)

    const [viewlist, setviewlist] = useState({
        isOpen: false,
        nftList: [],
    });

    const [filter, setFilter] = useState({
        orderBy: "parentAddedAt",
        order: -1,
        limit: 20,
        search: "",
        userId: _id,
    });

    const [searchText, setSearchText] = useState("");
    const [crewMemberList, setCrewMemberList] = useState([]);
    const pageOptions = {
        sizePerPage: 20,
        totalSize: totalCrewMembers,
        custom: true,
        onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
    };

    useEffect(() => {
        onGetCrewMembers({ ...filter });
    }, [JSON.stringify(filter)]);

    useEffect(() => {
        setCrewMemberList(crewMembers || []);
    }, [crewMembers]);

    const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
        if (type === "search") {
            setSearchText(searchText.trim());
        }

        if (type === "sort") {
            if (sortField == "createdAt") {
                sortField = "parentAddedAt";
            }

            if (sortOrder == "desc") {
                sortOrder = -1;
            } else if (sortOrder == "asc") {
                sortOrder = 1;
            }

            setFilter((prevState) => ({
                ...prevState,
                orderBy: sortField,
                order: sortOrder,
            }));
        }
    };

    const toggleViewListModal = useCallback((nftList) => {
        setviewlist((prevState) => ({ isOpen: !prevState.isOpen, nftList: nftList } ));
    }, []);

    const onSearch = (event) => {
        event.preventDefault();
        setFilter((prevState) => ({ ...prevState, search: searchText.trim() }));
    };

    return (
        <React.Fragment>
            <ViewlistModal
                NftList={viewlist.nftList}
                Settings={settings}
                isOpen={viewlist.isOpen}
                toggle={toggleViewListModal}
                onHide={ () => setviewlist((prevState) => ({ isOpen: !prevState.isOpen } ))}
            />

            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Crew Members"
                        breadcrumbItem={props.t("Crew Members")}
                        breadcrumbItems={[ { title: props.t("Crew Codes"), link: "/crew-codes" }, { title: props.t("Crew Members") },]}
                    />

                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="_id"
                                                data={crewMemberList || []}
                                                columns={UsersColumns(
                                                    toggleViewListModal,
                                                    props.t,
                                                )}
                                                bootstrap4
                                                search
                                            >
                                                {(toolkitProps) => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col md="4" lg="3">
                                                                <SearchInput
                                                                    {...toolkitProps.searchProps}
                                                                    triggerSearch={onSearch}
                                                                    placeholder={props.t("Search")}
                                                                    searchText={searchText}
                                                                />
                                                            </Col>

                                                            <Col sm="2">
                                                                {!!filter.search && (
                                                                    <div
                                                                        className="mr-4 mb-2"
                                                                        style={{
                                                                            height: "calc( 1.5em + 0.94rem + 2px)",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                        }}
                                                                    >
                                                                        <Link
                                                                            to="#"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                setFilter((prevState) => ({
                                                                                    ...prevState,
                                                                                    search: "",
                                                                                }));
                                                                                setSearchText("");
                                                                            }}
                                                                            className=""
                                                                        >
                                                                            {props.t("Clear Filters")}
                                                                        </Link>
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive spinner-content">
                                                                    <BootstrapTable
                                                                        responsive
                                                                        remote
                                                                        bordered={false}
                                                                        striped={false}
                                                                        classes={
                                                                            "table table-centered table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        onTableChange={handleTableChange}
                                                                        {...paginationTableProps}
                                                                        defaultSorted={[
                                                                            {
                                                                                dataField: "createdAt",
                                                                                order: "desc",
                                                                            },
                                                                        ]}
                                                                        sort={{
                                                                            sortCaret: (order) =>
                                                                                order === "asc" ? (
                                                                                    <span className="caret">
                                                                                        <i className="bx bx-caret-up" />
                                                                                    </span>
                                                                                ) : order === "desc" ? (
                                                                                    <span className="caret">
                                                                                        <i className="bx bx-caret-down" />
                                                                                    </span>
                                                                                ) : null,
                                                                        }}
                                                                    />

                                                                    {/* {loading && (
                                                                        <div className="spinner">
                                                                            <Spinner color="primary" />
                                                                        </div>
                                                                    )} */}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

CrewCodes.propTypes = {
    t: PropTypes.any,
    onGetCrewMembers: PropTypes.func,
    crewMembers: PropTypes.array,
    totalCrewMembers: PropTypes.number,
};

const mapStateToProps = ({ CrewCode }) => ({
    crewMembers: CrewCode?.crewMembers,
    totalCrewMembers: CrewCode?.totalCount,
    NftList: CrewCode?.nftList,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onGetCrewMembers: (data) => { dispatch(getCrewMembers(data)) },
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CrewCodes))
);
