import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Spinner,
  Badge,
  Label,
} from "reactstrap";
import moment from "moment";
import usdt from "../../../assets/images/usdt_icon.png"
import bomb from "../../../assets/images/bomb.png"
import crystal from "../../../assets/images/diamond.png"

// Breadcrumbs
import Breadcrumbs from "components/Common/Breadcrumb2";

// Actions
import { getMobileGameDetail,  } from "store/actions";

const ViewRoundHistory = (props) => {
  const {
    match: { params },
    onGetGameDetail,
    gameHistoryDetail,
    loading,
  } = props;
  useEffect(() => {
    console.log(params.id, "params.id");
    if (params && params.id) {
      onGetGameDetail(params.id);
    }
  }, [params.id]);

  const [matchDetail, setMatchDetail] = useState({});
  const status = {
    "active": "Active",
    "completed": "Completed"
  }
  const NFTSTATUS = {
    "pending":"Pending", 
    "transferred":"Transferred"
  }

  useEffect(() => {
    if (gameHistoryDetail) {
      console.log("gameHistoryDetail", gameHistoryDetail)
      setMatchDetail(gameHistoryDetail);
    }
  }, [gameHistoryDetail]);

  // useEffect(() => {
  //   if (matchResult && matchResult.length > 0) {
  //     let arr = matchResult.map((item) => item.result);
  //     const singleArray = [].concat(...arr);
  //     setGameRound(singleArray);
  //   }
  // }, [matchResult]);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="spinner-content">
          {loading && (
            <div className="spinner">
              <Spinner color="primary" />
            </div>
          )}

          <Breadcrumbs
            title="Game Details"
            breadcrumbItem={props.t("Game Details")}
            breadcrumbItems={[ { title: props.t("Game History"), link: "/mobile-game-history" }, { title: props.t("Details") },]}
          />

          <Row className="justify-content-center">
            <Col sm="12">
              <Row>
                <Col>
                  <Card className="overflow-hidden">
                    <CardBody className="">
                      <Row>
                        <Col lg="12" className="my-2">
                          <h4 className="text-primary">Match Details</h4>
                          <ul className="list-unstyled ps-0 mb-0 mt-3 row">
                          <li className="col-lg-4 col-sm-6 my-2">
                              <p className="text-muted ">
                                RoundId :{" "}
                                <span className="font-weight-bold">
                                  {matchDetail?.roundId || "--"}
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className="text-muted">
                                Username :{" "}
                                <span className="font-weight-bold">
                                  {matchDetail?.userId?.username || "--"}
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className="text-muted">
                                Email :{" "}
                                <span className="font-weight-bold">
                                  {matchDetail?.userId?.email || "--"}
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className="text-muted">
                                Line Count :{" "}
                                <span className="font-weight-bold">
                                  {matchDetail?.lineCount || 0 }
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className="text-muted">
                                Previous Energy Balance :{" "}
                                <span className="font-weight-bold">
                                  {matchDetail?.prevEnergyBalance || 0 }
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className="text-muted">
                                Crystals Earned :{" "}
                                <span className="font-weight-bold">
                                  {matchDetail?.crystalsClaimed || 0 }
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className="text-muted">
                                USDT Earned :{" "}
                                <span className="font-weight-bold">
                                  {matchDetail?.usdtClaimed || 0 }
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className="text-muted">
                                USDT Transfer Status :{" "}
                                <span className="font-weight-bold">
                                  {matchDetail?.usdtAwardedStatus? NFTSTATUS[matchDetail?.usdtAwardedStatus] : "-" }
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className="text-muted">
                                NFT Earned :{" "}
                                <span className="font-weight-bold">
                                  {matchDetail?.nftsClaimed || 0 }
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className="text-muted">
                                NFT Type :{" "}
                                <span className="font-weight-bold">
                                  {matchDetail?.nftType || "-" }
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className="text-muted">
                                NFT Transfer Status :{" "}
                                <span className="font-weight-bold">
                                  {matchDetail?.nftAwardedStatus? NFTSTATUS[matchDetail?.nftAwardedStatus] : "-" }
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className="text-muted">
                                NFT Transferred :{" "}
                                <span className="font-weight-bold">
                                  {matchDetail?.awardedNft?.name || "-" }
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className="text-muted">
                                Status :{" "}
                                <span className="font-weight-bold">
                                  {matchDetail?.status? status[matchDetail.status] : "--"}
                                </span>
                              </p>
                            </li>

                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className="text-muted">
                                Started At :{" "}
                                <span className="font-weight-bold">
                                  {matchDetail?.date_created_utc ? 
                                  moment(matchDetail?.date_created_utc).format("DD MMM YYYY hh:mm A"): 
                                  "--"}
                                </span>
                              </p>
                            </li>

                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className="text-muted">
                                Completed At :{" "}
                                <span className="font-weight-bold">
                                  {matchDetail?.completedAt ? 
                                  moment(matchDetail?.completedAt).format("DD MMM YYYY hh:mm A"): 
                                  "--"}
                                </span>
                              </p>
                            </li>

                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className="text-muted">
                                Ended At :{" "}
                                <span className="font-weight-bold">
                                  {matchDetail?.endedAt ? 
                                  moment(matchDetail?.endedAt).format("DD MMM YYYY hh:mm A"): 
                                  "--"}
                                </span>
                              </p>
                            </li>
                            
                          </ul>
                        </Col>
                         <Col lg="12" className="my-2"> 
                          <h4 className="text-primary">Game Details</h4>
                          <div className="array-container">
                            {matchDetail?.roundResult?.map((item, index) => (
                              <div key={index} className="array-row">
                              <p className="mt-3 mr-3">{index+ 1}</p>
                                {item.row.map((cell, cellIndex) => (
                                  <p key={cellIndex} className={`array-cell background-${cell.isOpened}`}>
                                  {cell.value === "bomb" && <img className= {"leaderboard-icon"} src={bomb} />} 
                                  {cell.value === "usdt" && (<>
                                  <span>{cell.usdtValue}</span> 
                                  <img className= {"leaderboard-icon"} src={usdt}/>
                                  </>) } 

                                  {cell.value === "nft" && cell.nftType == "Sloop" && <img className= {"leaderboard-icon"} src={"https://piratepets.s3.eu-north-1.amazonaws.com/1706034814177shiplvl1-1.png"} /> } 

                                  {cell.value === "nft" && cell.nftType == "Schooner" && <img className= {"leaderboard-icon"} src={"https://piratepets.s3.eu-north-1.amazonaws.com/1706034814177shiplvl1-1.png"} /> } 

                                  {cell.value === "nft" && cell.nftType == "Brigantine" && <img className= {"leaderboard-icon"} src={"https://piratepets.s3.eu-north-1.amazonaws.com/1703153405977shiplvl3-1.png"} /> }   
                                  
                                  {!isNaN(cell.value) ? (<><span>{cell.value}</span> <img className= {"leaderboard-icon"} src= {crystal} /></>): ""}                                 
                                  </p>
                                ))}
                              </div>
                            ))}
                          </div>
                        </Col> 
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col xl="8"></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ViewRoundHistory.propTypes = {
  gameHistoryDetail: PropTypes.any,
  onGetGameDetail: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = ({ GameHistory }) => ({
  console: console.log(GameHistory, "gameeeeeeeeeeeee"),
  loading: GameHistory.loading,
  gameHistoryDetail: GameHistory.gameHistoryDetail,
  error: GameHistory.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetGameDetail: (data) => dispatch(getMobileGameDetail(data)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ViewRoundHistory))
);