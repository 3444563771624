import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Addbalancemod(props) {
 
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add_balance"
    >
      <Modal.Body>
        <div className="modal_connect position-relative">
          <a
            href="javascript:void(0)"
            className="close-modal"
            onClick={props.onhide}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 28 28"
              fill="none"
            >
              <rect width="28" height="28" rx="14" fill="#42a498" />
              <path
                d="M9.34766 18.4277C9.76368 18.8379 10.4844 18.8203 10.8652 18.4395L14 15.3047L17.1231 18.4336C17.5273 18.8379 18.2246 18.8379 18.6348 18.4219C19.0508 18.0059 19.0566 17.3086 18.6523 16.9043L15.5293 13.7754L18.6523 10.6523C19.0566 10.248 19.0508 9.55078 18.6348 9.14063C18.2188 8.72461 17.5273 8.71875 17.1231 9.12305L14 12.2461L10.8652 9.11719C10.4844 8.73633 9.76368 8.71875 9.34766 9.13477C8.9375 9.55078 8.94922 10.2656 9.33594 10.6465L12.4707 13.7754L9.33594 16.9102C8.94922 17.291 8.93164 18.0117 9.34766 18.4277Z"
                fill="white"
                fill-opacity="0.8"
              />
              <rect
                x="0.5"
                y="0.5"
                width="27"
                height="27"
                rx="13.5"
                stroke="black"
                stroke-opacity="0.04"
              />
            </svg>
          </a>

          <div className="modal_form">
            <h6><strong>Add Balance</strong></h6>
            <Form>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="selectuser">User</Label>
                    <Input type="select" name="type" id="selecttype" onChange={(e) => props.handleChange(e,"type")}>
                      <option>PPT</option>
                      <option>Crystal</option>
                      <option>Energy</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col lg={6}>
                  <FormGroup>
                    <Label for="Amountid">Amount</Label>
                    <Input
                      type="number"
                      name="amount"
                      id="Amountid" 
                      onChange={(e)=> props.handleChange(e, "amount")}
                    />
                  </FormGroup>
                </Col>
      

                <Col lg={12}>
                   <div className="btn_group d-flex gap-3">
                      <Button className="btn_Addmod" 
                      onClick={props.onAddBalance}
                      >Add</Button>
                      <Button className="btn_back" onClick={props.onhide}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M21 11H6.83l3.58-3.59L9 6l-6 6l6 6l1.41-1.41L6.83 13H21v-2z"/></svg>
                        Back</Button>
                   </div>
                </Col>

              </Row>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
