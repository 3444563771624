import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams, useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import toastr from "toastr";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import {
  addCollection,
  getCollection,
  uploadFile,
  removeFile,
} from "store/actions";

// Images
import avatar4 from "assets/images/users/avatar-9.jpg";

//i18n
import { withTranslation } from "react-i18next";

const AddCollection = ({
  error,
  loading,
  collection,
  onGetCollection,
  onUploadFile,
  ...props
}) => {
  const history = useHistory();
  console.log(history, 741);
  const { id } = useParams();

  const didMountRef = useRef();

  const [fields, setFields] = useState({
    name: "",
    blockchain: "BNB",
    image: null,
    maxSupply: "",
    symbol: "",
    description: "",
    royalityFee: "",
    artist: "",
    status: "active",
  });

  const [selectedFiles, setselectedFiles] = useState([]);

  useEffect(() => {
    if (id) {
      onGetCollection(id);
    }
  }, [id]);

  useEffect(() => {
    if (didMountRef.current) {
      try {
        setFields({
          name: collection.name || "",
          symbol: collection?.symbol || "",
          blockchain: collection?.blockchain,
          maxSupply: collection?.maxSupply,
          description: collection?.description || "",
          status: collection?.status || "active",
          image: collection?.image?._id,
        });

        if (collection?.image) {
          setselectedFiles({ preview: collection.image.link });
        }
      } catch (err) {
        console.log("parsing error ", err);
      }
    } else didMountRef.current = true;
  }, [JSON.stringify(collection)]);

  const uploadFileSuccess = (response) => {
    setFields((prevState) => ({ ...prevState, image: response.data._id }));
  };

  function handleAcceptedFiles(files) {
    onUploadFile({ image: files[0] }, uploadFileSuccess);

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setselectedFiles(files);
  }

  const handleChange = (name) => (event) => {
    if (name == "royalityFee") {
      setFields((prevState) => ({ ...prevState, [name]: parseInt(event.target.value) }));
    } else {
      setFields((prevState) => ({ ...prevState, [name]: (event.target.value) }));
    }

  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (loading) return;

    if (fields.artist?.length < 25) {
      return toastr.error("Please add valid wallet address.");
    }
    const callback = (response) => {
      if (response.status == "failure") {
        toastr.error(response.message);
      } else {
        console.log(response.message, 456);
        toastr.success(response.message);
        history.push("/collections");
      }
    };

    props.onAddCollection(fields, callback);
  };

  const goBack = () => {
    console.log("back");
    history.push("/collections");
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm={1} lg={2}></Col>
            <Col sm={10} lg={8}>
              <Breadcrumbs
                title="Collections"
                breadcrumbItem={props.t("Add") + " " + props.t("Collections")}
                breadcrumbItems={[
                  { title: props.t("Collection"), link: "/collections" },
                  { title: props.t(id ? "Edit" : "Add") },
                ]}
              />

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Form onSubmit={onSubmit} className="spinner-content">
                        {error && typeof error === "string" ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}

                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Name")}
                              </Label>

                              <Input
                                type="text"
                                id="formrow-firstname-Input"
                                value={fields.name}
                                onChange={handleChange("name")}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("Royalty")}</Label>

                              <InputGroup>
                                <Input
                                  type="number"
                                  value={fields.royalityFee}
                                  onChange={handleChange("royalityFee")}
                                  min={0}
                                  step={0.01}
                                  required
                                />
                                <div class="input-group-prepend">
                                  <span
                                    class="input-group-text"
                                    id="basic-addon1"
                                  >
                                    %
                                  </span>
                                </div>
                              </InputGroup>
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("Select Chain")}</Label>

                              <Input
                                type="select"
                                value={fields.blockchain}
                                onChange={handleChange("blockchain")}
                              >
                                {/* <option value="ethereum">Ethereum</option> */}
                                {/* <option value="polygon">Polygon</option> */}
                                <option value="BNB">Binance Smart Chain</option>
                              </Input>
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("Collection Type")}</Label>
                              <Input
                                type="select"
                                value={fields.collectionType}
                                onChange={handleChange("collectionType")}
                                required
                              >
                                <option value="">Type</option>
                                <option value="pirates">Pirates</option>
                                <option value="ships">Ships</option>
                                <option value="others">Others</option>
                              </Input>
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("Symbol")}</Label>
                              <Input
                                type="text"
                                style={{ textTransform: "upperCase" }}
                                value={fields.symbol}
                                onChange={handleChange("symbol")}
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("Max Supply")}</Label>
                              <Input
                                type="number"
                                min={0}
                                value={fields.maxSupply}
                                onChange={handleChange("maxSupply")}
                              />
                            </FormGroup>
                          </Col>

                          <Col md={12}>
                            <FormGroup>
                              <Label>{props.t("Description")}</Label>

                              <Input
                                type="textarea"
                                value={fields?.description}
                                onChange={handleChange("description")}
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("NFT Artist Address")}</Label>
                              <Input
                                type="text"
                                value={fields.artist}
                                onChange={handleChange("artist")}
                                required
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("Nft Fund Address")}</Label>
                              <Input
                                type="text"
                                value={fields.nftFundAddress}
                                onChange={handleChange("nftFundAddress")}
                                required
                              />

                            </FormGroup>
                          </Col>

                          {/* <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("status")}</Label>

                              <div className="status-switch square-switch">
                                <input
                                  type="checkbox"
                                  id="square-switch1"
                                  switch="none"
                                  checked={fields.status == "active"}
                                  onChange={() => {
                                    const value =
                                      fields.status == "active"
                                        ? "inactive"
                                        : "active";

                                    handleChange("status")({
                                      target: { value },
                                    });
                                  }}
                                />
                                <label
                                  htmlFor="square-switch1"
                                  data-on-label={props.t("active")}
                                  data-off-label={props.t("inactive")}
                                />
                              </div>
                            </FormGroup>
                          </Col> */}
                        </Row>

                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              <Label for="formrow-profile-image-Input">
                                {props.t("Collection")} {props.t("Image")}
                              </Label>

                              <Dropzone
                                onDrop={(acceptedFiles) => {
                                  handleAcceptedFiles(acceptedFiles);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => {
                                  const imageFile = selectedFiles[0];

                                  return (
                                    <div
                                      className="dropzone-single-image avatar-xl"
                                      {...getRootProps()}
                                    >
                                      <input
                                        {...getInputProps()}
                                        id="formrow-profile-image-Input"
                                        multiple={false}
                                      />

                                      <img
                                        className="rounded-circle avatar-xl"
                                        alt={
                                          !!imageFile && imageFile.name
                                            ? imageFile.name
                                            : "Legendary Racers"
                                        }
                                        src={
                                          !!imageFile
                                            ? imageFile.preview
                                            : avatar4
                                        }
                                      />

                                      <div className="edit">
                                        <i className="bx bx-pencil"></i>
                                      </div>
                                    </div>
                                  );
                                }}
                              </Dropzone>
                            </FormGroup>
                          </Col>
                        </Row>

                        <div className="mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            {props.t(id ? "Edit" : "Add")}
                          </button>

                          <button
                            type="button"
                            className="btn outline btn-outline-secondary w-md ml-3"
                            onClick={() => history.goBack()}
                          >
                            <i className="mdi mdi-arrow-left mr-1"></i>
                            {props.t("back")}
                          </button>
                        </div>

                        {loading && (
                          <div className="spinner">
                            <Spinner color="primary" />
                          </div>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm={1} lg={2}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddCollection.propTypes = {
  t: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onAddCollection: PropTypes.func,
  onUploadFile: PropTypes.func,
  onRemoveFile: PropTypes.func,
};

const mapStateToProps = ({ collections }) => ({
  error: collections.error,
  loading: collections.loading,
  collection: collections.collection,
});

const mapDispatchToProps = (dispatch) => ({
  onAddCollection: (data, history) => dispatch(addCollection(data, history)),
  onGetCollection: (id, callback) => dispatch(getCollection(id, callback)),
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
  onRemoveFile: (data) => dispatch(removeFile(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCollection))
);
