import {
  GET_MOBILE_GAME_HISTORY,
  GET_MOBILE_GAME_HISTORY_SUCCESS,
  GET_MOBILE_GAME_HISTORY_FAIL,
  GET_MOBILE_GAME_ENERGY_HISTORY,
  GET_MOBILE_GAME_ENERGY_HISTORY_SUCCESS,
  GET_MOBILE_GAME_ENERGY_HISTORY_FAIL,
  GET_MOBILE_GAME_LEADERBOARD_HISTORY,
  GET_MOBILE_GAME_LEADERBOARD_HISTORY_SUCCESS,
  GET_MOBILE_GAME_LEADERBOARD_HISTORY_FAIL,
  GET_MOBILE_GAME_DETAIL,
  GET_MOBILE_GAME_DETAIL_SUCCESS,
  GET_MOBILE_GAME_DETAIL_FAIL,
  UPDATE_MOBILE_GAME_LEADERBOARD_POINTS_SUCCESS,
  UPDATE_MOBILE_GAME_LEADERBOARD_POINTS_FAIL,
  UPDATE_MOBILE_GAME_LEADERBOARD_POINTS,
  UPDATE_MOBILE_GAME_HISTORY, 
  UPDATE_MOBILE_GAME_HISTORY_SUCCESS,
  UPDATE_MOBILE_GAME_HISTORY_FAIL,
  END_MOBILE_GAME, 
  END_MOBILE_GAME_SUCCESS, 
  END_MOBILE_GAME_FAIL
} from "./actionTypes";


/* Get Mobile Game history */
export const getMobileGameHistory = (data) => ({
  type: GET_MOBILE_GAME_HISTORY,
  payload: data,
});

export const getMobileGameHistorySuccess = (games) => ({
  type: GET_MOBILE_GAME_HISTORY_SUCCESS,
  payload: games,
});

export const getMobileGameHistoryFail = (error) => ({
  type: GET_MOBILE_GAME_HISTORY_FAIL,
  payload: error,
});

/* update Mobile Game history */
export const updateMobileGameHistory = (data, callBack) => ({
  type: UPDATE_MOBILE_GAME_HISTORY,
  payload: { data, callBack }
});

export const updateMobileGameHistorySuccess = (data, callBack) => ({
  type: UPDATE_MOBILE_GAME_HISTORY_SUCCESS,
  payload: { data, callBack }
});

export const updateMobileGameHistoryFail = (error) => ({
  type: UPDATE_MOBILE_GAME_HISTORY_FAIL,
  payload: error,
});



/* Get Mobile Game  energy history */
export const getMobileGameEnergyHistory = (data) => ({
  type: GET_MOBILE_GAME_ENERGY_HISTORY,
  payload: data,
});

export const getMobileGameEnergyHistorySuccess = (energies) => ({
  type: GET_MOBILE_GAME_ENERGY_HISTORY_SUCCESS,
  payload: energies,
});

export const getMobileGameEnergyHistoryFail = (error) => ({
  type: GET_MOBILE_GAME_ENERGY_HISTORY_FAIL,
  payload: error,
});


/* Get Mobile Game Leaderboard history */
export const getMobileGameLeaderboardHistory = (data) => ({
  type: GET_MOBILE_GAME_LEADERBOARD_HISTORY,
  payload: data,
});

export const getMobileGameLeaderboardHistorySuccess = (leaderboards) => ({
  type: GET_MOBILE_GAME_LEADERBOARD_HISTORY_SUCCESS,
  payload: leaderboards,
});

export const getMobileGameLeaderboardHistoryFail = (error) => ({
  type: GET_MOBILE_GAME_LEADERBOARD_HISTORY_FAIL,
  payload: error,
});

/* update Mobile Game Leaderboard points */
export const updateMobileGameLeaderboardPoints = (data, callBack) => ({
  type: UPDATE_MOBILE_GAME_LEADERBOARD_POINTS,
  payload: { data, callBack }
});

export const updateMobileGameLeaderboardPointSuccess = (data, callBack) => ({
  type: UPDATE_MOBILE_GAME_LEADERBOARD_POINTS_SUCCESS,
  payload: { data, callBack }
});

export const updateMobileGameLeaderboardPointFail = (error) => ({
  type: UPDATE_MOBILE_GAME_LEADERBOARD_POINTS_FAIL,
  payload: error,
});

/* Get Mobile Game details*/
export const getMobileGameDetail = (data) => ({
  type: GET_MOBILE_GAME_DETAIL,
  payload: data,
});

export const getMobileGameDetailSuccess = (leaderboards) => ({
  type: GET_MOBILE_GAME_DETAIL_SUCCESS,
  payload: leaderboards,
});

export const getMobileGameDetailFail = (error) => ({
  type: GET_MOBILE_GAME_DETAIL_FAIL,
  payload: error,
});


/* end Mobile Game*/
export const endMobileGame = (data,callback) => ({
  type: END_MOBILE_GAME,
  payload: {data,callback}
});

export const endMobileGameSuccess = (data, callBack) => ({
  type: END_MOBILE_GAME_SUCCESS,
  payload:  { data, callBack },
});

export const endMobileGameFail = (error) => ({
  type: END_MOBILE_GAME_FAIL,
  payload: error,
});