import {
  ADD_OPERATORS,
  ADD_OPERATORS_FAIL,
  ADD_OPERATORS_SUCCESS,
  GET_OPERATORS,
  GET_OPERATORS_FAIL,
  GET_OPERATORS_SUCCESS,
  REMOVE_OPERATORS,
  REMOVE_OPERATORS_FAIL,
  REMOVE_OPERATORS_SUCCESS,
  UPDATE_OPERATORS,
  UPDATE_OPERATORS_FAIL,
  UPDATE_OPERATORS_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  items: [],
  totalItems: 0,
};

const Operators = (state = INIT_STATE, action) => {


  switch (action.type) {

    case ADD_OPERATORS:
      return {
        ...state,
        loading: true,
      };

    case ADD_OPERATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case ADD_OPERATORS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };



    case GET_OPERATORS:
      return {
        ...state,
        loading: true,
      };

    case GET_OPERATORS_SUCCESS:
      return {
        ...state,
        items: action.payload.data || [],
        totalItems: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case GET_OPERATORS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };



    case UPDATE_OPERATORS:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_OPERATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case UPDATE_OPERATORS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case REMOVE_OPERATORS:
      return {
        ...state,
        loading: true,
      };

    case REMOVE_OPERATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case REMOVE_OPERATORS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };


    default:
      return state;
  }
};

export default Operators;
