import {
    GET_NFT_REPAIR_HISTORY,
    GET_NFT_REPAIR_HISTORY_SUCCESS,
    GET_NFT_REPAIR_HISTORY_FAIL,
} from "./actionTypes";


const INIT_STATE = {
    error: "",
    loading: false,
    repairHistory: [],
    totalHistory: 0,
};


const NFTRepairHistory = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_NFT_REPAIR_HISTORY:
        return {
          ...state,
          loading: true,
        };
  
      case GET_NFT_REPAIR_HISTORY_SUCCESS:
        return {
          ...state,
          repairHistory: action.payload.data || [],
          totalHistory: action.payload.total || 0,
          loading: false,
          error: "",
        };
  
      case GET_NFT_REPAIR_HISTORY_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
    
      default:
        return state;
  }
};

export default NFTRepairHistory;