import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";
import ConfirmModal from "./ConfirmModal";
import toastr from "toastr";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import {
  getOperator, removeOperator

} from "store/actions";
import ListColumns, { selectRow } from "./ListColumns";

const Items = ({
  accessLevel,
  history,
  items,
  totalItems,
  onGetItems,
  loading,
  onRemoveOperator,
  ...props
}) => {

  const [searchText, setSearchText] = useState("");
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    id: null,
  });


  const [filter, setFilter] = useState({

    page: 0,
    limit: 10,
    search: "",

  });
  const [itemsList, setItemsList] = useState([]);



  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalItems,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };


  useEffect(() => {
    onGetItems(filter);
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    setItemsList(items || []);
  }, [items]);

  const handleTableChange = (type, { searchText, sortField, sortOrder, searchToken }) => {

    if (type === "search") {
      setSearchText(searchText.trim());
    }
    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "create_date";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };



  const toggleConfirmModal = (id) => {

    setConfirmModal((prevState) => ({ isOpen: !prevState.isOpen, id :id }));
  };

  const removeConfirm = () => {
    const { id, isOpen } = confirmModal;

    const onComplete = (res) => {
      if (res.status == "success") {
        toastr.success("Operator deleted successfully!");
        window.location.reload();
      } else {
        toastr.error("Operator not remove!");
      }
    };

    setConfirmModal({ isOpen: !isOpen, id: null });
    onRemoveOperator({ _id: id }, onComplete);
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText.trim() }));
  };




  return (
    <React.Fragment>

      <ConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Operators"
            breadcrumbItem={props.t("Operators")}
            breadcrumbItems={[{ title: props.t("Operators") }]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={itemsList || []}
                        columns={ListColumns(
                          toggleConfirmModal,
                          props.t,
                          "text"
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">

                              <Row className="mb-2">
                                <Col md="4" lg="3">
                                  <SearchInput
                                    {...toolkitProps.searchProps}
                                    triggerSearch={onSearch}
                                    placeholder={props.t("Search")}
                                    searchText={searchText}
                                  />
                                </Col>

                                <Col sm="2">
                                  {!!filter.search && (
                                    <div
                                      className="mr-4 mb-2"
                                      style={{
                                        height: "calc( 1.5em + 0.94rem + 2px)",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setFilter((prevState) => ({
                                            ...prevState,
                                            search: "",
                                          }));
                                          setSearchText("");
                                        }}
                                        className=""
                                      >
                                        {props.t("Clear Filters")}
                                      </Link>
                                    </div>
                                  )}
                                </Col>
                              </Row>

                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Items.propTypes = {
  items: PropTypes.array,
  totalItems: PropTypes.number,
  onGetItems: PropTypes.func,
  onRemoveOperator: PropTypes.func,

};

const mapStateToProps = ({ Operators }) => ({
  loading: Operators.loading,
  items: Operators.items,
  totalItems: Operators.totalItems,
});

const mapDispatchToProps = (dispatch) => ({
  onGetItems: (data) => dispatch(getOperator(data)),
  onRemoveOperator: (data, onComplete) =>
    dispatch(removeOperator(data, onComplete)),

});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Items))
);
