import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MinigameRewardsSettings from "./MinigameRewardsSettings";
import LeaderboardSettings from "./Leaderboard";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Alert,
} from "reactstrap";

// Actions
import { getSettings, putSettings } from "store/actions";

// Other Settings
import GeneralSettings from "./General";


const Settings = (props) => {
  const { onGetSettings, onPutSettings, loading, error } = props;

  const [fields, setFields] = useState({

    adminCommission: 0,
    crystalConversionRate: 0,
    crystalCommission: 0,
    adminWalletAddress: "",
    gameCreateJoin: "enabled",
    minigameRewardsRange: props.settings.minigameRewardsRange || {
      start: 0,
      end: 0,
      fixPoint1: 0,
      fixPoint2: 0,
      minFixPoint2: 0,
      maxFixPoint2: 0
    },
    minigameLeaderboard: props.settings.minigameLeaderboard || {
      status: "active",
      weekCount: 1
    }
  });

  useEffect(() => {
    onGetSettings();
  }, []);

  useEffect(() => {
    if (props.settings) {
      setFields((prevState) => ({
        ...prevState,

        "adminCommission": props.settings.minigameSetting?.adminCommission,
        "crystalCommission":  props.settings.minigameSetting?.crystalCommission,
        "crystalConversionRate":  props.settings.minigameSetting?.crystalConversionRate,
        "adminWalletAddress": props.settings.minigameSetting?.adminWalletAddress,
        "gameCreateJoin": props.settings.minigameSetting?.gameCreateJoin,
        minigameRewardsRange: props.settings.minigameRewardsRange || {
          start: 0,
          end: 0,
          fixPoint1: 0,
          fixPoint2: 0,
          minFixPoint2: 0,
          maxFixPoint2: 0
        },
        minigameLeaderboard: props.settings.minigameLeaderboard
      }));
    }
  }, [JSON.stringify(props?.settings)]);

  const handleChange = (name) => (event) => {
    const { value } = event.target;

    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  const handleInnerChange = (name) => (name2) => (event) => {
    const { value } = event.target;

    setFields((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], [name2]: value },
    }));
  };

  function submit() {
    onPutSettings({
      minigameRewardsRange: fields.minigameRewardsRange,
      minigameSetting: {
        adminCommission: fields.adminCommission,
        crystalConversionRate: fields.crystalConversionRate,
        crystalCommission: fields.crystalCommission,
        adminWalletAddress: fields.adminWalletAddress,
        gameCreateJoin: fields.gameCreateJoin,
      },
      minigameLeaderboard: fields.minigameLeaderboard,
      _id: props.settings?._id,
    });
  }

  return (
    <div className="page-content">
      <Container fluid>
        {/* <Breadcrumbs title="Settings" breadcrumbItem="Settings" /> */}

        <Row>
          <Col sm={1} lg={2}></Col>

          <Col sm={10} lg={8}>
            <Card>
              <CardBody className="spinner-content">
                {error && typeof error === "string" ? (
                  <Alert color="danger">{error}</Alert>
                ) : null}

                {loading && <div className="spinner"></div>}

                <GeneralSettings fields={fields} handleChange={handleChange} />

                <hr className="my-3" />

                <MinigameRewardsSettings
                  fields={fields.minigameRewardsRange}
                  handleChange={handleInnerChange("minigameRewardsRange")}
                />

                <hr className="my-3" />

                <LeaderboardSettings
                  fields={fields.minigameLeaderboard}
                  handleChange={handleInnerChange("minigameLeaderboard")}
                />
                <Row>
                  <Col></Col>

                  <Col className="d-flex justify-content-end">
                    {loading && <Spinner color="primary" className="mr-2" />}

                    <Button className="buttoncolor" onClick={submit}>
                      Save Changes
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col sm={1} lg={2}></Col>
        </Row>
      </Container>
    </div>
  );
};

Settings.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  onGetSettings: PropTypes.func,
  onPutSettings: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onPutSettings: (data) => dispatch(putSettings(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
);
