import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const CustomersColumns = (toggleConfirmModal, t) => [
  {
    dataField: "type",
    text: t("Type"),
    sort: true,
    formatter: (_, row) => (row.type ? row.type : "-"),
  },
  {
    dataField: "amount",
    text: t("Amount"),
    sort: true,
    formatter: (_, row) => (row.amount ? (row.amount)/Math.pow(10, 18) : "-"),
  },
  {
    dataField: "status",
    text: t("Status"),
    sort: true,
    formatter: (_, row) => (row.status ? row.status : "-"),
  },
  {
    dataField: "response",
    text: t("Transaction Hash"),
    sort: true,
    formatter: (_, row) => (row.response ? row.response : "-"),
  },
  {
    text: t("Created At"),
    dataField: "createdAt",
    sort: true,
    formatter: (_, row) =>
      row.date_created_utc ? (
        <>
          {moment(row.date_created_utc).local().format("YYYY-MM-DD HH:mm:ss")}
          {/* moment(row.date_created_utc).utcOffset(330).format("DD MMM YYYY LT") */}
        </>
      ) : (
        "-"
      ),
  },
  {
    dataField: "error",
    text: t("Error"),
    sort: true,
    formatter: (_, row) => (row.error ? row.error : "-"),
  },

];

export default CustomersColumns;
