import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import Instructions from "./Instructions";

const MinigameRewardSettings = (props) => {
  const { fields, handleChange } = props;
  return (
    <React.Fragment>
      <Row>
        <Col lg={4}>
          <Instructions t={props.t} />
        </Col>

        <Col lg={8}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Start</Label>

                <Input
                  type="number"
                  value={fields?.start}
                  onChange={handleChange("start")}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>End</Label>

                <Input
                  type="text"
                  value={fields?.end}
                  onChange={handleChange("end")}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Fix Point 1</Label>
                <Input
                  type="number"
                  value={fields?.fixPoint1}
                  onChange={handleChange("fixPoint1")}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Fix Point 2</Label>
                <Input className="my-2"
                  placeholder="Min Value"
                  type="text"
                  value={fields?.minFixPoint2}
                  onChange={handleChange("minFixPoint2")}
                />
                <Input
                  placeholder="Max Value"
                  type="text"
                  value={fields?.maxFixPoint2}
                  onChange={handleChange("maxFixPoint2")}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

MinigameRewardSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MinigameRewardSettings)
);
