import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import { ACTIVITY_TYPE, BLOCKCHAIN_CURRENCY_CODE } from "helpers/contants";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (history, toggleConfirmModal, accesses, t, settings) => [
  {
    dataField: "history_type",
    text: t("Type"),
    formatter: (_, row) => {
      return (
        <>
          <p>{row?.history_type || "----"}</p>
        </>
      );
    },
  },
  {
    dataField: "from.email",
    text: t("Email"),
    formatter: (_, row) => {
      return (
        <>
          <p>{row?.from?.email || "----"}</p>
        </>
      );
    },
  },

  {
    dataField: "from.username",
    text: t("Username"),
    formatter: (_, row) => {
      return (
        <>
          <p>{row?.from?.username || "----"}</p>
        </>
      );
    },
  },
  {
    dataField: "from.walletAddress",
    text: t("WalletAddress"),
    formatter: (_, row) => {
      return (
        <>
          <p>{row?.from?.walletAddress || "----"}</p>
        </>
      );
    },
  },
  {
    dataField: "item.name",
    text: t("Item Name"),
    formatter: (_, row) => {
      return (
        <>
          <p>{row?.item?.name || "----"}</p>
        </>
      );
    },
  },
  {
    dataField: "transaction_hash",
    text: t("TnxHash"),
    formatter: (_, row) => {
      return (
        <>
          <p>
            <a
              href={
                settings?.blockchainNetworkMode == "mainnet"
                  ? `https://bscscan.com/tx/${row?.transaction_hash}`
                  : `https://testnet.bscscan.com/tx/${row?.transaction_hash}`
              }
              target={"_blank"}
            >
              {row?.transaction_hash}
            </a>
          </p>
        </>
      );
    },
  },
  {
    text: t("Created Date"),
    dataField: "date_created_utc",
    sort: true,
    formatter: (_, row) => (
      <>
        {/* moment(row.created_date).format("DD MMM YYYY hh:mm A"), */}
        {moment(row.created_date).local().format("DD MMM YYYY hh:mm A")}
      </>
    ),
  },
];

export default ListColumns;
