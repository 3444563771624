import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Profilebalance from "./Profilebalance";
import Profileinfo from "./Profileinfo";
// import Header from "Component/Header/header";
// import { Outlet } from "react-router-dom";
import UserHeader from "components/UserHeader/header";
import { connect, useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    FormGroup,
    Label,
    Spinner,
    Button,
} from "reactstrap";
import { getCustomer, changeStatus, getSettings, updateUserProfile, onAddBalance } from "store/actions";

const ViewCustomer = (props) => {
    let auth = localStorage.getItem("authUser");
    let adminRole = JSON.parse(auth)?.data?.role;
    const [state, setstate] = useState({ status: "active" });
    const [nftListModal, setNftListModal] = useState({
        isOpen: false,
        items: null,
    });
    const {
        match: { params },
        onGetCustomer,
        onUpdateProfile,
        customer,
        userBalance,
        NftList,
        bonusHistory,
        crewMembers,
        error,
        loading,
    } = props;

    const dispatch = useDispatch();
    const { settings } = useSelector(state => state.Settings)
    useEffect(() => {
        if (params && params?.id) {
            onGetCustomer(params?.id);
        }
    }, [params]);

    useEffect(() => {
        dispatch(getSettings());
    }, []);

    useEffect(() => {
        if (!customer) return;
        setstate((prev) => ({ ...prev, status: customer?.status }));
    }, [customer]);

    const handleChange = (e) => {
        const { checked } = e.target;
        setstate((prev) => ({ ...prev, status: checked ? "blocked" : "active" }));
        dispatch(
            changeStatus({
                status: checked ? "blocked" : "active",
                userId: params?.id,
            })
        );
    };
    const handleClick = (e) => {
        e.preventDefault();
        if (NftList.length > 0) {
            setNftListModal((prevState) => ({ isOpen: !prevState.isOpen, NftList }));
        }
    }
    const toggleNftModel = (e) => {
        setNftListModal((prevState) => ({ isOpen: !prevState.isOpen }));
    }

    return (

        <React.Fragment>
            {loading && (
                <div className="page-content">
                    <Spinner color="primary" />
                </div>
            )}

            {!loading && (

                <div className="wrappign_dashborad">
                    <div className="profile_left">
                        <Profilebalance
                            customer={customer}
                            userBalance={userBalance}
                            onUpdateProfile={onUpdateProfile}
                            adminRole={adminRole}
                        />
                    </div>

                    <div className="profile_right">
                        <Profileinfo
                            customer={customer}
                            NftList={NftList}
                            bonusHistory={bonusHistory}
                            crewMembers = {crewMembers}
                            Settings={settings || {}}
                            onUpdateProfile={onUpdateProfile}
                            adminRole={adminRole}

                        />
                    </div>
                </div>
            )}
        </React.Fragment>

    );
};

ViewCustomer.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string,
    customer: PropTypes.any,
    onGetCustomer: PropTypes.func,
    onUpdateProfile: PropTypes.func,

};

const mapStateToProps = ({ customers }) => ({
    error: customers.error,
    loading: customers.loading,
    customer: customers.customer,
    NftList: customers.NftList,
    bonusHistory: customers.bonusHistory,
    userBalance: customers.userBalance,
    crewMembers: customers.crewMembers,
});

const mapDispatchToProps = (dispatch) => ({
    onGetCustomer: (id) => dispatch(getCustomer(id)),
    onUpdateProfile: (data, callback) => dispatch(updateUserProfile(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomer);
