import { call, put, takeLatest } from "redux-saga/effects";

// Collection Redux States
import {
  GET_MOBILE_GAME_HISTORY,
  GET_MOBILE_GAME_ENERGY_HISTORY,
  GET_MOBILE_GAME_LEADERBOARD_HISTORY,
  GET_MOBILE_GAME_DETAIL,
  UPDATE_MOBILE_GAME_LEADERBOARD_POINTS,
  UPDATE_MOBILE_GAME_HISTORY,
  END_MOBILE_GAME
} from "./actionTypes";

import {
  getMobileGameHistoryFail,
  getMobileGameHistorySuccess,
  getMobileGameEnergyHistoryFail,
  getMobileGameEnergyHistorySuccess,
  getMobileGameLeaderboardHistoryFail,
  getMobileGameLeaderboardHistorySuccess,
  getMobileGameDetailSuccess,
  getMobileGameDetailFail,
  updateMobileGameLeaderboardPointFail,
  updateMobileGameLeaderboardPointSuccess,
  updateMobileGameHistorySuccess,
  updateMobileGameHistoryFail,
  endMobileGameFail,
  endMobileGameSuccess
} from "./actions";

import {
  postGetMobileGameHistory,
  postGetMobileGameEnergyHistory,
  postGetMobileGameLeaderboardHistory,
  getMobileGameDetail,
  updateLeaderboardPoint,
  updateMobileGameHistory,
  endMobileGame
} from "helpers/backend_helper";

function* getMobileGameHistorySaga({ payload, callback }) {
  try {
    const response = yield call(postGetMobileGameHistory, payload);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(getMobileGameHistoryFail(response.message));
    }
    yield put(getMobileGameHistorySuccess(response));
  } catch (error) {
    yield put(getMobileGameHistoryFail(error));
  }
}

function* getMobileGameEnergyHistorySaga({ payload, callback }) {
  try {
    const response = yield call(postGetMobileGameEnergyHistory, payload);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(getMobileGameEnergyHistoryFail(response.message));
    }
    yield put(getMobileGameEnergyHistorySuccess(response));
  } catch (error) {
    yield put(getMobileGameEnergyHistoryFail(error));
  }
}

function* getMobileGameLeaderboardHistorySaga({ payload, callback }) {
  try {
    const response = yield call(postGetMobileGameLeaderboardHistory, payload);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(getMobileGameLeaderboardHistoryFail(response.message));
    }
    yield put(getMobileGameLeaderboardHistorySuccess(response));
  } catch (error) {
    yield put(getMobileGameLeaderboardHistoryFail(error));
  }
}

function* getMobileGameDetailSaga({ payload, callback }) {
  try {
    const response = yield call(getMobileGameDetail, payload);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(getMobileGameDetailFail(response.message));
    }
    yield put(getMobileGameDetailSuccess(response));
  } catch (error) {
    yield put(getMobileGameDetailFail(error));
  }
}

function* updateMobileGameLeaderboardPointSaga({ payload: { data, callBack } }) {
  try {
    const response = yield call(updateLeaderboardPoint, data);
    callBack && callBack(response);
    if (response.status == "failure") {
      return yield put(updateMobileGameLeaderboardPointFail(response.message));
    }
    yield put(updateMobileGameLeaderboardPointSuccess(response));
  } catch (error) {
    yield put(updateMobileGameLeaderboardPointFail(error));
  }
}

function* updateMobileGameHistorySaga({ payload: { data, callBack } }) {
  try {
    const response = yield call(updateMobileGameHistory, data);
    callBack && callBack(response);
    if (response.status == "failure") {
      return yield put(updateMobileGameHistoryFail(response.message));
    }
    yield put(updateMobileGameHistorySuccess(response));
  } catch (error) {
    yield put(updateMobileGameHistoryFail(error));
  }
}

function* endMobileGameSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(endMobileGame, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(endMobileGameFail(response.message));
    }
    yield put(endMobileGameSuccess(response));
  } catch (error) {
    yield put(endMobileGameFail(error));
  }
}

function* MobileGameSaga() {
  yield takeLatest(GET_MOBILE_GAME_HISTORY, getMobileGameHistorySaga);
  yield takeLatest(GET_MOBILE_GAME_ENERGY_HISTORY, getMobileGameEnergyHistorySaga);
  yield takeLatest(GET_MOBILE_GAME_LEADERBOARD_HISTORY, getMobileGameLeaderboardHistorySaga);
  yield takeLatest(GET_MOBILE_GAME_DETAIL, getMobileGameDetailSaga);
  yield takeLatest(UPDATE_MOBILE_GAME_LEADERBOARD_POINTS, updateMobileGameLeaderboardPointSaga);
  yield takeLatest(UPDATE_MOBILE_GAME_HISTORY, updateMobileGameHistorySaga);
  yield takeLatest(END_MOBILE_GAME, endMobileGameSaga)
}

export default MobileGameSaga;