import {
  GET_MATCH_HISTORY,
  GET_MATCH_HISTORY_SUCCESS,
  GET_MATCH_HISTORY_FAIL,
  VIEW_MATCH_HISTORY,
  VIEW_MATCH_HISTORY_SUCCESS,
  VIEW_MATCH_HISTORY_FAIL,
  END_MATCH, END_MATCH_SUCCESS, END_MATCH_FAIL
} from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  matchHistoryDetail: [],
  matchHistory: [],
  totalMatchHistory: 0,
  date: "",
};

const MatchHistory = (state = INIT_STATE, action) => {
  // console.log(action.payload, "SSS");
  switch (action.type) {
    /* Get Collections */
    case GET_MATCH_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case GET_MATCH_HISTORY_SUCCESS:
      return {
        ...state,
        matchHistory: action.payload.data || [],
        totalMatchHistory: action.payload.total || 0,
        loading: false,
        error: "",
      };

    case GET_MATCH_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    /* VIEW tournament */
    case VIEW_MATCH_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case VIEW_MATCH_HISTORY_SUCCESS:
      return {
        ...state,
        matchHistoryDetail: action.payload,
        loading: false,
        error: "",
      };

    case VIEW_MATCH_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case END_MATCH:
      return {
        ...state,
        loading: true,
      };

    case END_MATCH_SUCCESS:
      return {
        ...state,
        matchHistoryDetail: action.payload,
        loading: false,
        error: "",
      };

    case END_MATCH_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default MatchHistory;
