import { UPLOAD_FILE, UPLOAD_ITEM_FILE, REMOVE_FILE, API_FAIL, UPLOAD_MULTIPLE_FILE } from "./actionTypes"

export const apiFail = error => ({
  type: API_FAIL,
  payload: error,
})

export const uploadFile = (data, callback) => ({
  type: UPLOAD_FILE,
  payload: { data, callback },
})

export const uploadItemFile = (data, callback) => ({
  type: UPLOAD_ITEM_FILE,
  payload: { data, callback },
})

export const removeFile = fileId => ({
  type: REMOVE_FILE,
  payload: fileId,
})

export const uploadMultipleFile = (data, callback) => ({
  type: UPLOAD_MULTIPLE_FILE,
  payload: { data, callback },
})
