import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  Row,
  Col,
  Alert,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  Container,
  Button,
} from "reactstrap";

import NetworkUrl from "./NetworkUrl";

// Actions
import { getSettings, putSettings } from "store/actions";

const DEFAULT_BLOCKCHAIN = {
  status: "",
  label: "",
  type: "",
  networkUrl: [
    {
      url: "",
      type: "testnet",
      apiKey: "",
      chainId: "",
      chainName: "",
      blockExplorerUrls: [],
      nativeCurrency: {
        name: "",
        symbol: "",
        decimals: 18,
      },
    },
    {
      url: "",
      type: "mainnet",
      apiKey: "",
      chainId: "",
      chainName: "",
      blockExplorerUrls: [],
      nativeCurrency: {
        name: "",
        symbol: "",
        decimals: 18,
      },
    },
  ],
};

const BlockchainNetwork = (props) => {
  // const { fields, handleChange } = props;

  const { onGetSettings, onPutSettings, loading, error } = props;

  const [fields, setFields] = useState({
    blockchainNetworkMode: "testnet",
    blockchain: [{ ...DEFAULT_BLOCKCHAIN }],
  });

  useEffect(() => {
    onGetSettings();
  }, []);

  useEffect(() => {
    if (!fields.blockchain) {
      let newBlockchain = [...fields.blockchain, { ...DEFAULT_BLOCKCHAIN }];
      handleChange("blockchain")({ target: { value: newBlockchain } });
    }
  }, [fields.blockchain]);

  const addBlockchain = (event) => {
    event.preventDefault();

    let newBlockchain = [...fields.blockchain, { ...DEFAULT_BLOCKCHAIN }];
    handleChange("blockchain")({ target: { value: newBlockchain } });
  };

  const removeBlockchain = (index) => (event) => {
    event.preventDefault();

    if (fields.blockchain.index <= 1) return;

    let newBlockchain = [
      ...fields.blockchain.slice(0, index),
      ...fields.blockchain.slice(index + 1),
    ];
    handleChange("blockchain")({ target: { value: newBlockchain } });
  };

  const handleBlockchainChange = (index, name) => (event) => {
    let newBlockchain = [
      ...fields.blockchain.slice(0, index),
      {
        ...fields.blockchain[index],
        [name]: event.target.value,
      },
      ...fields.blockchain.slice(index + 1),
    ];
    handleChange("blockchain")({ target: { value: newBlockchain } });
  };

  const handleNetworkUrlChange = (index) => (name) =>
    handleBlockchainChange(index, name);

  useEffect(() => {
    if (props.settings) {
      setFields((prevState) => ({
        ...prevState,
        name: props.settings.name,
        logo: props.settings.logo,
        adminCommission: props.settings.adminCommission,
        blockchainNetworkMode:
          props.settings.blockchainNetworkMode || "testnet",
        blockchain: props.settings.blockchain || [{ ...DEFAULT_BLOCKCHAIN }],
      }));
    }
  }, [JSON.stringify(props?.settings)]);

  const handleChange = (name) => (event) => {
    const { value } = event.target;

    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  function submit() {
    onPutSettings({ ...fields, _id: props.settings._id });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody className="spinner-content">
                {error && typeof error === "string" ? (
                  <Alert color="danger">{error}</Alert>
                ) : null}

                {loading && <div className="spinner"></div>}

                <Col lg={8}>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <h5 className="text-capitalize">
                            Blockchain Network Mode
                          </h5>

                          <div className="status-switch square-switch">
                            <input
                              className="switcher"
                              type="checkbox"
                              id="blockchain-network-mode"
                              switch="none"
                              checked={
                                fields.blockchainNetworkMode === "mainnet"
                              }
                              onChange={() => {
                                handleChange("blockchainNetworkMode")({
                                  target: {
                                    value:
                                      fields.blockchainNetworkMode === "mainnet"
                                        ? "testnet"
                                        : "mainnet",
                                  },
                                });
                              }}
                            />
                            <label
                              htmlFor="blockchain-network-mode"
                              data-on-label="Mainnet"
                              data-off-label="Testnet"
                            />
                          </div>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <h5 className="text-capitalize">Add Network Mode</h5>

                          <Link
                            to={`#`}
                            onClick={addBlockchain}
                            className="mr-3 text-primary"
                          >
                            <i
                              className="bx bx-plus font-size-22 mr-3"
                              id="addoptiontooltip"
                            />
                          </Link>
                        </FormGroup>
                      </Col>

                      <Col md={12} className="mt-3">
                        {fields.blockchain?.map((item, index) => (
                          <React.Fragment key={"blockchain-" + index}>
                            <Row className="mb-3 mb-sm-2">
                              <Col xs={12} className="">
                                <h5 className="">Network Mode</h5>
                                <hr className="my-3" />
                              </Col>

                              <Col xs={6} sm={6} className="pb-3">
                                <Label className="">Label</Label>

                                <Input
                                  type="text"
                                  className="form-control"
                                  value={item.label || ""}
                                  onChange={handleBlockchainChange(
                                    index,
                                    "label"
                                  )}
                                  required
                                />
                              </Col>

                              <Col xs={6} sm={6} className="pb-3">
                                <Label className="">Type</Label>

                                <Input
                                  type="text"
                                  className="form-control"
                                  value={item.type || ""}
                                  onChange={handleBlockchainChange(
                                    index,
                                    "type"
                                  )}
                                  required
                                />
                              </Col>

                              <Col xs={6} sm={6} className="pb-3">
                                <FormGroup>
                                  <Label className="text-capitalize">
                                    Status
                                  </Label>

                                  <div className="status-switch square-switch">
                                    <input
                                      className="switcher"
                                      type="checkbox"
                                      id={`blockchain-${index}-status`}
                                      switch="none"
                                      checked={item.status}
                                      onChange={() => {
                                        handleBlockchainChange(
                                          index,
                                          "status"
                                        )({
                                          target: {
                                            value: !item.status,
                                          },
                                        });
                                      }}
                                    />
                                    <label
                                      htmlFor={`blockchain-${index}-status`}
                                      data-on-label="Enable"
                                      data-off-label="Disable"
                                    />
                                  </div>
                                </FormGroup>
                              </Col>

                              <Col xs={6} sm={6}>
                                <Label className="">Actions</Label>

                                <div className="d-flex align-items-center">
                                  <Link
                                    disabled={fields.blockchain?.length === 1}
                                    to={`#`}
                                    onClick={removeBlockchain(index)}
                                    className={
                                      fields.blockchain?.length === 1
                                        ? "text-secondary"
                                        : "text-danger"
                                    }
                                  >
                                    <i
                                      className="bx bx-trash font-size-22"
                                      id="removeoptiontooltip"
                                    />
                                  </Link>
                                </div>
                              </Col>
                            </Row>

                            <NetworkUrl
                              fields={item}
                              handleChange={handleNetworkUrlChange(index)}
                            />
                          </React.Fragment>
                        ))}
                      </Col>
                    </Row>
                  </Form>

                  <Button className="buttoncolor" onClick={submit}>
                    Save Changes
                  </Button>
                </Col>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

BlockchainNetwork.propTypes = {
  fields: PropTypes.object,
  storeTypes: PropTypes.array,
  storeVendors: PropTypes.object,
  handleChange: PropTypes.func,
  handleStoreTypes: PropTypes.func,
  handleStoreVendors: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onPutSettings: (data) => dispatch(putSettings(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BlockchainNetwork)
);
