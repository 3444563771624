import {
  GET_BONUS_HISTORY,
  GET_BONUS_HISTORY_SUCCESS,
  GET_BONUS_HISTORY_FAIL,
  UPDATE_BONUS_HISTORY,
  UPDATE_BONUS_HISTORY_SUCCESS,
  UPDATE_BONUS_HISTORY_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  bonusHistoryDetail: {},
  bonusHistory: [],
  totalBonusHistory: 0,
  date: "",
};

const BonusHistory = (state = INIT_STATE, action) => {
  // console.log(action.payload, "SSS");
  switch (action.type) {
    /* Get Collections */
    case GET_BONUS_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case GET_BONUS_HISTORY_SUCCESS:
      return {
        ...state,
        bonusHistory: action.payload.data || [],
        totalBonusHistory: action.payload.total || 0,
        loading: false,
        error: "",
      };

    case GET_BONUS_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    /* VIEW sail history details */
    case UPDATE_BONUS_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_BONUS_HISTORY_SUCCESS:
      return {
        ...state,
        bonusHistoryDetail: action.payload,
        loading: false,
        error: "",
      };

    case UPDATE_BONUS_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default BonusHistory;
