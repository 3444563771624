import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (toggleConfirmModal, t) => [
  {
    dataField: "name",
    text: t("Name"),
  },
  {
    dataField: "fee_type",
    text: t("Fee Type"),
  },
  {
    dataField: "minimum_players",
    text: t("Min Players"),
  },
  {
    dataField: "maximum_players",
    text: t("Max Players"),
  },
  {
    dataField: "status",
    text: t("Status"),
  },
  {
    text: t("Start Date"),
    dataField: "start_date",
    sort: true,
    formatter: (_, row) => moment(row.start_date).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    text: t("End Date"),
    dataField: "end_date",
    sort: true,
    formatter: (_, row) => moment(row.end_date).format("YYYY-MM-DD HH:mm:ss"),
  },

  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <Link to={`/games/view/${row._id}`} className="mr-3 text-secondary">
          <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />

          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            View
          </UncontrolledTooltip>
        </Link>
        <Link to={`/games/${row._id}/edit`} className="mr-3 text-secondary">
          <i className="far fa-edit mr-3" id={`view-${row._id}-tooltip`} />
          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            Edit
          </UncontrolledTooltip>
        </Link>
        {row?.status == "created" && (
          <Link
            to={"#"}
            className="mr-3 text-secondary"
            onClick={(evt) => {
              evt.preventDefault();
              toggleConfirmModal(row._id);
              console.log(row._id, "row._id asdf ====== >");
            }}
          >
            <i
              className="fas fa-trash-alt mr-3"
              id={`delete-${row._id}-tooltip`}
            />

            <UncontrolledTooltip
              placement="top"
              target={`delete-${row._id}-tooltip`}
            >
              {t("delete")}
            </UncontrolledTooltip>
          </Link>
        )}
      </>
    ),
  },
];

export default ListColumns;
