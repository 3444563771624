export const TOURNAMENTS_HISTORY_API_FAIL = "TOURNAMENT_HISTORY_API_FAIL";

// TOURNAMENTS
export const GET_TOURNAMENTS_HISTORY = "GET_TOURNAMENTS_HISTORY";
export const GET_TOURNAMENTS_HISTORY_FAIL = "GET_TOURNAMENTS_HISTORY_FAIL";
export const GET_TOURNAMENTS_HISTORY_SUCCESS =
  "GET_TOURNAMENTS_HISTORY_SUCCESS";

export const VIEW_TOURNAMENTS_HISTORY = "VIEW_TOURNAMENTS_HISTORY";
export const VIEW_TOURNAMENTS_HISTORY_FAIL = "VIEW_TOURNAMENTS_HISTORY_FAIL";
export const VIEW_TOURNAMENTS_HISTORY_SUCCESS =
  "VIEW_TOURNAMENTS_HISTORY_SUCCESS";
