import { ADD_TOURNAMENT } from "helpers/url_helper";
import {
  MATCH_HISTORY_API_FAIL,
  GET_MATCH_HISTORY,
  GET_MATCH_HISTORY_SUCCESS,
  GET_MATCH_HISTORY_FAIL,
  VIEW_MATCH_HISTORY,
  VIEW_MATCH_HISTORY_SUCCESS,
  VIEW_MATCH_HISTORY_FAIL,
  END_MATCH, END_MATCH_FAIL, END_MATCH_SUCCESS
} from "./actionTypes";

export const apiFail = (error) => ({
  type: MATCH_HISTORY_API_FAIL,
  payload: error,
});

/* Get collections */
export const getMatchHistory = (data) => ({
  type: GET_MATCH_HISTORY,
  payload: data,
});

export const getMatchHistorySuccess = (matches) => ({
  type: GET_MATCH_HISTORY_SUCCESS,
  payload: matches,
});

export const getMatchHistoryFail = (error) => ({
  type: GET_MATCH_HISTORY_FAIL,
  payload: error,
});

export const viewMatchHistory = (data) => ({
  console: console.log(data, "actionhistoryDetail"),
  type: VIEW_MATCH_HISTORY,
  payload: data,
});

export const viewMatchHistorySuccess = (Match) => ({
  type: VIEW_MATCH_HISTORY_SUCCESS,
  payload: Match,
});

export const viewMatchHistoryFail = (error) => ({
  type: VIEW_MATCH_HISTORY_FAIL,
  payload: error,
});

export const endMatch = (data, callBack) => ({
  type: END_MATCH,
  payload: { data, callBack },
});

export const endMatchSuccess = (matches) => ({
  type: END_MATCH_SUCCESS,
  payload: matches,
});

export const endMatchFail = (error) => ({
  type: END_MATCH_FAIL,
  payload: error,
});
