import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, Spinner, Alert, Badge } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

// Actions
import { compileDeployCollectionV2 as postDeployCollection, compileDeployCollectionUpdt as postDeployCollectionUpdt } from "store/actions";
// import { } from "store/actions";

const DeployConfirmModal = (props) => {
  const { isOpen, toggle, collection } = props;

  const onConfirm = () => {
    props.onPostDeployCollection(
      { collectionId: collection._id, type: collection.blockchain },
      props.onSuccess
    );
  };

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      autoFocus={true}
      centered={true}
      toggle={toggle}
      scrollable={"true"}
    >
      <div className="modal-content remove-confirm spinner-content">
        {props.loading && (
          <div className="spinner">
            <Spinner color="primary" />
          </div>
        )}

        <ModalBody>
          {/* {props.error && typeof props.error === "string" ? (
            <Alert color="danger">{props.error}</Alert>
          ) : null} */}

          <h4>{props.t("Compile & Deploy Collection")}</h4>

          <p className="text-center">
            <span className="text-dark">Contract Address: </span>
            <br />
            {collection?.contractAddress || "NA"}{" "}
            {collection?.receiptStatus === "pending" && (
              <Badge color="warning">InProcess</Badge>
            )}
          </p>

          <div className="delete-btns">
            <Button type="button" outline color="secondary" onClick={toggle}>
              {props.t("No Cancel")}
            </Button>

            <Button type="button" color="primary" onClick={onConfirm}>
              {props.t("Yes Deploy")}
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

DeployConfirmModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

const mapStateToProps = ({ collections }) => ({
  loading: collections.loading,
  error: collections.error,
});

const mapDispatchToProps = (dispatch) => ({
  onPostDeployCollection: (data, callback) =>
    dispatch(postDeployCollection(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DeployConfirmModal));
