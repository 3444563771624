import { call, put, takeLatest } from "redux-saga/effects";

// Collection Redux States
import {
    GET_NFT_REPAIR_HISTORY,
} from "./actionTypes";

import {
    getNftRepairHistoryFail,
    getNftRepairHistorySuccess,
} from "./actions";

import {
    postGetNFTRepairHistory,
} from "helpers/backend_helper";
  

function* getNftRepairHistorySaga({ payload, callback }) {
    try {
      const response = yield call(postGetNFTRepairHistory, payload);
      callback && callback(response);
      if (response.status == "failure") {
        return yield put(getNftRepairHistoryFail(response.message));
      }
      yield put(getNftRepairHistorySuccess(response));
    } 
    catch (error) {
      yield put(getNftRepairHistoryFail(error));
    }
}

function* NFTRepairSaga() {
    yield takeLatest(GET_NFT_REPAIR_HISTORY, getNftRepairHistorySaga);
}
  
export default NFTRepairSaga;