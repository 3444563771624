import React, { useEffect, useState } from "react";
// import { Canvas } from "@react-three/fiber";
// import { OrbitControls } from "@react-three/drei";
// import GltfModel from "./GltfModel";

const ModelViewer = ({src }) => {

  


  const [model, setmodel] = useState(src)



  useEffect(() => {
    if(src){
      setmodel(src)
      console.log(src,"<==src");
    }
  }, [src])
  console.log(model, "model");
  return (
    <>
    
    <model-viewer alt="NFT Model" src={model} 
    
    ios-src={model}
    shadow-intensity="1" camera-controls touch-action="pan-y"></model-viewer>
    </>
  );
};

export default ModelViewer;