import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, FormGroup, FormText, Label, Input } from "reactstrap";
import Instructions from "./Instructions";
import moment from 'moment'
// Actions
import { uploadFile } from "store/actions";

const GeneralSettings = (props) => {
  const { fields, handleChange, onUploadFile } = props;

  return (
    <React.Fragment>
      <Row>
        <Col lg={4}>
          <Instructions t={props.t} />
        </Col>

        <Col lg={8}>
          <Row>


            <Col md={6}>
              <FormGroup>
                <Label>Step Count to Award Key</Label>
                <Input
                  type="number"
                  value={fields?.stepCountToAwardKey}
                  onChange={(e) => {
                    handleChange("stepCountToAwardKey")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Treasure Box Open Radius (m)</Label>

                <Input
                  type="number"
                  value={fields?.treasureBoxOpenRadius}
                  onChange={(e) => {
                    handleChange("treasureBoxOpenRadius")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Map Treasure Distance (m)</Label>

                <Input
                  type="number"
                  value={fields?.mapTreasureDistance}
                  onChange={(e) => {
                    handleChange("mapTreasureDistance")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Map Monster Distance (m)</Label>

                <Input
                  type="number"
                  value={fields?.mapMonsterDistance}
                  onChange={(e) => {
                    handleChange("mapMonsterDistance")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>



            <Col md={6}>
              <FormGroup>
                <Label>Max Count For Non-NFT User</Label>
                <Input
                  type="number"
                  value={fields?.maxLimitForNonNFTUser}
                  onChange={(e) => {
                    handleChange("maxLimitForNonNFTUser")(e);
                  }}
                  min={0}
                  step={1}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Crystal Count For Non-NFT User</Label>
                <Input
                  type="number"
                  value={fields?.crystalCountForNonNFTUser}
                  onChange={(e) => {
                    handleChange("crystalCountForNonNFTUser")(e);
                  }}
                  min={0}
                  step={1}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>App Build Number</Label>
                <Input
                  type="text"
                  value={fields?.appBuildNumber}
                  onChange={(e) => {
                    handleChange("appBuildNumber")(e);
                  }}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Next Sail Start Time</Label>
                <Input
                  type="number"
                  value={fields?.nextSailStartTime}
                  onChange={(e) => {
                    handleChange("nextSailStartTime")(e);
                  }}
                  min={0}
                  step={1}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Exchange Rate for BNB to Crystal</Label>
                <Input
                  type="number"
                  value={fields?.bnbToCrystalExchangeRate}
                  onChange={(e) => {
                    handleChange("bnbToCrystalExchangeRate")(e);
                  }}
                  min={0}
                  step={1}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Referral Bonus Percentage</Label>
                <Input
                  type="number"
                  value={fields?.referralBonusPercentage}
                  onChange={(e) => {
                    handleChange("referralBonusPercentage")(e);
                  }}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Referral Day Count</Label>
                <Input
                  type="number"
                  value={fields?.referralUserDayCount}
                  onChange={(e) => {
                    handleChange("referralUserDayCount")(e);
                  }}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Referral User Date</Label>
                <Input
                  type="datetime-local"
                  id="formrow-Start-date-Input"
                  value={moment(fields?.referralUserDate).format("YYYY-MM-DDTHH:mm")}
                  onChange={(e) => {
                    handleChange("referralUserDate")(e);
                  }}
                  required
                  min={new Date().toISOString().split("T")[0]}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Referral treasure box open count</Label>
                <Input
                  type="number"
                  value={fields?.referralTreasureboxCount}
                  onChange={(e) => {
                    handleChange("referralTreasureboxCount")(e);
                  }}
                  min={0}
                  step={1}
                />
              </FormGroup>
            </Col>


            <Col md={6}>
              <FormGroup>
                <Label>Referral award energy amount</Label>
                <Input
                  type="number"
                  value={fields?.referralEnergyAmount}
                  onChange={(e) => {
                    handleChange("referralEnergyAmount")(e);
                  }}
                  min={0}
                  step={1}
                />
              </FormGroup>
            </Col>


            <Col md={6}>
              <FormGroup>
                <Label>Maintenance Message</Label>
                <Input
                  type="text"
                  value={fields?.maintenanceMessage}
                  onChange={(e) => {
                    handleChange("maintenanceMessage")(e);
                  }}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>App Under Maintenance</Label>

                <div className="status-switch square-switch">
                  <input
                    type="checkbox"
                    id="square-switch1"
                    switch="none"
                    checked={fields?.isUnderMaintenance === true}
                    onChange={() => {
                      const value =
                        fields?.isUnderMaintenance === true ? false : true;

                      handleChange("isUnderMaintenance")({ target: { value } });
                    }}
                  />
                  <label
                    htmlFor="square-switch1"
                    data-on-label={"Yes"}
                    data-off-label={"No"}
                  />
                </div>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Marketplace under Maintenance</Label>

                <div className="status-switch square-switch">
                  <input
                    type="checkbox"
                    id="square-switch2"
                    switch="none"
                    checked={fields?.isMarketplaceUnderMaintenance === true}
                    onChange={() => {
                      const value =
                        fields?.isMarketplaceUnderMaintenance === true ? false : true;

                      handleChange("isMarketplaceUnderMaintenance")({ target: { value } });
                    }}
                  />
                  <label
                    htmlFor="square-switch2"
                    data-on-label={"Yes"}
                    data-off-label={"No"}
                  />
                </div>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Wallet buy under Maintenance</Label>

                <div className="status-switch square-switch">
                  <input
                    type="checkbox"
                    id="square-switch3"
                    switch="none"
                    checked={fields?.isWalletBuyUnderMaintenance === true}
                    onChange={() => {
                      const value =
                        fields?.isWalletBuyUnderMaintenance === true ? false : true;

                      handleChange("isWalletBuyUnderMaintenance")({ target: { value } });
                    }}
                  />
                  <label
                    htmlFor="square-switch3"
                    data-on-label={"Yes"}
                    data-off-label={"No"}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

GeneralSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({ }) => ({});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneralSettings)
);
