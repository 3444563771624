import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";
import toastr from "toastr";
//i18n
import { withTranslation } from "react-i18next";

import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import SearchInput from "components/Common/SearchInput";

import {
  getLogs,
 
} from "store/actions";
import LogsColumns from "./Columns";

const Logs = (props) => {
  const {
    history,
    logs,
    totalLogs,
    onGetLogs,
    loading,
  } = props;
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    userId: null,
  });

  const [filter, setFilter] = useState({
    orderBy: "",
    order: -1,
    page: 0,
    limit: 20,
    search: "",
    fields: [],
  });
  const [searchText, setSearchText] = useState("");
  const [logsList, setLogsList] = useState([]);
  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalLogs,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    onGetLogs({ ...filter });
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    setLogsList(logs || []);
  }, [logs]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
   

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (userId) => {
    setConfirmModal((prevState) => ({ isOpen: !prevState.isOpen, userId }));
  };


 
  return (
    <React.Fragment>
 
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Logs"
            breadcrumbItem={props.t("Logs")}
            breadcrumbItems={[{ title: props.t("Logs") }]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={logsList || []}
                        columns={LogsColumns(
                          toggleConfirmModal,
                          props.t,
                          "text"
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                       
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {/* {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )} */}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Logs.propTypes = {
  t: PropTypes.any,
  logs: PropTypes.array,
  totalLogs: PropTypes.number,
  onGetLogs: PropTypes.func,
};

const mapStateToProps = ({ Logs }) => ({
  loading: Logs.loading,
  logs: Logs.logs,
  totalLogs: Logs.totalLogs,
});

const mapDispatchToProps = (dispatch) => {
  return {
 
    onGetLogs: (data) => { dispatch(getLogs(data))},
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Logs))
);
