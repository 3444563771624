import { call, put, takeEvery } from "redux-saga/effects";

// Collection Redux States
import {
    GET_LEADERBOARD,
    REDUCE_LEADERBOARD_POINTS
} from "./actionTypes";

import {
    getLeaderboardFail,
    getLeaderboardSuccess,
    reduceLeaderboardPointsFail,
    reduceLeaderboardPointsSuccess
} from "./actions";

import {
    getLeaderBoard,
    reduceLeaderboardPoints
} from "helpers/backend_helper";

import toastr from "toastr";

function* getLeaderBoardSaga({ payload, callback }) {
    try {
        const response = yield call(getLeaderBoard, payload);
        console.log(response, "all listrtttt");
        callback && callback(response);
        if (response.status == "failure") {
            return yield put(getLeaderboardFail(response.message));
        }
        yield put(getLeaderboardSuccess(response));
    } catch (error) {
        yield put(getLeaderboardFail(error));
    }
}

function* reduceLeaderboardPointsSaga({ payload: { data, callback }  }) {
    try {
        const response = yield call(reduceLeaderboardPoints, data);
        console.log(response, "reduce points reponse");
        callback && callback(response);

        if (response.status == "failure") {
            return yield put(reduceLeaderboardPointsFail(response.message));
        }
        yield put(reduceLeaderboardPointsSuccess(response));
    } 
    catch (error) {
        console.log("err", error);
        toastr.error("User does not have enough points!");
        yield put(reduceLeaderboardPointsFail(error));
    }
}

function* LeaderboardSaga() {
    yield takeEvery(GET_LEADERBOARD, getLeaderBoardSaga);
    yield takeEvery(REDUCE_LEADERBOARD_POINTS, reduceLeaderboardPointsSaga);
}

export default LeaderboardSaga;