import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_DASHBOARD_REPORTS,
  GET_DASHBOARD_REPORTS_SUCCESS,
  GET_DASHBOARD_REPORTS_FAIL,
} from "./actionTypes"
import { dashboardSuccess, dashboardFail } from "./actions"

import { getDashboard, getDashboardWithCountry } from "helpers/backend_helper"

function* fetchDashboard(data) {
  console.log(data, "fetch dashboard data")
  try {
    let response = null;
    if(data && data.data){ 
      response = yield call(getDashboardWithCountry, data?.data)
    }
    else{
      response = yield call(getDashboard)
    }
    yield put(dashboardSuccess(response?.data))
    return response
  } catch (error) {
    yield put(dashboardFail(error))
  }
}

function* DashboardSaga() {
  yield takeEvery(GET_DASHBOARD_REPORTS, fetchDashboard)
}

export default DashboardSaga
