import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Spinner,
  Badge,
  Label,
} from "reactstrap";
import moment from "moment";

//Import Images
import profile1 from "assets/images/profile-img.png";

// Breadcrumbs
import Breadcrumbs from "components/Common/Breadcrumb2";

// Actions
import { viewTournament } from "store/actions";
import axios from "axios";
import { viewTournamentsHistory } from "store/gameHistory/actions";
import { indexOf } from "lodash";
import { Accordion, Modal } from "react-bootstrap";
import SnapModal from "./modal/SnapModal";

const ViewTournamentHistory = (props) => {
  const {
    match: { params },
    onGetTournaments,
    tournamentHistoryDetail,
    loading,
  } = props;
  useEffect(() => {
    console.log(params.id, "params.idparams.id");
    if (params && params.id) {
      onGetTournaments(params.id);
    }
  }, [params.id]);

  function downloadObjectAsJson(verifyLink) {
    const exportName = "abi.json";
    fetch(verifyLink)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        var dataStr =
          "data:text/json;charset=utf-8," +
          encodeURIComponent(JSON.stringify(data));
        var downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      })
      .catch(function (err) {
        console.warn("Something went wrong.", err);
      });
  }
  const [gameResult, setGameResult] = useState();
  const [gameDetail, setGameDetail] = useState();
  const [gameRound, setGameRound] = useState();
  useEffect(() => {
    if (tournamentHistoryDetail) {
      setGameResult(tournamentHistoryDetail?.results);
      setGameDetail(tournamentHistoryDetail?.details);
    }
  }, [tournamentHistoryDetail]);

  useEffect(() => {
    if (gameResult && gameResult.length > 0) {
      let arr = gameResult.map((item) => item.result);
      const singleArray = [].concat(...arr);
      setGameRound(singleArray);
    }
  }, [gameResult]);

  // const gameDetail = tournamentHistoryDetail?.details;
  // const gameResult = tournamentHistoryDetail?.results;
  // const gameRound = gameResult?.result;

  console.log(
    gameRound,
    tournamentHistoryDetail,
    "tournamentDetailtournamentDetail view"
  );

  const [SnapShot, setSnapShot] = useState(false);
  const [imgData, setImgData] = useState({});

  const handleSnapShot = (image) => {
    setSnapShot(!SnapShot);
    setImgData(image);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="spinner-content">
          {loading && (
            <div className="spinner">
              <Spinner color="primary" />
            </div>
          )}

          <Breadcrumbs title="Tournaments" breadcrumbItem="detail" />

          <Row className="justify-content-center">
            <Col sm="12">
              <Row>
                <Col>
                  <Card className="overflow-hidden">
                    <CardBody className="">
                      <Row>
                        <Col lg="12" className="my-2">
                          <h4 className="text-primary">Tournament Details</h4>
                          <ul className="list-unstyled ps-0 mb-0 mt-3 row">
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className=" font-weight-bold">
                                Name -{" "}
                                <span className="text-muted">
                                  {gameDetail?.name}
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className=" font-weight-bold">
                                Fee Type -{" "}
                                <span className="text-muted">
                                  {gameDetail?.fee_type}
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className=" font-weight-bold">
                                Entry Fee -{" "}
                                <span className="text-muted">
                                  {gameDetail?.entry_fee}
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className=" font-weight-bold">
                                Join Type -{" "}
                                <span className="text-muted">
                                  {gameDetail?.join_type}
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className=" font-weight-bold">
                                Maximum Player -{" "}
                                <span className="text-muted">
                                  {gameDetail?.maximum_players}
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className=" font-weight-bold">
                                Minimum Player -{" "}
                                <span className="text-muted">
                                  {gameDetail?.minimum_players}
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className=" font-weight-bold">
                                Tournament Type -{" "}
                                <span className="text-muted">
                                  {gameDetail?.tournamentType}
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className=" font-weight-bold">
                                Registration Date -{" "}
                                <span className="text-muted">
                                  {gameDetail?.registration_date}
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className=" font-weight-bold">
                                Total Round -{" "}
                                <span className="text-muted">
                                  {gameDetail?.total_rounds}
                                </span>
                              </p>
                            </li>
                          </ul>
                        </Col>
                        <Col lg="12" className="my-2">
                          <h4 className="text-primary">Round Details</h4>

                          <Accordion defaultActiveKey="0">
                            {gameResult &&
                              gameResult.length > 0 &&
                              gameResult?.map((data, index) => (
                                <>
                                  <Accordion.Item eventKey={index}>
                                    <Accordion.Header>
                                      Round {index + 1}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <Row>
                                        {gameRound &&
                                          gameRound.length > 0 &&
                                          gameRound?.map((round, index) => (
                                            <Col lg="12" className="my-2">
                                              <Accordion defaultActiveKey={0}>
                                                <Accordion.Item
                                                  eventKey={index}
                                                >
                                                  <Accordion.Header>
                                                    Match {index + 1}
                                                  </Accordion.Header>
                                                  <Accordion.Body>
                                                    <Row>
                                                      <Col
                                                        md="6"
                                                        className="my-2"
                                                      >
                                                        <div className="top text-center border-bottom mb-2">
                                                          <h4 className="py-2 m-0 fw-bold theme-clr">
                                                            Winner
                                                          </h4>
                                                        </div>
                                                        <ul className="list-unstyled ps-0 mb-0">
                                                          <li className="py-2">
                                                            <p class=" font-weight-bold m-0">
                                                              Name :{" "}
                                                              <span class="text-muted">
                                                                {
                                                                  round
                                                                    ?.winner_id
                                                                    ?.username
                                                                }
                                                              </span>
                                                            </p>
                                                          </li>
                                                          <li className="py-2">
                                                            <p class=" font-weight-bold m-0">
                                                              email :{" "}
                                                              <span class="text-muted">
                                                                {
                                                                  round
                                                                    ?.winner_id
                                                                    ?.email
                                                                }
                                                              </span>
                                                            </p>
                                                          </li>
                                                          <li className="py-2">
                                                            <Accordion>
                                                              <Accordion.Item eventKey="0">
                                                                <Accordion.Header>
                                                                  Snapshots
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                  <ul className="list-unstyled ps-0 mb-0 d-flex flex-wrap align-items-center gap-10 snapShotList justify-content-center">
                                                                    {round?.winner_snapshot.map(
                                                                      (
                                                                        item,
                                                                        index
                                                                      ) => (
                                                                        <li className="">
                                                                          <img
                                                                            onClick={() =>
                                                                              handleSnapShot(
                                                                                item
                                                                              )
                                                                            }
                                                                            src={
                                                                              item
                                                                            }
                                                                            alt=""
                                                                            className="img-fluid"
                                                                          />
                                                                        </li>
                                                                      )
                                                                    )}
                                                                  </ul>
                                                                </Accordion.Body>
                                                              </Accordion.Item>
                                                            </Accordion>
                                                          </li>
                                                        </ul>
                                                      </Col>
                                                      <Col
                                                        md="6"
                                                        className="my-2"
                                                      >
                                                        <div className="top text-center border-bottom mb-2">
                                                          <h4 className="py-2 m-0 fw-bold theme-clr">
                                                            Looser
                                                          </h4>
                                                        </div>
                                                        <ul className="list-unstyled ps-0 mb-0">
                                                          <li className="py-2">
                                                            <p class=" font-weight-bold m-0">
                                                              Name :{" "}
                                                              <span class="text-muted">
                                                                {round
                                                                  ?.looser_id
                                                                  ?.username ||
                                                                  "-"}
                                                              </span>
                                                            </p>
                                                          </li>
                                                          <li className="py-2">
                                                            <p class=" font-weight-bold m-0">
                                                              email :{" "}
                                                              <span class="text-muted">
                                                                {round
                                                                  ?.looser_id
                                                                  ?.email ||
                                                                  "-"}
                                                              </span>
                                                            </p>
                                                          </li>
                                                          <li className="py-2">
                                                            <Accordion>
                                                              <Accordion.Item eventKey="0">
                                                                <Accordion.Header>
                                                                  Snapshots
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                  <ul className="list-unstyled ps-0 mb-0 d-flex flex-wrap align-items-center gap-10 snapShotList justify-content-center">
                                                                    {round?.looser_snapshot.map(
                                                                      (
                                                                        item,
                                                                        index
                                                                      ) => (
                                                                        <li className="">
                                                                          <img
                                                                            onClick={() => {
                                                                              handleSnapShot(
                                                                                item
                                                                              );
                                                                            }}
                                                                            src={
                                                                              item
                                                                            }
                                                                            alt=""
                                                                            className="img-fluid"
                                                                          />
                                                                        </li>
                                                                      )
                                                                    )}
                                                                  </ul>
                                                                </Accordion.Body>
                                                              </Accordion.Item>
                                                            </Accordion>
                                                          </li>
                                                        </ul>
                                                      </Col>
                                                    </Row>
                                                  </Accordion.Body>
                                                </Accordion.Item>
                                              </Accordion>
                                            </Col>
                                          ))}
                                      </Row>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </>
                              ))}
                          </Accordion>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col xl="8"></Col>
          </Row>
        </Container>
      </div>
      <SnapModal
        SnapShot={SnapShot}
        setSnapShot={setSnapShot}
        imgData={imgData}
      />
    </React.Fragment>
  );
};

ViewTournamentHistory.propTypes = {
  tournaments: PropTypes.any,
  date: PropTypes.string,
  onGetTournaments: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = ({ TournamentHistory }) => ({
  console: console.log(TournamentHistory, "gameeeeeeeeeeeee"),
  loading: TournamentHistory.loading,
  // tournaments: Game.tournament,
  tournamentHistoryDetail: TournamentHistory.tournamentHistoryDetail?.data,
  date: TournamentHistory.date,
  error: TournamentHistory.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetTournaments: (data) => dispatch(viewTournamentsHistory(data)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ViewTournamentHistory))
);
