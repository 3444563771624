import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// Actions
import { getSettings, putMobileSettings } from "store/actions";
import { Row, Col, FormGroup, Label, Input, Button, Spinner, Container, Card, CardBody } from "reactstrap";
import Instructions from "./Instructions";
import MultiRangeSlider from "multi-range-slider-react";
import Select from 'react-select';

const MGameSettings = (props) => {
  const { onGetSettings, onPutSettings, loading, error } = props;
  const [options, setOptions ] = useState([{
    value: 'crystal', 
    label: 'Crystal'
  }, {
    value:'token', 
    label: 'PPT Token'
  }, {
    value:'usdt', 
    label: "USDT"
  }]);
  const findDefaultOption = (value) => {

    return options.find(option => option.value === value);
  };

  const [fields, setFields] = useState({
    minigameSettings: {
      maxEnergy: 0,
      crystalPurchaseAmountFor1Energy: 0,
      pptPurchaseAmountFor1Energy: 0,
      leaderboardPointfor10thLine: 0,
      level1PirateDailyEnergy: 0,
      level2PirateDailyEnergy: 0,
      level3PirateDailyEnergy: 0,
      level4PirateDailyEnergy: 0,
      level5PirateDailyEnergy: 0,
      minLine1: 0,
      maxLine1: 0,
      bombCountLine1: 0,
      minLine2: 0,
      maxLine2: 0,
      bombCountLine2: 0,
      minLine3: 0,
      maxLine3: 0,
      bombCountLine3: 0,
      minLine4: 0,
      maxLine4: 0,
      bombCountLine4: 0,
      minLine5: 0,
      maxLine5: 0,
      bombCountLine5: 0,
      minLine6: 0,
      maxLine6: 0,
      bombCountLine6: 0,
      minLine7: 0,
      maxLine7: 0,
      bombCountLine7: 0,
      minLine8: 0,
      maxLine8: 0,
      bombCountLine8: 0,
      minLine9: 0,
      maxLine9: 0,
      bombCountLine9: 0,
      nftCountLine9: 0,
      minLine10: 0,
      maxLine10: 0,
      bombCountLine10: 0,
      nftCountLine10: 0,
      usdtCountLine9: 0,
      usdtCountLine10: 0,
      usdtValueLine9: 0,
      usdtValueLine10: 0,
      leaderboardPrizeForRank1: 0,
      leaderboardPrizeTypeForRank1: "",
      leaderboardPrizeForRank2: 0,
      leaderboardPrizeTypeForRank2: "",
      leaderboardPrizeForRank3: 0,
      leaderboardPrizeTypeForRank3: "",
      leaderboardPrizeForRank4: 0,
      leaderboardPrizeTypeForRank4: "",
      leaderboardPrizeForRank5: 0,
      leaderboardPrizeTypeForRank5: "",
      leaderboardPrizeForRank6: 0,
      leaderboardPrizeTypeForRank6: "",
      leaderboardPrizeForRank7: 0,
      leaderboardPrizeTypeForRank7: "",
      leaderboardPrizeForRank8: 0,
      leaderboardPrizeTypeForRank8: "",
      leaderboardPrizeForRank9: 0,
      leaderboardPrizeTypeForRank9: "",
      leaderboardPrizeForRank10:  0,
      leaderboardPrizeTypeForRank10: "",
      leaderboardStatus:""
    },
  });

  useEffect(() => {
    onGetSettings();
  }, []);


  useEffect(() => {
    if (props.settings) {
      setFields((prevState) => ({
        ...prevState,
        minigameSettings: props.settings?.mobileAppSetting?.minigameSettings,
      }));
    }
  }, [JSON.stringify(props?.settings)]);


  const handleChange = (name1) => (name) => (event) => {
    setFields((prevFields) => ({ 
      ...prevFields, 
      [name1]: { 
        ...prevFields[name1], 
        [name]: Number(event?.target?.value) 
      } 
    }));
  };

  const handleChangeString = (name1) => (name) => (event) => {
    setFields((prevFields) => ({ 
      ...prevFields, 
      [name1]: { 
        ...prevFields[name1], 
        [name]: event?.target?.value 
      } 
    }));
  };

  const handleSliderChange = (name) => (name1, name2) => (event) => {
    setFields((prevFields) => ({ 
      ...prevFields, 
      [name]: { 
        ...prevFields[name], 
        [name1]: Number(event?.minValue),
        [name2]:  Number(event?.maxValue)
      } 
    }));
  };

  const handleSelectChange = (name1) => (name) => (event) => {
    setFields((prevFields) => ({ 
      ...prevFields, 
      [name1]: { 
        ...prevFields[name1], 
        [name]: event?.value
      } 
    }));
  };

  
  function submit() {
    // console.log("fields.minigameSettings,", fields.minigameSettings)
    onPutSettings({
      mobileAppSetting: {
        ...props.settings?.mobileAppSetting,
        "minigameSettings": fields.minigameSettings,
      }
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm={10} lg={8}>
              <Card>
                <CardBody className="spinner-content">
                  {error && typeof error === "string" ? (
                    <Alert color="danger">{error}</Alert>
                  ) : null}

                  {loading && <div className="spinner"></div>}

                  <Col lg={8}>
                    <Instructions t={props.t} />
                  </Col>

                  <Col lg={8}>
                    <Row>
                    <Label className="m-3 label text-bold text-size-large">Energy</Label>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Max Energy Balance</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.maxEnergy}
                            onChange={
                              handleChange("minigameSettings")("maxEnergy")
                            }
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}/>
                        
                      <Col md={6}>
                        <FormGroup>
                          <Label>Charge Crystal for 1 Energy</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.crystalPurchaseAmountFor1Energy}
                            onChange={
                              handleChange("minigameSettings")("crystalPurchaseAmountFor1Energy")
                            }
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Charge PPT Token for 1 Energy</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.pptPurchaseAmountFor1Energy}
                            onChange={
                              handleChange("minigameSettings")("pptPurchaseAmountFor1Energy")
                            }
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Label className="m-3 label text-bold text-size-large">Energy Rewards (Daily)</Label>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Level 1 Pirate</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.level1PirateDailyEnergy}
                            onChange={
                              handleChange("minigameSettings")("level1PirateDailyEnergy")
                            }
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Level 2 Pirate</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.level2PirateDailyEnergy}
                            onChange={
                              handleChange("minigameSettings")("level2PirateDailyEnergy")
                            }
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Level 3 Pirate</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.level3PirateDailyEnergy}
                            onChange={
                              handleChange("minigameSettings")("level3PirateDailyEnergy")
                            }
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Level 4 Pirate</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.level4PirateDailyEnergy}
                            onChange={
                              handleChange("minigameSettings")("level4PirateDailyEnergy")
                            }
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Level 5 Pirate</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.level5PirateDailyEnergy}
                            onChange={
                              handleChange("minigameSettings")("level5PirateDailyEnergy")
                            }
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}/>
                      <Label className="m-3 label text-bold text-size-large">Line Settings</Label>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 1 Crystal Range</Label>
                        
                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.minigameSettings?.minLine1}
                            onChange={
                              handleChange("minigameSettings")("minLine1")
                            }
                            min={1}
                            step={1}
                          />
                         
                          
                         
                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.minigameSettings?.maxLine1}
                            onChange={
                              handleChange("minigameSettings")("maxLine1")
                            }
                            min={1}
                            step={1}
                          />
                          {/* <MultiRangeSlider
                          className="m-2 multi-range-slider-black"
                            min={1}
                            max={1000}
                            step={1}
                            minValue={fields?.minigameSettings?.minLine1}
                            maxValue={fields?.minigameSettings?.maxLine1}
                            ruler= {false}
                            onChange={(e) => handleSliderChange("minigameSettings")("minLine1", "maxLine1")(e)}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 1 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.bombCountLine1}
                            onChange={
                              handleChange("minigameSettings")("bombCountLine1")
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 2 Crystal Range</Label>
                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.minigameSettings?.minLine2}
                            onChange={
                              handleChange("minigameSettings")("minLine2")
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.minigameSettings?.maxLine2}
                            onChange={
                              handleChange("minigameSettings")("maxLine2")
                            }
                            min={1}
                            step={1}
                          />

                          {/* <MultiRangeSlider
                          className="m-2 multi-range-slider-black"
                            min={1}
                            max={1000}
                            step={1}
                            minValue={fields?.minigameSettings?.minLine2}
                            maxValue={fields?.minigameSettings?.maxLine2}
                            ruler= {false}
                            onChange={(e) => handleSliderChange("minigameSettings")("minLine2", "maxLine2")(e)}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 2 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.bombCountLine2}
                            onChange={
                              handleChange("minigameSettings")("bombCountLine2")
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 3 Crystal Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.minigameSettings?.minLine3}
                            onChange={
                              handleChange("minigameSettings")("minLine3")
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.minigameSettings?.maxLine3}
                            onChange={
                              handleChange("minigameSettings")("maxLine3")
                            }
                            min={1}
                            step={1}
                          />

                          {/* <MultiRangeSlider
                          className="m-2 multi-range-slider-black"
                            min={1}
                            max={1000}
                            step={1}
                            minValue={fields?.minigameSettings?.minLine3}
                            maxValue={fields?.minigameSettings?.maxLine3}
                            ruler= {false}
                            onChange={(e) => handleSliderChange("minigameSettings")("minLine3", "maxLine3")(e)}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 3 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.bombCountLine3}
                            onChange={
                              handleChange("minigameSettings")("bombCountLine3")
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 4 Crystal Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.minigameSettings?.minLine4}
                            onChange={
                              handleChange("minigameSettings")("minLine4")
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.minigameSettings?.maxLine4}
                            onChange={
                              handleChange("minigameSettings")("maxLine4")
                            }
                            min={1}
                            step={1}
                          />

                          {/* <MultiRangeSlider
                          className="m-2 multi-range-slider-black"
                            min={1}
                            max={1000}
                            step={1}
                            minValue={fields?.minigameSettings?.minLine4}
                            maxValue={fields?.minigameSettings?.maxLine4}
                            ruler= {false}
                            onChange={(e) => handleSliderChange("minigameSettings")("minLine4", "maxLine4")(e)}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 4 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.bombCountLine4}
                            onChange={
                              handleChange("minigameSettings")("bombCountLine4")
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 5 Crystal Range</Label>


                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.minigameSettings?.minLine5}
                            onChange={
                              handleChange("minigameSettings")("minLine5")
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.minigameSettings?.maxLine5}
                            onChange={
                              handleChange("minigameSettings")("maxLine5")
                            }
                            min={1}
                            step={1}
                          />

                          {/* <MultiRangeSlider
                          className="m-2 multi-range-slider-black"
                            min={1}
                            max={1000}
                            step={1}
                            minValue={fields?.minigameSettings?.minLine5}
                            maxValue={fields?.minigameSettings?.maxLine5}
                            ruler= {false}
                            onChange={(e) => handleSliderChange("minigameSettings")("minLine5", "maxLine5")(e)}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 5 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.bombCountLine5}
                            onChange={
                              handleChange("minigameSettings")("bombCountLine5")
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 6 Crystal Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.minigameSettings?.minLine6}
                            onChange={
                              handleChange("minigameSettings")("minLine6")
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.minigameSettings?.maxLine6}
                            onChange={
                              handleChange("minigameSettings")("maxLine6")
                            }
                            min={1}
                            step={1}
                          />

                          {/* <MultiRangeSlider
                          className="m-2 multi-range-slider-black"
                            min={1}
                            max={1000}
                            step={1}
                            minValue={fields?.minigameSettings?.minLine6}
                            maxValue={fields?.minigameSettings?.maxLine6}
                            ruler= {false}
                            onChange={(e) => handleSliderChange("minigameSettings")("minLine6", "maxLine6")(e)}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 6 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.bombCountLine6}
                            onChange={
                              handleChange("minigameSettings")("bombCountLine6")
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 7 Crystal Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.minigameSettings?.minLine7}
                            onChange={
                              handleChange("minigameSettings")("minLine7")
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.minigameSettings?.maxLine7}
                            onChange={
                              handleChange("minigameSettings")("maxLine7")
                            }
                            min={1}
                            step={1}
                          />

                          {/* <MultiRangeSlider
                          className="m-2 multi-range-slider-black"
                            min={1}
                            max={1000}
                            step={1}
                            minValue={fields?.minigameSettings?.minLine7}
                            maxValue={fields?.minigameSettings?.maxLine7}
                            ruler= {false}
                            onChange={(e) => handleSliderChange("minigameSettings")("minLine7", "maxLine7")(e)}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 7 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.bombCountLine7}
                            onChange={
                              handleChange("minigameSettings")("bombCountLine7")
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 8 Crystal Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.minigameSettings?.minLine8}
                            onChange={
                              handleChange("minigameSettings")("minLine8")
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.minigameSettings?.maxLine8}
                            onChange={
                              handleChange("minigameSettings")("maxLine8")
                            }
                            min={1}
                            step={1}
                          />
                          {/* <MultiRangeSlider
                          className="m-2 multi-range-slider-black"
                            min={1}
                            max={1000}
                            step={1}
                            minValue={fields?.minigameSettings?.minLine8}
                            maxValue={fields?.minigameSettings?.maxLine8}
                            ruler= {false}
                            onChange={(e) => handleSliderChange("minigameSettings")("minLine8", "maxLine8")(e)}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 8 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.bombCountLine8}
                            onChange={
                              handleChange("minigameSettings")("bombCountLine8")
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 9 Crystal Range</Label>
                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.minigameSettings?.minLine9}
                            onChange={
                              handleChange("minigameSettings")("minLine9")
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.minigameSettings?.maxLine9}
                            onChange={
                              handleChange("minigameSettings")("maxLine9")
                            }
                            min={1}
                            step={1}
                          />

{/* 
                          <MultiRangeSlider
                          className="m-2 multi-range-slider-black"
                            min={1}
                            max={1000}
                            step={1}
                            minValue={fields?.minigameSettings?.minLine9}
                            maxValue={fields?.minigameSettings?.maxLine9}
                            ruler= {false}
                            onChange={(e) => handleSliderChange("minigameSettings")("minLine9", "maxLine9")(e)}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 9 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.bombCountLine9}
                            onChange={
                              handleChange("minigameSettings")("bombCountLine9")
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 10 Crystal Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.minigameSettings?.minLine10}
                            onChange={
                              handleChange("minigameSettings")("minLine10")
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.minigameSettings?.maxLine10}
                            onChange={
                              handleChange("minigameSettings")("maxLine10")
                            }
                            min={1}
                            step={1}
                          />

                          {/* <MultiRangeSlider
                          className="m-2 multi-range-slider-black"
                            min={1}
                            max={1000}
                            step={1}
                            minValue={fields?.minigameSettings?.minLine10}
                            maxValue={fields?.minigameSettings?.maxLine10}
                            ruler= {false}
                            onChange={(e) => handleSliderChange("minigameSettings")("minLine10", "maxLine10")(e)}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 10 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.bombCountLine10}
                            onChange={
                              handleChange("minigameSettings")("bombCountLine10")
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 9 NFT Count</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.nftCountLine9}
                            onChange={
                              handleChange("minigameSettings")("nftCountLine9")
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 10 NFT Count</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.nftCountLine10}
                            onChange={
                              handleChange("minigameSettings")("nftCountLine10")
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 9 USDT Count</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.usdtCountLine9}
                            onChange={
                              handleChange("minigameSettings")("usdtCountLine9")
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 9 USDT Amount</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.usdtValueLine9}
                            onChange={
                              handleChange("minigameSettings")("usdtValueLine9")
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 10 USDT Count</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.usdtCountLine10}
                            onChange={
                              handleChange("minigameSettings")("usdtCountLine10")
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 10 USDT Amount</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.usdtValueLine10}
                            onChange={
                              handleChange("minigameSettings")("usdtValueLine10")
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Label className="m-3 label text-bold text-size-large">Leaderboard Settings</Label>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Point for 10th line</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.leaderboardPointfor10thLine}
                            onChange={
                              handleChange("minigameSettings")("leaderboardPointfor10thLine")
                            }
                            min={1}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}/>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 10th rank</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.leaderboardPrizeForRank10}
                            onChange={
                              handleChange("minigameSettings")("leaderboardPrizeForRank10")
                            }
                            min={1}
                            step={1}
                          />


<Select
                            className="mt-3"
                            value={findDefaultOption(fields?.minigameSettings?.leaderboardPrizeTypeForRank10)}
                            onChange={
                              handleSelectChange("minigameSettings")("leaderboardPrizeTypeForRank10")
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="rank9TypeOptions"
                            id="rank9Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 9th rank</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.leaderboardPrizeForRank9}
                            onChange={
                              handleChange("minigameSettings")("leaderboardPrizeForRank9")
                            }
                            min={1}
                            step={1}
                          />


<Select
                            className="mt-3"
                            value={findDefaultOption(fields?.minigameSettings?.leaderboardPrizeTypeForRank9)}                          onChange={
                              handleSelectChange("minigameSettings")("leaderboardPrizeTypeForRank9")
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="rank9TypeOptions"
                            id="rank9Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 8th rank</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.leaderboardPrizeForRank8}
                            onChange={
                              handleChange("minigameSettings")("leaderboardPrizeForRank8")
                            }
                            min={1}
                            step={1}
                          />

<Select
                            className="mt-3"
                            value={findDefaultOption(fields?.minigameSettings?.leaderboardPrizeTypeForRank8)}
                            onChange={
                              handleSelectChange("minigameSettings")("leaderboardPrizeTypeForRank8")
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="rank8TypeOptions"
                            id="rank8Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 7th rank</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.leaderboardPrizeForRank7}
                            onChange={
                              handleChange("minigameSettings")("leaderboardPrizeForRank7")
                            }
                            min={1}
                            step={1}
                          />

<Select
                            className="mt-3"
                            value={findDefaultOption(fields?.minigameSettings?.leaderboardPrizeTypeForRank7)}
                            onChange={
                              handleSelectChange("minigameSettings")("leaderboardPrizeTypeForRank7")
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="rank7TypeOptions"
                            id="rank7Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 6th rank</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.leaderboardPrizeForRank6}
                            onChange={
                              handleChange("minigameSettings")("leaderboardPrizeForRank6")
                            }
                            min={1}
                            step={1}
                          />


<Select
                            className="mt-3"
                            value={findDefaultOption(fields?.minigameSettings?.leaderboardPrizeTypeForRank6)}
                            onChange={
                              handleSelectChange("minigameSettings")("leaderboardPrizeTypeForRank6")
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="rank6TypeOptions"
                            id="rank6Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 5th rank</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.leaderboardPrizeForRank5}
                            onChange={
                              handleChange("minigameSettings")("leaderboardPrizeForRank5")
                            }
                            min={1}
                            step={1}
                          />

                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.minigameSettings?.leaderboardPrizeTypeForRank5)}
                            onChange={
                              handleSelectChange("minigameSettings")("leaderboardPrizeTypeForRank5")
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="rank5TypeOptions"
                            id="rank5Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 4th rank</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.leaderboardPrizeForRank4}
                            onChange={
                              handleChange("minigameSettings")("leaderboardPrizeForRank4")
                            }
                            min={1}
                            step={1}
                          />

                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.minigameSettings?.leaderboardPrizeTypeForRank4)}
                            onChange={
                              handleSelectChange("minigameSettings")("leaderboardPrizeTypeForRank4")
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="rank4TypeOptions"
                            id="rank4Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 3rd rank</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.leaderboardPrizeForRank3}
                            onChange={
                              handleChange("minigameSettings")("leaderboardPrizeForRank3")
                            }
                            min={1}
                            step={1}
                          />
                          <Select
                          className="mt-3 text-muted"
                            value={findDefaultOption(fields?.minigameSettings?.leaderboardPrizeTypeForRank3)}
                            onChange={
                              handleSelectChange("minigameSettings")("leaderboardPrizeTypeForRank3")
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="rank3TypeOptions"
                            id="rank3Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 2nd rank</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.leaderboardPrizeForRank2}
                            onChange={
                              handleChange("minigameSettings")("leaderboardPrizeForRank2")
                            }
                            min={1}
                            step={1}
                          />
                          <Select
                          className="mt-3"
                            value={findDefaultOption(fields?.minigameSettings?.leaderboardPrizeTypeForRank2)}
                            onChange={
                              handleSelectChange("minigameSettings")("leaderboardPrizeTypeForRank2")
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="rank2TypeOptions"
                            id="rank2Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 1st rank</Label>

                          <Input
                            type="number"
                            value={fields?.minigameSettings?.leaderboardPrizeForRank1}
                            onChange={
                              handleChange("minigameSettings")("leaderboardPrizeForRank1")
                            }
                            min={1}
                            step={1}
                          />

                          <Select
                          className="mt-3"
                            value={findDefaultOption(fields?.minigameSettings?.leaderboardPrizeTypeForRank1)}
                            onChange={
                              handleSelectChange("minigameSettings")("leaderboardPrizeTypeForRank1")
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="rank1TypeOptions"
                            id="rank1Type"
                          />
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Status</Label>

                          <div className="status-switch square-switch">
                            <input
                              type="checkbox"
                              id="square-switch-leaderboardStatus"
                              switch="none"
                              checked={fields?.minigameSettings?.leaderboardStatus == "active"}
                              onChange={() => {
                                const value =
                                  fields?.minigameSettings?.leaderboardStatus == "active" ? "inactive" : "active";

                                  handleChangeString("minigameSettings")("leaderboardStatus")({ target: { value } });
                              }}
                            />
                            <label
                              htmlFor="square-switch-leaderboardStatus"
                              data-on-label={"Active"}
                              data-off-label={"Inactive"}
                            />
                          </div>
                        </FormGroup>
                      </Col>

                      <Col md={6}/>

                      <Col className="d-flex justify-content-end">
                        {loading && <Spinner color="primary" className="mr-2" />}

                        <Button className="buttoncolor" onClick={submit}>
                          Save Changes
                        </Button>
                      </Col>

                    </Row>
                  </Col>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>

    </React.Fragment>
  );
};

MGameSettings.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  onGetSettings: PropTypes.func,
  onPutSettings: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onPutSettings: (data) => dispatch(putMobileSettings(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MGameSettings)
);