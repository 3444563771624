import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
const STATUS = {
  "active":"Active", 
  "ended":"Ended"
}
export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (t) => [
  {
    dataField: "user_id.username",
    text: t("Username"),
    formatter: (_, row) => (row.user_id?.username ? row.user_id?.username : "-"),
  },
  {
    dataField: "user_id.email",
    text: t("Email"),
    formatter: (_, row) => (row.user_id?.email ? row.user_id?.email : "-"),
  },
  {
    dataField: "stepCount",
    text: t("Step Count"),
    formatter: (_, row) => (row.stepCount ? row.stepCount : 0),
  },
  {
    text: t("Boxes Opened"),
    dataField: "totalOpenedCount",
    formatter: (_, row) => (row?.totalOpenedCount ? row?.totalOpenedCount : 0),
  },
  {
    dataField: "user_id.dailyLimit",
    text: t("Daily limit"),
    formatter: (_, row) => (row.user_id?.dailyLimit ? row?.user_id?.dailyLimit : 0),
  },
  {
    text: t("Ship Damage (%)"),
    dataField: "shipDamage",
    formatter: (_, row) => (row?.shipDamage ? row?.shipDamage : 0),
  },
  {
    text: t("Keys Awarded"),
    dataField: "keyCount",
    formatter: (_, row) => (row?.woodenKeyAwardedCount + row?.steelKeyAwardedCount + row?.bronzeKeyAwardedCount
      + row?.silverKeyAwardedCount + row?.goldenKeyAwardedCount ),
  },
  {
    text: t("Start Key Balance"),
    dataField: "keyCount",
    formatter: (_, row) => (row?.startKeyBalance || 0 ),
  },
  {
    text: t("End Key Balance"),
    dataField: "keyCount",
    formatter: (_, row) => (row?.endKeyBalance || 0 ),
  },
  {
    dataField: "pptTokenEarned",
    text: t("PPT Earning"),
    formatter: (_, row) => (row?.pptTokenEarned ? row?.pptTokenEarned?.toFixed(2) : 0),
  },
  {
    dataField: "prevPPTBalance",
    text: t("Start PPT Balance"),
    formatter: (_, row) => (row?.prevPPTBalance ? row?.prevPPTBalance?.toFixed(2) : 0),
  },
  {
    dataField: "endPPTBalance",
    text: t("End PPT Balance"),
    formatter: (_, row) => (row?.endPPTBalance ? row?.endPPTBalance?.toFixed(2) : 0),
  },
  {
    text: t("Crystal Earned"),
    dataField: "crystalEarned",
    formatter: (_, row) => (row?.crystalEarned ? row?.crystalEarned : 0),
  },
  {
    dataField: "prevCrystalBalance",
    text: t("Start Crystal Balance"),
    formatter: (_, row) => (row?.prevCrystalBalance ? row?.prevCrystalBalance : 0),
  },
  {
    dataField: "endCrystalBalance",
    text: t("End Crystal Balance"),
    formatter: (_, row) => (row?.endCrystalBalance ? row?.endCrystalBalance : 0),
  },

  {
    text: t("Sail Time (min)"),
    dataField: "sailTime",
    formatter: (_, row) => (row?.sailTime ? row?.sailTime : 0),
  },


  {
    dataField: "status",
    text: t("Status"),
    formatter: (cellContent, row) => (
      <Badge
        className={
          `text-capitalize font-size-13 badge-soft-${row.status}`
        }
        color={row.badgeClass}
      >
        {t(STATUS[row.status])|| "-"}
      </Badge>
    ),
  },
  {
    dataField: "created_at",
    text: t("Start Date"),
    sort: true,
    sortValue: (_, row) =>row?.created_at,
    formatter: (_, row) => (row?.created_at ? moment(row?.created_at).format("YYYY-MM-DD HH:mm:ss") : "--"),
  },
  {
    dataField: "ended_at",
    text: t("End Date"),
    sort: true,
    sortValue: (_, row) =>row?.ended_at,
    formatter: (_, row) => (row?.ended_at ? moment(row?.ended_at).format("YYYY-MM-DD HH:mm:ss") : "--"),
  },

  // {
  //   isDummyField: true,
  //   text: t("Action"),
  //   dataField: "action",
  //   formatter: (_, row) => (
  //     <>
  //       <Link
  //         to={`/match-history/view/${row._id}`}
  //         className="mr-3 text-secondary"
  //       >
  //         <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />

  //         <UncontrolledTooltip
  //           placement="top"
  //           target={`view-${row._id}-tooltip`}
  //         >
  //           View
  //         </UncontrolledTooltip>
  //       </Link>
  //     </>
  //   ),
  // },
];

export default ListColumns;
