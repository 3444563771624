import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import { BLOCKCHAIN_CURRENCY_CODE } from "helpers/contants";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = ( toggleConfirmModal, t) => [
  {
    dataField: "minShipCount",
    text: t("Min Ship Count"),
   
  },
  {
    dataField: "maxShipCount",
    text: t("Max Ship Count"),
   
  },
 
  {
    dataField: "woodenBoxLimit",
    text: t("Wooden Box Limit"),
   
  },

  {
    dataField: "steelBoxLimit",
    text: t("Steel Box Limit"),
   
  },
  {
    dataField: "bronzeBoxLimit",
    text: t("Bronze Box Limit"),
   
  },
 
  {
    dataField: "silverBoxLimit",
    text: t("Silver Box Limit"),
   
  },
  {
    dataField: "goldenBoxLimit",
    text: t("Golden Box Limit"),
   
  },

  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <Link to={`/edit-daily-limit/${row._id}`} className="mr-2 text-secondary">
          <i className="far fa-edit mr-1" id={`view-${row._id}-tooltip`} />

          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            Edit
          </UncontrolledTooltip>
        </Link>


      </>
    ),
  },
];

export default ListColumns;
