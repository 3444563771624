import { call, put, takeEvery } from "redux-saga/effects";

// Collection Redux States
import {
  END_MATCH,END_MATCH_FAIL, END_MATCH_SUCCESS,
  GET_MATCH_HISTORY,
  VIEW_MATCH_HISTORY,
  VIEW_TOURNAMENTS_HISTORY,
} from "./actionTypes";
import {
  getTournamentsHistoryFail,
  viewTournamentsHistoryFail,
  viewTournamentsHistorySuccess,
  getMatchHistoryFail,
  getMatchHistorySuccess,
  viewMatchHistoryFail,
  viewMatchHistorySuccess,
  endMatch, endMatchFail, endMatchSuccess
} from "./actions";
import toastr from "toastr";

import {
  postGetMatchHistory,
  postViewMatchtHistory,
  postViewTournamentHistory,
  postEndMatch
} from "helpers/backend_helper";

function* getMatchHistorySaga({ payload, callback }) {
  try {
    const response = yield call(postGetMatchHistory, payload);
    console.log(response, "all listrtttt");
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(getMatchHistoryFail(response.message));
    }
    yield put(getMatchHistorySuccess(response));
  } catch (error) {
    yield put(getMatchHistoryFail(error));
  }
}

function* viewMatchHistorySaga({ payload, callback }) {
  try {
    const response = yield call(postViewMatchtHistory, payload);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(viewMatchHistoryFail(response.message));
    }
    yield put(viewMatchHistorySuccess(response));
  } catch (error) {
    yield put(getMatchHistoryFail(error));
  }
}

function* endMatchSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(postEndMatch, data);
    callback && callback(response?.status);
    if (response.status == "success") {
      toastr.success(response.message);
      yield put(endMatchSuccess(response));
    }else{
      return yield put(endMatchFail(response.message));
    }
  } catch (error) {
    yield put(endMatchFail(error));
  }
}

function* MatchHistorySaga() {
  yield takeEvery(GET_MATCH_HISTORY, getMatchHistorySaga);
  yield takeEvery(VIEW_MATCH_HISTORY, viewMatchHistorySaga);
  yield takeEvery(END_MATCH, endMatchSaga);

}

export default MatchHistorySaga;
