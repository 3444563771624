import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { GET_CREW_MEMBERS, GET_REFERRAL_CLAIM_HISTORY, UPDATE_REFERRAL_CLAIM_HISTORY } from "./actionTypes";
import { getCrewMembersFail, getCrewMembersSuccess, 
    getReferralClaimHistoryFail, getReferralClaimHistorySuccess,
updateReferralClaimHistoryFail, updateReferralClaimHistorySuccess } from "./actions";
import { getCrewMembers, getReferralClaimHistory, updateReferralClaimHistory } from "helpers/backend_helper";
import toastr from "toastr";
toastr.options = {
    newestOnTop: true,
    progressBar: true,
};

function* fetchCrewMembers({ payload }) {
    try {
        const response = yield call(getCrewMembers, payload);
        if (response.status == "failure") {
            return yield put(getCrewMembersFail(response.message));
        }
        yield put(getCrewMembersSuccess(response));
    } 
    catch (error) {
        yield put(getCrewMembersFail(error));
    }
}

function* fetchReferralClaims({ payload }) {
    try {
        const response = yield call(getReferralClaimHistory, payload);
        if (response.status == "failure") {
            return yield put(getReferralClaimHistoryFail(response.message));
        }
        yield put(getReferralClaimHistorySuccess(response));
    } 
    catch (error) {
        yield put(getReferralClaimHistoryFail(error));
    }
}


function* updateReferralClaimHistorySaga({ payload: { data, callback } }) {
    try {
      const response = yield call(updateReferralClaimHistory, data);
      callback && callback(response);
      if (response.status == "failure") {
        return yield put(updateReferralClaimHistoryFail(response.message));
      }
      yield put(updateReferralClaimHistorySuccess(response));
    } catch (error) {
      console.log(error, "<=== error")
      yield put(updateReferralClaimHistoryFail(error));
    }
  }

function* crewMembersSaga() {
    yield takeLatest(GET_CREW_MEMBERS, fetchCrewMembers);
    yield takeLatest(GET_REFERRAL_CLAIM_HISTORY, fetchReferralClaims);
    yield takeEvery(UPDATE_REFERRAL_CLAIM_HISTORY, updateReferralClaimHistorySaga);
}

export default crewMembersSaga;