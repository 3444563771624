import {
    GET_IP_HISTORY,
    GET_IP_HISTORY_FAIL,
    GET_IP_HISTORY_SUCCESS,
} from "./actionTypes";

export const getIPHistory = (data) => ({
    type: GET_IP_HISTORY,
    payload: data,
});

export const getIPHistorySuccess = (history) => ({
    type: GET_IP_HISTORY_SUCCESS,
    payload: history,
});

export const getIPHistoryFail = (error) => ({
    type: GET_IP_HISTORY_FAIL,
    payload: error,
});
