import axios from "axios";
import authHeader from "./jwt-token-access/auth-token-header";

import store from "store";
import { showAlert } from "store/actions";

//apply base url for axios
export const API_BASE_URL = process.env.REACT_APP_MOBILE_URL;
const API_VERSION = process.env.REACT_APP_API_VERSION;
export const SOCKET_URL = process.env.REACT_APP_API_SOCKET_URL;

const axiosApi = axios.create({
  baseURL: API_BASE_URL,
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export const updateTokenMobile = (token) => {
  axiosApi.defaults.headers.common["Authorization"] = "Bearer " + token;
};

const obj = JSON.parse(localStorage.getItem("authUser"));

updateTokenMobile(obj?.data?.Token ? obj.data.Token : null);

const LogoutUser = () => {
  localStorage.clear();
  window.location.replace("/");
};

export async function mobileGet(url, config) {
  if (!config) {
    config = authHeader();
  }

  return await axiosApi.get(url, { ...config }).then((response) => {
    if (response.data.status === "failure") {
      if (response.data.isInvalidToken) {
        return LogoutUser();
      } else if (response.data.message === "Invalid Login Credential!") {
        LogoutUser();
        return { status: "failure" };
      } else if (response.data.message === "ACCESS_DENIED") {
        store.dispatch(showAlert());
      }
    }

    return response.data;
  });
}

export async function mobilePost(url, data, config) {
  console.log("urlll", url)
  console.log("data", data)

  if (!config) {
    config = authHeader();
  }

  return axiosApi.post(url, data, { ...config }).then((response) => {
    console.log(response.data, "response.data.status");
    if (response.data.status === false) {
      if (
        response.data.message === "INVALID_TOKEN" ||
        "Access denied. you didn't have permission to access this end point"
      ) {
        LogoutUser();
        return { status: false };
      } else if (response.data.message === "ACCESS_DENIED") {
        store.dispatch(showAlert());
      }
    }

    return response.data;
  });
}

export async function mobilePut(url, data, config) {
  if (!config) {
    config = authHeader();
  }

  return axiosApi.put(url, { ...data }, { ...config }).then((response) => {
    if (response.data.status === "failure") {
      if (response.data.message === "INVALID_TOKEN") {
        LogoutUser();
        return { status: "failure" };
      } else if (response.data.message === "ACCESS_DENIED") {
        store.dispatch(showAlert());
      }
    }

    return response.data;
  });
}

export async function mobileDel(url, config) {
  if (!config) {
    config = authHeader();
  }

  return await axiosApi.delete(url, { ...config }).then((response) => {
    if (response.data.status === "failure") {
      if (response.data.message === "INVALID_TOKEN") {
        LogoutUser();
        return { status: "failure" };
      } else if (response.data.message === "ACCESS_DENIED") {
        store.dispatch(showAlert());
      }
    }

    return response.data;
  });
}
