import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { Card, CardBody, Col, Container, Row, Spinner, Input } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import { getMobileGameHistory, updateMobileGameHistory, getItems } from "store/actions";
import ListColumns from "./ListColumns";
import toastr from "toastr";
import ConfirmModal from "./ConfirmModal";
import ConfirmModel2 from "./ConfirmModel2";


const MobileGameHistory = ({
  GameHistory,
  totalGameHistory,
  onGetGameHistory,
  onUpdateAwardStatus,
  onGetItems,
  items,
  totalItems,
  loading,
  ...props
}) => {
    let auth = localStorage.getItem("authUser");
    let adminRole = JSON.parse(auth)?.data?.role;
    const { id } = useParams();
    const [filter, setFilter] = useState({
        orderBy: "date_created_utc",
        order: -1,
        page: 1,
        limit: 20,
        search: "",
        status: "",
        userId: id
    });
    const [searchText, setSearchText] = useState("");
    const [GameHistoryList, setGameHistory] = useState([]);
    const [completeMod, setCompleteMod] = useState({ show: false, gameHistory: "" });
    const [transferMod, setTransferMod] = useState({ show: false, gameHistory: "" });
    const [item, setItem] = useState({});
    const [itemsList, setItemsList] = useState([]);

    const pageOptions = {
        sizePerPage: 20,
        totalSize: totalGameHistory,
        custom: true,
        onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
    };

    const itemFilter = {
        orderBy: "create_date",
        order: -1,
        page: 0,
        limit: 2000,
    };

    useEffect(() => {
        onGetGameHistory(filter);
    }, [JSON.stringify(filter)]);

    useEffect(() => {
        setGameHistory(GameHistory || []);
    }, [GameHistory]);

    useEffect(() => {
        onGetItems(itemFilter);
    }, [])

    useEffect(() => {
        setItemsList(items || []);
    }, [items])

    const toggleConfirmModal = useCallback((gameHistory) => {
        setCompleteMod((prevState) => ({ show: !prevState.show, gameHistory: gameHistory }));
    }, []);

    const toggleTransferModal = useCallback((gameHistory) => {
        setTransferMod((prevState) => ({ show: !prevState.show, gameHistory: gameHistory }));
      }, []);
    

    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
        if (type === "search") {
        setSearchText(searchText.trim());
        }

        if (type === "sort") {
        if (sortField == "createdAt") {
            sortField = "date_created_utc";
        }

        if (sortOrder == "desc") {
            sortOrder = -1;
        } else if (sortOrder == "asc") {
            sortOrder = 1;
        }

        setFilter((prevState) => ({
            ...prevState,
            orderBy: sortField,
            order: sortOrder,
        }));
        }
    };

    const onSearch = (event) => {
        event.preventDefault();
        setFilter((prevState) => ({ ...prevState, search: searchText.trim() }));
    };

    const handleChange = (e) => {
        console.log("target------>", e.target.value)
        setItem(e.target.value);
    }

    return (
        <>
        <ConfirmModal
            show={completeMod.show}
            toggle={toggleConfirmModal}
            handleChange={handleChange}
            itemsList={itemsList}
            onConfirm={() => {
            const callback = (res) => {
                setCompleteMod({ show: false, gameHistory: null });
                setItem("");
                if (res.status === "success") {
                toastr.success("History updated successfully.");
                onGetGameHistory(filter);
                }
                else {
                toastr.error(res.message);
                }
            };
            let updateMod = {
                ...completeMod.gameHistory,
                gameHistoryId: completeMod.gameHistory._id,
                nftAwardedStatus: "transferred",
                awardedNft: JSON.parse(item)?._id
            } 
            // console.log("updateMod ---->", updateMod)
            onUpdateAwardStatus(updateMod, callback)
            }}
        />

        <ConfirmModel2
            isOpen={transferMod.show}
            toggle={toggleTransferModal}
            onConfirm={() => {
          const callback = (res) => {
            setTransferMod({ show: false, gameHistory: null });
            if (res.status === "success") {
              toastr.success("History updated successfully.");
              onGetGameHistory(filter);
            }
            else {
              toastr.error(res.message);
            }
          };
          let updateMod = {
            ...transferMod.gameHistory,
            gameHistoryId: transferMod.gameHistory._id,
            usdtAwardedStatus: "transferred",
          } 
          // console.log("updateMod ---->", updateMod)
          onUpdateAwardStatus(updateMod, callback)
            }}
        />

        <React.Fragment>
            <div className="">
            <Container fluid>
                <Row>
                <Col xs="12">
                    <Card>
                    <CardBody>
                    
                        <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        >
                        {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                            keyField="_id"
                            data={GameHistoryList.length > 0 ? GameHistoryList : []}
                            columns={ListColumns(toggleConfirmModal, toggleTransferModal, props.t, adminRole)}
                            bootstrap4
                            search
                            >
                            {(toolkitProps) => (
                                <React.Fragment>
                                {/* <Row className="mb-2">
                                    <Col sm="3">
                                    <SearchInput
                                        {...toolkitProps.searchProps}
                                        triggerSearch={onSearch}
                                        placeholder={props.t("Search by email, username...")}
                                        searchText={searchText}
                                    />
                                    </Col>

                                    <Col sm="3">
                                    <Input
                                        type="select"
                                        value={filter.status}
                                        onChange={(e) =>
                                        setFilter((prev) => ({
                                            ...prev,
                                            status: e.target.value,
                                            filter: "",
                                        }))
                                        }
                                    >
                                        <option value="">Select Status</option>
                                        <option value="active">Active</option>
                                        <option value="completed">Completed</option>
                                    </Input>
                                    </Col>
                                    <Col sm="2">
                                    {!!filter.search || !!filter.status ? (
                                        <div
                                        className="mr-4 mb-2"
                                        style={{
                                            height: "calc( 1.5em + 0.94rem + 2px)",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                        >
                                        <Link
                                            to="#"
                                            onClick={(e) => {
                                            e.preventDefault();
                                            setFilter((prevState) => ({
                                                ...prevState,
                                                search: "",
                                                status: "",
                                                filter: "all"
                                            }));
                                            setSearchText("");
                                            }}
                                            className=""
                                        >
                                            {props.t("Clear Filters")}
                                        </Link>
                                        </div>
                                    ) : ""}
                                    </Col>

                                </Row> */}
                                
                                <Row>
                                    <Col xl="12">
                                    <div className="table-responsive spinner-content">
                                        <BootstrapTable
                                        responsive
                                        remote
                                        bordered={false}
                                        striped={false}
                                        classes={
                                            "table table-centered table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        defaultSorted={[
                                            {
                                            dataField: "date_created_utc",
                                            order: "desc",
                                            },
                                        ]}
                                        sort={{
                                            sortCaret: (order) =>
                                            order === "asc" ? (
                                                <span className="caret">
                                                <i className="bx bx-caret-up" />
                                                </span>
                                            ) : order === "desc" ? (
                                                <span className="caret">
                                                <i className="bx bx-caret-down" />
                                                </span>
                                            ) : null,
                                        }}
                                        />

                                        {loading && (
                                        <div className="spinner">
                                            <Spinner color="primary" />
                                        </div>
                                        )}
                                    </div>
                                   
                                    </Col>
                                </Row>
                                {GameHistoryList.length == 0 && <p className="text-muted w-100 text-center">No History found</p>}

                                <Row className="align-items-md-center mt-30">
                                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                        {...paginationProps}
                                    />
                                    </Col>
                                </Row>
                                </React.Fragment>
                            )}
                            </ToolkitProvider>
                        )}
                        </PaginationProvider>
                    </CardBody>
                    </Card>
                </Col>
                </Row>
            </Container>
            </div>
        </React.Fragment>
        </>

    );
};

MobileGameHistory.propTypes = {
  GameHistory: PropTypes.array,
  totalGameHistory: PropTypes.number,
  onGetGameHistory: PropTypes.func,
  items: PropTypes.array,
  totalItems: PropTypes.number,
  onUpdateAwardStatus: PropTypes.func,
  onGetItems: PropTypes.func,
};

const mapStateToProps = ({ GameHistory, items }) => ({
  loading: GameHistory.loading,
  GameHistory: GameHistory.gameHistory,
  totalGameHistory: GameHistory.totalGameHistory,
  items: items.items,
  totalItems: items.totalItems,
});

const mapDispatchToProps = (dispatch) => ({
  onGetGameHistory: (data) => dispatch(getMobileGameHistory(data)),
  onUpdateAwardStatus: (data, callback) => dispatch(updateMobileGameHistory(data, callback)),
  onGetItems: (data) => dispatch(getItems(data))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(MobileGameHistory))
);