import {
    GET_NFT_LVL_UPGRADE_HISTORY,
    GET_NFT_LVL_UPGRADE_HISTORY_SUCCESS,
    GET_NFT_LVL_UPGRADE_HISTORY_FAIL,
} from "./actionTypes";


/* Get NFT lvl upgrade history */
export const getNftLvlUpgradeHistory = (data) => ({
    type: GET_NFT_LVL_UPGRADE_HISTORY,
    payload: data,
});
  
export const getNftLvlUpgradeHistorySuccess = (history) => ({
    type: GET_NFT_LVL_UPGRADE_HISTORY_SUCCESS,
    payload: history,
});
  
export const getNftLvlUpgradeHistoryFail = (error) => ({
    type: GET_NFT_LVL_UPGRADE_HISTORY_FAIL,
    payload: error,
});