import {
  GET_BONUS_HISTORY,
  GET_BONUS_HISTORY_SUCCESS,
  GET_BONUS_HISTORY_FAIL,
  UPDATE_BONUS_HISTORY,
  UPDATE_BONUS_HISTORY_SUCCESS,
  UPDATE_BONUS_HISTORY_FAIL,
} from "./actionTypes";


/* Get collections */
export const getBonusHistory = (data) => ({
  type: GET_BONUS_HISTORY,
  payload: data,
});

export const getBonusHistorySuccess = (sails) => ({
  type: GET_BONUS_HISTORY_SUCCESS,
  payload: sails,
});

export const getBonusHistoryFail = (error) => ({
  type: GET_BONUS_HISTORY_FAIL,
  payload: error,
});

export const updateBonusHistory = (data, callback) => ({
  type: UPDATE_BONUS_HISTORY,
  payload: { data, callback},
});

export const updateBonusHistorySuccess = (data, callback)=> ({
  type: UPDATE_BONUS_HISTORY_SUCCESS,
  payload: { data, callback },
});

export const updateBonusHistoryFail = (error) => ({
  type: UPDATE_BONUS_HISTORY_FAIL,
  payload: error,
});