import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { getSettings, putSettings } from "store/actions"

import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"

const WalletSettings = (props) => {
  const { onGetSettings, onPutSettings, loading, error } = props

  const [fields, setFields] = useState({
    apiKey: "",
    secretKey: "",
  })

  useEffect(() => {
    onGetSettings()
  }, [])

  useEffect(() => {
    if (props.settings && props.settings.pinataIpfsSetting) {
      setFields((prevState) => ({
        ...prevState,
        apiKey: props.settings.pinataIpfsSetting.apiKey,
        secretKey: props.settings.pinataIpfsSetting.secretKey,
      }))
    }
  }, [JSON.stringify(props?.settings)])

  const handleChange = (name) => (event) => {
    const { value } = event.target

    setFields((prevFields) => ({ ...prevFields, [name]: value }))
  }

  function submit() {
    try {
      onPutSettings({
        pinataIpfsSetting: {
          apiKey: fields.apiKey,
          secretKey: fields.secretKey,
        },
        _id: props.settings._id,
      })
    } catch {
      throw new Error("Some Error Occurred!!")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Settings" breadcrumbItem="Pinata IPFS Settings" />
          <Row>
            <Card>
              <CardBody className="spinner-content">
                <Col md={12}>
                  <Form>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label>API Key</Label>

                          <Input
                            type="text"
                            value={fields.apiKey}
                            onChange={handleChange("apiKey")}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label>Secret Key</Label>

                          <Input
                            type="text"
                            value={fields.secretKey}
                            onChange={handleChange("secretKey")}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <hr className="my-3" />

                <Row>
                  <Col></Col>

                  <Col className="d-flex justify-content-end">
                    {loading && <Spinner color="primary" className="mr-2" />}
                    <Button
                      className="buttoncolor"
                      disabled={loading}
                      onClick={submit}
                    >
                      Save Changes
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

WalletSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
}

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
})

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onPutSettings: (data) => dispatch(putSettings(data)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WalletSettings),
)
