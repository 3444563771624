import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Spinner,
  Badge,
} from "reactstrap";
import moment from "moment";

//Import Images
import profile1 from "assets/images/profile-img.png";

// Breadcrumbs
import Breadcrumbs from "components/Common/Breadcrumb2";

// Actions
import { getCollection } from "store/actions";
import axios from "axios";

const ViewCollection = (props) => {
  const {
    match: { params },
    onGetCollection,
    collection,
    loading,
  } = props;

  useEffect(() => {
    if (params && params.id) {
      onGetCollection(params.id);
    }
  }, [params.id]);

  function downloadObjectAsJson(verifyLink) {
    const exportName = "abi.json";
    fetch(verifyLink)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        var dataStr =
          "data:text/json;charset=utf-8," +
          encodeURIComponent(JSON.stringify(data));
        var downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      })
      .catch(function (err) {
        console.warn("Something went wrong.", err);
      });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="spinner-content">
          {loading && (
            <div className="spinner">
              <Spinner color="primary" />
            </div>
          )}

          <Breadcrumbs
            title="Collections"
            breadcrumbItem="Collection Details"
          />

          <Row>
            <Col xl="4">
              <Row>
                <Col>
                  <Card className="overflow-hidden">
                    <div className="bg-soft-primary">
                      <Row>
                        <Col xs="7"></Col>
                        <Col xs="5" className="align-self-end">
                          <img src={profile1} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <Row>
                        <Col sm="12" style={{ display: "flex" }}>
                          <div className="avatar-md profile-user-wid mb-4">
                            <img
                              style={{ height: "100%" }}
                              src={
                                !!collection?.image
                                  ? collection.image.link
                                  : null
                              }
                              alt=""
                              className="img-thumbnail rounded-circle"
                            />
                          </div>

                          <h5 className="pl-3 font-size-15 text-truncate text-capitalize">
                            {collection?.name}
                          </h5>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="7">
                          <h5 className="font-size-15">
                            {moment(collection?.date_created_utc).format(
                              "DD MMM YYYY LT"
                            )}
                          </h5>
                          <p className="text-muted mb-0">
                            {props.t("Created")} {props.t("At")}
                          </p>
                        </Col>

                        <Col xs="5">
                          <h5 className="font-size-15 text-capitalize">
                            {collection?.status || "Active"}
                          </h5>
                          <p className="text-muted mb-0">{props.t("Status")}</p>
                        </Col>
                      </Row>

                      <div className="mt-4 mb-3 mt-xl-3">
                        <h5>Contract Address</h5>

                        <p className="text-muted">
                          {collection?.contractAddress || "N/A"}{" "}
                          {collection?.receiptStatus === "pending" && (
                            <Badge color="warning">InProcess</Badge>
                          )}
                        </p>
                      </div>

                      <div className="mt-4 mb-3 mt-xl-3">
                        <h5>NFT Artist Address</h5>

                        <p className="text-muted">
                          {collection?.artist || "N/A"}{" "}
                          {collection?.receiptStatus === "pending" && (
                            <Badge color="warning">InProcess</Badge>
                          )}
                        </p>
                      </div>
                      <div className="mt-4 mb-3 mt-xl-3">
                        <h5>Nft Fund Address</h5>

                        <p className="text-muted">
                          {collection?.nftFundAddress || "N/A"}{" "}
                          {collection?.receiptStatus === "pending" && (
                            <Badge color="warning">InProcess</Badge>
                          )}
                        </p>
                      </div>

                      <div className="mt-4 mb-3 mt-xl-3">
                        <h5>Contract Verify Link</h5>

                        <p className="text-muted">
                          {/* <a href="#" onClick={downloadObjectAsJson(collection?.verifyContractUrl)}>{collection?.verifyContractUrl}</a> */}

                          <a
                            href="#"
                            onClick={() =>
                              downloadObjectAsJson(
                                collection?.verifyContractUrl
                              )
                            }
                            target="_blank"
                          >
                            {collection?.verifyContractUrl}
                          </a>
                        </p>
                      </div>

                      <div className="mt-4 mb-3 mt-xl-3">
                        <h5>Description</h5>

                        <p className="text-muted">{collection?.description}</p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        <Row>
                          <Col>{props.t("Information")}</Col>
                        </Row>
                      </CardTitle>

                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">{props.t("Full Name")} :</th>
                              <td className="text-wrap text-capitalize">
                                {collection?.name}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">{props.t("Symbol")} :</th>
                              <td className="text-wrap text-capitalize">
                                {collection?.symbol}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">{props.t("Blockchain")} :</th>
                              <td className="text-wrap text-capitalize">
                                {collection?.blockchain}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">{props.t("Max Supply")} :</th>
                              <td className="text-wrap text-capitalize">
                                {collection?.maxSupply}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">{props.t("Royalty")} :</th>
                              <td className="text-wrap text-capitalize">
                                {collection?.royalityFee} %
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col xl="8"></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ViewCollection.propTypes = {
  collection: PropTypes.any,
  date: PropTypes.string,
  onGetCollection: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = ({ collections }) => ({
  loading: collections.loading,
  collection: collections.collection,
  date: collections.date,
  error: collections.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCollection: (data) => dispatch(getCollection(data)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ViewCollection))
);
