import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { sideMenus } from "./sideMenus";

//i18n
import { withTranslation } from "react-i18next";


const SidebarContent = (props) => {

  const role = props?.user?.role;
  const permissions = props?.user?.permissions;

  const [menuArry, setMenu] = useState(sideMenus);

  useEffect(() => {
    if (role !== "ADMIN") {
      setMenu(permissions);
    }
  }, [permissions]);


  return (
    <React.Fragment>
      {
        menuArry?.map((parent, index) => {
          if (role == "ADMIN" || parent?.isSelected === true) {
            return (
              <li key={index}>
                <Link to={parent.route} className={parent.class}>
                  <i className={parent.icon} />
                  <span>{props.t(parent.label)}</span>
                </Link>
                {
                  parent?.options?.length > 0 &&
                  <ul className="sub-menu" aria-expanded="false">
                    {
                      parent?.options?.map((child, childIndex) => (
                        <>
                          {(role == "ADMIN" || child?.isSelected === true) && <li key={childIndex}>
                            <Link className={child.class}
                              to={child.route}
                            >{props.t(child.label)}</Link>
                            {
                              child?.options?.length > 0 &&
                              <ul className="sub-menu" aria-expanded="false">
                                {
                                  child?.options?.map((subChild, subChildIndx) => (
                                    <>
                                      {(role == "ADMIN" || subChild?.isSelected === true) &&
                                        <li key={subChildIndx}>

                                          <Link to={subChild.route}>{props.t(subChild.label)}</Link>

                                        </li>
                                      }

                                    </>
                                  ))
                                }
                              </ul>
                            }
                          </li>}
                        </>
                      ))
                    }
                  </ul>
                }
              </li>
            )
          }

        })
      }
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  t: PropTypes.any,

};

export default withRouter(withTranslation()(SidebarContent));
