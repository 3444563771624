import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
const STATUS = {
  "active":"Active", 
  "completed":"Completed",
  "ended":"Ended"
}

const NFTSTATUS = {
  "pending":"Pending", 
  "transferred":"Transferred"
}
export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (toggleConfirmModal, toggleTransferModal, t, adminRole) => [
  {
    dataField: "roundId",
    text: t("Round Id"),
    formatter: (_, row) => (row.roundId ? row.roundId : "-"),
  },
  {
    dataField: "user.username",
    text: t("Username"),
    formatter: (_, row) => (row.user?.username ? row.user?.username : "-"),
  },
  {
    dataField: "user.email",
    text: t("Email"),
    formatter: (_, row) => (row.user?.email ? row.user?.email : "-"),
  },
  {
    dataField: "lineCount",
    text: t("Line Count"),
    formatter: (_, row) => (row.lineCount ? row.lineCount : 0),
  },
 
  {
    dataField: "crystalsClaimed",
    text: t("Crystals Earned"),
    formatter: (_, row) => (row.crystalsClaimed ? row.crystalsClaimed : 0),
  },
  {
    dataField: "usdtClaimed",
    text: t("USDT Earned"),
    formatter: (_, row) => (row.usdtClaimed ? row.usdtClaimed : 0),
  },
  {
    dataField: "nftsClaimed",
    text: t("NFT Count"),
    formatter: (_, row) => (row.nftsClaimed ? row.nftsClaimed : 0),
  },
  {
    dataField: "nftType",
    text: t("NFT Type"),
    formatter: (_, row) => (row.nftType ? row.nftType :  "-"),
  },
  {
    dataField: "nftAwardedStatus",
    text: t("NFT Transfer Status "),
    formatter: (cellContent, row) => (
      <Badge
        className={
          `text-capitalize font-size-13 badge-soft-${row.nftAwardedStatus}`
        }
        color={row.badgeClass}
      >
        {t(NFTSTATUS[row.nftAwardedStatus])|| "-"}
      </Badge>
    ),
    // formatter: (_, row) => (row.nftAwardedStatus ? NFTSTATUS[row?.nftAwardedStatus]:  "-"),
  },
  {
    dataField: "usdtAwardedStatus",
    text: t("USDT Transfer Status "),
    formatter: (cellContent, row) => (
      <Badge
        className={
          `text-capitalize font-size-13 badge-soft-${row.usdtAwardedStatus}`
        }
        color={row.badgeClass}
      >
        {t(NFTSTATUS[row.usdtAwardedStatus])|| "-"}
      </Badge>
    ),
    // formatter: (_, row) => (row.usdtAwardedStatus ? NFTSTATUS[row?.usdtAwardedStatus]:  "-"),
  },
  {
    dataField: "status",
    text: t("Status"),
    formatter: (cellContent, row) => (
      <Badge
        className={
          `text-capitalize font-size-13 badge-soft-${row.status}`
        }
        color={row.badgeClass}
      >
        {t(STATUS[row.status])}
      </Badge>
    ),
  },
  {
    dataField: "date_created_utc",
    text: t("Created Date"),
    sort: true,
    sortValue: (_, row) =>row?.date_created_utc,
    formatter: (_, row) => (row?.date_created_utc ? moment(row?.date_created_utc).format("YYYY-MM-DD HH:mm:ss") : "--"),
  },

  (adminRole !== "SUBADMIN" &&{
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <Link to={`/user-game-history/detail/${row._id}`} className="mr-3 text-secondary">
          <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />
          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            {t("View")} {t("Details")}
          </UncontrolledTooltip>
        </Link>

        {row?.nftAwardedStatus == "pending" && (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            toggleConfirmModal(row);
          }}
          className="mr-3 text-secondary"
        >
          <i className="fa fa-check mr-3" id={`transfer-${row._id}-tooltip`} />

          <UncontrolledTooltip
            placement="top"
            target={`transfer-${row._id}-tooltip`}
          >
            Mark NFT Transferred
          </UncontrolledTooltip>
        </Link>
        )}

        {row?.usdtAwardedStatus == "pending" && (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            toggleTransferModal(row);
          }}
          className="mr-3 text-secondary"
        >
          <i className="fa fa-check mr-3" id={`transfer-${row._id}-tooltip`} />

          <UncontrolledTooltip
            placement="top"
            target={`transfer-${row._id}-tooltip`}
          >
            Mark USDT Transferred
          </UncontrolledTooltip>
        </Link>
        )}
        </>
    )
  }),
  // {
  //   dataField: "completedAt",
  //   text: t("Completed Date"),
  //   sort: true,
  //   sortValue: (_, row) =>row?.completedAt,
  //   formatter: (_, row) => (row?.completedAt ? moment(row?.completedAt).format("YYYY-MM-DD HH:mm:ss") : "--"),
  // },
  // {
  //   dataField: "transactionHash",
  //   text: t("TransactionHash"),
  //   formatter: (_, row) => (row.transactionHash ? row.transactionHash : "-"),
  // },
];

export default ListColumns;
