import { call, put, takeEvery } from "redux-saga/effects";

// Collection Redux States
import {
  ADD_BALANCE,
  GET_BALANCE_HISTORY,
  REMOVE_BALANCE,
  GET_USER_BALANCE,
  GET_ACTIVITY_HISTORY
  // ADD_TOURNAMENT,
} from "./actionTypes";
import {
  addBalanceFail,
  addBalanceSuccess,
  removeBalanceFail,
  removeBalanceSuccess,
  getBalanceHistoryFail,
  getBalanceHistorySuccess,
  getUserBalanceSuccess,
  getUserBalanceFail,
  getActivityHistoryFail,
  getActivityHistorySuccess
} from "./actions";


import { postAddBalance,postRemoveBalance, postGetBalanceHistory ,postGetUserBalance, postGetActivityHistory} from "helpers/backend_helper";

function* addBalanceSaga({ payload, callback }) {
  console.log(payload, "payeload add Balance");
  try {
    const response = yield call(postAddBalance, payload);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(addBalanceFail(response.message));
    }
    yield put(addBalanceSuccess(response));
  } catch (error) {
    yield put(addBalanceFail(error));
  }
}

function* removeBalanceSaga({ payload, callback }) {
  try {
    const response = yield call(postRemoveBalance, payload);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(removeBalanceFail(response.message));
    }
    yield put(removeBalanceSuccess(response));
  } catch (error) {
    yield put(removeBalanceFail(error));
  }
}

function* getBalanceHistorySaga({ payload, callback }) {
  try {
    const response = yield call(postGetBalanceHistory, payload);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(getBalanceHistoryFail(response.message));
    }
    yield put(getBalanceHistorySuccess(response));
  } catch (error) {
    yield put(getBalanceHistoryFail(error));
  }
}

function* getActivityHistorySaga({ payload, callback }) {
  try {
    const response = yield call(postGetActivityHistory, payload);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(getActivityHistoryFail(response.message));
    }
    yield put(getActivityHistorySuccess(response));
  } catch (error) {
    yield put(getActivityHistoryFail(error));
  }
}


function* getUserBalanceSaga({ payload, callback }) {


  try {
    const response = yield call(postGetUserBalance, payload);
    callback && callback(response);
    if (response.status == "failure") {
      console.log("1", response)

      return yield put(getUserBalanceFail(response.message));
    }
    yield put(getUserBalanceSuccess(response));
  } catch (error) {
    console.log("2", error)

    yield put(getUserBalanceFail(error));
  }
}

function* BalanceSaga() {
  yield takeEvery(ADD_BALANCE, addBalanceSaga);
  yield takeEvery(REMOVE_BALANCE, removeBalanceSaga);
  yield takeEvery(GET_BALANCE_HISTORY, getBalanceHistorySaga);
  yield takeEvery(GET_USER_BALANCE, getUserBalanceSaga);
  yield takeEvery(GET_ACTIVITY_HISTORY, getActivityHistorySaga);
}

export default BalanceSaga;
