import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { withRouter, useParams, Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import Toastr from "toastr";
import { useDropzone } from "react-dropzone";

import {
  Card,
  CardTitle,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
  Button,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import {
  addItem,
  uploadFile,
  removeFile,
  getFdCategories,
  getItem,
  updateItem,
  resetItem,
} from "store/actions";
import { post } from "../../../helpers/api_helper";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { GET_COLLECTIONS, VIEW_CATEGORIES } from "../../../helpers/url_helper";
// import Nft3d from "common/nftUpload/index";

// Images
import avatar4 from "assets/images/users/avatar-9.jpg";

//i18n
import { withTranslation } from "react-i18next";
import toastr from "toastr";
import {
  acceptOnlyNumber,
  acceptOnlyNumberForLayer,
  levelFilter,
  makeNFTWithJimp,
} from "helpers/contants";
import moment from "moment";
import "moment-timezone";
let categories = {};

const AddItem = ({
  collections,
  onAddItem,
  onUploadFile,
  onRemoveFile,
  onRestItem,
  onGetItem,
  onGetFdCategories,
  item,
  fdCategories,
  error,
  errorItem,
  loading,
  ...props
}) => {
  const {
    match: { params },
  } = props;
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const [filter, setFilter] = useState({
    storeTypeId: "",
    orderBy: "sortOrder",
    order: 1,
    page: 1,
    limit: 1000,
    search: "",
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
  });

  const [fields, setFields] = useState({
    current_owner: obj.data._id,
    author_id: obj.data._id,
    name: "",
    blockchain: "polygon",
    subCatName: "",
    price: "",
    category_id: "",
    catName: "Pirates",
    subcategory_id: "",
    collection_id: "",
    media: null,
    mediapreview: null,
    thumb: null,
    visible: "no",
    thumbpreview: null,
    description: "",
    status: "created",
    minted_start_date: null,
    minted_end_date: null,
    saleType: "offer",
    level: 1,
  });

  const history = useHistory();
  const [selectedFiles, setselectedFiles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [thumb, setThumb] = useState(false);
  const [thumbloading, setThumbloading] = useState(false);

  const [fdCategoriesList, setFdCategoriesList] = useState([]);

  useEffect(() => {
    onGetFdCategories("new", filter);
  }, [JSON.stringify(filter)]);
  useEffect(() => {
    if (fdCategories) {
      setFdCategoriesList(fdCategories);
    }
  }, [fdCategories]);

  useEffect(() => {
    if (params && params.id) {
      onGetItem(params.id);
    }
    return () => onRestItem();
  }, [params]);

  useEffect(() => {
    setFields((pre) => ({ ...pre, ...item }));
  }, [item]);

  useEffect(() => {
    let formData = {
      orderBy: "date_created_utc",
      order: -1,
      page: 0,
      limit: 500,
      search: "",
      role: "ADMIN",
      fields: [
        {
          fieldName: "productId",
          fieldValue: null,
        },
        {
          fieldName: "",
          fieldValue: "",
        },
      ],
    };
    (async () => {
      let res = await makeNFTWithJimp();
    })();

    const headerToken = {
      Authorization: accessToken,
    };
    post(GET_COLLECTIONS, formData, JSON.stringify(headerToken))
      .then((res) => {
        getCollectionList(res.data || []);
      })
      .catch((err) => {
        setLoader(false);
      });
  }, []);
  const [collectionList, getCollectionList] = useState([]);
  // const [categoryList, getCategoryList] = useState([]);

  const uploadFileSuccess = (response, type) => {
    // onPutCustomer({ _id: customer._id, profileImage: response.data._id })
    setLoader(false);
    if (type == "thumb") {
      setFields((prevState) => ({
        ...prevState,
        thumb: response.data._id,
        thumbpreview: response.data.link,
      }));
    } else {
      setFields((prevState) => ({
        ...prevState,
        media: response.data._id,
        mediapreview: response.data.link?.replace("piratepets.s3.eu-north-1.amazonaws.com", "d1ihmhsgmb4un6.cloudfront.net"),
      }));
    }
  };

  function handleAcceptedFiles(files) {
    setLoader(true);
    const ext = files[0].name.split(".").pop();
    try {
      if (files[0] && files[0].size >= 5000000) {
        Toastr.error("Please upload a file smaller than 5 MB");
        setLoader(false);
        return false;
      }
      if (
        // ext !== "mp3" &&
        ext !== "png" &&
        ext !== "jpg" &&
        ext !== "jpeg" &&
        // ext !== "wav" &&
        ext !== "ogg" &&
        ext !== "gif"
      ) {
        toastr.error("Only .jpg, .jpeg, .png, extension allow!!");
        setLoader(false);
        return;
      }

      if (selectedFiles.length > 0) {
        if (fields.image) {
          onRemoveFile({ _id: fields.image });

          setFields((prevState) => ({ ...prevState, image: null }));
        }
      }
      console.log(files, "files");
      setFields((prevState) => ({ ...prevState, image: files[0] }));
      onUploadFile({ image: files[0] }, uploadFileSuccess);

      files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );

      setselectedFiles(files);
    } catch (error) {
      console.log(error, "<<=======err");
      setLoader(false);
    }
  }

  function handleAcceptedThumbFiles(files) {
    setLoader(true);
    const ext = files[0].name.split(".").pop();
    try {
      if (files[0] && files[0].size >= 10e6) {
        Toastr.error("Please upload a file smaller than 10 MB");
        setLoader(false);
        return false;
      }
      if (ext !== "png" && ext !== "jpg" && ext !== "jpeg") {
        toastr.error("Only .png,.jpg,.jpeg, extension allow!!");
        setLoader(false);
        return;
      }

      if (selectedFiles.length > 0) {
        if (fields.image) {
          onRemoveFile({ _id: fields.image });

          setFields((prevState) => ({ ...prevState, image: null }));
        }
      }
      console.log(files, "files");
      setFields((prevState) => ({ ...prevState, image: files[0] }));
      onUploadFile({ image: files[0] }, (res) =>
        uploadFileSuccess(res, "thumb")
      );

      files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );

      setselectedFiles(files);
    } catch (error) {
      console.log(error, "<<=======err");
      setLoader(false);
    }
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleChange = (name) => (event) => {
    if (name === "minted_start_date") {
      setFields((prevState) => ({
        ...prevState,
        ["minted_end_date"]: "",
      }));
    }
    if (name == "category_id") {
      categories = fdCategoriesList?.find((o) => o._id == event.target.value);
      setFields((prevState) => ({
        ...prevState,
        [name]: event.target.value,
        catName: categories?.catName,
        subcategory_id: "",
        subCatName: "",
      }));
      return null;
    }
    if (name == "subcategory_id" && categories?.catName == "Ships") {
      let subCat = categories?.subcategories?.find(
        (o) => o._id == event.target.value
      );

      setFields((prevState) => ({
        ...prevState,
        [name]: event.target.value,
        catName: "Ships",
        subCatName: subCat?.catName,
      }));
      return null;
    } else if (name == "subcategory_id" && categories?.catName == "Pirates") {
      setFields((prevState) => ({
        ...prevState,
        [name]: event.target.value,
        catName: "Pirates",
        subCatName: "",
      }));
      return null;
    } else {
      setFields((prevState) => ({
        ...prevState,
        [name]: event.target.value,
      }));
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const itemCallback = (response) => {
      if (response.status == "failure") {
        toastr.error(response.message);
      } else {
        toastr.success("Item updated successfully", { timeOut: 5000 });
        history.push("/adminitems");
      }
    };
    if (loading) return;
    let data = {
      itemId: fields?._id,
      visible: fields.visible,
      blockchain: "BNB",

      name: fields?.name,
      price: fields?.price,
      category_id: fields?.category_id,
      description: fields?.description,
      subcategory_id: fields?.subcategory_id,
      level: fields?.level,
    };
    onAddItem(data, itemCallback);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm={1} lg={2}></Col>
            <Col sm={10} lg={6}>
              <Breadcrumbs
                title="Items"
                breadcrumbItem={props.t("Add") + " " + props.t("Items")}
                breadcrumbItems={[
                  { title: props.t("Admin Items"), link: "/adminitems" },
                  { title: props.t("Preview") },
                ]}
              />

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Form onSubmit={onSubmit} className="spinner-content">
                        {error && typeof error === "string" ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}

                        <Row>
                          {thumb && (
                            <Col md={12}>
                              <Label for="formrow-profile-image-Input">
                                {props.t("Thumbnail")} {props.t("Image")}
                                <b>
                                  (File types supported: JPG, PNG size: 10 MB)
                                </b>
                              </Label>
                              <div className="dropzone-single-image avatar-xl">
                                <input
                                  type="file"
                                  onChange={(e) =>
                                    handleAcceptedThumbFiles(e.target.files)
                                  }
                                  required
                                />

                                {fields.thumbpreview && (
                                  <img
                                    className="rounded-circle custom_avtal_xl mt-2"
                                    src={
                                      fields.thumbpreview
                                        ? fields.thumbpreview
                                        : avatar4
                                    }
                                  />
                                )}
                              </div>
                            </Col>
                          )}
                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Name")}
                              </Label>
                              <Input
                                type="text"
                                id="formrow-firstname-Input"
                                value={fields.name}
                                readOnly
                                // onChange={handleChange("name")}
                                required
                              />

                              <small>
                                If you leave this empty it will give the NFT a
                                random number or if collection increases the
                                number of each NFT.
                              </small>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("Price")}</Label>

                              <InputGroup>
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    {fields.blockchain == "ethereum"
                                      ? "ETH"
                                      : fields.blockchain == "polygon"
                                      ? "MATIC"
                                      : "BNB"}
                                  </span>
                                </div>

                                <Input
                                  type="number"
                                  // min={1}
                                  onWheel={(e) => e.target.blur()}
                                  onKeyDown={acceptOnlyNumber}
                                  value={fields.price}
                                  required
                                  onChange={handleChange("price")}

                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>

                          <Col md={12}>
                            <FormGroup>
                              <Label>{props.t("Description")}</Label>

                              <Input
                                type="textarea"
                                value={fields?.description}
                                onChange={handleChange("description")}

                              />
                              <small>
                                The description will be included on the item's
                                detail page underneath its image.Markdown syntax
                                is supported.
                              </small>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("Select Category")}</Label>

                              <Input
                                type="select"
                                value={fields.category_id}
                                onChange={handleChange("category_id")}

                              >
                                <option value="">Select</option>
                                {fdCategoriesList.length > 0 &&
                                  fdCategoriesList?.map((category) => {
                                    return (
                                      <option value={category._id}>
                                        {category?.catName}
                                      </option>
                                    );
                                  })}
                              </Input>
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("Select Subcategory")}</Label>

                              <Input
                                type="select"
                                value={fields.subcategory_id}
                                onChange={handleChange("subcategory_id")}
                              >
                                <option value="">Select</option>
                                {fdCategoriesList?.length > 0 &&
                                  fdCategoriesList
                                    ?.find(
                                      (cat) => cat._id == fields.category_id
                                    )
                                    ?.subcategories?.filter(
                                      (o) => o?.catName !== "All"
                                    )
                                    ?.map((subcategory) => {
                                      return (
                                        <option value={subcategory._id}>
                                          {subcategory?.catName}
                                        </option>
                                      );
                                    })}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label>
                                {props.t(
                                  `Level (${
                                    levelFilter[fields?.subCatName]?.minValue ||
                                    1
                                  } , ${
                                    levelFilter[fields?.subCatName]?.maxValue ||
                                    5
                                  })`
                                )}
                              </Label>

                              <InputGroup>
                                <Input
                                  type="number"
                                  onKeyDown={acceptOnlyNumber}
                                  onChange={handleChange("level")}
                                  min="1"
                                  value={fields.level}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("Visible")}</Label>
                              <Input
                                type="select"
                                value={fields?.visible}
                                onChange={handleChange("visible")}
                              >
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>

                        {(loading || props.fileLoading) && (
                          <div className="spinner loader_bg">
                            <Spinner color="#378f88" />{" "}
                            {props.fileLoading && (
                              <span className="ml-2"> image uploading</span>
                            )}
                          </div>
                        )}
                        <div className="mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            {props.t("Update")}
                          </button>

                          <button
                            type="button"
                            className="btn outline btn-outline-secondary w-md ml-3"
                            onClick={() => history.goBack()}
                          >
                            <i className="mdi mdi-arrow-left mr-1"></i>
                            {props.t("back")}
                          </button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm={1} lg={4}>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <img
                      className="avatar-xl"
                      alt=""
                      style={{
                        height: "26rem",
                        marginTop: "45px",
                        width: "20.5rem",
                      }}
                      src={fields?.image_url?.replace("piratepets.s3.eu-north-1.amazonaws.com", "d1ihmhsgmb4un6.cloudfront.net") || avatar4}
                    />
                    <p
                      className="text-center text-muted mt-2"
                      style={{ fontSize: "18px" }}
                    >
                      Preview
                    </p>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddItem.propTypes = {
  t: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onAddItem: PropTypes.func,
  onUploadFile: PropTypes.func,
  onRemoveFile: PropTypes.func,
  fileLoading: PropTypes.bool,
};

const mapStateToProps = ({ customers, items, Categoryes, File }) => ({
  error: customers.error,
  fileLoading: File.loading,
  errorItem: items.error,
  item: items.item,
  loading: items.loading,
  fdCategories: Categoryes.fdCategories,
});

const mapDispatchToProps = (dispatch) => ({
  onGetItem: (id) => dispatch(getItem(id)),
  onGetFdCategories: (storeType, data) =>
    dispatch(getFdCategories(storeType, data)),
  onRestItem: () => dispatch(resetItem()),

  onAddItem: (data, callback) => dispatch(updateItem(data, callback)),
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback, true)),
  onRemoveFile: (data) => dispatch(removeFile(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddItem))
);
