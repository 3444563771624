import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import { BLOCKCHAIN_CURRENCY_CODE } from "helpers/contants";

export const selectRow = (props) => ({
    mode: "checkbox",
    clickToSelect: true,
    selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
        <div className="custom-control custom-checkbox">
            <Input
                type="checkbox"
                className="custom-control-input"
                ref={(input) => {
                    if (input) input.indeterminate = indeterminate;
                }}
                {...rest}
            />
            <Label className="custom-control-label">&nbsp;</Label>
        </div>
    ),
    selectionRenderer: ({ mode, rowKey, ...rest }) => (
        <div className="custom-control custom-checkbox" key={rowKey}>
            <input type="checkbox" className="custom-control-input" {...rest} />
            <Label className="custom-control-label">&nbsp;</Label>
        </div>
    ),
    ...props,
});

const ListColumns = (toggleConfirmModal, toggleRejectModal, t) => [
    {
        dataField: "user_id.username",
        text: t("Username"),
        formatter: (_, row) => (row.user_id?.username ? row.user_id?.username : "-"),
    },
    {
        dataField: "walletAddress",
        text: t("Wallet Address"),
        formatter: (_, row) => (row.walletAddress ? row.walletAddress: "-"),
    },
    {
        dataField: "crystalAmount",
        text: t("Crystal Amount"),
        formatter: (_, row) => (row.crystalAmount ? row.crystalAmount: "0"),
    },

    {
        dataField: "busdAmount",
        text: t("Busd Amount"),
        formatter: (_, row) => (row.busdAmount ? row.busdAmount: "0"),
    },

    {
        dataField: "fee",
        text: t("Fee"),
        formatter: (_, row) => (row.fee ? row.fee: "0"),
    },
    {
        dataField: "receivableAmount",
        text: t("Receivable Amount"),
        formatter: (_, row) => (row.receivableAmount ? row.receivableAmount: "0"),
    },
    {
        dataField: "status",
        text: t("Status"),
        formatter: (cellContent, row) => (
          <Badge
            className={
              "text-capitalize font-size-13 badge-soft-" 
            }
            color={row.badgeClass}
          >
            {t(row?.status)}
          </Badge>
        ),
    },
    {
        isDummyField: true,
        text: t("Action"),
        dataField: "action",
        formatter: (_, row) => (
            <>
                <Link
                    to="#"
                    onClick={(e) => {
                        e.preventDefault();
                        toggleConfirmModal(row._id);
                    }} className="mr-2 text-secondary">
                   <i class="fa fa-check" aria-hidden="true"  id={`view-${row._id}-tooltip`}></i>

                    {/* <i className="far fa-check mr-1" aria-hidden="true" id={`view-${row._id}-tooltip`} /> */}

                    <UncontrolledTooltip
                        placement="top"
                        target={`view-${row._id}-tooltip`}
                    >
                        Confirm
                    </UncontrolledTooltip>
                </Link>
                <Link
                    to="#"
                    onClick={(e) => {
                        e.preventDefault();
                        toggleRejectModal(row._id);
                    }} className="mr-2 text-secondary">
                    <i class="fa fa-times" aria-hidden="true" id={`view2-${row._id}-tooltip`}></i>
                    {/* <i className="far fa-times mr-1" id={`view2-${row._id}-tooltip`} /> */}

                    <UncontrolledTooltip
                        placement="top"
                        target={`view2-${row._id}-tooltip`}
                    >
                        Reject
                    </UncontrolledTooltip>
                </Link>
            </>
        ),
    },

 
];

export default ListColumns;
