import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import { ACTIVITY_TYPE, BLOCKCHAIN_CURRENCY_CODE } from "helpers/contants";
const STATUS = {
  "pending": "Pending",
  "success": "Success"
}
export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = ( t) => [
  {
    dataField: "ipAddress",
    text: t("IP Address"),
    formatter: (_, row) => {
      return (
        <>
          <p>{row?.ipAddress || "----"}</p>
        </>
      );
    },
  },
  {
    dataField: "country",
    text: t("Country"),
    formatter: (_, row) => {
      return (
        <>
          <p>{row?.country || "----"}</p>
        </>
      );
    },
  },


  {
    text: t("Login Time"),
    dataField: "loginAt",
    sort: true,
    formatter: (_, row) => (
      <>
        {/* moment(row.created_date).format("DD MMM YYYY hh:mm A"), */}
        {moment(row.loginAt).local().format("YYYY-MM-DD HH:mm:ss")}
      </>
    ),
  },

  // {
  //   isDummyField: true,
  //   text: t("Action"),
  //   dataField: "action",
  //   formatter: (_, row) => (
  //     <>
  //       <Link to="#"
  //         onClick={(e) => {
  //           e.preventDefault();
  //           toggleConfirmModal(row?.item, row?.from?.walletAddress);
  //         }} className="mr-2 text-secondary">
  //         <i className="far fa-edit mr-1" id={`airdrop-${row._id}-tooltip`} />

  //         <UncontrolledTooltip
  //           placement="top"
  //           target={`airdrop-${row._id}-tooltip`}
  //         >
  //           Airdrop
  //         </UncontrolledTooltip>
  //       </Link>


  //     </>
  //   ),
  // },
];

export default ListColumns;
