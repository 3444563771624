import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import Toastr from "toastr";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
  Button,
  InputGroup,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import { addMultipleItems, uploadFile, removeFile, getFdCategories } from "store/actions";
import { post } from "../../../helpers/api_helper";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { GET_COLLECTIONS } from "../../../helpers/url_helper";
import avatar4 from "assets/images/users/avatar-9.jpg";
import { withTranslation } from "react-i18next";
import toastr from "toastr";
import { acceptOnlyNumber, levelFilter, makeNFTWithJimp } from "helpers/contants";
import "moment-timezone";

const AddMultipleItem = ({
  collections,
  onAddMultipleItems,
  onUploadFile,
  onRemoveFile,
  onGetItems,
  onGetFdCategories,
  fdCategories,
  error,
  errorItem,
  loading,
  ...props
}) => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const viewData = {
        current_owner: obj.data._id,
        author_id: obj.data._id,
        name: "",
        blockchain: "BNB",
        subCatName: "",
        price: "",
        category_id: "",
        catName: "Pirates",
        subcategory_id: "",
        collection_id: "",
        media: null,
        mediapreview: null,
        thumb: null,
        visible: "no",
        thumbpreview: null,
        description: "",
        status: "created",
        minted_start_date: null,
        minted_end_date: null,
        saleType: "offer",
        level: 1,
        isNotOthers: true,
        files: [],
        image: null
    }
    const [viewsData, setViewsData] = useState([viewData]);
    const [filter, setFilter] = useState({
        storeTypeId: "",
        orderBy: "sortOrder",
        order: 1,
        page: 1,
        limit: 1000,
        search: "",
        fields: [
        {
            fieldName: "",
            fieldValue: "",
        },
        ],
    });
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [thumb, setThumb] = useState(false);
    const [fdCategoriesList, setFdCategoriesList] = useState([]);
    const [collectionList, getCollectionList] = useState([]);

    useEffect(() => {
        onGetFdCategories("new", filter);
    }, [JSON.stringify(filter)]);
    
    useEffect(() => {
        if (fdCategories) {
            setFdCategoriesList(fdCategories);
        }
    }, [fdCategories]);

    useEffect(() => {
        let formData = {
            orderBy: "date_created_utc",
            order: -1,
            page: 0,
            limit: 500,
            search: "",
            role: "ADMIN",
            fields: [
                {
                fieldName: "productId",
                fieldValue: null,
                },
                {
                fieldName: "",
                fieldValue: "",
                },
            ],
        };
        (async () => {
        let res = await makeNFTWithJimp();
        })();

        const headerToken = {
        Authorization: accessToken,
        };
        post(GET_COLLECTIONS, formData, JSON.stringify(headerToken))
        .then((res) => {
            getCollectionList(res.data || []);
        })
        .catch((err) => {
            setLoader(false);
        });
    }, []);

    // useEffect(() => {
    //     if (fdCategoriesList.length > 0 && viewsData && viewsData.length > 0) {
    //         viewsData.forEach((view, index) => {
    //             const cat = fdCategoriesList.find((category) => category.catName === "Others");
    //             const updatedViewsData = [...viewsData];
    //             if (cat && cat._id === view.category_id) {
    //                 updatedViewsData[index] = {
    //                     ...view,
    //                     isNotOthers: false,
    //                 };
    //             } 
    //             else {
    //                 updatedViewsData[index] = {
    //                     ...view,
    //                     isNotOthers: true,
    //                 };
    //             }
    //             setViewsData(updatedViewsData);
    //         });
    //     }
    // }, [fdCategoriesList, viewsData]);

    const uploadFileSuccess = (response, type, index) => {
        setLoader(false);
        console.log("uploadFileSuccess type ---->", type);
        console.log("uploadFileSuccess index  ---->", index); 

        if (type == "thumb") {
            const updatedViewsData = [...viewsData];
            updatedViewsData[index] = {
                ...updatedViewsData[index],
                thumb: response.data._id,
                thumbpreview: response.data.link,
            };
            setViewsData(updatedViewsData);
        } 
        else {
            const updatedViewsData = [...viewsData];
            updatedViewsData[index] = {
                ...updatedViewsData[index],
                media: response.data._id,
                mediapreview: response.data.link,
            };
            setViewsData(updatedViewsData);
        }
    };

    function handleAcceptedFiles(files, index) {
        setLoader(true);
        console.log("handleAcceptedFiles ---->",files, index);
        const ext = files[0].name.split(".").pop();
        try {
            if (files[0] && files[0].size >= 5000000) {
                Toastr.error("Please upload a file smaller than 5 MB");
                setLoader(false);
                return false;
            }
            if (
                // ext !== "mp3" &&
                ext !== "png" &&
                ext !== "jpg" &&
                ext !== "jpeg" &&
                // ext !== "wav" &&
                ext !== "ogg" &&
                ext !== "gif"
            ) {
                toastr.error("Only .jpg, .jpeg, .png, extension allow!!");
                setLoader(false);
                return;
            }

            if (viewsData?.[index]?.files.length > 0) {
                if (viewsData?.[index]?.image) {
                    onRemoveFile({ _id: viewsData?.[index]?.image });

                    const updatedViewsData = [...viewsData];
                    updatedViewsData[index] = {
                        ...updatedViewsData[index],
                        image: null,
                    };
                    setViewsData(updatedViewsData);
                }
            }
          
            const updatedViewsData = [...viewsData];
            updatedViewsData[index] = {
                ...updatedViewsData[index],
                image: files[0],
            };
            setViewsData(updatedViewsData);
            onUploadFile({ image: files[0] }, (res) =>
                uploadFileSuccess(res, null, index)
            );
        } 
        catch (error) {
            console.log(error, "<<=======err");
            setLoader(false);
        }
    }

    function handleAcceptedThumbFiles(files, index) {
        setLoader(true);
        const ext = files[0].name.split(".").pop();
        try {
            if (files[0] && files[0].size >= 10e6) {
                Toastr.error("Please upload a file smaller than 10 MB");
                setLoader(false);
                return false;
            }
            if (ext !== "png" && ext !== "jpg" && ext !== "jpeg") {
                toastr.error("Only .png,.jpg,.jpeg, extension allow!!");
                setLoader(false);
                return;
            }

            if (viewsData?.[index]?.files?.length > 0) {
                if (viewsData?.[index]?.image) {
                    onRemoveFile({ _id: viewsData?.[index]?.image });

                    const updatedViewsData = [...viewsData];
                    updatedViewsData[index] = {
                        ...updatedViewsData[index],
                        image: null
                    };
                    setViewsData(updatedViewsData);
                }
            }
            console.log("handleAcceptedThumbFiles ====>", files, index);

            const updatedViewsData = [...viewsData];
            updatedViewsData[index] = {
                ...updatedViewsData[index],
                image: files[0]
            };
            setViewsData(updatedViewsData);
            
            onUploadFile({ image: files[0] }, (res) =>
                uploadFileSuccess(res, "thumb", index)
            );
        } 
        catch (error) {
            console.log(error, "<<=======err");
            setLoader(false);
        }
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const onSubmit = (event) => {
        event.preventDefault();
        console.log("on submit event called-------->", viewsData);
        if (loading) return;
        try{
            viewsData?.forEach((view, index) => {
                if (view.price == 0) return toastr.error(`Please enter price in NFT ${index+1}`);
                if (!view.subcategory_id) return toastr.error(`Please select sub category in NFT ${index+1}`);
                if(view.catName == "Ships"){
                    switch (view.subCatName) {
                        case "Schooner":
                        case "Brigantine":
                        case "Galleon":
                        case "Sloop":
                        case "Frigate":
                        if (
                            view.level < levelFilter[view.subCatName].minValue ||
                            view.level > levelFilter[view.subCatName].maxValue
                        ) {
                            toastr.error(
                            `Please select level from ${levelFilter[view.subCatName].minValue
                            }- ${levelFilter[view.subCatName].maxValue}`
                            );
                            return null;
                        }
                        default:
                        break;
                    }
                }
                else if (view.level > 5){
                    return toastr.error(`Level should be less then or equal to 5 in NFT ${index+1}`);
                }
            });

            const itemCallback = (response) => {
                console.log("response ------>", response);
                if (response.status == "failure") {
                    toastr.error(response.message);
                    loading = false;
                } 
                else {
                    toastr.success("Items created successfully", { timeOut: 5000 });
                    history.push("/adminitems");
                }
            };
            onAddMultipleItems({ items: viewsData },itemCallback );

        }
        catch(err){
            console.log(err)
        }
    };

    // Function to add a new view
    const addView = () => {
        setViewsData([...viewsData, viewData]);
    };
    
    // Function to remove a view
    const removeView = (index) => {
        if (viewsData.length > 1) {
          const updatedViewsData = viewsData.filter((_, i) => i !== index);
          setViewsData(updatedViewsData);
        }
    };

    const handleChange = (index) => (name, value) => {
        const updatedViewsData = [...viewsData];
        
        if (name === "minted_start_date") {
          updatedViewsData[index] = {
            ...updatedViewsData[index],
            ["minted_end_date"]: "",
          };
        } 
        else if (name === "category_id") {
          const categories = fdCategoriesList?.find((o) => o._id === value);
          updatedViewsData[index] = {
            ...updatedViewsData[index],
            [name]: value,
            catName: categories.catName,
            subcategory_id: "",
            subCatName: "",
          };
        } 
        else if (name === "subcategory_id") {
          const categories = fdCategoriesList?.find((o) => o._id === updatedViewsData[index].category_id);
          if (categories?.catName === "Ships") {
            const subCat = categories?.subcategories?.find((o) => o._id === value);
            updatedViewsData[index] = {
              ...updatedViewsData[index],
              [name]: value,
              catName: "Ships",
              subCatName: subCat.catName,
            };
          } else if (categories?.catName === "Pirates") {
            updatedViewsData[index] = {
              ...updatedViewsData[index],
              [name]: value,
              catName: "Pirates",
              subCatName: "",
            };
          } else {
            updatedViewsData[index] = {
              ...updatedViewsData[index],
              [name]: value,
            };
          }
        } 
        else {
          updatedViewsData[index] = {
            ...updatedViewsData[index],
            [name]: value,
          };
        }
        setViewsData(updatedViewsData);
    };

    const singleItemView = (index) =>(
        <Row>
            <Col lg={10}>
                <Card>
                    <CardBody>
                        <FormGroup>
                            <Label for="formrow-profile-image-Input">
                                {props.t("Item")} {props.t("Image")}
                                <b>
                                    (File types supported: PNG, JPG, JPEG, Smaller than 5
                                    MB)
                                </b>
                            </Label>

                            <Dropzone
                                onDrop={(acceptedFiles) => {
                                    handleAcceptedFiles(acceptedFiles, index);
                                }}
                            >
                            {({
                                getRootProps: getRootMainProps,
                                getInputProps: getInputMainProps,
                            }) => {
                                const imageFile = viewsData?.[index]?.mediapreview?.replace("piratepets.s3.eu-north-1.amazonaws.com", "d1ihmhsgmb4un6.cloudfront.net");
                                return (
                                <div
                                    className="dropzone-single-image avatar-xl"
                                    {...getRootMainProps()}
                                >
                                    <input
                                    {...getInputMainProps()}
                                    id="formrow-profile-image-Input"
                                    multiple={false}
                                    />
                                    <img
                                    className="rounded-circle avatar-xl"
                                    alt={
                                        !!imageFile && imageFile.name
                                        ? imageFile.name
                                        : "MintFactory"
                                    }
                                    src={!!imageFile ? imageFile : avatar4}
                                    />
                                    <div className="edit">
                                    <i className="bx bx-pencil"></i>
                                    </div>
                                </div>
                                );
                            }}
                            </Dropzone>
                        </FormGroup>

                        <Form onSubmit={onSubmit} className="spinner-content">
                            {error && typeof error === "string" ? (
                            <Alert color="danger">{error}</Alert>
                            ) : null}

                            <Row>
                            {thumb && (
                                <Col md={12}>
                                    <Label for="formrow-profile-image-Input">
                                        {props.t("Thumbnail")} {props.t("Image")}
                                        <b>
                                        (File types supported: JPG, PNG size: 10 MB)
                                        </b>
                                    </Label>
                                    <div className="dropzone-single-image avatar-xl">
                                        <input
                                        type="file"
                                        onChange={(e) =>
                                            handleAcceptedThumbFiles(e.target.files, index)
                                        }
                                        required
                                        />

                                        {viewsData[index]?.thumbpreview && (
                                        <img
                                            className="rounded-circle custom_avtal_xl mt-2"
                                            src={
                                                viewsData[index]?.thumbpreview
                                                ? viewsData[index]?.thumbpreview
                                                : avatar4
                                            }
                                        />
                                        )}
                                    </div>
                                </Col>
                            )}
                            <Col md={6}>
                                <FormGroup>
                                <Label for="formrow-firstname-Input">
                                    {props.t("Name")}
                                </Label>
                                <Input
                                    type="text"
                                    id="formrow-firstname-Input"
                                    value={viewsData[index]?.name}
                                    onChange={(e) => handleChange(index)("name", e.target.value)}
                                    required
                                />

                                <small>
                                    If you leave this empty it will give the NFT a
                                    random number or if collection increases the
                                    number of each NFT.
                                </small>
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                <Label>{props.t("Visible")}</Label>
                                <Input
                                    type="select"
                                    value={viewsData[index]?.visible}
                                    onChange={(e) => handleChange(index)("visible", e.target.value)}
                                >
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </Input>
                                </FormGroup>
                            </Col>

                            <Col md={12}>
                                <FormGroup>
                                <Label>{props.t("Description")}</Label>

                                <Input
                                    type="textarea"
                                    value={viewsData[index]?.description}
                                    onChange={(e) => handleChange(index)("description", e.target.value)}
                                    // onChange={handleChange("description")}
                                />
                                <small>
                                    The description will be included on the item's
                                    detail page underneath its image.Markdown syntax
                                    is supported.
                                </small>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                <Label>{props.t("Select Category")}</Label>

                                <Input
                                    type="select"
                                    value={viewsData[index]?.category_id}
                                    onChange={(e) => handleChange(index)("category_id", e.target.value)}
                                    // onChange={handleChange("category_id")}
                                >
                                    <option value="">Select</option>
                                    {fdCategoriesList.length > 0 &&
                                    fdCategoriesList?.map((category) => {
                                        return (
                                        <option value={category._id}>
                                            {category.catName}
                                        </option>
                                        );
                                    })}
                                </Input>
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                <Label>{props.t("Select Subcategory")}</Label>

                                <Input
                                    type="select"
                                    value={viewsData[index]?.subcategory_id}
                                    onChange={(e) => handleChange(index)("subcategory_id", e.target.value)}
                                    // onChange={handleChange("subcategory_id")}
                                >
                                    <option value="">Select</option>
                                    {fdCategoriesList?.length > 0 &&
                                    fdCategoriesList
                                        ?.find(
                                        (cat) => cat._id == viewsData[index]?.category_id
                                        )
                                        ?.subcategories?.filter(
                                        (o) => o.catName !== "All"
                                        )
                                        ?.map((subcategory) => {
                                        return (
                                            <option value={subcategory._id}>
                                            {subcategory.catName}
                                            </option>
                                        );
                                        })}
                                </Input>
                                </FormGroup>
                            </Col>
                            </Row>
                            <Row>

                            {viewsData[index]?.isNotOthers && (
                                <Col md={6}>
                                <FormGroup>
                                    <Label>
                                    {props.t(
                                        `Level (${levelFilter[viewsData[index]?.subCatName]?.minValue ||
                                        1
                                        } , ${levelFilter[viewsData[index]?.subCatName]?.maxValue ||
                                        5
                                        })`
                                    )}
                                    </Label>

                                    <InputGroup>
                                    <Input
                                        type="number"
                                        onKeyDown={acceptOnlyNumber}
                                        onChange={(e) => handleChange(index)("level", e.target.value)}
                                        // onChange={handleChange("level")}
                                        min="1"
                                        value={viewsData[index]?.level}
                                    />
                                    </InputGroup>
                                </FormGroup>
                                </Col>
                            )}
                            <Col md={viewsData[index]?.isNotOthers ? 6 : 12}>
                                <FormGroup>
                                <Label>{props.t("Select Chain")}</Label>

                                <Input
                                    type="select"
                                    value={viewsData[index]?.blockchain}
                                    onChange={(e) => handleChange(index)("blockchain", e.target.value)}
                                    // onChange={handleChange("blockchain")}
                                >
                                    {/* <option value="ethereum">Ethereum</option> */}
                                    {/* <option value="polygon">Polygon</option> */}
                                    <option value="BNB">Binance Smart Chain</option>
                                </Input>
                                </FormGroup>
                            </Col>
                            </Row>
                            <Row>
                            <Col md={6}>
                                <FormGroup>
                                <Label>{props.t("Price")}</Label>

                                <InputGroup>
                                    <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        {viewsData[index]?.blockchain == "ethereum"
                                        ? "ETH"
                                        : viewsData[index]?.blockchain == "polygon"
                                            ? "MATIC"
                                            : "BNB"}
                                    </span>
                                    </div>

                                    <Input
                                    type="number"
                                    // min={1}
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={acceptOnlyNumber}
                                    value={viewsData[index]?.price}
                                    onChange={(e) => handleChange(index)("price", e.target.value)}
                                    // onChange={handleChange("price")}
                                    required
                                    />
                                </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                <Label className="mb-0">
                                    {props.t("Choose collection")}
                                </Label>
                                <br />

                                <InputGroup className="mt-2">
                                    <Input
                                    type="select"
                                    value={viewsData[index]?.collection_id}
                                    onChange={(e) => handleChange(index)("collection_id", e.target.value)}
                                    // onChange={handleChange("collection_id")}
                                    >
                                    {/* {console.log(
                                        viewsData[index]?.catName?.toLowerCase(),
                                        "viewsData[index]?.catName?.toLowerCase()"
                                    )} */}
                                    <option value="">Select Collection</option>
                                    {collectionList &&
                                        collectionList.length > 0 &&
                                        collectionList?.map(
                                        (col) =>
                                            col.blockchain == viewsData[index]?.blockchain &&
                                            col.collectionType ==
                                            viewsData[index]?.catName?.toLowerCase() &&
                                            col.transactionHash && (
                                            <option value={col._id}>
                                                {col.name}
                                            </option>
                                            )
                                        )}
                                    </Input>
                                </InputGroup>
                                <small>
                                    Choose an exiting collection or{" "}
                                    <Link to="/collection/add">
                                    create a new one
                                    </Link>
                                </small>
                                </FormGroup>
                            </Col>
                            </Row>

                            {(loading || props.fileLoading) && (
                            <div className="spinner loader_bg">
                                <Spinner color="#378f88" />{" "}
                                {props.fileLoading && (
                                <span className="ml-2"> image uploading</span>
                                )}
                            </div>
                            )}
                            
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );

    const multipleItemViews = viewsData.map((data, index) => (
        <div key={index}>
            <p className="mt-3 text-change-color text-bold">{index+1 +  "."}</p>

            {singleItemView(index)}

            {index > 0 && <div className="w-100 remove-btn-div">
                <button
                    type="button"
                    className="btn btn-danger ml-2 remove-btn"
                    onClick={() => removeView(index)}
                >
                    Remove
                </button>
            </div>}
        
        </div>
    ));

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Breadcrumbs title="Items"
                            breadcrumbItem={props.t("Add") + " " + props.t("Items")}
                            breadcrumbItems={[
                                { title: props.t("Items"), link: "/adminitems" },
                                { title: props.t("Add Multiple") },
                            ]}
                        />
                        <div className="w-100 add-btn-div">
                            <button 
                                type="button" 
                                className="btn btn-success mb-3 add-btn" 
                                onClick={(e) => addView(e)}>
                            Add More
                            </button>
                        </div>
                        
                        {multipleItemViews}

                        <div className="mt-4 multi-add-button">
                            <button
                                type="submit"
                                className="btn btn-primary w-md"
                                onClick={(e) => onSubmit(e)}>
                            {props.t("Add")}
                            </button>

                            <button
                                type="button"
                                className="btn outline btn-outline-secondary w-md ml-3"
                                onClick={() => history.goBack()}>
                            <i className="mdi mdi-arrow-left mr-1"></i>
                                {props.t("back")}
                            </button>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

AddMultipleItem.propTypes = {
  t: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onAddMultipleItems: PropTypes.func,
  onUploadFile: PropTypes.func,
  onRemoveFile: PropTypes.func,
  fileLoading: PropTypes.bool,
};

const mapStateToProps = ({ customers, items, Categoryes, File }) => ({
    error: customers.error,
    fileLoading: File.loading,
    errorItem: items.error,
    loading: items.loading,
    fdCategories: Categoryes.fdCategories,
});
  

const mapDispatchToProps = (dispatch) => ({
  onGetFdCategories: (storeType, data) =>  dispatch(getFdCategories(storeType, data)),
  onAddMultipleItems: (data, callback) => dispatch(addMultipleItems(data, callback)),
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback, true)),
  onRemoveFile: (data) => dispatch(removeFile(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddMultipleItem))
);