import { call, put, takeLatest } from "redux-saga/effects";

import {
    GET_NOTIFICATION_LIST,
    SEND_NOTIFICATION
} from "./actionTypes";

import {
    getNotificationListFail,
    getNotificationListSuccess,
    sendNotificationFail,
    sendNotificationSuccess
} from "./actions";

import {
    postGetNotificationList,
    postSendNotification
} from "helpers/backend_helper";
  

function* getNotificationSaga({ payload, callback }) {
    try {
      const response = yield call(postGetNotificationList, payload);
      callback && callback(response);
      if (response.status == "failure") {
        return yield put(getNotificationListFail(response.message));
      }
      yield put(getNotificationListSuccess(response));
    } 
    catch (error) {
      yield put(getNotificationListFail(error));
    }
}

function* sendNotificationSaga({payload: { data, callback } }) {
  try {
    console.log("payload data---->", data)
    const response = yield call(postSendNotification, data);
    console.log("response---->", response)
    callback && callback(null, response);
    if (response.status == "failure") {
      return yield put(sendNotificationFail(response.message));
    }
    yield put(sendNotificationSuccess(response));
  } 
  catch (error) {
    console.log(error);
    yield put(sendNotificationFail(error));
  }
}

function* NotificationSaga() {
  yield takeLatest(GET_NOTIFICATION_LIST, getNotificationSaga),
  yield takeLatest(SEND_NOTIFICATION,sendNotificationSaga)
}
  
export default NotificationSaga;