import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import { BLOCKCHAIN_CURRENCY_CODE } from "helpers/contants";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (history, toggleConfirmModal, accesses, t) => [
  {
    dataField: "level",
    text: t("Level"),
  },

  {
    text: t("Created At"),
    dataField: "create_date",
    sort: true,
    formatter: (_, row) =>
      moment(row?.image?.date_created).format("DD MMM YYYY"),
  },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <a
          onClick={() => toggleConfirmModal(row._id)}
          className="mr-3 text-secondary"
        >
          <i className="fa fa-trash mr-3" id={`delete-${row._id}-tooltip`} />

          <UncontrolledTooltip
            placement="top"
            target={`delete-${row._id}-tooltip`}
          >
            Delete
          </UncontrolledTooltip>
        </a>
        <Link
          to={`/background/layer/${row._id}/edit`}
          className="mr-3 text-secondary"
        >
          <i className="far fa-edit mr-3" id={`view-${row._id}-tooltip`} />

          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            Edit
          </UncontrolledTooltip>
        </Link>
      </>
    ),
  },
];

export default ListColumns;
