import Web3 from "web3";
import moment from "moment";
import {ethers, Contract} from "ethers";

// Constant
import {
  getNetworkUrl,
  getContractDetails,
  getContractDetailsFoGov,
} from "helpers/contants";

const MAKE_UNIX_DAYS = (period) => {
  return moment().add(period, "seconds").diff(moment(), "days", true);
};

const MAKE_DAYS_TO_UNIX = (days) => {
  return Math.ceil(Number(days) * 86400);
};

const MAKE_UNIX_TIME = (since, period) => {
  return moment(since, "X").add(period, "seconds").format("X");
};

class Web3Intraction {
  constructor(blockchain, settings) {
    const networkUrl = getNetworkUrl(blockchain || "ethereum", settings);
    console.log(networkUrl, 6456564546);

    this.provider = new ethers.providers.Web3Provider(window.ethereum);
    this.signer = this.provider.getSigner();

    this.settings = settings;
    // if (getGovernanceToken) {
    //   // getContractDetailsFoGov
    //   this.tokenContractSetting = getContractDetailsFoGov(
    //     blockchain || "ethereum",
    //     settings
    //   );
    // } else {
    //   this.tokenContractSetting = getContractDetails(
    //     blockchain || "ethereum",
    //     settings
    //   );
    // }
    // console.log(this.tokenContractSetting, "tokenContractSetting");
    // // this.tokenContractSetting = getContractDetails(
    // //   blockchain || "ethereum",
    // //   settings
    // // );
    if (networkUrl) {
      this.networkUrl = networkUrl;
      this.web3 = new Web3(networkUrl.url);
    }
  }

  isNetworkServiceWorking = () => {
    if (this.web3) {
      return true;
    }

    return false;
  };

  convertPriceToEther = (price) => {
    return Web3.utils.toWei(price.toString(), "ether");
  };

  getTransactionReceipt = (transactionHash, getToken) => {
    return new Promise(async (resolve, reject) => {
      this.contractInterval = setInterval(async () => {
        try {
          let receipt = await this.web3.eth.getTransactionReceipt(
            transactionHash
          );

          console.log("final receipt", receipt);

          if (!!receipt) {
            if (getToken && !!receipt?.logs && !!receipt.logs[0]) {
              receipt.token_id = this.web3.utils.hexToNumberString(
                receipt.logs[0].topics[3]
              );
            }
            clearInterval(this.contractInterval);
            this.contractInterval = null;
            resolve(receipt);
          }
        } catch (error) {
          clearInterval(this.contractInterval);
          this.contractInterval = null;
          reject(error);
        }
      }, 15000);
    });
  };

  getContract = (abi, address) => {
    try {
      let contract;
      if (abi) {
        contract = new this.web3.eth.Contract(JSON.parse(abi), address);
      }

      return contract;
    } catch (error) {
      console.log("error", error);
      return null;
    }
  };

  getContractEthers = (abi, address) => {
    // window.ethereum
    const contract = new Contract(address, JSON.parse(abi), this.signer);
    return contract;
  }

  /* Get Deposit Info */
  getDepositInfo = (walletAddress) => {
    return new Promise(async (resolve, reject) => {
      try {
        const contract = this.getContract(
          this.tokenContractSetting.abi,
          this.tokenContractSetting.contractAddress
        );

        const { _stake, _rewards, _since } = await contract.methods
          .getDepositInfo(walletAddress)
          .call({ from: walletAddress });

        let minHoldTime = await contract.methods.minHoldTime().call();
        minHoldTime = MAKE_UNIX_TIME(_since, minHoldTime);

        let maxHoldTime = await contract.methods.maxHoldTime().call();
        maxHoldTime = MAKE_UNIX_TIME(_since, maxHoldTime);

        let maxReward = await contract.methods.maxReward().call();

        resolve({
          stake: Number(this.web3.utils.fromWei(_stake)),
          rewards: Number(this.web3.utils.fromWei(_rewards)),
          since: _since,
          minHoldTime,
          isMinComplete: moment(minHoldTime, "X").isBefore(),
          maxHoldTime,
          isMaxComplete: moment(maxHoldTime, "X").isBefore(),
          maxReward,
        });
      } catch (err) {
        reject(err);
      }
    });
  };



  eth_sendTransaction = (params, getToken) => {
    return new Promise(async (resolve, reject) => {
      try {
        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [params],
        });

        let receipt = await this.getTransactionReceipt(txHash, getToken);
        resolve({ txHash, receipt });
      } catch (error) {
        console.log("eth_sendTransaction error",error)
        reject(error);
      }
    });
  };

  /* Get Token Info */
  getTokenInfo = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const contract = this.getContract(
          this.tokenContractSetting?.abi,
          this.tokenContractSetting?.contractAddress
        );

        console.log(contract, "this.tokenContractSetting");
        return null;
        let minHoldTime = await contract?.methods?.minHoldTime().call();
        minHoldTime = MAKE_UNIX_DAYS(minHoldTime);

        let maxHoldTime = await contract?.methods.maxHoldTime().call();
        maxHoldTime = MAKE_UNIX_DAYS(maxHoldTime);

        let minReward = await contract?.methods.minReward().call();
        let maxReward = await contract?.methods.maxReward().call();

        let supply = await contract?.methods.totalSupply().call();

        supply = Number(this.web3.utils.fromWei(String(supply || 0)));

        let soldTokens = 0;
        try {
          soldTokens = await contract?.methods.soldTokens().call();
          soldTokens = Number(this.web3.utils.fromWei(soldTokens));
        } catch (err) {}

        resolve({
          minHoldTime,
          minReward,
          maxHoldTime,
          maxReward,
          tokens: supply,
          sold: soldTokens,
          unsold: Number(supply) - Number(soldTokens),
        });
      } catch (err) {
        reject(err);
      }
    });
  };
  getTokenInfoGov = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const contract = this.getContract(
          this.tokenContractSetting?.abi,
          this.tokenContractSetting?.contractAddress
        );

        let setVotingPeriod = await contract?.methods.setVotingPeriod(0).call();
        let setVotingDelay = await contract?.methods.setVotingDelay().call();
        // let setVotingDelay = await contract?.methods.setVotingDelay().call();
        let setProposalThreshold = await contract?.methods
          .setProposalThreshold()
          .call();

        try {
          soldTokens = await contract?.methods.soldTokens().call();
          soldTokens = Number(this.web3.utils.fromWei(soldTokens));
        } catch (err) {}

        resolve({
          setVotingPeriod,
          setProposalThreshold,
          setVotingDelay,
        });
      } catch (err) {
        reject(err);
      }
    });
  };

  // Set token info
  setTokenInfo = (type, fields) => {
    return new Promise(async (resolve, reject) => {
      try {
        const contract = this.getContract(
          this.tokenContractSetting.abi,
          this.tokenContractSetting.contractAddress
        );

        let myAddress = this.settings.walletAddress.publicKey;
        let PRIVATE_KEY = this.settings.walletAddress.privateKey;

        let data;

        switch (type) {
          case "minHoldTime":
            data = contract.methods
              .setMinHoldTime(MAKE_DAYS_TO_UNIX(fields.minHoldTime))
              .encodeABI();
            break;

          case "maxHoldTime":
            data = contract.methods
              .setMaxHoldTime(MAKE_DAYS_TO_UNIX(fields.maxHoldTime))
              .encodeABI();
            break;

          case "minReward":
            data = contract.methods.setMinReward(fields.minReward).encodeABI();
            break;

          case "maxReward":
            data = contract.methods.setMaxReward(fields.maxReward).encodeABI();
            break;

          default:
            throw new Error("Invalid action");
        }

        const transaction1 = {
          to: this.tokenContractSetting.contractAddress,
          from: myAddress,
          data: data,
        };

        let estimateGas = await this.web3.eth.estimateGas(transaction1);

        const transaction = {
          from: myAddress,
          to: this.tokenContractSetting.contractAddress,
          gas: estimateGas,
          data: data,
        };

        const signedTx = await this.web3.eth.accounts.signTransaction(
          transaction,
          PRIVATE_KEY
        );

        this.web3.eth.sendSignedTransaction(
          signedTx.rawTransaction,
          async (error, hash) => {
            if (!error) {
              const receipt = await this.getTransactionReceipt(hash);
              resolve(receipt);
            } else {
              reject(error);
            }
          }
        );
      } catch (err) {
        reject(err);
      }
    });
  };
}

export default Web3Intraction;

export const convertPriceToEther = (price) => {
  return Web3.utils.toWei(price.toString(), "ether");
};

export const convertHexToString = (hex) => {
  return Web3.utils.hexToNumberString(hex);
};

export const convertNumberToHex = (number) => {
  return Web3.utils.numberToHex(Number(number));
};
