
// Crew Members
export const GET_CREW_MEMBERS = "GET_CREW_MEMBERS";
export const GET_CREW_MEMBERS_FAIL = "GET_CREW_MEMBERS_FAIL";
export const GET_CREW_MEMBERS_SUCCESS = "GET_CREW_MEMBERS_SUCCESS";

// Referral Claim History
export const GET_REFERRAL_CLAIM_HISTORY = "GET_REFERRAL_CLAIM_HISTORY";
export const GET_REFERRAL_CLAIM_HISTORY_FAIL = "GET_REFERRAL_CLAIM_HISTORY_FAIL";
export const GET_REFERRAL_CLAIM_HISTORY_SUCCESS = "GET_REFERRAL_CLAIM_HISTORY_SUCCESS";

// Referral Claim History
export const UPDATE_REFERRAL_CLAIM_HISTORY = "UPDATE_REFERRAL_CLAIM_HISTORY";
export const UPDATE_REFERRAL_CLAIM_HISTORY_FAIL = "UPDATE_REFERRAL_CLAIM_HISTORY_FAIL";
export const UPDATE_REFERRAL_CLAIM_HISTORY_SUCCESS = "UPDATE_REFERRAL_CLAIM_HISTORY_SUCCESS";