import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";
import { walletHooks } from "helpers/Wallet/walletHooks";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  Spinner,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import {
  getActivities,
  getFdCategories,
  airdropNFT
} from "store/actions";
import ListColumns from "./ListColumns";
import toastr from "toastr";

const MobileActivityList = ({
  accessLevel,
  history,
  items,
  totalItems,
  onGetActivities,
  onAirdropActivity,
  onPutActivitiesStatus,
  loading,
  ...props
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const categories = useSelector((store) => store?.Categoryes?.fdCategories);
  const { settings } = useSelector((store) => store?.Settings);
  const [filter, setFilter] = useState({
    orderBy: "_id",
    order: -1,
    page: 0,
    limit: 20,
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
    author: id,
    filter: "all"
  });
  const [itemsList, setItemsList] = useState([]);
  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalItems,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    // setItemsList([])
    onGetActivities(filter);
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    dispatch(getFdCategories("new", filter));
  }, []);

  useEffect(() => {
    setItemsList(items.map((item, idx) => ({ ...item, _id: idx })) || []);
  }, [items]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { sortField, sortOrder }) => {
 
    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };
  const handleItemStatusChange = (status) => {
    const callback = () => {
      setSelected([]);
      onGetActivities(filter);
    };

    onPutActivitiesStatus({ _id: selected, status }, callback);
  };

  return (
    <React.Fragment>
      <div className="page-content p-0">
        <Container fluid>

          <Row className="table-row">
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={itemsList || []}
                        columns={ListColumns(
                          history,
                          props.t,
                          settings
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="3">
                                <Input
                                  type="select"
                                  onChange={(e) =>
                                    setFilter((prev) => ({
                                      ...prev,
                                      category_id: e.target.value,
                                    }))
                                  }
                                >
                                  <option value="">All</option>
                                  {categories?.map((o, idx) => (
                                    <option key={idx} value={o._id}>
                                      {o.catName}
                                    </option>
                                  ))}
                                </Input>
                              </Col>

                              <Col sm="2">
                                {!!filter.fields.fieldName ||
                                  (!!filter.search && (
                                    <div
                                      className="mr-4 mb-2"
                                      style={{
                                        height: "calc( 1.5em + 0.94rem + 2px)",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setFilter((prevState) => ({
                                            ...prevState,
                                            fields: {
                                              fieldName: "",
                                              fieldValue: "",
                                            },
                                            search: "",
                                          }));
                                          setSearchText("");
                                        }}
                                        className=""
                                      >
                                        {props.t("clear_filters")}
                                      </Link>
                                    </div>
                                  ))}
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  // selectRow={selectRow({
                                  //   selected,
                                  //   onSelect,
                                  //   onSelectAll,
                                  // })}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

MobileActivityList.propTypes = {
  items: PropTypes.array,
  totalItems: PropTypes.number,
  onGetActivities: PropTypes.func,
};

const mapStateToProps = ({ Activities }) => ({
  loading: Activities.loading,
  items: Activities.items,
  totalItems: Activities.totalActivities,
});

const mapDispatchToProps = (dispatch) => ({
  onGetActivities: (data) => dispatch(getActivities(data)),
  onAirdropActivity: (data, callback) => dispatch(airdropNFT(data, callback))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MobileActivityList))
);
