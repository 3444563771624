import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { walletHooks } from "helpers/Wallet/walletHooks";
import Web3 from "web3";
// web3
import Web3Intraction from "helpers/web3Intraction";
// import Web3IntractionEth from "helpers/web3IntractionEth";
import {
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  Card,
  CardBody,
  Button,
  Form,
  Spinner,
} from "reactstrap";
// Actions
import * as ACTIONS from "store/actions";
import toastr from "toastr";
import AirDropPlotsList from "pages/AirDropPlotsList";
import { plotPrice } from "helpers/contants";
import {
  airDrop,
  airDropV2,
  getItems,
  airDropList,
  putSettings,
  loading,
} from "store/actions";
import {
  getNetworkUrl,
} from "helpers/contants";
const propsAreEqual = (prevProps, nextProps) => {
  console.log(prevProps, nextProps, "prevProps, nextProps");
  if (JSON.stringify(prevProps) == JSON.stringify(nextProps)) {
    return true;
  }
};
const Actions = (props) => {
  const wallet = walletHooks();
  const { settings } = useSelector((state) => state?.Settings);

  const [internalLoader, setinternalLoader] = useState(false);
  const [fields, setFields] = useState({
    tokenUri: "",
    toAddress: "",
  });

  const [filter, setFilter] = useState({
    orderBy: "create_date",
    order: -1,
    page: 0,
    limit: 2000,
    search: "",
    status: "created",
    // role:"ADMIN",
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
  });

  const dispatch = useDispatch();

  useEffect(() => {
    // props.onGetSettings();
    dispatch(getItems(filter));
  }, []);

  const handleChange = (name) => (event) => {
    const { value } = event.target;
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  const onSubmit = async (evt) => {
    evt.preventDefault();
    setinternalLoader(true)
    // Sign and send the transaction
    //   const transaction = {
    //     from: "0x654f9839f110174a86831F849fa2BE7Fb2fC9e2B",
    //     to: '0x654f9839f110174a86831F849fa2BE7Fb2fC9e2B', // Replace with the recipient's address
    //     value: web3.utils.toWei('0.1', 'ether'), // Replace with the amount in BNB
    //   };
    // web3.eth.sendTransaction(transaction)
    // .on('transactionHash', (hash) => {
    //   console.log('Transaction Hash:', hash);
    // })
    // .on('confirmation', (confirmationNumber, receipt) => {
    //   console.log('Confirmation Number:', confirmationNumber);
    //   console.log('Receipt:', receipt);
    // })
    // .on('error', (error) => {
    //   console.error('Error:', error.message);
    // });

    // console.log("fields", fields?.tokenUri)
    if (!wallet?.isActive) {
      setinternalLoader(false);
      return toastr.error("Please Connect To Metamask!")
    };
    if (!fields?.tokenUri) {
      setinternalLoader(false);
      return toastr.error("Please Select NFT")
    };
    let parseData = JSON.parse(fields?.tokenUri);
    // console.log(  parseData?.collection_id?.contractAbi,
    //   parseData?.collection_id?.contractAddress)
    let web3 = new Web3();
    console.log("nft Data", parseData)
    if (!web3.utils.isAddress(fields.toAddress)) {
      setinternalLoader(false);
      return toastr.error("Invalid wallet address");
    }

    const web3Intraction = new Web3Intraction("BNB", settings);
    const networkUrl = await getNetworkUrl("BNB", settings);
    // wallet.connectWallet();

    await wallet.switchChain({ networkUrl });
    // const contract = await web3Intraction?.getContract(
    //   parseData?.collection_id?.contractAbi,
    //   parseData?.collection_id?.contractAddress
    // );
    const contract = await web3Intraction?.getContractEthers(
      parseData?.collection_id?.contractAbi,
      parseData?.collection_id?.contractAddress
    );
    const getData = await contract.owner();

    console.log("contractMethods", parseData?.attributes)
    // console.log("getOwnerData", getData, "walletAccount", wallet?.account)
    let data = {
      type: "BNB",
      collectionId: parseData.collection_id,
      toAddress: web3.utils.toChecksumAddress(fields.toAddress),
      tokenUri: parseData?.token_uri,
      itemId: parseData._id,
      level: parseData?.attributes?.level,
      charge: parseData?.attributes?.charge,
      voucher: parseData.voucher,
      _Shipname: parseData.category_id.catName,
      _Shipcategory: parseData.subcategory_id.catName,
      _MintType: "Airdrop",
      _Level: Number(parseData.attributes.level),
      _Durablity: parseData.attributes.Durablity,
      _Amount: parseData.price
    };
    const callback = (res) => {
      const { status, message } = res;
      if (status == "failure") {
        toastr.error(message);
      } else {
        dispatch(airDropList());
        dispatch(getItems(filter));
        setFields({ tokenUri: "", toAddress: "" });
        toastr.success(message);
      }
    };
    // console.log("APIDATA", data)
    if ((getData.toLowerCase()) != ((wallet?.account).toLowerCase())) {
      const firstPart = getData.slice(0, 5);
      // Extract the last 5 characters
      const lastPart = getData.slice(-5);
      // Create the transformed string
      const transformedString = `${firstPart}****${lastPart}`;
      setinternalLoader(false);
      return toastr.error(`Please Connect Metamask with ${transformedString} account!`);
    }
    console.log("data._Shipname,", data._Shipname)
    if (data._Shipname == "Ships") {
      try {
        console.log("ggg====>", data)
        const transaction = await contract
          .redeem(
            data.toAddress,
            data.voucher,
            data._Shipname,
            data._Shipcategory,
            data._MintType,
            "0",
            data._Level,
            data._Durablity,
          );

        const receipt = await transaction.wait();
        data.hash = receipt.transactionHash;
        setinternalLoader(false);
        dispatch(airDropV2(data, callback));
        // console.log("data1", datas)
      } 
      catch (e) {
        setinternalLoader(false);
        toastr.error("Transaction Failed!")
        console.log("e1", e)
      }
    } 
    else if (data._Shipname == "Others") {
      try {
        const transaction = await contract
          .redeem(
            data.toAddress,
            data.voucher,
            "0",
            data._MintType,
          );
        // Send the transaction
        const receipt = await transaction.wait();
        data.hash = receipt.transactionHash;
        setinternalLoader(false);
        dispatch(airDropV2(data, callback));
      } 
      catch (e) {
        setinternalLoader(false);
        console.log("e1", e)
        toastr.error("Transaction Failed!")
      }
    } 
    else {
      try {
        const transaction = await contract
          .redeem(
            data.toAddress,
            data.voucher,
            "0",
            data._MintType,
            data._Level,
            "0"
          )
        // Send the transaction
        const receipt = await transaction.wait();
        data.hash = receipt.transactionHash;
        setinternalLoader(false);
        dispatch(airDropV2(data, callback));
      } 
      catch (e) {
        setinternalLoader(false);
        console.log("e2", e);
        toastr.error("Transaction Failed!")
      }
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <Card className="mb-3">
            <CardBody className="px-0 py-2">
              <Row>
                <Col md={12}>
                  <React.Fragment>
                    <Form onSubmit={onSubmit}>
                      <Row className="mb-3 mb-sm-2">
                        <Col sm={4} className="pb-3 pb-sm-0">
                          <Label className="">To Address</Label>
                          <Input
                            type="text"
                            className="form-control"
                            value={fields.toAddress}
                            onChange={handleChange("toAddress")}
                            required
                          />
                        </Col>

                        <Col sm={4} className="pb-3 pb-sm-0">
                          <Label className="">NFT</Label>

                          <Input
                            type="select"
                            className="form-control"
                            value={
                              fields?.tokenUri &&
                              JSON.parse(fields?.tokenUri)?.tokenUri
                            }
                            onChange={handleChange("tokenUri")}
                            required
                          >
                            <option value="">Select Nft</option>
                            {props?.items
                              ?.filter((o) => o.visible == "yes")
                              ?.map((o, idx) => (
                                <option value={JSON.stringify(o)} key={idx}>
                                  {o.name}
                                </option>
                              ))}
                          </Input>
                        </Col>
                        <Col sm={4} className=""></Col>
                      </Row>{" "}
                      {props.loading && (
                        <div className="spinner">
                          <Spinner color="primary" className="mr-2" />
                        </div>
                      )}
                      <div className="mt-4">
                        {
                          internalLoader ? (<><button
                            type={"submit"}
                            disabled={internalLoader}
                            className="btn btn-primary w-md"
                          >
                            Submit
                          </button>
                            <p>Please Wait...</p>
                          </>) : (<button
                            type={"submit"}
                            className="btn btn-primary w-md"
                          >
                            Submit
                          </button>)
                        }

                      </div>
                    </Form>

                    <hr className="d-block d-sm-none" />
                  </React.Fragment>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <div className="airdrop_list">
        <AirDropPlotsList />
      </div>
    </React.Fragment>
  );
};

Actions.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({ Settings, collections, items }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  collection: collections.collection,
  items: items.items,
  totalItems: items.totalItems,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(ACTIONS.getSettings()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(memo(Actions, propsAreEqual))
);
