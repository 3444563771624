export const PROMO_CODE_API_FAILED = "PROMO_CODE_API_FAILED"

// PROMO_CODEs
export const GET_PROMO_CODES = "GET_PROMO_CODES"
export const GET_PROMO_CODES_FAIL = "GET_PROMO_CODES_FAIL"
export const GET_PROMO_CODES_SUCCESS = "GET_PROMO_CODES_SUCCESS"

// Add PROMO_CODE
export const ADD_PROMO_CODE = "ADD_PROMO_CODE"
export const ADD_PROMO_CODE_FAIL = "ADD_PROMO_CODE_FAIL"
export const ADD_PROMO_CODE_SUCCESS = "ADD_PROMO_CODE_SUCCESS"

// Get PROMO_CODE
export const GET_PROMO_CODE = "GET_PROMO_CODE"
export const GET_PROMO_CODE_FAIL = "GET_PROMO_CODE_FAIL"
export const GET_PROMO_CODE_SUCCESS = "GET_PROMO_CODE_SUCCESS"

// update PROMO_CODE
export const PUT_PROMO_CODE = "PUT_PROMO_CODE"
export const PUT_PROMO_CODE_FAIL = "PUT_PROMO_CODE_FAIL"
export const PUT_PROMO_CODE_SUCCESS = "PUT_PROMO_CODE_SUCCESS"

// Delete PROMO_CODE
export const DELETE_PROMO_CODE = "DELETE_PROMO_CODE"
export const DELETE_PROMO_CODE_FAIL = "DELETE_PROMO_CODE_FAIL"
export const DELETE_PROMO_CODE_SUCCESS = "DELETE_PROMO_CODE_SUCCESS"

// Update Multi PROMO_CODE Status
export const PUT_PROMO_CODES_STATUS = "PUT_PROMO_CODES_STATUS"
export const PUT_PROMO_CODES_STATUS_FAIL = "PUT_PROMO_CODES_STATUS_FAIL"
export const PUT_PROMO_CODES_STATUS_SUCCESS = "PUT_PROMO_CODES_STATUS_SUCCESS"

// get promo code users
export const GET_PROMO_CODE_USERS = "GET_PROMO_CODE_USERS"
export const GET_PROMO_CODE_USERS_FAIL = "GET_PROMO_CODE_USERS_FAIL"
export const GET_PROMO_CODE_USERS_SUCCESS = "GET_PROMO_CODE_USERS_SUCCESS"
