import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import { BLOCKCHAIN_CURRENCY_CODE } from "helpers/contants";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = ( t) => [
  {
    dataField: "userId.username",
    text: t("Username"),
    formatter: (_, row) => (row.userId?.username ? row.userId?.username : "-"),
  },
  {
    dataField: "crystalAmount",
    text: t("Crystal Amount"),

  },

  {
    dataField: "busdAmount",
    text: t("Busd Amount"),

  },

  {
    dataField: "adminCommission",
    text: t("Commission"),

  },
  {
    dataField: "totalPaid",
    text: t("Total Amount"),

  },

  {
    dataField: "status",
    text: t("Status"),
    formatter: (cellContent, row) => (
      <Badge
        className={
          "text-capitalize font-size-13 badge-soft-"
        }
        color={row.badgeClass}
      >
        {t(row.status)}
      </Badge>
    ),
  },
  {
    dataField: "transactionHash",
    text: t("Transaction Hash"),
    formatter: (_, row) => (row.transactionHash ? <a href={"https://bscscan.com/tx/"+row.transactionHash}>{row.transactionHash}</a> : "-"),
  },
];
export default ListColumns;
