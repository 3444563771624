import {
    GET_CRYSTAL_PURCHASE_HISTORY,
    GET_CRYSTAL_PURCHASE_HISTORY_SUCCESS,
    GET_CRYSTAL_PURCHASE_HISTORY_FAIL,
  } from "./actionTypes";
  
  const INIT_STATE = {
    error: "",
    loading: false,
    crystalHistoryDetail: {},
    crystalHistory: [],
    totalCrystalHistory: 0,
    date: "",
  };
  
  const CrystalPurchaseHistory = (state = INIT_STATE, action) => {
    // console.log(action.payload, "SSS");
    switch (action.type) {
      /* Get Collections */
      case GET_CRYSTAL_PURCHASE_HISTORY:
        return {
          ...state,
          loading: true,
        };
  
      case GET_CRYSTAL_PURCHASE_HISTORY_SUCCESS:
        return {
          ...state,
          crystalHistory: action.payload.data || [],
          totalCrystalHistory: action.payload.total || 0,
          loading: false,
          error: "",
        };
  
      case GET_CRYSTAL_PURCHASE_HISTORY_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };

      default:
        return state;
    }
  };
  
export default CrystalPurchaseHistory;