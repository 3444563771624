import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import Dropzone from "react-dropzone";
import { isObject } from "lodash";

import { Row, Col, FormGroup, FormText, Label, Input } from "reactstrap";
import Instructions from "./Instructions";

// Actions
import { uploadFile } from "store/actions";

// Images
import logo_placeholder from "assets/images/logo-placeholder.png";



const GeneralSettings = (props) => {
  const { fields, handleChange, onUploadFile } = props;


  const [logoselectedFiles, setlogoselectedFiles] = useState([]);

 

  // alert("yaa");
  return (
    <React.Fragment>
      <Row>
        <Col lg={4}>
          <Instructions t={props.t} />
        </Col>

        <Col lg={8}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 1 Points</Label>

                <Input
                  type="number"
                  value={fields?.level1Points}
                  onChange={(e) => {
                    handleChange("level1Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 1 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level1SecondPoints}
                  onChange={(e) => {
                    handleChange("level1SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 2 Points</Label>

                <Input
                  type="number"
                  value={fields?.level2Points}
                  onChange={(e) => {
                    handleChange("level2Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 2 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level2SecondPoints}
                  onChange={(e) => {
                    handleChange("level2SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 3 Points</Label>

                <Input
                  type="number"
                  value={fields?.level3Points}
                  onChange={(e) => {
                    handleChange("level3Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 3 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level3SecondPoints}
                  onChange={(e) => {
                    handleChange("level3SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 4 Points</Label>

                <Input
                  type="number"
                  value={fields?.level4Points}
                  onChange={(e) => {
                    handleChange("level4Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 4 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level4SecondPoints}
                  onChange={(e) => {
                    handleChange("level4SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 5 Points</Label>

                <Input
                  type="number"
                  value={fields?.level5Points}
                  onChange={(e) => {
                    handleChange("level5Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 5 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level5SecondPoints}
                  onChange={(e) => {
                    handleChange("level5SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 6 Points</Label>

                <Input
                  type="number"
                  value={fields?.level6Points}
                  onChange={(e) => {
                    handleChange("level6Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 6 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level6SecondPoints}
                  onChange={(e) => {
                    handleChange("level6SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 7 Points</Label>

                <Input
                  type="number"
                  value={fields?.level7Points}
                  onChange={(e) => {
                    handleChange("level7Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 7 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level7SecondPoints}
                  onChange={(e) => {
                    handleChange("level7SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 8 Points</Label>

                <Input
                  type="number"
                  value={fields?.level8Points}
                  onChange={(e) => {
                    handleChange("level8Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 8 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level8SecondPoints}
                  onChange={(e) => {
                    handleChange("level8SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 9 Points</Label>

                <Input
                  type="number"
                  value={fields?.level9Points}
                  onChange={(e) => {
                    handleChange("level9Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 9 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level9SecondPoints}
                  onChange={(e) => {
                    handleChange("level9SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 10 Points</Label>

                <Input
                  type="number"
                  value={fields?.level10Points}
                  onChange={(e) => {
                    handleChange("level10Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 10 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level10SecondPoints}
                  onChange={(e) => {
                    handleChange("level10SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 11 Points</Label>

                <Input
                  type="number"
                  value={fields?.level11Points}
                  onChange={(e) => {
                    handleChange("level11Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 11 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level11SecondPoints}
                  onChange={(e) => {
                    handleChange("level11SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 12 Points</Label>

                <Input
                  type="number"
                  value={fields?.level12Points}
                  onChange={(e) => {
                    handleChange("level12Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 12 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level12SecondPoints}
                  onChange={(e) => {
                    handleChange("level12SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 13 Points</Label>

                <Input
                  type="number"
                  value={fields?.level13Points}
                  onChange={(e) => {
                    handleChange("level13Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 13 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level13SecondPoints}
                  onChange={(e) => {
                    handleChange("level13SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 14 Points</Label>

                <Input
                  type="number"
                  value={fields?.level14Points}
                  onChange={(e) => {
                    handleChange("level14Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 14 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level14SecondPoints}
                  onChange={(e) => {
                    handleChange("level14SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 15 Points</Label>

                <Input
                  type="number"
                  value={fields?.level15Points}
                  onChange={(e) => {
                    handleChange("level15Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 15 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level15SecondPoints}
                  onChange={(e) => {
                    handleChange("level15SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 16 Points</Label>

                <Input
                  type="number"
                  value={fields?.level16Points}
                  onChange={(e) => {
                    handleChange("level16Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 16 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level16SecondPoints}
                  onChange={(e) => {
                    handleChange("level16SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 17 Points</Label>

                <Input
                  type="number"
                  value={fields?.level17Points}
                  onChange={(e) => {
                    handleChange("level17Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 17 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level17SecondPoints}
                  onChange={(e) => {
                    handleChange("level17SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 18 Points</Label>

                <Input
                  type="number"
                  value={fields?.level18Points}
                  onChange={(e) => {
                    handleChange("level18Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 18 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level18SecondPoints}
                  onChange={(e) => {
                    handleChange("level18SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 19 Points</Label>

                <Input
                  type="number"
                  value={fields?.level19Points}
                  onChange={(e) => {
                    handleChange("level19Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 19 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level19SecondPoints}
                  onChange={(e) => {
                    handleChange("level19SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 20 Points</Label>

                <Input
                  type="number"
                  value={fields?.level20Points}
                  onChange={(e) => {
                    handleChange("level20Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 20 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level20SecondPoints}
                  onChange={(e) => {
                    handleChange("level20SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>


          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 21 Points</Label>

                <Input
                  type="number"
                  value={fields?.level21Points}
                  onChange={(e) => {
                    handleChange("level21Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 21 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level21SecondPoints}
                  onChange={(e) => {
                    handleChange("level21SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 22 Points</Label>

                <Input
                  type="number"
                  value={fields?.level22Points}
                  onChange={(e) => {
                    handleChange("level22Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 22 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level22SecondPoints}
                  onChange={(e) => {
                    handleChange("level22SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 23 Points</Label>

                <Input
                  type="number"
                  value={fields?.level23Points}
                  onChange={(e) => {
                    handleChange("level23Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 23 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level23SecondPoints}
                  onChange={(e) => {
                    handleChange("level23SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 24 Points</Label>

                <Input
                  type="number"
                  value={fields?.level24Points}
                  onChange={(e) => {
                    handleChange("level24Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 24 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level24SecondPoints}
                  onChange={(e) => {
                    handleChange("level24SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 25 Points</Label>

                <Input
                  type="number"
                  value={fields?.level25Points}
                  onChange={(e) => {
                    handleChange("level25Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 25 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level25SecondPoints}
                  onChange={(e) => {
                    handleChange("level25SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 26 Points</Label>

                <Input
                  type="number"
                  value={fields?.level26Points}
                  onChange={(e) => {
                    handleChange("level26Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 26 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level26SecondPoints}
                  onChange={(e) => {
                    handleChange("level26SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 27 Points</Label>

                <Input
                  type="number"
                  value={fields?.level27Points}
                  onChange={(e) => {
                    handleChange("level27Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 27 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level27SecondPoints}
                  onChange={(e) => {
                    handleChange("level27SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 28 Points</Label>

                <Input
                  type="number"
                  value={fields?.level28Points}
                  onChange={(e) => {
                    handleChange("level28Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 28 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level28SecondPoints}
                  onChange={(e) => {
                    handleChange("level28SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 29 Points</Label>

                <Input
                  type="number"
                  value={fields?.level29Points}
                  onChange={(e) => {
                    handleChange("level29Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 29 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level29SecondPoints}
                  onChange={(e) => {
                    handleChange("level29SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 30 Points</Label>

                <Input
                  type="number"
                  value={fields?.level30Points}
                  onChange={(e) => {
                    handleChange("level30Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 30 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level30SecondPoints}
                  onChange={(e) => {
                    handleChange("level30SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>


          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 31 Points</Label>

                <Input
                  type="number"
                  value={fields?.level31Points}
                  onChange={(e) => {
                    handleChange("level31Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 31 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level31SecondPoints}
                  onChange={(e) => {
                    handleChange("level31SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 32 Points</Label>

                <Input
                  type="number"
                  value={fields?.level32Points}
                  onChange={(e) => {
                    handleChange("level32Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 32 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level32SecondPoints}
                  onChange={(e) => {
                    handleChange("level32SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 33 Points</Label>

                <Input
                  type="number"
                  value={fields?.level33Points}
                  onChange={(e) => {
                    handleChange("level33Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 33 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level33SecondPoints}
                  onChange={(e) => {
                    handleChange("level33SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 34 Points</Label>

                <Input
                  type="number"
                  value={fields?.level34Points}
                  onChange={(e) => {
                    handleChange("level34Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 34 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level34SecondPoints}
                  onChange={(e) => {
                    handleChange("level34SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 35 Points</Label>

                <Input
                  type="number"
                  value={fields?.level35Points}
                  onChange={(e) => {
                    handleChange("level35Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 35 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level35SecondPoints}
                  onChange={(e) => {
                    handleChange("level35SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 36 Points</Label>

                <Input
                  type="number"
                  value={fields?.level36Points}
                  onChange={(e) => {
                    handleChange("level36Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 36 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level36SecondPoints}
                  onChange={(e) => {
                    handleChange("level36SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 37 Points</Label>

                <Input
                  type="number"
                  value={fields?.level37Points}
                  onChange={(e) => {
                    handleChange("level37Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 37 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level37SecondPoints}
                  onChange={(e) => {
                    handleChange("level37SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 38 Points</Label>

                <Input
                  type="number"
                  value={fields?.level38Points}
                  onChange={(e) => {
                    handleChange("level38Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 38 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level38SecondPoints}
                  onChange={(e) => {
                    handleChange("level38SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 39 Points</Label>

                <Input
                  type="number"
                  value={fields?.level39Points}
                  onChange={(e) => {
                    handleChange("level39Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 39 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level39SecondPoints}
                  onChange={(e) => {
                    handleChange("level39SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 40 Points</Label>

                <Input
                  type="number"
                  value={fields?.level40Points}
                  onChange={(e) => {
                    handleChange("level40Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 40 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level40SecondPoints}
                  onChange={(e) => {
                    handleChange("level40SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>


          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 41 Points</Label>

                <Input
                  type="number"
                  value={fields?.level41Points}
                  onChange={(e) => {
                    handleChange("level41Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 41 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level41SecondPoints}
                  onChange={(e) => {
                    handleChange("level41SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 42 Points</Label>

                <Input
                  type="number"
                  value={fields?.level42Points}
                  onChange={(e) => {
                    handleChange("level42Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 42 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level42SecondPoints}
                  onChange={(e) => {
                    handleChange("level42SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 43 Points</Label>

                <Input
                  type="number"
                  value={fields?.level43Points}
                  onChange={(e) => {
                    handleChange("level43Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 43 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level43SecondPoints}
                  onChange={(e) => {
                    handleChange("level43SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 44 Points</Label>

                <Input
                  type="number"
                  value={fields?.level44Points}
                  onChange={(e) => {
                    handleChange("level44Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 44 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level44SecondPoints}
                  onChange={(e) => {
                    handleChange("level44SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 45 Points</Label>

                <Input
                  type="number"
                  value={fields?.level45Points}
                  onChange={(e) => {
                    handleChange("level45Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 45 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level45SecondPoints}
                  onChange={(e) => {
                    handleChange("level45SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 46 Points</Label>

                <Input
                  type="number"
                  value={fields?.level46Points}
                  onChange={(e) => {
                    handleChange("level46Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 46 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level46SecondPoints}
                  onChange={(e) => {
                    handleChange("level46SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 47 Points</Label>

                <Input
                  type="number"
                  value={fields?.level47Points}
                  onChange={(e) => {
                    handleChange("level47Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 47 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level47SecondPoints}
                  onChange={(e) => {
                    handleChange("level47SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 48 Points</Label>

                <Input
                  type="number"
                  value={fields?.level48Points}
                  onChange={(e) => {
                    handleChange("level48Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 48 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level48SecondPoints}
                  onChange={(e) => {
                    handleChange("level48SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Level 49 Points</Label>

                <Input
                  type="number"
                  value={fields?.level49Points}
                  onChange={(e) => {
                    handleChange("level49Points")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Level 49 Second Points</Label>

                <Input
                  type="number"
                  value={fields?.level49SecondPoints}
                  onChange={(e) => {
                    handleChange("level49SecondPoints")(e);
                  }}
                  min={0}
                  step={0.01}
                />
              </FormGroup>
            </Col>
          </Row>
        
        </Col>
        
      </Row>
    </React.Fragment>
  );
};

GeneralSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneralSettings)
);
