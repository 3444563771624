import {
  GET_SAIL_HISTORY,
  GET_SAIL_HISTORY_SUCCESS,
  GET_SAIL_HISTORY_FAIL,
  VIEW_SAIL_HISTORY,
  VIEW_SAIL_HISTORY_SUCCESS,
  VIEW_SAIL_HISTORY_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  sailHistoryDetail: [],
  sailHistory: [],
  totalSailHistory: 0,
  date: "",
};

const SailHistory = (state = INIT_STATE, action) => {
  // console.log(action.payload, "SSS");
  switch (action.type) {
    /* Get Collections */
    case GET_SAIL_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case GET_SAIL_HISTORY_SUCCESS:
      return {
        ...state,
        sailHistory: action.payload.data || [],
        totalSailHistory: action.payload.total || 0,
        loading: false,
        error: "",
      };

    case GET_SAIL_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    /* VIEW sail history details */
    case VIEW_SAIL_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case VIEW_SAIL_HISTORY_SUCCESS:
      return {
        ...state,
        sailHistoryDetail: action.payload,
        loading: false,
        error: "",
      };

    case VIEW_SAIL_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default SailHistory;
