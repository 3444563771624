import {
  GET_MOBILE_GAME_HISTORY,
  GET_MOBILE_GAME_HISTORY_SUCCESS,
  GET_MOBILE_GAME_HISTORY_FAIL,

  GET_MOBILE_GAME_ENERGY_HISTORY,
  GET_MOBILE_GAME_ENERGY_HISTORY_SUCCESS,
  GET_MOBILE_GAME_ENERGY_HISTORY_FAIL,

  GET_MOBILE_GAME_LEADERBOARD_HISTORY,
  GET_MOBILE_GAME_LEADERBOARD_HISTORY_SUCCESS,
  GET_MOBILE_GAME_LEADERBOARD_HISTORY_FAIL,

  GET_MOBILE_GAME_DETAIL,
  GET_MOBILE_GAME_DETAIL_SUCCESS,
  GET_MOBILE_GAME_DETAIL_FAIL,

  UPDATE_MOBILE_GAME_LEADERBOARD_POINTS_SUCCESS,
  UPDATE_MOBILE_GAME_LEADERBOARD_POINTS_FAIL,
  UPDATE_MOBILE_GAME_LEADERBOARD_POINTS,
  UPDATE_MOBILE_GAME_HISTORY,
  UPDATE_MOBILE_GAME_HISTORY_SUCCESS,
  UPDATE_MOBILE_GAME_HISTORY_FAIL,
  END_MOBILE_GAME,
  END_MOBILE_GAME_SUCCESS,
  END_MOBILE_GAME_FAIL
} from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  gameHistoryDetail: {},
  gameHistory: [],
  totalGameHistory: 0,
  gameEnergyHistory: [],
  totalGameEnergyHistory: 0,
  gameLeaderboardHistory: [],
  leaderboardDetail: {},
  totalGameLeaderboardHistory: 0,
};

const GameHistory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MOBILE_GAME_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case GET_MOBILE_GAME_HISTORY_SUCCESS:
      return {
        ...state,
        gameHistory: action.payload.data || [],
        totalGameHistory: action.payload.total || 0,
        loading: false,
        error: "",
      };

    case GET_MOBILE_GAME_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

      case GET_MOBILE_GAME_ENERGY_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case GET_MOBILE_GAME_ENERGY_HISTORY_SUCCESS:
      return {
        ...state,
        gameEnergyHistory: action.payload.data || [],
        totalGameEnergyHistory: action.payload.total || 0,
        loading: false,
        error: "",
      };

    case GET_MOBILE_GAME_ENERGY_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
      
      case GET_MOBILE_GAME_LEADERBOARD_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case GET_MOBILE_GAME_LEADERBOARD_HISTORY_SUCCESS:
      return {
        ...state,
        gameLeaderboardHistory: action.payload.data || [],
        totalGameLeaderboardHistory: action.payload.total || 0,
        loading: false,
        error: "",
      };

    case GET_MOBILE_GAME_LEADERBOARD_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };  

      case GET_MOBILE_GAME_DETAIL:
      return {
        ...state,
        loading: true,
      };

    case GET_MOBILE_GAME_DETAIL_SUCCESS:
      return {
        ...state,
        gameHistoryDetail: action.payload.data || {},
        loading: false,
        error: "",
      };

    case GET_MOBILE_GAME_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

      case UPDATE_MOBILE_GAME_LEADERBOARD_POINTS:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_MOBILE_GAME_LEADERBOARD_POINTS_SUCCESS:
      return {
        ...state,
        leaderboardDetail: action.payload.data || {},
        loading: false,
        error: "",
      };

    case UPDATE_MOBILE_GAME_LEADERBOARD_POINTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

      case UPDATE_MOBILE_GAME_HISTORY:
        return {
          ...state,
          loading: true,
        };
  
      case UPDATE_MOBILE_GAME_HISTORY_SUCCESS:
        return {
          ...state,
          gameHistoryDetail: action.payload.data || {},
          loading: false,
          error: "",
        };
  
      case UPDATE_MOBILE_GAME_HISTORY_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
        
        case END_MOBILE_GAME:
          return {
            ...state,
            loading: true,
          };
    
        case END_MOBILE_GAME_SUCCESS:
          return {
            ...state,
            gameHistory: action.payload.data || {},
            loading: false,
            error: "",
          };
    
        case END_MOBILE_GAME_FAIL:
          return {
            ...state,
            error: action.payload,
            loading: false,
          };
    default:
      return state;
  }
};

export default GameHistory;
