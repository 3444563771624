import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import Instructions from "./Instructions";

const SocialMediaSettings = (props) => {
  const { fields, handleChange } = props;

  return (
    <React.Fragment>
      <Row>
        <Col lg={4}>
          <Instructions t={props.t} />
        </Col>

        <Col lg={8}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Instagram</Label>

                <Input
                  type="text"
                  value={fields.instagram}
                  onChange={handleChange("instagram")}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Twitter</Label>

                <Input
                  type="text"
                  value={fields.twitter}
                  onChange={handleChange("twitter")}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Discord</Label>

                <Input
                  type="text"
                  value={fields.discord}
                  onChange={handleChange("discord")}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Telegram</Label>

                <Input
                  type="text"
                  value={fields.telegram}
                  onChange={handleChange("telegram")}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Youtube</Label>

                <Input
                  type="text"
                  value={fields.youtube}
                  onChange={handleChange("youtube")}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

SocialMediaSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SocialMediaSettings)
);
