import { call, put, takeLatest } from "redux-saga/effects";

// Collection Redux States
import {
    GET_NFT_LVL_UPGRADE_HISTORY,
} from "./actionTypes";

import {
    getNftLvlUpgradeHistoryFail,
    getNftLvlUpgradeHistorySuccess,
} from "./actions";

import {
    postGetNFTLvlUpgradeHistory,
} from "helpers/backend_helper";
  

function* getNftLvlUpgradeHistorySaga({ payload, callback }) {
    try {
      const response = yield call(postGetNFTLvlUpgradeHistory, payload);
      callback && callback(response);
      if (response.status == "failure") {
        return yield put(getNftLvlUpgradeHistoryFail(response.message));
      }
      yield put(getNftLvlUpgradeHistorySuccess(response));
    } catch (error) {
      yield put(getNftLvlUpgradeHistoryFail(error));
    }
}


function* NFTLvlUpgradeSaga() {
    yield takeLatest(GET_NFT_LVL_UPGRADE_HISTORY, getNftLvlUpgradeHistorySaga);
}
  
export default NFTLvlUpgradeSaga;