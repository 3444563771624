import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import crystalIcon  from "../../../assets/images/diamond.png";
import tokenIcon  from "../../../assets/images/ppt_token.png";
import usdtIcon  from "../../../assets/images/usdt_icon.png";
const STATUS = {
  "pending":"Pending", 
  "completed":"Completed",
  "rejected":"Rejected"
}
export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (toggleConfirmModal, t) => [
  {
    dataField: "rank",
    text: t("Rank"),
    formatter: (_, row) => (row.rank ? row.rank : 0),
  },
  {
    dataField: "user.username",
    text: t("Username"),
    formatter: (_, row) => (row.user?.username ? row.user?.username : "-"),
  },
  {
    dataField: "user.email",
    text: t("Email"),
    formatter: (_, row) => (row.user?.email ? row.user?.email : "-"),
  },
  {
    dataField: "points",
    text: t("Points"),
    formatter: (_, row) => (row.points ? row.points : 0),
  },
  
  {
    dataField: "prizes",
    text: t("Prizes"),
    formatter: (_, row) => (<div>
      {row.prizes ? row.prizes : 0}
      {row.prizeType === "crystal" && <img className= "img-fluid leaderboard-icon m-2" src={crystalIcon} />}
      {row.prizeType === "token" && <img className= "img-fluid leaderboard-icon m-2" src={tokenIcon} />}
      {row.prizeType === "usdt" && <img className= "img-fluid leaderboard-icon m-2" src={usdtIcon }/>}
    </div>),
  },
  {
    dataField: "weekNo",
    text: t("Week"),
    formatter: (_, row) => (row.weekNo ? row.weekNo : 0),
  },
  // {
  //   dataField: "status",
  //   text: t("Status"),
  //   formatter: (cellContent, row) => (
  //     <Badge
  //       className={
  //         `text-capitalize font-size-13 badge-soft-${row.status}`
  //       }
  //       color={row.badgeClass}
  //     >
  //       {t(STATUS[row.status])}
  //     </Badge>
  //   ),
  // },
  {
    dataField: "date_created_utc",
    text: t("Created Date"),
    sort: true,
    sortValue: (_, row) =>row?.date_created_utc,
    formatter: (_, row) => (row?.date_created_utc ? moment(row?.date_created_utc).format("YYYY-MM-DD HH:mm:ss") : "--"),
  },
  // {
  //   dataField: "completedAt",
  //   text: t("Completed Date"),
  //   sort: true,
  //   sortValue: (_, row) =>row?.completedAt,
  //   formatter: (_, row) => (row?.completedAt ? moment(row?.completedAt).format("YYYY-MM-DD HH:mm:ss") : "--"),
  // },
  // {
  //   dataField: "transactionHash",
  //   text: t("TransactionHash"),
  //   formatter: (_, row) => (row.transactionHash ? row.transactionHash : "-"),
  // },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
      {
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            toggleConfirmModal(row?._id, row?.points);
          }}
          className="mr-3 text-secondary"
        >
            <i className="far fa-edit mr-3" id={`edit-${row._id}-tooltip`} />
            <UncontrolledTooltip
              placement="top"
              target={`edit-${row._id}-tooltip`}
            >
              {t("edit")}
            </UncontrolledTooltip>
          </Link>
        }
      </>
    ),
  },
];

export default ListColumns;
