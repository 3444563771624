import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import { Row, Col, FormGroup, FormText, Label, Input, Button, Spinner } from "reactstrap";
import Instructions from "./Instructions";

// Actions
import { getSettings, putMobileSettings } from "store/actions";

const LvlUpgradeSettings = (props) => {
  const { onGetSettings, onPutSettings, loading, error } = props;


  const [fields, setFields] = useState({
    pirateLevelUpPrice: {
      "level1": 0,
      "level2": 0,
      "level3": 0,
      "level4": 0,
      "level5": 0
    },
    shipLevelUpPrice: {
      "level2" : 0,
			"level3" : 0,
			"level4" : 0,
			"level5" : 0,
			"level6" : 0,
			"level7" : 0,
			"level8" : 0,
			"level9" : 0,

			"level11" : 0,
			"level12" : 0,
			"level13" : 0,
			"level14" : 0,
			"level15" : 0,
			"level16" : 0,
			"level17" : 0,
			"level18" : 0,
			"level19" : 0,

			"level21" : 0,
			"level22" : 0,
			"level23" : 0,
			"level24" : 0,
			"level25" : 0,
			"level26" : 0,
			"level27" : 0,
			"level28" : 0,
			"level29" : 0,

			"level31" : 0,
			"level32" : 0,
			"level33" : 0,
			"level34" : 0,
			"level35" : 0,
			"level36" : 0,
			"level37" : 0,
			"level38" : 0,
			"level39" : 0,

			"level41" : 0,
			"level42" : 0,
			"level43" : 0,
			"level44" : 0,
			"level45" : 0,
			"level46" : 0,
			"level47" : 0,
			"level48" : 0,
			"level49" : 0,
    }
  });


  useEffect(() => {
    onGetSettings();
  }, []);


  useEffect(() => {
    if (props.settings) {
      setFields((prevState) => ({
        ...prevState,
        nftLevelUpgradeTimeInHours: props.settings?.mobileAppSetting?.nftLevelUpgradeTimeInHours,
        pirateLevelUpPrice: props.settings?.mobileAppSetting?.pirateLevelUpPrice,
        shipLevelUpPrice: props.settings?.mobileAppSetting?.shipLevelUpPrice,
      }));
    }
  }, [JSON.stringify(props?.settings)]);

  const handleChange = (name) => (event) => {
    setFields((prevFields) => ({ 
      ...prevFields,
      [name]: Number(event?.target?.value) 
    }));
  };
  const handleInnerChange = (name1) => (name) => (event) => {
    console.log("handleInnerChange ---->", name1, name, event);
    setFields((prevFields) => ({ 
      ...prevFields, 
      [name1]: { 
        ...prevFields[name1], 
        [name]: Number(event?.target?.value) 
      } 
    }));
  };

  function submit() {
    onPutSettings({
      mobileAppSetting: {
        ...props.settings?.mobileAppSetting,
        "nftLevelUpgradeTimeInHours": fields.nftLevelUpgradeTimeInHours,
        "pirateLevelUpPrice": fields.pirateLevelUpPrice,
        "shipLevelUpPrice": fields.shipLevelUpPrice,
      }
    });
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col lg={4}>
            <Instructions t={props.t} />
          </Col>

          <Col lg={12}>
            <Row className="p-5">

              <Col md={6}>
                  <FormGroup>
                    <Label>NFT Upgrade Time (in hours)</Label>

                    <Input
                      type="number"
                      value={fields?.nftLevelUpgradeTimeInHours}
                      onChange={(e) => {
                        handleChange("nftLevelUpgradeTimeInHours")(e);
                      }}
                      min={0}
                      step={1}
                    />
                  </FormGroup>
              </Col>

              <Label className="text-size-large label mb-3 mt-3">Pirate NFT Upgrade Prices</Label>

              <Col md={6}>
                  <FormGroup>
                    <Label>Level 2</Label>

                    <Input
                      type="number"
                      value={fields?.pirateLevelUpPrice?.level2}
                      onChange={(e) => {
                        handleInnerChange('pirateLevelUpPrice')("level2")(e);
                      }}
                      min={0}
                      step={1}
                    />
                  </FormGroup>
              </Col>

              <Col md={6}>
                  <FormGroup>
                    <Label>Level 3</Label>

                    <Input
                      type="number"
                      value={fields?.pirateLevelUpPrice?.level3}
                      onChange={(e) => {
                        handleInnerChange('pirateLevelUpPrice')("level3")(e);
                      }}
                      min={0}
                      step={1}
                    />
                  </FormGroup>
              </Col>

              <Col md={6}>
                  <FormGroup>
                    <Label>Level 4</Label>

                    <Input
                      type="number"
                      value={fields?.pirateLevelUpPrice?.level4}
                      onChange={(e) => {
                        handleInnerChange('pirateLevelUpPrice')("level4")(e);
                      }}
                      min={0}
                      step={1}
                    />
                  </FormGroup>
              </Col>

              <Col md={6}>
                  <FormGroup>
                    <Label>Level 5</Label>

                    <Input
                      type="number"
                      value={fields?.pirateLevelUpPrice?.level5}
                      onChange={(e) => {
                        handleInnerChange('pirateLevelUpPrice')("level5")(e);
                      }}
                      min={0}
                      step={1}
                    />
                  </FormGroup>
              </Col>

              <Label className="mb-3 mt-3 text-size-large label">Ship NFT Upgrade Prices</Label>
            
              <Col md={6}>
                <FormGroup>
                  <Label>Level 2</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level2}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level2")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
            
              <Col md={6}>
                <FormGroup>
                  <Label>Level 3</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level3}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level3")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
            
              <Col md={6}>
                <FormGroup>
                  <Label>Level 4</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level4}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level4")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 5</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level5}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level5")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
        
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 6</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level6}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level6")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
              
              <Col md={6}>
                <FormGroup>
                  <Label>Level 7</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level7}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level7")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 8</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level8}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level8")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
            
              <Col md={6}>
                <FormGroup>
                  <Label>Level 9 Points</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level9}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level9")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
        
              <Col md={6}>
                <FormGroup>
                  <Label>Level 11</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level11}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level11")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 12</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level12}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level12")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 13 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level13}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level13")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 14</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level14}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level14")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
            
              <Col md={6}>
                <FormGroup>
                  <Label>Level 15</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level15}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level15")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 16</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level16}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level16")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
        
              <Col md={6}>
                <FormGroup>
                  <Label>Level 17</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level17}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level17")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
        
              <Col md={6}>
                <FormGroup>
                  <Label>Level 18</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level18}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level18")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
            
              <Col md={6}>
                <FormGroup>
                  <Label>Level 19</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level19}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level19")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>

          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 21 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level21}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level21")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 22 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level22}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level22")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
        
              <Col md={6}>
                <FormGroup>
                  <Label>Level 23</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level23}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level23")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 24</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level24}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level24")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 25</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level25}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level25")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
            
              <Col md={6}>
                <FormGroup>
                  <Label>Level 26</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level26}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level26")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 27</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level27}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level27")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
            
              <Col md={6}>
                <FormGroup>
                  <Label>Level 28</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level28}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level28")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
        
              <Col md={6}>
                <FormGroup>
                  <Label>Level 29</Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level29}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level29")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>

    
              <Col md={6}>
                <FormGroup>
                  <Label>Level 31 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level31}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level31")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 32 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level32}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level32")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
        
              <Col md={6}>
                <FormGroup>
                  <Label>Level 33 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level33}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level33")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 34 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level34}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level34")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
            
              <Col md={6}>
                <FormGroup>
                  <Label>Level 35 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level35}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level35")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 36 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level36}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level36")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
            
              <Col md={6}>
                <FormGroup>
                  <Label>Level 37 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level37}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level37")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 38 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level38}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level38")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 39 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level39}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level39")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>

          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 41 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level41}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level41")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 42 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level42}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level42")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
            
              <Col md={6}>
                <FormGroup>
                  <Label>Level 43 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level43}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level43")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
        
              <Col md={6}>
                <FormGroup>
                  <Label>Level 44 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level44}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level44")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 45 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level45}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level45")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
      
              <Col md={6}>
                <FormGroup>
                  <Label>Level 46 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level46}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level46")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 47 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level47}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level47")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
          
              <Col md={6}>
                <FormGroup>
                  <Label>Level 48 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level48}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level48")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>
        
              <Col md={6}>
                <FormGroup>
                  <Label>Level 49 </Label>

                  <Input
                    type="number"
                    value={fields?.shipLevelUpPrice?.level49}
                    onChange={(e) => {
                      handleInnerChange('shipLevelUpPrice')("level49")(e);
                    }}
                    min={0}
                    step={1}
                  />
                </FormGroup>
              </Col>

          <Col md={6}></Col>

          <Col className="d-flex justify-content-end">
            {loading && <Spinner color="primary" className="mr-2" />}

            <Button className="buttoncolor" onClick={submit}>
              Save Changes
            </Button>
          </Col>
        </Row>
          
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

LvlUpgradeSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onPutSettings: (data) => dispatch(putMobileSettings(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LvlUpgradeSettings)
);