import React, { useEffect, useState } from "react";
import { Container } from 'react-bootstrap'
import { NavLink } from 'reactstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Profile from '../../pages/HomeProfile/UserProfile';
import UserActivity from '../../pages/UserActivity';
import LuckyPirateHistory from '../../pages/HomeProfile/LuckyPirateHistory';
import MatchHistory from '../../pages/HomeProfile/MatchHistory';
import TournamentHistory from '../../pages/HomeProfile/TournamentHistory';
import NFTHistory from '../../pages/HomeProfile/NFTHistory';
import SailHistory from "../../pages/HomeProfile/SailHistory/List";
import IPHistory from "../../pages/HomeProfile/IPHistory";
import Breadcrumbs from "components/Common/Breadcrumb2";

const Header = (props) => {
     let auth = localStorage.getItem("authUser");
     let adminRole = JSON.parse(auth)?.data?.role;
     console.log("adminRole", adminRole);
     const [selectedTab, setSelectedTab] = useState('Profile');
     return (
          <>
               <Breadcrumbs
                    title="User Profile"
                    breadcrumbItem={"User Profile"}
                    breadcrumbItems={[{ title: "Users", link: "/users" }, { title: "Profile" },]}
               />

               <section className='header_main'>
                    <Container fluid>
                         <Tabs
                              defaultActiveKey="Profile"
                              id="uncontrolled-tab-example"
                              className="menu_main mb-3"
                              onSelect={(res) => setSelectedTab(res)}
                         >
                              <Tab eventKey="Profile" title="Profile" />
                              <Tab eventKey="Activities" title="Wallet Activities" />
                              <Tab eventKey="NFT History" title="NFT History" />
                              <Tab eventKey="Lucky Pirate History" title="Lucky Pirate History" />
                              <Tab eventKey="Sail History" title="Sail History" />
                              <Tab eventKey="Match History" title="Match History" />
                              <Tab eventKey="Tournament History" title="Tournament History" />
                              <Tab eventKey="IP History" title="IP History" />
                              {/* <Tab eventKey="Rewards" title="Rewards" />
                              <Tab eventKey="Statistics" title="Statistics" /> */}
                         </Tabs>
                         {selectedTab === 'Profile' && <Profile {...props} />}
                         {selectedTab === 'Activities' && <UserActivity {...props} />}
                         {selectedTab === 'Lucky Pirate History' && <LuckyPirateHistory {...props} />}
                         {selectedTab === 'NFT History' && <NFTHistory {...props} />}
                         {selectedTab === "Sail History" && <SailHistory {...props} />}
                         {adminRole !== "SUBADMIN" && selectedTab === 'Match History' && <MatchHistory {...props} />}
                         {adminRole !== "SUBADMIN" && selectedTab === 'Tournament History' && <TournamentHistory {...props} />}
                         {adminRole !== "SUBADMIN" && selectedTab === "IP History" && <IPHistory {...props} />}
                    </Container>
               </section>
          </>

     )
}
export default Header