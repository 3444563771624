import {
  PROMO_CODE_API_FAILED,
  GET_PROMO_CODES,
  GET_PROMO_CODES_FAIL,
  GET_PROMO_CODES_SUCCESS,
  ADD_PROMO_CODE,
  ADD_PROMO_CODE_FAIL,
  ADD_PROMO_CODE_SUCCESS,
  GET_PROMO_CODE,
  GET_PROMO_CODE_FAIL,
  GET_PROMO_CODE_SUCCESS,
  PUT_PROMO_CODE,
  PUT_PROMO_CODE_FAIL,
  PUT_PROMO_CODE_SUCCESS,
  DELETE_PROMO_CODE,
  DELETE_PROMO_CODE_FAIL,
  DELETE_PROMO_CODE_SUCCESS,
  GET_PROMO_CODE_USERS,
  GET_PROMO_CODE_USERS_FAIL,
  GET_PROMO_CODE_USERS_SUCCESS,
} from "./actionTypes"

// export const apiFailedCustom = error => ({
//   type: PROMO_CODE_API_FAILED,
//   payload: error,
// })

/* Get promos */
export const getPromoCodes = data => ({
  type: GET_PROMO_CODES,
  payload: data,
})

export const getPromoCodesSuccess = promoCodes => ({
  type: GET_PROMO_CODES_SUCCESS,
  payload: promoCodes,
})

export const getPromoCodesFail = error => ({
  type: GET_PROMO_CODES_FAIL,
  payload: error,
})
/* Get promos END */

/* Add Promo */
export const addPromoCode = (promoCode, history) => ({
  type: ADD_PROMO_CODE,
  payload: { promoCode, history },
})

export const addPromoCodeFail = error => ({
  type: ADD_PROMO_CODE_FAIL,
  payload: error,
})

export const addPromoCodeSuccess = () => ({
  type: ADD_PROMO_CODE_SUCCESS,
})
/* Add Promo END */

/* Get Promo */
export const getPromoCode = id => ({
  type: GET_PROMO_CODE,
  payload: id,
})

export const getPromoCodeFail = error => ({
  type: GET_PROMO_CODE_FAIL,
  payload: error,
})

export const getPromoCodeSuccess = promoCode => ({
  type: GET_PROMO_CODE_SUCCESS,
  payload: promoCode,
})
/* Get promo END */

/* Update promo */
export const putPromoCode = (data, history) => ({
  type: PUT_PROMO_CODE,
  payload: { data, history },
})

export const putPromoCodeFail = error => ({
  type: PUT_PROMO_CODE_FAIL,
  payload: error,
})

export const putPromoCodeSuccess = () => ({
  type: PUT_PROMO_CODE_SUCCESS,
})
/* Update Promo END */

/* Delete Promo */
export const deletePromoCode = (data, callback) => ({
  type: DELETE_PROMO_CODE,
  payload: { data, callback },
})

export const deletePromoCodeFail = error => ({
  type: DELETE_PROMO_CODE_FAIL,
  payload: error,
})

export const deletePromoCodeSuccess = () => ({
  type: DELETE_PROMO_CODE_SUCCESS,
})
/* Delete Promo END */

/* Get promo code users */
export const getPromoCodeUsers = (data, callback) => ({
  type: GET_PROMO_CODE_USERS,
  payload: { data, callback },
})

export const getPromoCodeUsersFail = error => ({
  type: GET_PROMO_CODE_USERS_FAIL,
  payload: error,
})

export const getPromoCodeUsersSuccess = (data) => ({
  type: GET_PROMO_CODE_USERS_SUCCESS,
  payload: data
})
/* Get promo code users END */
