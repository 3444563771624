

export const ADD_OPERATORS = "ADD_OPERATORS";
export const ADD_OPERATORS_FAIL = "ADD_OPERATORS_FAIL";
export const ADD_OPERATORS_SUCCESS = "ADD_OPERATORS_SUCCESS";


export const GET_OPERATORS = "GET_OPERATORS";
export const GET_OPERATORS_FAIL = "GET_OPERATORS_FAIL";
export const GET_OPERATORS_SUCCESS = "GET_OPERATORS_SUCCESS";


export const UPDATE_OPERATORS = "UPDATE_OPERATORS";
export const UPDATE_OPERATORS_FAIL = "UPDATE_OPERATORS_FAIL";
export const UPDATE_OPERATORS_SUCCESS = "UPDATE_OPERATORS_SUCCESS";


export const REMOVE_OPERATORS = "REMOVE_OPERATORS";
export const REMOVE_OPERATORS_FAIL = "REMOVE_OPERATORS_FAIL";
export const REMOVE_OPERATORS_SUCCESS = "REMOVE_OPERATORS_SUCCESS";

