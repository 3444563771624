import {
    GET_NOTIFICATION_LIST,
    GET_NOTIFICATION_LIST_SUCCESS,
    GET_NOTIFICATION_LIST_FAIL,
    SEND_NOTIFICATION,
    SEND_NOTIFICATION_FAIL,
    SEND_NOTIFICATION_SUCCESS
} from "./actionTypes";


const INIT_STATE = {
    error: "",
    loading: false,
    message: "",
    notifications: [],
    total: 0,
};


const Notifications = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_NOTIFICATION_LIST:
        return {
          ...state,
          loading: true,
        };
  
      case GET_NOTIFICATION_LIST_SUCCESS:
        return {
          ...state,
          notifications: action.payload.data || [],
          total: action.payload.total || 0,
          loading: false,
          error: "",
        };
  
      case GET_NOTIFICATION_LIST_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      
      case SEND_NOTIFICATION:
          return {
            ...state,
            loading: true,
          };
    
        case SEND_NOTIFICATION_SUCCESS:
          return {
            ...state,
            loading: false,
            message: action.payload,
            error: "",
          };
    
        case SEND_NOTIFICATION_FAIL:
          return {
            ...state,
            error: action.payload,
            loading: false,
          };  
    
        default:
        return state;
  }
};

export default Notifications;