import * as CONST from "./actionTypes";

const INIT_STATE = {
    crewMembers: [],
    nftList: [],
    totalCount: 0,
    referralClaims: [],
    totalClaimCount: 0,
    referralClaimDetail: {},
    error: "",
    keys_error: "",
    loading: false,
    keys_loading: false,
};


const CrewMembers = (state = INIT_STATE, action) => {
    switch (action.type) {

        /* Get Crew Members */
        case CONST.GET_CREW_MEMBERS:
            return {
                ...state,
                loading: true,
            };

        case CONST.GET_CREW_MEMBERS_SUCCESS:
            return {
                ...state,
                crewMembers: action.payload.data || [],
                nftList: action.payload?.data?.items || [],
                totalCount: action.payload.total || 0,
                loading: false,
            };

        case CONST.GET_CREW_MEMBERS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        case CONST.GET_REFERRAL_CLAIM_HISTORY:
            return {
                ...state,
                loading: true,
            };

        case CONST.GET_REFERRAL_CLAIM_HISTORY_SUCCESS:
            return {
                ...state,
                referralClaims: action.payload.data || [],
                totalClaimCount: action.payload.total || 0,
                loading: false,
            };

        case CONST.GET_REFERRAL_CLAIM_HISTORY_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        case CONST.UPDATE_REFERRAL_CLAIM_HISTORY:
            return {
                ...state,
                loading: true,
            };

        case CONST.UPDATE_REFERRAL_CLAIM_HISTORY_SUCCESS:
            return {
                ...state,
                referralClaimDetail: action.payload,
                totalCount: action.payload.total || 0,
                loading: false,
            };

        case CONST.UPDATE_REFERRAL_CLAIM_HISTORY_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export default CrewMembers;