export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"

export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL"

export const REMOVE_WALLET_ADDRESS = "REMOVE_WALLET_ADDRESS"
export const REMOVE_WALLET_ADDRESS_SUCCESS = "REMOVE_WALLET_ADDRESS_SUCCESS"
export const REMOVE_WALLET_ADDRESS_FAIL = "REMOVE_WALLET_ADDRESS_FAIL"

export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS"
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS"
export const UPDATE_USER_STATUS_FAIL = "UPDATE_USER_STATUS_FAIL"