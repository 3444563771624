export const BALANCE_API_FAIL = "BALANCE_API_FAIL";

// Add Balance

export const ADD_BALANCE = "ADD_BALANCE";
export const ADD_BALANCE_FAIL = "ADD_BALANCE_FAIL";
export const ADD_BALANCE_SUCCESS = "ADD_BALANCE_SUCCESS";

export const REMOVE_BALANCE = "REMOVE_BALANCE";
export const REMOVE_BALANCE_FAIL = "REMOVE_BALANCE_FAIL";
export const REMOVE_BALANCE_SUCCESS = "REMOVE_BALANCE_SUCCESS";

export const GET_BALANCE_HISTORY = "GET_BALANCE_HISTORY";
export const GET_BALANCE_HISTORY_FAIL = "GET_BALANCE_HISTORY_FAIL";
export const GET_BALANCE_HISTORY_SUCCESS = "GET_BALANCE_HISTORY_SUCCESS";


export const GET_USER_BALANCE = "GET_USER_BALANCE";
export const GET_USER_BALANCE_FAIL = "GET_USER_BALANCE_FAIL";
export const GET_USER_BALANCE_SUCCESS = "GET_USER_BALANCE_SUCCESS";


export const GET_ACTIVITY_HISTORY = "GET_ACTIVITY_HISTORY";
export const GET_ACTIVITY_HISTORY_FAIL = "GET_ACTIVITY_HISTORY_FAIL";
export const GET_ACTIVITY_HISTORY_SUCCESS = "GET_ACTIVITY_HISTORY_SUCCESS";