import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import FormButton from "components/Common/FormButtons";
import mime from "mime-types";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
  FormText,
} from "reactstrap";

// Images
import avatar4 from "assets/images/logo-placeholder.png";
import logoFile from "assets/images/logo-file.png";

import {
  addDocument,
  getDocument,
  putDocument,
  uploadFile,
} from "store/actions";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";

const AddDocument = ({
  history,
  error,
  onAddDocument,
  onGetDocument,
  onPutDocument,
  onUploadFile,
  document,
  loading,
  ...props
}) => {
  const { id } = useParams();
  const didMountRef = useRef(null);

  const [fields, setFields] = useState({
    title: "",
    logo: "",
    file: "",
    status: "active",
  });
  const [selectedFiles, setselectedFiles] = useState({});

  useEffect(() => {
    if (id) {
      onGetDocument(id);
    }
  }, [id]);

  useEffect(() => {
    if (id && document) {
      try {
        setFields({
          title: document.title || "",
          logo: document.logo?._id,
          file: document.file?._id,
          status: document.status || "active",
        });

        if (document.logo && document.logo.link) {
          setselectedFiles((prevState) => ({
            ...prevState,
            logo: { preview: document.logo.link },
          }));
        }

        if (document.file && document.file.link) {
          setselectedFiles((prevState) => ({
            ...prevState,
            file: { preview: document.file.link },
          }));
        }
      } catch (err) {
        console.log("parsing error ", err);
      }
    }
  }, [id, JSON.stringify(document)]);

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const uploadFileSuccess = (name) => (response) => {
    const { _id: image } = response.data;

    setFields((prevState) => ({ ...prevState, [name]: image }));
  };

  function handleAcceptedFiles(name, _files) {
    if (id) return;

    const files = _files?.filter((file) => file.size < 101288000);

    if (files.length < _files.length) {
      return toastr.error(props.t("Max. upload file size: 100MB"));
    }

    onUploadFile({ image: files[0] }, uploadFileSuccess(name));

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setselectedFiles((prevState) => ({ ...prevState, [name]: files[0] }));
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (loading) return;

    if (id) {
      onPutDocument({ _id: document._id, ...fields }, history);
    } else {
      onAddDocument({ ...fields }, history);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Documents"
            breadcrumbItem={
              (id ? props.t("edit") : props.t("add")) +
              " " +
              props.t("document")
            }
            breadcrumbItems={[
              { title: props.t("documents"), link: "/documents" },
              { title: id ? props.t("edit") : props.t("add") },
            ]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form onSubmit={onSubmit} className="spinner-content">
                    {error && typeof error === "string" ? (
                      <Alert color="danger">{error}</Alert>
                    ) : null}

                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Title")}
                          </Label>
                          <Input
                            type="text"
                            id="formrow-firstname-Input"
                            value={fields.title}
                            onChange={handleChange("title")}
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-profile-image-Input">
                            {props.t("Logo")}
                          </Label>

                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles("logo", acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => {
                              const imageFile = selectedFiles.logo;

                              return (
                                <div
                                  className="dropzone-single-image avatar-xl"
                                  {...getRootProps()}
                                >
                                  <input
                                    {...getInputProps()}
                                    accept="image/*"
                                    id="formrow-profile-image-Input"
                                    multiple={false}
                                  />

                                  <img
                                    className="rounded avatar-xl"
                                    alt={""}
                                    src={
                                      !!imageFile ? imageFile.preview : avatar4
                                    }
                                  />

                                  <div className="edit">
                                    <i className="bx bx-pencil"></i>
                                  </div>
                                </div>
                              );
                            }}
                          </Dropzone>

                          <FormText>Max. upload file size: 5MB</FormText>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-profile-image-Input">
                            {props.t("Document")}
                          </Label>

                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles("file", acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => {
                              const imageFile = selectedFiles.file;

                              let file = !!fields.file
                                ? fields.file
                                : !!imageFile
                                ? imageFile.preview
                                : avatar4;

                              const mimeType = mime.lookup(file);
                              let isFile = false;

                              if (!!mimeType) {
                                isFile = mimeType?.split("/")[0] !== "image";
                              }
                              if (isFile) {
                                file = logoFile;
                              }

                              return (
                                <div
                                  className="dropzone-single-image avatar-xl"
                                  {...getRootProps()}
                                >
                                  <input
                                    {...getInputProps()}
                                    accept="application/pdf,application/vnd.ms-excel"
                                    id="formrow-profile-image-Input"
                                    multiple={false}
                                  />

                                  <img
                                    className="rounded avatar-xl"
                                    alt=""
                                    src={file}
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = logoFile;
                                    }}
                                  />

                                  <div className="edit">
                                    <i className="bx bx-pencil"></i>
                                  </div>
                                </div>
                              );
                            }}
                          </Dropzone>

                          <FormText>Max. upload file size: 5MB</FormText>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>{props.t("status")}</Label>

                          <div className="status-switch square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              switch="none"
                              checked={fields.status == "active"}
                              onChange={() => {
                                const value =
                                  fields.status == "active"
                                    ? "inactive"
                                    : "active";

                                handleChange("status")({ target: { value } });
                              }}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label={props.t("active")}
                              data-off-label={props.t("inactive")}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormButton goBack={() => history.goBack()} />

                    {loading && (
                      <div className="spinner">
                        <Spinner color="primary" />
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddDocument.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  document: PropTypes.object,
  onAddDocument: PropTypes.func,
  onGetDocument: PropTypes.func,
  onPutDocument: PropTypes.func,
};

const mapStateToProps = ({ documents, File }) => ({
  error: documents.error,
  loading: File.loading || documents.loading,
  document: documents.document,
});

const mapDispatchToProps = (dispatch) => ({
  onAddDocument: (data, history) => dispatch(addDocument(data, history)),
  onGetDocument: (id) => dispatch(getDocument(id)),
  onPutDocument: (data, history) => dispatch(putDocument(data, history)),
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddDocument))
);
