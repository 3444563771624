import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Alert
import Alert from "./Alert/reducer";

// Authentication
import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Register from "./auth/register/reducer";
import Settings from "./Settings/reducer";
import customers from "./customers/reducer";
import Logs from "./logs/reducer";

import Stake from "./stake/reducer";

import Operators from "./operators/reducer";


// Files
import File from "./File/reducer";

// Faqs
import documents from "./document/reducer";

// Content Page
import content_page from "./contentpage/reducer";
import collections from "./collection/reducer";
import Activities from "./activity/reducer";
// Faqs
import faqs from "./faq/reducer";

// nft items
import items from "./items/reducer";
import Categoryes from "./categoryes/reducer";

import promoCodes from "./promo/reducer";
import Game from "./game/reducer";
import TournamentHistory from "./gameHistory/reducer";
import MatchHistory from "./matchHistory/reducer";
import AddBalance from "./AddBalance/reducer";
import Leaderboard from "./leaderboard/reducer";
import SailHistory from "./sailHistory/reducer";
import Dashboard from "./dashboard/reducer";
import CrewCode from "./crewCode/reducer";
import BonusHistory from "./bonusHistory/reducer";
import CrystalPurchaseHistory from "./crystalPuchase/reducer";
import GameHistory from "./mobilegameHistory/reducer";

import NFTUpgradeHistory from "./nftLvlUpgradeHistory/reducer";

import NFTRepairHistory from "./nftRepairHistory/reducer";

import Languages from "./language/reducer";
import Notifications from "./notification/reducer";
import IPHistory from "./ipHistory/reducer";

const rootReducer = combineReducers({
  // public
  collections,
  Layout,
  Activities,
  Alert,
  Login,
  Stake,
  content_page,
  ForgetPassword,
  Profile,
  Register,
  Settings,
  customers,
  File,
  documents,
  faqs,
  items,
  Categoryes,
  promoCodes,
  Game,
  TournamentHistory,
  MatchHistory,
  AddBalance,
  Leaderboard,
  Operators,
  Logs,
  SailHistory,
  Dashboard,
  CrewCode,
  BonusHistory,
  CrystalPurchaseHistory,
  GameHistory,
  NFTUpgradeHistory,
  NFTRepairHistory,
  Languages,
  Notifications,
  IPHistory
});

export default rootReducer;