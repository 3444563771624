import {
  GET_SAIL_HISTORY,
  GET_SAIL_HISTORY_SUCCESS,
  GET_SAIL_HISTORY_FAIL,
  VIEW_SAIL_HISTORY,
  VIEW_SAIL_HISTORY_SUCCESS,
  VIEW_SAIL_HISTORY_FAIL,
} from "./actionTypes";


/* Get collections */
export const getSailHistory = (data) => ({
  type: GET_SAIL_HISTORY,
  payload: data,
});

export const getSailHistorySuccess = (sails) => ({
  type: GET_SAIL_HISTORY_SUCCESS,
  payload: sails,
});

export const getSailHistoryFail = (error) => ({
  type: GET_SAIL_HISTORY_FAIL,
  payload: error,
});

export const viewSailHistory = (data) => ({
  type: VIEW_SAIL_HISTORY,
  payload: data,
});

export const viewSailHistorySuccess = (Match) => ({
  type: VIEW_SAIL_HISTORY_SUCCESS,
  payload: Match,
});

export const viewSailHistoryFail = (error) => ({
  type: VIEW_SAIL_HISTORY_FAIL,
  payload: error,
});