import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE, CHANGE_PASSWORD, REMOVE_WALLET_ADDRESS, UPDATE_USER_STATUS } from "./actionTypes"
import {
  profileSuccess,
  profileError,
  putChangePwdSuccess,
  putChangePwdError,
  removeWalletAddressSuccess,
  removeWalletAddressError,
  updateUserStatusSuccess,
  updateUserStatusError
} from "./actions"

//Include Both Helper File with needed methods
import { putProfile, putChangePassword, removeWalletAddress, updateUserStatus } from "helpers/backend_helper"

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(putProfile, user)
    if (response.status === "failure") {
      return yield put(profileError(response.message))
    }

    yield put(profileSuccess(response))
  } catch (error) {
    yield put(profileError("Internal Server Error"))
  }
}

function* changePassword({ payload: { data, callback } }) {
  try {
    const response = yield call(putChangePassword, data)
    if (response.status === "failure") {
      return yield put(putChangePwdError(response.message))
    }

    callback && callback()
    yield put(putChangePwdSuccess(response))
  } catch (error) {
    yield put(putChangePwdError("Internal Server Error"))
  }
}

function* onRemoveWalletAddress({ payload: { data, callback } }) {
  try {
    const response = yield call(removeWalletAddress, data)
    callback && callback(response)
    if (response.status === "failure") {
      return yield put(removeWalletAddressError(response.message))
    }

    yield put(removeWalletAddressSuccess(response))
  } catch (error) {
    yield put(removeWalletAddressError("Internal Server Error"))
  }
}

function* updateUserStatusSaga({ payload: { data, callback } }) {
  try {
    console.log("data", data);
    const response = yield call(updateUserStatus, data);
    callback && callback(response);
    if (response.status === "failure") {
      return yield put(updateUserStatusError(response.message));
    }

    yield put(updateUserStatusSuccess(response));
  } catch (error) {
    yield put(updateUserStatusError("Internal Server Error"));
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(CHANGE_PASSWORD, changePassword)
  yield takeEvery(REMOVE_WALLET_ADDRESS, onRemoveWalletAddress)
  yield takeEvery(UPDATE_USER_STATUS, updateUserStatusSaga)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
