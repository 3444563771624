import { call, put, retry, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "store/actions";
import * as API from "helpers/backend_helper";

import { updateToken } from "helpers/api_helper";
import { updateTokenGame } from "helpers/game_api_helper";
function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(API.postLogin, {
      email: user.email,
      password: user.password,
    });

    if (response.status == "failure") {
      return yield put(ACTIONS.apiError(response.message));
    }
    
    console.log(JSON.stringify(response));
    localStorage.setItem("authUser", JSON.stringify(response));
    updateToken(response.data.Token);
    updateTokenGame(response.data.Token);
    yield put(ACTIONS.loginSuccess(response));
    history.push("/dashboard");
    window.location.reload();
  } catch (error) {
    yield put(ACTIONS.apiError(error));
  }
}

function* currentUser() {
  try {
    const response = yield call(API.getCurrentUser);

    if (response.status == "failure") {
      return yield put(ACTIONS.currentUserFail(response.message));
    }

    yield put(ACTIONS.currentUserSuccess(response));
  } catch (error) {
    yield put(ACTIONS.currentUserFail("Server Error"));
  }
}

function* logoutUser({ payload: { history, role } }) {
  try {
    localStorage.removeItem("authUser");
    yield put(ACTIONS.logoutUserSuccess());
    history.push("/login");
  } catch (error) {
    yield put(ACTIONS.apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(TYPES.LOGIN_USER, loginUser);
  yield takeEvery(TYPES.GET_CURRENT_USER, currentUser);
  yield takeEvery(TYPES.LOGOUT_USER, logoutUser);
}

export default authSaga;
