import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  Spinner,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import {
  getItems,
  deleteItem,
  putItemsStatus,
  deployItem,
  getFdCategories,
  tempAirdropNft,
  updateItemsPrices,
  removeMultipleItems
} from "store/actions";
import ListColumns, { selectRow } from "./ListColumns";
import ConfirmModal from "./ConfirmModal";
import AirdropModal from "./AirdropModal";
import PriceModal from "./PriceModal"
import toastr from "toastr";
const Items = ({
  accessLevel,
  history,
  items,
  totalItems,
  onGetItems,
  onDeleteItem,
  onPutItemsStatus,
  onUpdateItemPrices,
  onDeleteItems,
  loading,
  onDeployItem,
  onAirdropItem,
  ...props
}) => {

  const dispatch = useDispatch();
  const categories = useSelector((store) => store?.Categoryes?.fdCategories);
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  });
  const [selected, setSelected] = useState([]);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    itemId: null,
  });
  const [confirmModal2, setConfirmModal2] = useState({
    isOpen: false,
    itemId: null,
  });
  const [priceModal, setPriceModal] = useState({
    isOpen: false,
    items: [],
  });
  const [filter, setFilter] = useState({
    orderBy: "create_date",
    order: -1,
    page: 0,
    limit: 20,
    search: "",
    searchToken: "",
    // role:"ADMIN",
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
  });
  const [searchText, setSearchText] = useState("");
  const [searchToken, setSearchToken] = useState("");
  const [itemsList, setItemsList] = useState([]);
  const [airdropAddress, setAirdropAddress] = useState("");
  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalItems,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    dispatch(getFdCategories("new", filter));
  }, []);

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canBlock: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
      canDelete: false,
      canBlock: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;

        case "DELETE":
          data.canDelete = item.value;
          break;

        case "BLOCK":
          data.canBlock = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    onGetItems(filter);
    setItemsList([]);
  }, [onGetItems, JSON.stringify(filter)]);

  useEffect(() => {
    setItemsList(items || []);
  }, [items]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder, searchToken }) => {
    console.log("type", type)
    if (type === "search") {
      setSearchText(searchText.trim());
    }


    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "create_date";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (item) => {
    setConfirmModal((prevState) => ({
      isOpen: !prevState.isOpen,
      itemId: item._id,
      name: item.name,
    }));
  };

  const toggleConfirmModal2 = (item) => {
    setConfirmModal2((prevState) => ({
      isOpen: !prevState.isOpen,
      itemId: item._id,
      name: item.name,
    }));
  };

  const airdropConfirm = () => {
    const { itemId, isOpen } = confirmModal2;

    const onComplete = (success, res) => {
      if (success?.status !== "failure") {
        onGetItems(filter);
        toastr.success("NFT airdroped successfully.", {
          duration: 5000,
        });
      } else {
        toastr.error(success.message);
      }
    };

    setConfirmModal2({ isOpen: !isOpen, itemId: null });
    onAirdropItem({ itemId: itemId, walletAddress: airdropAddress }, onComplete);
  };

  const deployConfirm = () => {
    const { itemId, isOpen } = confirmModal;

    const onComplete = (success, res) => {
      if (success?.status !== "failure") {
        onGetItems(filter);
        toastr.success("Item Delete successfully.", {
          duration: 5000,
        });
      } else {
        toastr.error(success.message);
      }
    };

    setConfirmModal({ isOpen: !isOpen, itemId: null });
    onDeleteItem(itemId, onComplete);
  };

  const onStatusChange = (value) => {
    const fieldName = !!value ? "status" : "";
    const fieldValue = value;

    setFilter((prevState) => ({
      ...prevState,
      fields: { fieldName, fieldValue },
    }));
  };

  const onWalletAddressChange = (event) => {
    let value = event?.target?.value
    setAirdropAddress(value);
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText.trim() }));
  };

  const onSearchToken = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, searchToken: searchToken }));
  };

  const onSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected((prevSelected) => [...prevSelected, row._id]);
    } 
    else {
      setSelected((prevSelected) => prevSelected.filter((x) => x !== row._id));
    }
  };

  const onSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r._id);
    if (isSelect) {
      setSelected(ids);
    } else {
      setSelected([]);
    }
  };

  const handleItemStatusChange = (status) => {
    const callback = () => {
      setSelected([]);
      onGetItems(filter);
    };

    onPutItemsStatus({ _id: selected, status }, callback);
  };

  const handleRemoveSelected = () => {
    const callback = () => {
      setSelected([]);
      onGetItems(filter);
    };
    onDeleteItems({ itemIds: selected }, callback);
  }

  const handlePriceChange =(item,  i)=> (event) => {
    let value = event?.target?.value
    setPriceModal((prevState) => ({
      ...prevState,
      items: [...prevState.items.slice(0, i), {
        ...item, price: parseFloat(value) 
      },...prevState.items.slice(i + 1) ]
    }));
   
  };

  const togglePriceModal = (items) => {
    const itemsWithMatchingId = itemsList.filter(item => items.includes(item._id));
    setPriceModal((prevState) => ({
      isOpen: !prevState.isOpen,
      items: itemsWithMatchingId,
    }));
  }

  const onClose = () => {
    setPriceModal((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  }

  const onPriceChangeSubmit = () => {
    const callback = () => {
      setSelected([]);
      setPriceModal({ isOpen: false, items: [] })
      onGetItems(filter);
      toastr.success("Prices updated successfully!")
    };

    onUpdateItemPrices({ items: priceModal.items }, callback);
  };


  return (
    <React.Fragment>
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        name={confirmModal?.name}
        toggle={toggleConfirmModal}
        onConfirm={deployConfirm}
        type="deploy"
      />

      <AirdropModal
        isOpen={confirmModal2.isOpen}
        name={confirmModal2?.name}
        toggle={toggleConfirmModal2}
        handleChange={onWalletAddressChange}
        onConfirm={airdropConfirm}
        type="airdrop"
      />

      <PriceModal
        isOpen={priceModal.isOpen}
        toggle={togglePriceModal}
        handleChange={handlePriceChange}
        onConfirm={onPriceChangeSubmit}
        items={priceModal.items}
        onClose={onClose}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Admin Items"
            breadcrumbItem={props.t("Admin Items")}
            breadcrumbItems={[{ title: props.t("Admin Items") }]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={itemsList || []}
                        columns={ListColumns(
                          history,
                          toggleConfirmModal,
                          toggleConfirmModal2,
                          accesses,
                          props.t
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            {/* {console.log(toolkitProps, "<<=====toolkitProps")} */}
                            <Row className="mb-2">
                              <Col sm="3">
                                <SearchInput
                                  {...toolkitProps.searchProps}

                                  triggerSearch={onSearch}
                                  placeholder={props.t("Search name...")}
                                  searchText={searchText}
                                />
                                <SearchInput
                                  {...toolkitProps.searchProps}
                                  onSearch={(value) => setSearchToken(value)}
                                  triggerSearch={onSearchToken}
                                  placeholder={props.t("Search tokenID...")}
                                  searchText={searchToken}
                                />
                              </Col>

                              <Col sm="3">
                                <Input
                                  type="select"
                                  onChange={(e) =>
                                    setFilter((prev) => ({
                                      ...prev,
                                      category_id: e.target.value,
                                    }))
                                  }
                                >
                                  <option value="" >All</option>
                                  {categories?.map((o, idx) => (
                                    <option key={idx} value={o._id}>
                                      {o.catName}
                                    </option>
                                  ))}
                                </Input>
                              </Col>

                              <Col sm="3">
                                <Input
                                  type="select"
                                  onChange={(e) =>
                                    setFilter((prev) => ({
                                      ...prev,
                                      receiptStatus: e.target.value,
                                    }))
                                  }
                                >
                                  <option value="" >Select Mint Status</option>
                                  <option value="success">Success</option>
                                  <option value="process">Process</option>
                                  <option value="pending">Pending</option>
                                </Input>
                              </Col>

                              <Col sm="2">
                                {!!filter.fields.fieldName ||
                                  (!!filter.search || !!filter.searchToken && (
                                    <div
                                      className="mr-4 mb-2"
                                      style={{
                                        height: "calc( 1.5em + 0.94rem + 2px)",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setFilter((prevState) => ({
                                            ...prevState,
                                            fields: {
                                              fieldName: "",
                                              fieldValue: "",
                                            },
                                            search: "",
                                            searchToken: ""
                                          }));
                                          setSearchText("");
                                          setSearchToken("");
                                        }}
                                        className=""
                                      >
                                        {props.t("clear_filters")}
                                      </Link>
                                    </div>
                                  ))}
                              </Col>
                            </Row>

                            {(accesses.canEdit || accesses.canDelete) && (
                              <AnimateHeight
                                duration={500}
                                height={selected.length > 0 ? "auto" : 0}
                              >
                                <Row className="pb-2">
                                  {accesses.canEdit && (
                                    <Col sm="2">
                                      <div className="form-group row">
                                        <label className="col-md-5 pr-0 col-form-label">
                                          {props.t("action")}:
                                        </label>

                                        <div className="col-md-7">
                                        <button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          togglePriceModal(selected)
                                        }
                                        
                                      >
                                        {props.t("Change Price")}{" "}
                                        {/* {props.t("selected")} */}
                                      </button>
                                        </div>
                                      </div>
                                    </Col>
                                  )}

                                  {accesses.canDelete && (
                                    <Col sm="2">
                                      <button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          handleRemoveSelected()
                                        }
                                      >
                                        {props.t("delete")}{" "}
                                        {props.t("selected")}
                                      </button>
                                    </Col>
                                  )}

                                  <Col></Col>
                                </Row>
                              </AnimateHeight>
                            )}

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  selectRow={selectRow({
                                    selected,
                                    onSelect,
                                    onSelectAll,
                                  })}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Items.propTypes = {
  items: PropTypes.array,
  totalItems: PropTypes.number,
  onGetItems: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onPutItemsStatus: PropTypes.func,
  onAirdropItem:PropTypes.func,
  onUpdateItemPrices: PropTypes.func,
  onDeleteItems:  PropTypes.func,
};

const mapStateToProps = ({ items }) => ({
  loading: items.loading,
  items: items.items,
  totalItems: items.totalItems,
});

const mapDispatchToProps = (dispatch) => ({
  onGetItems: (data) => dispatch(getItems(data)),
  onDeleteItem: (data, callback) =>
    dispatch(deleteItem(data, callback, "itemId")),
  onDeleteItems: (data, callback) =>
    dispatch(removeMultipleItems(data, callback)),
  onDeployItem: (data, callback) => dispatch(deployItem(data, callback)),
  onPutItemsStatus: (data, callback) =>
    dispatch(putItemsStatus(data, callback)),
  onAirdropItem: (data, callback) => dispatch(tempAirdropNft(data,callback)),
  onUpdateItemPrices: (data, callback) => dispatch(updateItemsPrices(data, callback))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Items))
);
