import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

import SearchInput from "components/Common/SearchInput";

//i18n
import { withTranslation } from "react-i18next";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import { getPromoCodes, deletePromoCode, getPromoCodeUsers } from "store/actions";
import ListColumns, { selectRow } from "./ListColumns";
import ConfirmModal from "./ConfirmModal";
import ViewModel from "./ViewModel";

const PromoCodes = ({
  accessLevel,
  history,
  promoCodes,
  totalPromoCodes,
  onGetPromoCodes,
  onDeletePromoCode,
  onGetPromoCodeUsers,
  promoCodeUsers,
  loading,
  ...props
}) => {
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  });
  const [selected, setSelected] = useState([]);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    promoCodeId: null,
  });
  const [usedPromoModal, setUsedPromoModal] = useState({
    isOpen: false,
    promoCodeId: null,
  });
  const [filter, setFilter] = useState({
    orderBy: "date_created_utc",
    order: -1,
    page: 0,
    limit: 20,
    search: "",
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
  });
  const [searchText, setSearchText] = useState("");
  const [promoCodesList, setPromoCodesList] = useState([]);
  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalPromoCodes,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };
  const { SearchBar } = Search;

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canBlock: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
      canDelete: false,
      canBlock: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;

        case "DELETE":
          data.canDelete = item.value;
          break;

        case "BLOCK":
          data.canBlock = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    onGetPromoCodes(filter);
    setPromoCodesList(promoCodes);
  }, [onGetPromoCodes, JSON.stringify(filter)]);

  useEffect(() => {
    setPromoCodesList(promoCodes || []);
  }, [promoCodes]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText.trim());
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (promoCodeId) => {
    setConfirmModal((prevState) => ({ isOpen: !prevState.isOpen, promoCodeId }));
  };

  const toggleUsedPromoModel = (promoCodeId) => {
    setUsedPromoModal((prevState) => ({ isOpen: !prevState.isOpen, promoCodeId }));
  };

  const removeConfirm = () => {
    const { promoCodeId, isOpen } = confirmModal;

    const onComplete = () => {
      onGetPromoCodes(filter);
    };

    setConfirmModal({ isOpen: !isOpen, promoCodeId: null });
    onDeletePromoCode({ _id: promoCodeId }, onComplete);
  };

  const onStatusChange = (value) => {
    const fieldName = !!value ? "status" : "";
    const fieldValue = value;

    setFilter((prevState) => ({
      ...prevState,
      fields: { fieldName, fieldValue },
    }));
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText.trim(), page: 1 }));
  };

  const onSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected((prevSelected) => [...prevSelected, row._id]);
    } else {
      setSelected((prevSelected) => prevSelected.filter((x) => x !== row._id));
    }
  };

  const onSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r._id);
    if (isSelect) {
      setSelected(ids);
    } else {
      setSelected([]);
    }
  };

  return (
    <React.Fragment>
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />

      <ViewModel
        isOpen={usedPromoModal.isOpen}
        toggle={toggleUsedPromoModel}
        promoCodeId={usedPromoModal.promoCodeId || ""}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Promo Codes"
            breadcrumbItem={props.t("promoCodes")}
            breadcrumbItems={[{ title: props.t("promo Codes") }]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={promoCodesList || []}
                        columns={ListColumns(
                          history,
                          toggleConfirmModal,
                          toggleUsedPromoModel,
                          accesses,
                          props.t
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row>
                              <Col
                                className="mb-3"
                                xs={12}
                                md={7}
                                lg={8}
                                xl={7}
                              >
                                {/* <div className="custom-flex-sm d-flex align-items-center">
                                  <div className="filter">
                                    <div className="form-group d-flex align-items-center mb-0">
                                      <label
                                        className="mb-0"
                                        style={{ minWidth: "54px" }}
                                      >
                                        {props.t("filter_by")}:
                                      </label>

                                      <div className="w-auto pl-3">
                                        <select
                                          className="custom-select w-100"
                                          onChange={(e) =>
                                            onStatusChange(e.target.value)
                                          }
                                          value={filter.fields.fieldValue}
                                          style={{ minWidth: "106px" }}
                                        >
                                          <option value="">
                                            {props.t("status")}
                                          </option>
                                          <option value="active">
                                            {props.t("active")}
                                          </option>
                                          <option value="inactive">
                                            {props.t("inactive")}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="search d-flex align-items-center">
                                    <div className="pl-3">
                                      <SearchInput
                                        {...toolkitProps.searchProps}
                                        triggerSearch={onSearch}
                                        placeholder={props.t("search")}
                                        searchText={searchText}
                                      />
                                    </div>

                                    {!!filter.fieldName ||
                                      (!!filter.search && (
                                        <div
                                          className="mr-4"
                                          style={{
                                            minWidth: "73px",
                                          }}
                                        >
                                          <Link
                                            to="#"
                                            onClick={(e) => {
                                              e.preventDefault()
                                              setFilter((prevState) => ({
                                                ...prevState,
                                                fields: {
                                                  fieldName: "",
                                                  fieldValue: "",
                                                },
                                                search: "",
                                              }))
                                              setSearchText("")
                                            }}
                                            className=""
                                          >
                                            {props.t("clear_filters")}
                                          </Link>
                                        </div>
                                      ))}
                                  </div>
                                </div> */}
                              </Col>

                              <Col
                                className="mb-3"
                                xs={12}
                                md={5}
                                lg={4}
                                xl={5}
                              >
                                <div className="text-sm-left text-md-right">
                                  {accesses.canAdd && (
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded waves-effect waves-light mb-2 mr-2"
                                      onClick={() => history.push("/promo-codes/add")}
                                    >
                                      <i className="mdi mdi-plus mr-1" />
                                      {props.t("Add Promo Code")}
                                    </Button>
                                  )}
                                </div>
                              </Col>
                            </Row>

                            {(accesses.canEdit || accesses.canDelete) && (
                              <AnimateHeight
                                duration={500}
                                height={selected.length > 0 ? "auto" : 0}
                              >
                                <div className="custom-flex-xs d-flex align-items-center mb-3">
                                  {accesses.canEdit && (
                                    <div className="filter form-group d-flex align-items-center mb-0 mr-3">
                                      <label
                                        className="mb-0"
                                        style={{ minWidth: "48px" }}
                                      >
                                        {props.t("action")}:
                                      </label>

                                    </div>
                                  )}

                                 
                                </div>
                              </AnimateHeight>
                            )}

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

PromoCodes.propTypes = {
  promoCodes: PropTypes.array,
  totalPromoCodes: PropTypes.number,
  onGetPromoCodes: PropTypes.func,
  onDeletePromoCode: PropTypes.func,
};

const mapStateToProps = ({ Settings, promoCodes }) => ({
  settings: Settings.settings,
  loading: promoCodes.loading,
  promoCodes: promoCodes.promocodes,
  totalPromoCodes: promoCodes.totalPromoCodes,
  promoCodeUsers: promoCodes.promoCodeUsers
});

const mapDispatchToProps = (dispatch) => ({
  onGetPromoCodes: (data) => dispatch(getPromoCodes(data)),
  onDeletePromoCode: (data, callback) => dispatch(deletePromoCode(data, callback)),
  onGetPromoCodeUsers: (data, callback) => dispatch(getPromoCodeUsers(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PromoCodes))
);
