import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip, img } from "reactstrap";
import moment from "moment-timezone";
import pptToken from "../../assets/images/ppt_token.png"
const STATUS = {
  "pending":"Pending", 
  "done":"Completed"
}

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = ( t) => [
  {
    dataField: "userDetails.username",
    text: t("Username"),
    formatter: (_, row) => (row.userDetails?.username ? row.userDetails?.username : "-"),
  },
  {
    dataField: "userDetails.email",
    text: t("Email"),
    formatter: (_, row) => (row.userDetails?.email ? row.userDetails?.email : "-"),
  },
  {
    dataField: "itemDetails.name",
    text: t("NFT"),
    formatter: (_, row) => (row.itemDetails?.name ? row.itemDetails?.name : "-"),
  },
  {
    dataField: "prevLevel",
    text: t("Prev. Level"),
    formatter: (_, row) => (row.prevLevel ? row.prevLevel: 0),
  },
  {
    dataField: "newLevel",
    text: t("New Level"),
    formatter: (_, row) => (row.newLevel ? row.newLevel: 0),
  },
  {
    dataField: "charges",
    text: t("PPT Charged"),
    formatter: (_, row) => (row.charges ? row.charges : 0),
  },
  {
    dataField: "status",
    text: t("Status"),
    formatter: (cellContent, row) => (
      <Badge
        className={
          `text-capitalize font-size-13 badge-soft-${row.status}`
        }
        color={row.badgeClass}
      >
        {t(STATUS[row.status])}
      </Badge>
    ),
  },
  {
    dataField: "upgradeTime",
    text: t("Upgrade Time"),
    sort: true,
    sortValue: (_, row) =>row?.upgradeTime,
    formatter: (_, row) => (row?.upgradeTime ? moment(row?.upgradeTime).format("YYYY-MM-DD HH:mm:ss") : "--"),
  },
  {
    dataField: "date_created_utc",
    text: t("Created Date"),
    sort: true,
    sortValue: (_, row) =>row?.date_created_utc,
    formatter: (_, row) => (row?.date_created_utc ? moment(row?.date_created_utc).format("YYYY-MM-DD HH:mm:ss") : "--"),
  },
];

export default ListColumns;
