import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import Toastr from "toastr";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
    Spinner,
    Button,
    InputGroup,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import { addMultipleItems, uploadFile, removeFile, getFdCategories, uploadMultipleFile } from "store/actions";
import { post } from "../../../helpers/api_helper";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { GET_COLLECTIONS } from "../../../helpers/url_helper";
import avatar4 from "assets/images/users/avatar-9.jpg";
import { withTranslation } from "react-i18next";
import toastr from "toastr";
import { acceptOnlyNumber, levelFilter, makeNFTWithJimp } from "helpers/contants";
import "moment-timezone";

let categories = {};
const AddMultipleSameItem = ({
    collections,
    onAddMultipleItems,
    onUploadFile,
    onUploadMultipleFiles,
    onRemoveFile,
    onGetItems,
    onGetFdCategories,
    fdCategories,
    error,
    errorItem,
    loading,
    ...props
}) => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const [fields, setFields] = useState({
        current_owner: obj.data._id,
        author_id: obj.data._id,
        blockchain: "BNB",
        subCatName: "",
        category_id: "",
        catName: "Pirates",
        subcategory_id: "",
        collection_id: "",
        status: "created",
        saleType: "offer",
        level: 1,
        isNotOthers: false,
        visible: "yes",
        description: "",
        price: "",
    });
    const viewData = {
        current_owner: obj.data._id,
        author_id: obj.data._id,
        tempImageName: "",
        name: "",
        blockchain: "BNB",
        subCatName: fields.subCatName,
        category_id: fields.category_id,
        catName: fields.catName,
        subcategory_id: fields.subcategory_id,
        collection_id: fields.collection_id,
        media: null,
        mediapreview: null,
        thumb: null,
        thumbpreview: null,
        status: "created",
        minted_start_date: null,
        minted_end_date: null,
        saleType: "offer",
        files: [],
        image: null,
        visible: "yes",
        description: "",
        level: 1,
        price: "",
    }
    const [viewsData, setViewsData] = useState([viewData]);
    const [filter, setFilter] = useState({
        storeTypeId: "",
        orderBy: "sortOrder",
        order: 1,
        page: 1,
        limit: 1000,
        search: "",
        fields: [
            {
                fieldName: "",
                fieldValue: "",
            },
        ],
    });
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [thumb, setThumb] = useState(false);
    const [fdCategoriesList, setFdCategoriesList] = useState([]);
    const [collectionList, getCollectionList] = useState([]);

    useEffect(() => {
        onGetFdCategories("new", filter);
    }, [JSON.stringify(filter)]);

    useEffect(() => {
        if (fdCategories) {
            setFdCategoriesList(fdCategories);
        }
    }, [fdCategories]);

    useEffect(() => {
        let formData = {
            orderBy: "date_created_utc",
            order: -1,
            page: 0,
            limit: 500,
            search: "",
            role: "ADMIN",
            fields: [
                {
                    fieldName: "productId",
                    fieldValue: null,
                },
                {
                    fieldName: "",
                    fieldValue: "",
                },
            ],
        };
        (async () => {
            let res = await makeNFTWithJimp();
        })();

        const headerToken = {
            Authorization: accessToken,
        };
        post(GET_COLLECTIONS, formData, JSON.stringify(headerToken))
            .then((res) => {
                getCollectionList(res.data || []);
            })
            .catch((err) => {
                setLoader(false);
            });
    }, []);

    useEffect(() => {
        if (fdCategoriesList.length > 0) {
            const cat = fdCategoriesList?.find((category) => category.catName == "Others");
            if (cat._id == fields.category_id) {
                setFields((prev) => ({
                    ...prev,
                    isNotOthers: false
                }))
            }
            else {
                setFields((prev) => ({
                    ...prev,
                    isNotOthers: true
                }))
            }
        }
    }, [fdCategoriesList, fields.category_id])

    const uploadFileSuccess = (response, type, index, fileName) => {
        setLoader(false);
        console.log("uploadFileSuccess fileName ---->", fileName);
        console.log("uploadFileSuccess type ---->", type);
        console.log("uploadFileSuccess index  ---->", index);

        if (type && type == "thumb") {
            const updatedViewsData = [...viewsData];
            updatedViewsData[index] = {
                ...updatedViewsData[index],
                thumb: response.data._id,
                thumbpreview: response.data.link,
            };
            setViewsData(updatedViewsData);
        }
        else {
            console.log("updatedViewsData before--->", viewsData)
            const updatedViewsData = [...viewsData];

            updatedViewsData[index] = {
                ...updatedViewsData[index],
                media: response.data._id,
                mediapreview: response.data.link,
                tempImageName: fileName
            };
            console.log("updatedViewsData", updatedViewsData[index])
            setViewsData(updatedViewsData);
        }
    };

    const uploadMultipleFilesSuccess = (response, files) => {
        setLoader(false);
        console.log("uploadMultipleFilesSuccess response ---->", response);

        const updatedViewsData = [];

        response.data.forEach((responseData, i) => {
            const updatedView = {
                ...viewsData[0],
                media: responseData._id,
                mediapreview: responseData.link,
                tempImageName: files[i].name
            };
            updatedViewsData.push(updatedView);
        });
        setViewsData(updatedViewsData);

    };

    function handleAcceptedFiles(files, index) {
        setLoader(true);
        console.log("handleAcceptedFiles ---->", files, index);
        const fileName = files[0]?.name;
        console.log("fileName ---->", fileName.split(".")[0]);
        const ext = files[0].name.split(".").pop();
        try {
            if (files[0] && files[0].size >= 5000000) {
                Toastr.error("Please upload a file smaller than 5 MB");
                setLoader(false);
                return false;
            }
            if (
                // ext !== "mp3" &&
                ext !== "png" &&
                ext !== "jpg" &&
                ext !== "jpeg" &&
                // ext !== "wav" &&
                ext !== "ogg" &&
                ext !== "gif"
            ) {
                toastr.error("Only .jpg, .jpeg, .png, extension allow!!");
                setLoader(false);
                return;
            }

            if (viewsData?.[index]?.files.length > 0) {
                if (viewsData?.[index]?.image) {
                    onRemoveFile({ _id: viewsData?.[index]?.image });

                    const updatedViewsData = [...viewsData];
                    updatedViewsData[index] = {
                        ...updatedViewsData[index],
                        image: null,
                    };
                    setViewsData(updatedViewsData);
                }
            }

            const updatedViewsData = [...viewsData];
            updatedViewsData[index] = {
                ...updatedViewsData[index],
                image: files[0],
                tempImageName: fileName
            };
            setViewsData(updatedViewsData);
            onUploadFile({ image: files[0] }, (res) =>
                uploadFileSuccess(res, null, index, fileName)
            );
        }
        catch (error) {
            console.log(error, "<<=======err");
            setLoader(false);
        }
    }

    function handleMultipleFiles(e) {
        console.log("e ---->", e);
        setLoader(true);
        const files = Array.from(e.target.files);
        console.log("handleMultipleFiles ---->", files);
        try {
            console.log("files count ---->", files?.length);
            if (files && files.length > 0) {
                const updatedViewsData = [...viewsData];
                for (var i = 0; i < files.length; i++) {
                    const fileName = files[i]?.name;
                    console.log("fileName ---->", fileName.split(".")[0]);
                    const ext = files[0].name.split(".").pop();
                    if (files[i] && files[i].size >= 8000000) {
                        Toastr.error("Please upload a file smaller than 8 MB");
                        setLoader(false);
                        return false;
                    }
                    if (ext !== "png" && ext !== "jpg" && ext !== "jpeg" && ext !== "ogg" && ext !== "gif") {
                        toastr.error("Only .jpg, .jpeg, .png, extension allow!!");
                        setLoader(false);
                        return;
                    }
                }
                setViewsData(updatedViewsData);
                onUploadMultipleFiles({ image: files }, (res) =>
                    uploadMultipleFilesSuccess(res, files)
                );
            }
        }
        catch (error) {
            console.log(error, "<<=======err");
            setLoader(false);
        }
    }

    function handleAcceptedThumbFiles(files, index) {
        setLoader(true);
        const ext = files[0].name.split(".").pop();
        try {
            if (files[0] && files[0].size >= 10e6) {
                Toastr.error("Please upload a file smaller than 10 MB");
                setLoader(false);
                return false;
            }
            if (ext !== "png" && ext !== "jpg" && ext !== "jpeg") {
                toastr.error("Only .png,.jpg,.jpeg, extension allow!!");
                setLoader(false);
                return;
            }

            if (viewsData?.[index]?.files?.length > 0) {
                if (viewsData?.[index]?.image) {
                    onRemoveFile({ _id: viewsData?.[index]?.image });

                    const updatedViewsData = [...viewsData];
                    updatedViewsData[index] = {
                        ...updatedViewsData[index],
                        image: null
                    };
                    setViewsData(updatedViewsData);
                }
            }
            console.log("handleAcceptedThumbFiles ====>", files, index);

            const updatedViewsData = [...viewsData];
            updatedViewsData[index] = {
                ...updatedViewsData[index],
                image: files[0]
            };
            setViewsData(updatedViewsData);

            onUploadFile({ image: files[0] }, (res) =>
                uploadFileSuccess(res, "thumb", index)
            );
        }
        catch (error) {
            console.log(error, "<<=======err");
            setLoader(false);
        }
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const onSubmit = (event) => {
        event.preventDefault();
        console.log("on submit event called-------->", viewsData);
        if (loading) return;
        try {
            viewsData?.forEach((view, index) => {
                view.category_id = fields.category_id;
                view.catName = fields.catName;
                view.subcategory_id = fields.subcategory_id;
                view.subCatName = fields.subCatName;
                view.blockchain = fields.blockchain;
                view.collection_id = fields.collection_id;
                view.current_owner = fields.current_owner;
                view.author_id = fields.author_id;
                view.status = fields.status;
                view.saleType = fields.saleType;
                view.price = fields.price;
                view.description = fields.description;
                view.level = fields.level;
                view.visible = fields.visible;
                if (view.price == 0) return toastr.error(`Please enter price in NFT ${index + 1}`);
                if (!view.subcategory_id) return toastr.error(`Please select sub category in NFT ${index + 1}`);
                if (view.catName == "Ships") {
                    switch (view.subCatName) {
                        case "Schooner":
                        case "Brigantine":
                        case "Galleon":
                        case "Sloop":
                        case "Frigate":
                            if (
                                view.level < levelFilter[view.subCatName].minValue ||
                                view.level > levelFilter[view.subCatName].maxValue
                            ) {
                                toastr.error(
                                    `Please select level from ${levelFilter[view.subCatName].minValue
                                    }- ${levelFilter[view.subCatName].maxValue}`
                                );
                                return null;
                            }
                        default:
                            break;
                    }
                }
                else if (view.level > 5) {
                    return toastr.error(`Level should be less then or equal to 5 in NFT ${index + 1}`);
                }
            });
            console.log("viewsData-------->", viewsData);
            const itemCallback = (response) => {
                console.log("response ------>", response);
                if (response.status == "failure") {
                    toastr.error(response.message);
                    loading = false;
                }
                else {
                    toastr.success("Items created successfully", { timeOut: 5000 });
                    history.push("/adminitems");
                }
            };
            onAddMultipleItems({ items: viewsData }, itemCallback);
        }
        catch (err) {
            console.log(err)
        }
    };

    // Function to add a new view
    const addView = () => {
        setViewsData([...viewsData, viewData]);
    };

    // Function to remove a view
    const removeView = (index) => {
        if (viewsData.length > 1) {
            const updatedViewsData = viewsData.filter((_, i) => i !== index);
            setViewsData(updatedViewsData);
        }
    };

    const handleChange = (index) => (name, value) => {
        const updatedViewsData = [...viewsData];

        if (name === "minted_start_date") {
            updatedViewsData[index] = {
                ...updatedViewsData[index],
                ["minted_end_date"]: "",
            };
        }
        else if (name === "category_id") {
            const categories = fdCategoriesList?.find((o) => o._id === value);
            updatedViewsData[index] = {
                ...updatedViewsData[index],
                [name]: value,
                catName: categories.catName,
                subcategory_id: "",
                subCatName: "",
            };
        }
        else if (name === "subcategory_id") {
            const categories = fdCategoriesList?.find((o) => o._id === updatedViewsData[index].category_id);
            if (categories?.catName === "Ships") {
                const subCat = categories?.subcategories?.find((o) => o._id === value);
                updatedViewsData[index] = {
                    ...updatedViewsData[index],
                    [name]: value,
                    catName: "Ships",
                    subCatName: subCat.catName,
                };
            } else if (categories?.catName === "Pirates") {
                updatedViewsData[index] = {
                    ...updatedViewsData[index],
                    [name]: value,
                    catName: "Pirates",
                    subCatName: "",
                };
            } else {
                updatedViewsData[index] = {
                    ...updatedViewsData[index],
                    [name]: value,
                };
            }
        }
        else {
            updatedViewsData[index] = {
                ...updatedViewsData[index],
                [name]: value,
            };
        }
        setViewsData(updatedViewsData);
    };

    const handleChangeSingle = (name) => (event) => {
        if (name == "category_id") {
            categories = fdCategoriesList?.find((o) => o._id == event.target.value);
            console.log("categories", categories)
            setFields((prevState) => ({
                ...prevState,
                [name]: event.target.value,
                catName: categories.catName,
                subcategory_id: "",
                subCatName: "",
            }));
            return null;
        }
        if (name == "subcategory_id" && categories?.catName == "Ships") {
            console.log("else if Ships")
            console.log("categories", categories)
            let subCat = categories?.subcategories?.find(
                (o) => o._id == event.target.value
            );
            console.log("subCat", subCat);
            setFields((prevState) => ({
                ...prevState,
                [name]: event.target.value,
                catName: "Ships",
                subCatName: subCat?.catName,
            }));
            return null;
        }
        else if (name == "subcategory_id" && categories?.catName == "Pirates") {
            setFields((prevState) => ({
                ...prevState,
                [name]: event.target.value,
                catName: "Pirates",
                subCatName: "",
            }));
            return null;
        }
        else {
            setFields((prevState) => ({
                ...prevState,
                [name]: event.target.value,
            }));
        }
    };


    const singleItemView = (index) => (
        <Row>
            <Col lg={10}>
                <Card>
                    <CardBody className="row">
                        <p className="text-change-color text-bold">{index + 1 + "."}</p>
                        <FormGroup className="col-lg-5">
                            <Label for="formrow-profile-image-Input">
                                {props.t("Item")} {props.t("Image")}
                                <b>
                                    (File types supported: PNG, JPG, JPEG, Smaller than 5
                                    MB)
                                </b>
                            </Label>

                            <Dropzone
                                onDrop={(acceptedFiles) => {
                                    handleAcceptedFiles(acceptedFiles, index);
                                }}
                            >
                                {({
                                    getRootProps: getRootMainProps,
                                    getInputProps: getInputMainProps,
                                }) => {
                                    const imageFile = viewsData?.[index]?.mediapreview?.replace("piratepets.s3.eu-north-1.amazonaws.com", "d1ihmhsgmb4un6.cloudfront.net");
                                    return (
                                        <div
                                            className="dropzone-single-image avatar-xl"
                                            {...getRootMainProps()}
                                        >
                                            <input
                                                {...getInputMainProps()}
                                                id="formrow-profile-image-Input"
                                                multiple={false}
                                            />
                                            <img
                                                className="rounded-circle avatar-xl"
                                                alt={
                                                    !!imageFile && imageFile.name
                                                        ? imageFile.name
                                                        : "MintFactory"
                                                }
                                                src={!!imageFile ? imageFile : avatar4}
                                            />
                                            <div className="edit">
                                                <i className="bx bx-pencil"></i>
                                            </div>


                                        </div>
                                    );
                                }}
                            </Dropzone>
                            <p className="mt-2 ml-2 text-bold">{viewsData[index]?.tempImageName}</p>
                        </FormGroup>

                        <Form onSubmit={onSubmit} className="spinner-content col-md-5">
                            {error && typeof error === "string" ? (
                                <Alert color="danger">{error}</Alert>
                            ) : null}

                            <Row>
                                {/* {thumb && (
                                <Col md={5}>
                                    <Label for="formrow-profile-image-Input">
                                        {props.t("Thumbnail")} {props.t("Image")}
                                        <b>
                                            (File types supported: JPG, PNG size: 10 MB)
                                        </b>
                                    </Label>
                                    <div className="dropzone-single-image avatar-xl">
                                        <input
                                            type="file"
                                            onChange={(e) =>
                                                handleAcceptedThumbFiles(e.target.files, index)
                                            }
                                            required
                                        />

                                        {viewsData[index]?.thumbpreview && (
                                            <img
                                                className="rounded-circle custom_avtal_xl mt-2"
                                                src={
                                                    viewsData[index]?.thumbpreview
                                                        ? viewsData[index]?.thumbpreview
                                                        : avatar4
                                                }
                                            />
                                        )}
                                    </div>
                                </Col>
                            )} */}
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="formrow-firstname-Input">
                                            {props.t("Name")}
                                        </Label>
                                        <Input
                                            type="text"
                                            id="formrow-firstname-Input"
                                            value={viewsData[index]?.name}
                                            onChange={(e) => handleChange(index)("name", e.target.value)}
                                            required
                                        />

                                        <small>
                                            If you leave this empty it will give the NFT a
                                            random number or if collection increases the
                                            number of each NFT.
                                        </small>
                                    </FormGroup>
                                </Col>


                            </Row>

                            {(loading || props.fileLoading) && (
                                <div className="spinner loader_bg">
                                    <Spinner color="#378f88" />{" "}
                                    {props.fileLoading && (
                                        <span className="ml-2"> image uploading</span>
                                    )}
                                </div>
                            )}

                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );

    const multipleItemViews = viewsData.map((data, index) => (
        <div key={index}>
            {singleItemView(index)}

            {index > 0 && <div className="w-100 remove-btn-div">
                <button
                    type="button"
                    className="btn btn-danger ml-2 mb-2 remove-btn"
                    onClick={() => removeView(index)}
                >
                    Remove
                </button>
            </div>}

        </div>
    ));

    console.log("fields", fields);
    console.log("viewsData", viewsData);
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Breadcrumbs title="Items"
                            breadcrumbItem={props.t("Add") + " " + props.t("Items")}
                            breadcrumbItems={[
                                { title: props.t("Items"), link: "/adminitems" },
                                { title: props.t("Add Multiple") },
                            ]}
                        />

                        <div className="row">

                            <Col md={6}>
                                <FormGroup>
                                    <Label>{props.t("Select Category")}</Label>

                                    <Input
                                        type="select"
                                        value={fields?.category_id}
                                        onChange={handleChangeSingle("category_id")}
                                    >
                                        <option value="">Select</option>
                                        {fdCategoriesList.length > 0 &&
                                            fdCategoriesList?.map((category) => {
                                                return (
                                                    <option value={category._id}>
                                                        {category.catName}
                                                    </option>
                                                );
                                            })}
                                    </Input>
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label>{props.t("Select Subcategory")}</Label>
                                    <Input
                                        type="select"
                                        value={fields?.subcategory_id}
                                        onChange={handleChangeSingle("subcategory_id")}
                                    >
                                        <option value="">Select</option>
                                        {fdCategoriesList?.length > 0 &&
                                            fdCategoriesList
                                                ?.find(
                                                    (cat) => cat._id == fields?.category_id
                                                )
                                                ?.subcategories?.filter(
                                                    (o) => o.catName !== "All"
                                                )
                                                ?.map((subcategory) => {
                                                    return (
                                                        <option value={subcategory._id}>
                                                            {subcategory.catName}
                                                        </option>
                                                    );
                                                })}
                                    </Input>
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label>{props.t("Select Chain")}</Label>

                                    <Input
                                        type="select"
                                        value={fields?.blockchain}
                                        onChange={handleChangeSingle("blockchain")}
                                    >
                                        <option value="BNB">Binance Smart Chain</option>
                                    </Input>
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label className="mb-0">
                                        {props.t("Choose collection")}
                                    </Label>
                                    <br />

                                    <InputGroup className="mt-2">
                                        <Input
                                            type="select"
                                            value={fields?.collection_id}
                                            onChange={handleChangeSingle("collection_id")}
                                        >
                                            <option value="">Select Collection</option>
                                            {collectionList &&
                                                collectionList.length > 0 &&
                                                collectionList?.map(
                                                    (col) =>
                                                        col.blockchain == fields?.blockchain &&
                                                        col.collectionType ==
                                                        fields?.catName?.toLowerCase() &&
                                                        col.transactionHash && (
                                                            <option value={col._id}>
                                                                {col.name}
                                                            </option>
                                                        )
                                                )}
                                        </Input>
                                    </InputGroup>
                                    <small>
                                        Choose an exiting collection or{" "}
                                        <Link to="/collection/add">
                                            create a new one
                                        </Link>
                                    </small>
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label>{props.t("Visible")}</Label>
                                    <Input
                                        type="select"
                                        value={fields?.visible}
                                        onChange={handleChangeSingle("visible")}
                                    >
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </Input>
                                </FormGroup>
                            </Col>

                            {fields?.isNotOthers && (
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>
                                            {props.t(
                                                `Level (${levelFilter[fields?.subCatName]?.minValue ||
                                                1
                                                } , ${levelFilter[fields?.subCatName]?.maxValue ||
                                                5
                                                })`
                                            )}
                                        </Label>

                                        <InputGroup>
                                            <Input
                                                type="number"
                                                onKeyDown={acceptOnlyNumber}
                                                onChange={handleChangeSingle("level")}
                                                // onChange={handleChange("level")}
                                                min="1"
                                                value={fields?.level}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            )}

                            <Col md={fields?.isNotOthers ? 6 : 12}>
                                <FormGroup>
                                    <Label>{props.t("Price")}</Label>

                                    <InputGroup>
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                {fields?.blockchain == "ethereum"
                                                    ? "ETH"
                                                    : fields?.blockchain == "polygon"
                                                        ? "MATIC"
                                                        : "BNB"}
                                            </span>
                                        </div>

                                        <Input
                                            type="number"
                                            // min={1}
                                            onWheel={(e) => e.target.blur()}
                                            onKeyDown={acceptOnlyNumber}
                                            value={fields?.price}
                                            onChange={handleChangeSingle("price")}
                                            // onChange={handleChange("price")}
                                            required
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label>{props.t("Description")}</Label>

                                    <Input
                                        type="textarea"
                                        value={fields?.description}
                                        onChange={handleChangeSingle("description")}
                                    // onChange={handleChange("description")}
                                    />
                                    <small>
                                        The description will be included on the item's
                                        detail page underneath its image.Markdown syntax
                                        is supported.
                                    </small>
                                </FormGroup>
                            </Col>
                        </div>


                        {multipleItemViews}
                        <div className="w-100 add-btn-div">
                            <label htmlFor="fileInput" className="fileInputLabel">
                                Upload Multiple
                            </label>
                            <input
                                type="file"
                                id="fileInput"
                                className="fileInput"
                                onChange={handleMultipleFiles}
                                multiple
                                placeholder=" Upload Multiple"
                                style={{ display: 'none' }} // Hide the original file input
                            />
                            {/* <button
                                type="button"
                                className="btn btn-success mb-3"
                                onClick={(e) => handleMultipleFiles(e)}>
                                Upload Multiple
                            </button> */}
                        </div>
                        <div className="w-100 add-btn-div">
                            <button
                                type="button"
                                className="btn btn-success mb-3 add-btn"
                                onClick={(e) => addView(e)}>
                                Add More
                            </button>
                        </div>
                        <div className="mt-4 multi-add-button">
                            <button
                                type="submit"
                                className="btn btn-primary w-md"
                                onClick={(e) => onSubmit(e)}>
                                {props.t("Add")}
                            </button>

                            <button
                                type="button"
                                className="btn outline btn-outline-secondary w-md ml-3"
                                onClick={() => history.goBack()}>
                                <i className="mdi mdi-arrow-left mr-1"></i>
                                {props.t("back")}
                            </button>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

AddMultipleSameItem.propTypes = {
    t: PropTypes.any,
    loading: PropTypes.bool,
    error: PropTypes.string,
    onAddMultipleItems: PropTypes.func,
    onUploadFile: PropTypes.func,
    onRemoveFile: PropTypes.func,
    fileLoading: PropTypes.bool,
};

const mapStateToProps = ({ customers, items, Categoryes, File }) => ({
    error: customers.error,
    fileLoading: File.loading,
    errorItem: items.error,
    loading: items.loading,
    fdCategories: Categoryes.fdCategories,
});


const mapDispatchToProps = (dispatch) => ({
    onGetFdCategories: (storeType, data) => dispatch(getFdCategories(storeType, data)),
    onAddMultipleItems: (data, callback) => dispatch(addMultipleItems(data, callback)),
    onUploadFile: (data, callback) => dispatch(uploadFile(data, callback, true)),
    onUploadMultipleFiles: (data, callback) => dispatch(uploadMultipleFile(data, callback)),
    onRemoveFile: (data) => dispatch(removeFile(data)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddMultipleSameItem))
);