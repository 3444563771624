
export const GET_MOBILE_GAME_HISTORY = "GET_MOBILE_GAME_HISTORY";
export const GET_MOBILE_GAME_HISTORY_FAIL = "GET_MOBILE_GAME_HISTORY_FAIL";
export const GET_MOBILE_GAME_HISTORY_SUCCESS = "GET_MOBILE_GAME_HISTORY_SUCCESS";


export const UPDATE_MOBILE_GAME_HISTORY = "UPDATE_MOBILE_GAME_HISTORY";
export const UPDATE_MOBILE_GAME_HISTORY_FAIL = "UPDATE_MOBILE_GAME_HISTORY_FAIL";
export const UPDATE_MOBILE_GAME_HISTORY_SUCCESS = "UPDATE_MOBILE_GAME_HISTORY_SUCCESS";

export const GET_MOBILE_GAME_ENERGY_HISTORY = "GET_MOBILE_GAME_ENERGY_HISTORY";
export const GET_MOBILE_GAME_ENERGY_HISTORY_FAIL = "GET_MOBILE_GAME_ENERGY_HISTORY_FAIL";
export const GET_MOBILE_GAME_ENERGY_HISTORY_SUCCESS = "GET_MOBILE_GAME_ENERGY_HISTORY_SUCCESS";


export const GET_MOBILE_GAME_LEADERBOARD_HISTORY = "GET_MOBILE_GAME_LEADERBOARD_HISTORY";
export const GET_MOBILE_GAME_LEADERBOARD_HISTORY_FAIL = "GET_MOBILE_GAME_LEADERBOARD_HISTORY_FAIL";
export const GET_MOBILE_GAME_LEADERBOARD_HISTORY_SUCCESS = "GET_MOBILE_GAME_LEADERBOARD_HISTORY_SUCCESS";


export const GET_MOBILE_GAME_DETAIL = "GET_MOBILE_GAME_DETAIL";
export const GET_MOBILE_GAME_DETAIL_FAIL = "GET_MOBILE_GAME_DETAIL_FAIL";
export const GET_MOBILE_GAME_DETAIL_SUCCESS = "GET_MOBILE_GAME_DETAIL_SUCCESS";

export const UPDATE_MOBILE_GAME_LEADERBOARD_POINTS = "UPDATE_MOBILE_GAME_LEADERBOARD_POINTS";
export const UPDATE_MOBILE_GAME_LEADERBOARD_POINTS_FAIL = "UPDATE_MOBILE_GAME_LEADERBOARD_POINTS_FAIL";
export const UPDATE_MOBILE_GAME_LEADERBOARD_POINTS_SUCCESS = "UPDATE_MOBILE_GAME_LEADERBOARD_POINTS_SUCCESS";

export const END_MOBILE_GAME = "END_MOBILE_GAME";
export const END_MOBILE_GAME_FAIL = "END_MOBILE_GAME_FAIL";
export const END_MOBILE_GAME_SUCCESS = "END_MOBILE_GAME_SUCCESS";
