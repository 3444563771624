export const sideMenus =
  [
    {
      icon: "bx bx-collection",
      label: "Dashboard",
      route: "/dashboard",
      // options:[],
      class:"",
      value : 30,
      isSelected: false
    },
    {
      icon: "bx bx-collection",
      label: "Users",
      route: "/users",
      // options:[],
      class:"",
      value : 31,
      isSelected: false
    },
    {
      icon: "bx bx-bar-chart",
      label: "Logs",
      route: "/logs",
      // options:[],
      class:"",
      value : 32,
      isSelected: false
    },
    {
      icon: "bx bx-bar-chart",
      label: "Transaction Logs",
      route: "/transaction-logs",
      // options:[],
      class:"",
      value : 32,
      isSelected: false
    },
    {
      icon: "bx bx-cog",
      label: "Operators",
      route: "/#",
      class:"has-arrow waves-effect",
      isSelected: false,
      options: [
        {
          label: "List",
          route: "/operator/list",
          value: 1,
          isSelected: false

        },
        {
          label: "Add",
          route: "/operator/add",
          value: 2,
          isSelected: false
        },
      ]
    },

    {
      icon: "bx bx-cog",
      label: "MarketPlace",
      route: "/#",
      class:"has-arrow waves-effect",
      isSelected: false,
      options: [
        {
          icon: "bx bx-cog",
          label: "Tokens Management",
          route: "/#",
          class:"has-arrow waves-effect",
          isSelected: false,
          value: 3,
          options: [
            {
              label: "Pirated Pet Token (PPT)",
              route: "/token",
              value: 4,
              isSelected: false
            },
            {
              label: "Pirated Pet Gold (PPG)",
              route: "/ppg",
              value: 5,
              isSelected: false
            },
          ]
        },
        {
          icon: "bx bx-message-rounded-x",
          label: "Background Layers",
          route: "/#",
          class:"has-arrow waves-effect",
          isSelected: false,
          options: [
            {
              label: "Created",
              route: "/background/layer",
              value: 6,
              isSelected: false
            },
            {
              label: "Add",
              route: "/background/layer/add",
              value: 7,
              isSelected: false
            },
          ]
        },
        {
          icon: "bx bx-collection",
          label: "Collections",
          route: "/#",
          class:"has-arrow waves-effect",
          isSelected: false,
          options: [
            {
              label: "List",
              route: "/collections",
              value: 8,
              isSelected: false
            },
            {
              label: "Add",
              route: "/collection/add",
              value: 9,
              isSelected: false
            },
          ]
        },
        {
          icon: "bx bx-message-rounded-x",
          label: "NFTs",
          route: "/#",
          class:"has-arrow waves-effect",
          isSelected: false,
          options: [
            {
              label: "Created",
              route: "/adminitems",
              value: 10,
              isSelected: false
            },
            {
              label: "Add",
              route: "/adminitems/add",
              value: 11,
              isSelected: false
            },
           
            {
              label: "Add Multiple (Same Collection)",
              route: "/adminitems/multiple/add",
              value: 46,
              isSelected: false
            },
            {
              label: "Add Multiple",
              route: "/adminitems/add/multiple",
              value: 45,
              isSelected: false
            },
          ]
        },
        {
          icon: "bx bx-chevron-down-square",
          label: "Categories",
          route: "/#",
          class:"has-arrow waves-effect",
          isSelected: false,
          options: [
            {
              label: "List Categories",
              route: "/categories",
              value: 12,
              isSelected: false
            },
            {
              label: "Add Categories",
              route: "/add_category",
              value: 13,
              isSelected: false
            },
          ]
        },
        {
          icon: "bx bx-gift",
          label: "Promo Codes",
          route: "/promo-codes",
          isSelected: false
        },
        {
          icon: "bx bx-bar-chart",
          label: "Sales",
          route: "/activities",
          isSelected: false
        },
        {
          icon: "bx bxs-book-content",
          label: "Content Pages",
          route: "/content-pages",
          isSelected: false
        },
        {
          icon: "bx bx-bar-chart",
          label: "Faqs",
          route: "/faqs",
          isSelected: false
        },
        {
          icon: "bx bx-box",
          label: "Airdrop",
          route: "/airdrop",
          isSelected: false
        },
        {
          icon: "bx bx-cog",
          label: "Settings",
          route: "/#",
          class:"has-arrow waves-effect",
          isSelected: false,
          options: [

            {
              label: "General Settings",
              route: "/general",
              value: 14,
              isSelected: false
            },
            {
              label: "Blockchain Network",
              route: "/blockchain",
              value: 15,
              isSelected: false
            },
            {
              label: "Wallet Settings",
              route: "/wallet",
              value: 16,
              isSelected: false
            },
            {
              label: "Rewards Settings",
              route: "/rewards",
              value: 17,
              isSelected: false
            },
          ]
        },
      ]
    },
    {
      icon: "bx bx-collection",
      label: "Mini Games",
      class :"has-arrow waves-effect",
      route: "/#",
      isSelected: false,
      options: [
        {
          icon: "bx bx-collection",
          label: "Tournaments",
          route: "/#",
          class:"has-arrow waves-effect",
          value: 18,
          isSelected: false,
          options: [
            {
              label: "List",
              route: "/games",
              value: 19,
              isSelected: false
            },
            {
              label: "Add",
              route: "/games/add",
              value: 20,
              isSelected: false
            },
          ]
        },
        {
          icon: "bx bx-bar-chart",
          label: "Tournament History",
          route: "/tournament-history",
          isSelected: false
        },
        {
          icon: "bx bx-bar-chart",
          label: "Match History",
          route: "/match-history",
          isSelected: false
        },
        {
          icon: "bx bx-collection",
          label: "Balance",
          route: "/#",
          class:"has-arrow waves-effect",
          isSelected: false,
          options: [
            {
              label: "Add Balance",
              route: "/add-balance",
              value: 21,
              isSelected: false
            },
            {
              label: "Remove Balance",
              route: "/remove-balance",
              value: 22,
              isSelected: false
            },
            {
              label: "Balance History",
              route: "/balance/list",
              value: 23,
              isSelected: false
            },
          ]
        },
        {
          icon: "bx bx-collection",
          label: "Rewards History",
          route: "/mini-games-rewards",
          isSelected: false
        },
        {
          icon: "bx bx-collection",
          label: "Leaderboard",
          route: "/mini-games-leaderboard",
          isSelected: false
        },
        {
          icon: "bx bx-collection",
          label: "Crystal Withdraw",
          route: "/#",
          class:"has-arrow waves-effect",
          isSelected: false,
          options: [
            {
              label: "Pending",
              route: "/pending-withdraw",
              value: 24,
              isSelected: false
            },
            {
              label: "History",
              route: "/withdraw-history",
              value: 25,
              isSelected: false
            },
           
          ]
        },
        {
          icon: "bx bx-message-rounded-x",
          label: "Crystal Package",
          route: "/#",
          class:"has-arrow waves-effect",
          isSelected: false,
          options: [
            {
              label: "List",
              route: "/crystal/package",
              value: 26,
              isSelected: false
            },
            {
              label: "Add",
              route: "/crystal/package/add",
              value: 27,
              isSelected: false
            },
          ]
        },
        {
          icon: "bx bx-collection",
          label: "Crystal Purchase",
          route: "/crystal/package/purchase",
          isSelected: false
        },
        {
          icon: "bx bx-collection",
          label: "General Settings",
          route: "/mini-games-general",
          isSelected: false
        },
      ]
    },

    {
      icon: "bx bx-cog",
      label: "Mobile App",
      route: "/#",
      class:"has-arrow waves-effect",
      isSelected: false,
      options: [
      
        {
          icon: "bx bx-message-rounded-x",
          label: "Settings",
          route: "/#",
          class:"has-arrow waves-effect",
          isSelected: false,
          options: [
            {
              label: "General Settings",
              route: "/mobile-app-general",
              value: 29,
              isSelected: false
            },
            {
              label: "Game Settings",
              route: "/mobile-game-settings",
              value: 30,
              isSelected: false
            },
            {
              label: "Level Upgrade Settings",
              route: "/nft-lvlup-settings",
              value: 41,
              isSelected: false
            }
          ]
        },
        {
          label: "Daily Limit",
          route: "/daily-limit",
          value: 31,
          isSelected: false
        },
        {
          icon: "bx bx-collection",
          label: "Sail History",
          route: "/sail-history",
          value: 32,
          isSelected: false
        },
        {
          icon: "bx bx-collection",
          label: "Mobile Sales",
          route: "/mobile-activities",
          value: 33,
          isSelected: false
        },
        {
          icon: "bx bx-collection",
          label: "Crew Codes",
          route: "/crew-codes",
          value: 34,
          isSelected: false
        },
        {
          icon: "bx bx-collection",
          label: "Bonus History",
          route: "/bonus-history",
          value: 35,
          isSelected: false
        },
        {
          icon: "bx bx-collection",
          label: "Crystal Purchase History",
          route: "/crystal-purchase-history",
          value: 36,
          isSelected: false
        },
        {
          icon: "bx bx-collection",
          label: "Referral Claim History",
          route: "/referral-claim-history",
          value: 37,
          isSelected: false
        },
        {
          icon: "bx bx-collection",
          label: "Game History",
          route: "/mobile-game-history",
          value: 38,
          isSelected: false
        },
        {
          icon: "bx bx-collection",
          label: "Game Leaderboard",
          route: "/mobile-leaderboard-history",
          value: 39,
          isSelected: false
        },
        {
          icon: "bx bx-collection",
          label: "Energy History",
          route: "/mobile-energy-history",
          value: 40,
          isSelected: false
        },
        {
          icon: "bx bx-collection",
          label: "NFT Repair History",
          route: "/nft-repair-history",
          value: 42,
          isSelected: false
        },
        {
          icon: "bx bx-collection",
          label: "NFT Lvl Upgrade History",
          route: "/nft-upgrade-history",
          value: 43,
          isSelected: false
        },

        {
          icon: "bx bx-collection",
          label: "Manage Languages",
          route: "/languages",
          value: 44,
          isSelected: false
        },

        {
          icon: "bx bx-message-rounded-x",
          label: "Manage Notifications",
          route: "/#",
          class:"has-arrow waves-effect",
          isSelected: false,
          options: [
            {
              label: "List",
              route: "/manage-notifications",
              value: 47,
              isSelected: false
            },
            {
              label: "Send",
              route: "/notifications/send",
              value: 48,
              isSelected: false
            },
          ]
        },
      ]
    },
  ];