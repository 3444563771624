import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects";
import toastr from "toastr";
// Customer Redux States
import { GET_CUSTOMERS, GET_CUSTOMER, CHANGE_STATUS , UPDATE_USER_PROFILE} from "./actionTypes";
import {
  getCustomersFail,
  getCustomersSuccess,
  getCustomerFail,
  getCustomerSuccess,
  updateUserProfileFail, updateUserProfileSuccess
} from "./actions";

import {
  getCustomers,
  getCustomer,
  changeStatus,
  updateProfile
} from "helpers/backend_helper";

import * as actType from "./actions";

toastr.options = {
  newestOnTop: true,
  progressBar: true,
};

function* fetchCustomers({ payload }) {
  try {
    const response = yield call(getCustomers, payload);

    if (response.status == "failure") {
      return yield put(getCustomersFail(response.message));
    }

    yield put(getCustomersSuccess(response));
  } catch (error) {
    yield put(getCustomersFail(error));
  }
}

function* fetchCustomer({ payload }) {
  try {
    const response = yield call(getCustomer, payload);

    if (response.status == "failure") {
      return yield put(getCustomerFail(response.message));
    }

    yield put(getCustomerSuccess(response));
  } catch (error) {
    yield put(getCustomerFail(error));
  }
}

function* onChangeStatus({ payload }) {
  try {
    const response = yield call(changeStatus, payload);

    if (response.status == "failure") {
    
      return yield put(actType.changeStatusFail(response.message));
    }

    yield put(actType.changeStatusSuccess(response));
  } catch (error) {
    yield put(actType.changeStatusFail(error));
  }
}

function* updateUserProfile({ payload: { data, callback } }) {
  try {
    const response = yield call(updateProfile, data)
    callback && callback(response)

    if (response.status == "failure") {
      return yield put(updateUserProfileFail(response.message))

    }

    yield put(updateUserProfileSuccess())
  } catch (error) {
    yield put(updateUserProfileFail("Internal Server Error"))
  }
}

function* customersSaga() {
  yield takeLatest(GET_CUSTOMERS, fetchCustomers);
  yield takeLatest(CHANGE_STATUS, onChangeStatus);
  yield takeLatest(GET_CUSTOMER, fetchCustomer);
  yield takeLatest(UPDATE_USER_PROFILE, updateUserProfile);

}

export default customersSaga;
