import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationListStandalone, PaginationProvider, } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { withTranslation } from "react-i18next";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import SearchInput from "components/Common/SearchInput";
import { getCustomers, getCustomersSuccess } from "store/actions";
import UsersColumns from "./Columns";
import toastr from "toastr";

const CrewCodes = (props) => {
  const {
    users,
    totalUsers,
    getCustomersSuccess,
    onGetUsers,
    loading,
  } = props;

  const [filter, setFilter] = useState({
    orderBy: "date_created_utc",
    order: -1,
    page: 0,
    limit: 20,
    search: "",
    fields: [],
  });

  const [searchText, setSearchText] = useState("");
  const [userList, setUsersList] = useState([]);

  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalUsers,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    getCustomersSuccess({ data: [], totalcount: 0 });
    onGetUsers({ ...filter });
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    setUsersList(users || []);
  }, [users]);

  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText.trim());
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const onSearch = (event) => {
    event.preventDefault();
    console.log("searchText", searchText);
    setFilter((prevState) => ({ ...prevState, search: searchText.trim() }));
  };

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Crew Codes"
            breadcrumbItem={props.t("Crew Codes")}
            breadcrumbItems={[{ title: props.t("Crew Codes") }]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={userList || []}
                        columns={UsersColumns(
                          props.t,

                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4" lg="3">
                                <SearchInput
                                  {...toolkitProps.searchProps}
                                  triggerSearch={onSearch}
                                  placeholder={props.t("Search")}
                                  searchText={searchText}
                                />
                              </Col>

                              <Col sm="2">
                                {!!filter.search && (
                                  <div
                                    className="mr-4 mb-2"
                                    style={{
                                      height: "calc( 1.5em + 0.94rem + 2px)",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFilter((prevState) => ({
                                          ...prevState,
                                          search: "",
                                        }));
                                        setSearchText("");
                                      }}
                                      className=""
                                    >
                                      {props.t("Clear Filters")}
                                    </Link>
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {/* {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )} */}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

CrewCodes.propTypes = {
  t: PropTypes.any,
  users: PropTypes.array,
  totalUsers: PropTypes.number,
  getCustomersSuccess: PropTypes.func,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ customers }) => ({
  loading: customers.loading,
  users: customers.customers,
  totalUsers: customers.totalCustomers,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomersSuccess: (data) => dispatch(getCustomersSuccess(data)),
    onGetUsers: (data) => { dispatch(getCustomers(data)) },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CrewCodes))
);