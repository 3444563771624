import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import { getDocuments, deleteDocument } from "store/actions";
import ListColumns from "./ListColumns";
import ConfirmModal from "./ConfirmModal";

const Documents = ({
  history,
  documents,
  totalDocuments,
  onGetDocuments,
  onDeleteDocument,
  loading,
  ...props
}) => {
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    documentId: null,
  });
  const [filter, setFilter] = useState({
    orderBy: "date_created_utc",
    order: -1,
    page: 0,
    limit: 20,
    search: "",
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
  });
  const [documentsList, setDocumentsList] = useState([]);
  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalDocuments,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    onGetDocuments(filter);
    setDocumentsList(documents);
  }, [onGetDocuments, JSON.stringify(filter)]);

  useEffect(() => {
    setDocumentsList(documents || []);
  }, [documents]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (documentId) => {
    setConfirmModal((prevState) => ({ isOpen: !prevState.isOpen, documentId }));
  };

  const removeConfirm = () => {
    const { documentId, isOpen } = confirmModal;

    const onComplete = () => {
      onGetDocuments(filter);
    };

    setConfirmModal({ isOpen: !isOpen, documentId: null });
    onDeleteDocument({ _id: documentId }, onComplete);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Documents"
            breadcrumbItem={props.t("Documents")}
            breadcrumbItems={[{ title: props.t("Documents") }]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={documentsList || []}
                        columns={ListColumns(
                          history,
                          toggleConfirmModal,
                          props.t
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row>
                              <Col
                                className="mb-3"
                                xs={12}
                                md={7}
                                lg={8}
                                xl={7}
                              ></Col>

                              <Col
                                className="mb-3"
                                xs={12}
                                md={5}
                                lg={4}
                                xl={5}
                              >
                                <div className="text-sm-left text-md-right">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded waves-effect waves-light mb-2 mr-2"
                                    onClick={() =>
                                      history.push("/documents/add")
                                    }
                                  >
                                    <i className="mdi mdi-plus mr-1" />
                                    {props.t("Add Document")}
                                  </Button>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Documents.propTypes = {
  documents: PropTypes.array,
  totalDocuments: PropTypes.number,
  onGetDocuments: PropTypes.func,
  onDeleteDocument: PropTypes.func,
};

const mapStateToProps = ({ Settings, documents }) => ({
  settings: Settings.settings,
  loading: documents.loading,
  documents: documents.documents,
  totalDocuments: documents.totalDocuments,
});

const mapDispatchToProps = (dispatch) => ({
  onGetDocuments: (data) => dispatch(getDocuments(data)),
  onDeleteDocument: (data, callback) =>
    dispatch(deleteDocument(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Documents))
);
