import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Collection Redux States
import {
  GET_BONUS_HISTORY,
  UPDATE_BONUS_HISTORY,
} from "./actionTypes";

import {
  getBonusHistoryFail,
  getBonusHistorySuccess,
  updateBonusHistoryFail,
  updateBonusHistorySuccess,
} from "./actions";

import {
  postGetBonusHistory,
  updateBonusHistory,
} from "helpers/backend_helper";

function* getBonusHistorySaga({ payload, callback }) {
  try {
    const response = yield call(postGetBonusHistory, payload);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(getBonusHistoryFail(response.message));
    }
    yield put(getBonusHistorySuccess(response));
  } catch (error) {
    yield put(getBonusHistoryFail(error));
  }
}

function* updateBonusHistorySaga({ payload: { data, callback } }) {
  try {
    const response = yield call(updateBonusHistory, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(updateBonusHistoryFail(response.message));
    }
    yield put(updateBonusHistorySuccess(response));
  } catch (error) {
    console.log(error, "<=== error")
    yield put(updateBonusHistoryFail(error));
  }
}

function* SailHistorySaga() {
  yield takeLatest(GET_BONUS_HISTORY, getBonusHistorySaga);
  yield takeEvery(UPDATE_BONUS_HISTORY, updateBonusHistorySaga);
}

export default SailHistorySaga;