import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { connect, useDispatch, useSelector } from "react-redux";

//i18n
import { withTranslation } from "react-i18next";
import { getMatchHistory } from "store/actions";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import toastr from "toastr";

const ConfirmModal = (props) => {
  const {
    isOpen,
    toggle,
    userId,
    MatchHistory,
    totalMatchHistory,
    onGetMatchHistory,
  } = props;
  const [matchHistoryList, setMatchHistoryList] = useState([]);

  useEffect(() => {
    onGetMatchHistory({ filter: "winner", user_id: userId , order :-1, orderBy :"end_date_utc" });
  }, [userId]);

  useEffect(() => {
    setMatchHistoryList(MatchHistory || []);
  }, [MatchHistory]);

  return (
    <>
      <Modal
        size="lg"
        isOpen={isOpen}
        autoFocus={true}
        centered={true}
        toggle={toggle}
        scrollable="true"
      >
        <div className="modal-content remove-confirm remove_two position-relative">
          <div className="d-flex justify-content-between px-3 pt-3">
          <h5 className="heading">Winning History</h5>
            <a href="javascript:void(0)" onClick={toggle} className="closebtn">
              <svg width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6L6.4 19Z"
                />
              </svg>
            </a>
          </div>
          <ModalBody>
            <Table>
              <thead>
                <tr>
                  <th>MatchID</th>
                  <th>Creator</th>

                  <th>Winner</th>
                  <th>Opponent</th>

                  <th>Date</th>
                  <th>Points</th>
                  <th>Bet Amount</th>
                  <th>Won Amount</th>
                </tr>
              </thead>
              <tbody>
                {matchHistoryList &&
                    matchHistoryList?.map((list, idx) => {
                    return (
                      <tr>
                        <td>{list.matchID}</td>
                        <td>{list.creator?.username}</td>

                        <td>{list.winner?.username}</td>
                        <td>{list.looser?.username}</td>

                        <td>
                          {moment(list.end_date_utc).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </td>
                        <td>{list.winnerPoints}</td>
                        <td>{list.betamount? list.betamount : "-"}</td>

                        <td>{list.winAmount? list.winAmount : "-"}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};

ConfirmModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  MatchHistory: PropTypes.array,
  totalMatchHistory: PropTypes.number,
  onGetMatchHistory: PropTypes.func,
};
const mapStateToProps = ({ MatchHistory }) => ({
    loading: MatchHistory.loading,
    MatchHistory: MatchHistory.matchHistory,
    totalMatchHistory: MatchHistory.totalMatchHistory,
});

const mapDispatchToProps = (dispatch) => ({
    onGetMatchHistory: (data) => dispatch(getMatchHistory(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConfirmModal))
);
// export default withTranslation()(ConfirmModal);
