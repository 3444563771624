/** Super Admin apis start */

// LOGIN
export const POST_LOGIN = "/admin/login";
export const CHANGE_PASSWORD = "/user/changepassword";
export const REMOVE_WALLET_ADDRESS = "/user/admin/removeWalletAddress";

export const POST_UPDATE_PROFILE = "/user/updateUserProfile/";
export const UPDATE_USER_STATUS = "/user/admin/update/status";

/** Super Admin apis end */
export const POST_FORGOT_PASSWORD = "/admin/forgotpassword";
export const POST_RESET_PASSWORD = "/admin/resetpassword";

// Profile
export const GET_CURRENT_USER = "/store/me";
export const PUT_PROFILE = "/store/updateprofile";

// SIGNUP
export const POST_REGISTER_EMAIL = "/store/signupemail";
export const POST_STORE_NAME = "/store/check";
export const POST_REGISTER = "/store/signup";

// File
export const POST_FILE = "/file/add";
export const POST_ITEM_FILE = "/file/ipfs";
export const POST_REMOVE_FILE = "/file/remove";
export const POST_MULTIPLE_FILE = "/file/add/multiple";

// Air drop
export const AIR_DROP = "/setting/admin/airdrop";
export const AIR_DROP_V2 = "/setting/admin/airDropV2";
export const AIR_DROP_V2_CRYSTAL = "/setting/admin/airdropV2ForPurchased";
export const AIR_DROP_LIST = "/setting/airdrop/list";

//Mobile Settings

export const PUT_MOBILE_SETTINGS = "/setting/update";
export const POST_DAILY_LIMIT = "/dailyLimit/get";

export const POST_PENDING_WITHDRAWS = "/withdraw-list";
export const POST_WITHDRAWS_HISTORY = "/withdraw-list";
export const POST_WITHDRAW_STATUS = "/withdraw-update";



export const POST_EDIT_DAILY_LIMIT = "/dailyLimit/update/";

// Settings
export const API_SETTINGS = "/setting";
export const GET_SETTINGS = "/setting/view";
export const PUT_SETTINGS = "/setting/update";
export const POST_CONTRACT_DEPLOY = "/setting/admin/deploycontract";
export const POST_CONTRACT_DEPLOY_MULTISEND =
  "/setting/admin/deployContractForMultisend";
export const POST_COMPILE_CONTRACT = "/setting/admin/compiletoken";
export const POST_COMPILE_CONTRACT_PPG = "/setting/admin/compiletokenppg";
export const POST_COMPILE_CONTRACT_GOV = "/setting/admin/compilegovernance";
export const POST_CONTRACT_DEPLOY_PPG =
  "/setting/admin/deployppgContractForToken";
export const POST_CONTRACT_DEPLOY_GOV =
  "/setting/admin/deploygovContractForToken";


  export const GET_LOGS = "/user/logs/list";

// Users
export const GET_USERS = "/user/admin/list";
export const VIEW_USER = "/user/admin/view";
export const CHANGE_STATUS = "/user/admin/update/status";

// Stake
export const GET_STAKES = "/stake/admin/list";
export const GET_STAKE_STATS = "/stake/admin/stats";

// DOCUMENTS
export const GET_DOCUMENTS = "/document/admin/list";
export const ADD_DOCUMENT = "/document/admin/add";
export const GET_DOCUMENT = "/document/admin/view";
export const PUT_DOCUMENT = "/document/admin/update";
export const DELETE_DOCUMENT = "/document/admin/remove";

// CONTENT_PAGES
export const GET_CONTENT_PAGES = "/pages/admin/list";
export const ADD_CONTENT_PAGE = "/pages/admin/add";
export const GET_CONTENT_PAGE = "/pages/admin/view";
export const PUT_CONTENT_PAGE = "/pages/admin/update";
export const DELETE_CONTENT_PAGE = "/pages/admin/remove";
export const PUT_CONTENT_PAGES_STATUS = "/pages/updatestatus/all";

//collections
export const GET_COLLECTIONS = "/collection/admin/list";
export const VIEW_COLLECTION = "/collection/admin/view";
export const GET_COLLECTION = "/collection/admin/view";
export const COMPILE_DEPLOY_COLLECTIONS =
  "/collection/admin/compile-and-deploy-contract";

  export const COMPILE_DEPLOY_COLLECTIONS_V2 =
  "/collection/admin/compile-and-deploy-contractV2";

  export const COMPILE_DEPLOY_COLLECTIONS_UPDT =
  "/collection/admin/compile-and-deploy-contract-updt";

export const POST_COLLECTION = "/collection/admin/add";
export const PUT_COLLECTION = "/super-admin/billingplan/update";
export const DELETE_COLLECTION = "/super-admin/billingplan/remove";
export const PUT_COLLECTION_STATUS =
  "/super-admin/billingplan/updatestatus/all";
export const UPDATE_STORE_COLLECTION = "/super-admin/store/updatePlan";
export const PUT_COLLECTION_DISCOUNT = "/super-admin/billingplan/discount";

// Activity
export const GET_ACTIVITIES = "/item/activity";


// Activity
export const GET_USER_ACTIVITIES = "/user/balance/activity";

// FAQS
export const GET_FAQS = "/faq/admin/list";
export const ADD_FAQ = "/faq/admin/add";
export const GET_FAQ = "/faq/admin/view";
export const PUT_FAQ = "/faq/admin/update";
export const DELETE_FAQ = "/faq/admin/remove";
export const PUT_FAQS_STATUS = "/faq/updatestatus/all";

// Promo codes
export const GET_PROMO_CODES = "/promo/admin/list";
export const ADD_PROMO_CODE = "/promo/admin/add";
export const GET_PROMO_CODE = "/promo/admin/view";
export const PUT_PROMO_CODE = "/promo/admin/update";
export const DELETE_PROMO_CODE = "/promo/admin/remove";
export const GET_PROMO_CODE_USERS = "/user/admin/promoCodeUserList";

// Items
export const GET_ITEMS = "/item/admin/list";
export const GET_ITEM = "/item/admin/view";
export const GET_NOTIFICATIONS = "/item/admin/notifications";
export const PUT_NOTIFICATIONS = "/item/admin/readNotification";

export const CREATE_ITEM = "/item/admin/readNotification";
export const POST_ITEM = "/item/admin/add";
export const ADD_BACKGROUND_IMAGE = "/level/admin/add";
export const UPDATE_NFT = "/item/admin/update";
export const VIEW_BACKGROUND_IMAGE = "/level/admin/view/";
export const LIST_BACKGROUND_IMAGE = "/level/admin/list";
export const UPDATE_BACKGROUND_IMAGE = "/level/admin/update";
export const DELETE_BACKGROUND_IMAGE = "/level/admin/remove";
export const DEPLOY_ITEM = "/item/admin/mint";
export const DELETE_BLOG = "/blog/admin/remove";

// Category
export const ADD_CATEGORY = "/category/admin/add";
export const VIEW_CATEGORIES = "/category/admin/list";
export const DELETE_ITEM = "/category/admin/remove";
export const DELETE_ITEM_2 = "/item/admin/delete/";
export const REMOVE_MULTIPLE_ITEMS = "/item/remove/selected";
export const PUT_ITEM_STATUS = "/category/admin/updatestatus/all";
export const VIEW_CATEGORY = "/category/admin/view";
export const UPDATE_CATEGORY = "/category/admin/update";

//category
export const API_CATEGORY = "/categoryes";
export const GET_FD_CATEGORIES = "";
export const ADD_FD_CATEGORY = "/add";
export const GET_FD_CATEGORY = "/view";
export const PUT_FD_CATEGORY = "/update";
export const DELETE_FD_CATEGORY = "/archive";
export const PUT_FD_CATEGORIES_STATUS = "/categoryes/updatestatus/all";
export const PUT_FD_CATEGORY_SORT = "/categoryes/sortorder";
export const EXPORT_FD_CATEGORIES = "/store/export/vendor/categories";

//  tournament

export const ADD_TOURNAMENT = "/add-tournament";
export const GET_TOURNAMENTS = "/list-tournament";
export const REMOVE_TOURNAMENTS = "/remove-tournament/";
export const VIEW_TOURNAMENT = "/view-tournament/";
export const UPDATE_TOURNAMENT = "/update-tournament/";

// tournament history
export const GET_TOURNAMENTS_HISTORY = "/tournament-history";
export const VIEW_TOURNAMENTS_HISTORY = "/get-tournament/";

// tournament history
export const GET_MATCH_HISTORY = "/match-history";
export const VIEW_MATCH_HISTORY = "/get-match/";

// balance
export const ADD_BALANCE = "/add-balance";
export const REMOVE_BALANCE = "/reduce-balance";
export const GET_BALANCE_HISTORY = "/balance-history";
export const GET_USER_BALANCE = "/get-balance";


export const END_MATCH = "/end-match";
export const LEADER_BOARD = "/leaderboard"
export const reduceLeaderboardPoints = "/reduce-leaderboard-point"

export const CREATE_CRYSTAL_PACKAGE = "/package-create";
export const UPDATE_CRYSTAL_PACKAGE = "/package-update";
export const GET_CRYSTAL_PACKAGE = "/package-list";
export const GET_CRYSTAL_PACKAGE_PURCHASE_LIST = "/package-purchase-history";

export const ADD_OPERATOR = "/user/operator/add";
export const GET_OPERATOR = "/user/operator/list";
export const UPDATE_OPERATOR = "/user/operator/update/";
export const REMOVE_OPERATOR = "/user/operator/remove/";

export const GET_SAIL_HISTORY = "/sail/history";
export const VIEW_SAIL_HISTORY = "/sail-history";
export const GET_CREW_MEMBERS = "/user/referralList";
export const GET_DASHBOARD_DATA = "/dashboard/counts";
export const GET_BONUS_HISTORY = "/user/bonusHistory";
export const UPDATE_BONUS_HISTORY = "/user/updateBonusHistory";
export const GET_CRYSTAL_PURCHASE_HISTORY = "/purchase/history";
export const GET_MOBILE_GAME_HISTORY = "/game/history";
export const GET_MOBILE_GAME_DETAIL = "/game/detail/";
export const GET_MOBILE_GAME_ENERGY_HISTORY = "energy/history";
export const GET_MOBILE_GAME_LEADERBOARD_HISTORY = "game/leaderboard";
export const UPDATE_LEADERBOARD_POINTS = "game/update/leaderboard";
export const UPDATE_GAME_HISTORY = "game/reward/update";
export const GET_REFERRAL_CLAIM_HISTORY = "referral/claim/history";
export const UPDATE_REFERRAL_CLAIM_HISTORY = "referral/claim/update";

export const NFT_REPAIR_HISTORY = "nft/repair/history";

export const NFT_LVL_UPGRADE_HISTORY = "nft/upgrade/history";

export const TEMP_AIRDROP_NFT = "nft/airdrop/temp";

export const GET_LANGUAGE_LIST = "language/list";

export const END_MOBILE_GAME = "game/endByAdmin";

export const CANCEL_NFT_SALE = "nft/cancel/sale";

export const UPDATE_SELECTED_ITEM_PRICES = "item/update/prices";

export const ADD_MULTIPLE_ITEMS = "item/add/multiple";

export const GET_NOTIFICATIONS_LIST = "notifications/history";

export const GET_IP_HISTORY = "ip/history";

export const SEND_NOTIFICATION = "notifications/create";