import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import Admin from "./ADMIN";

const SidebarContent = (props) => {
  const { user, storeType, role, accessLevel, vendorStoreType } = props;
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously

  const initMenu = (pathName) => {
    try {

      console.log(pathName, "pathName")
      if (document.getElementById("side-menu")) {
         MetisMenu.attach("#side-menu");
        let matchingMenuItem = null;
        const ul = document.getElementById("side-menu");

        const items = ul.getElementsByTagName("a");
        for (let i = 0; i < items.length; ++i) {
          if (pathName === items[i].pathname) {
            matchingMenuItem = items[i];
            break;
          }
        }

        if (matchingMenuItem) {
          console.log("inside matchingMenuItem", matchingMenuItem)
          activateParentDropdown(matchingMenuItem);
        }
      }


    } catch (error) {
      console.log(error, "<===err")
    }
  };

  useEffect(() => {

    let pathName = props.location.pathname;
    const pathNameArr = pathName.split("/");
    pathName = pathNameArr.slice(0).join("/");
    console.log(pathName, "<===pathName")
    if (pathName) {
      setTimeout(() => {
        initMenu(pathName);
      }, 0);

    }
  }, [props.location.pathname]);

  // useEffect(() => {
  //   if (user?.role === "SUBADMIN" && user?.permissions.length) {
  //     let route = user?.permissions.filter((item)=> {
  //       return item.isSelected === true;
  //     });
  //     console.log("SUBADMIN pathName------->",  route[0]?.route);
  //     setTimeout(() => {
  //       if(route && route.length > 0){
  //         initMenu(route[0]?.route)
  //       }
  //     }, 0);
  //   }
  // }, [user])

  function activateParentDropdown(item) {
    if (item) {
      console.log(item, "<====item")
      item.classList.add("active");
      const parent = item.parentElement;
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;

          if (parent3) {
            parent3.classList.add("mm-active"); // li
            parent3.childNodes[0].classList.add("mm-active"); //a
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.add("mm-show")
              parent4.classList.add("mm-active");
            }
          }
        }
        return false;
      }
      return false;
    }
  }

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <Admin user={user} />
        </ul>
      </div>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  storeType: PropTypes.array,
  role: PropTypes.string,
};

const mapStateToProps = ({ Login, Settings }) => ({
  storeType: Settings?.settings?.storeTypeEnabled || [],
  user: Login?.user,
  role: Login?.user?.role || "",
  accessLevel: Login?.user?.accessLevel,
  vendorStoreType: Login?.user?.storeType || [],
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(SidebarContent))
);
