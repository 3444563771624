import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Alert,
} from "reactstrap";

// Actions
import { getSettings, putSettings } from "store/actions";

// Other Settings
import SocialMediaSettings from "./SocialMedia";
import SupportSettings from "./Support";
import GeneralSettings from "./General";

const Settings = (props) => {
  const { onGetSettings, onPutSettings, loading, error } = props;

  const [fields, setFields] = useState({
    name: props.settings.name,
    logo: props.settings.logo,
    adminCommission: props.settings.adminCommission,
    promoCode: props.settings.promoCode,
    homePageBannerSetting: {
      topHeading: "",
      subHeading: "",
      description: "",
      item: "",
    },
    footerSetting: {
      description: "",
    },
    socialMediaSetting: {
      facebook: "",
      twitter: "",
      instagram: "",
      linkedin: "",
      telegram: "",
    },
    supportSetting: {
      email: "",
      // mobileNumber: "",
      // address: "",
    },
  });

  useEffect(() => {
    onGetSettings();
  }, []);

  useEffect(() => {
    if (props.settings) {
      setFields((prevState) => ({
        ...prevState,
        name: props.settings.name,
        logo: props.settings.logo,
        adminCommission: props.settings.adminCommission,
        promoCode: props.settings.promoCode,
        homePageBannerSetting: {
          topHeading: "",
          subHeading: "",
          description: "",
          ...(props.settings.homePageBannerSetting || {}),
          item: props.settings.homePageBannerSetting?.item
            ? {
                label: props.settings.homePageBannerSetting.item.name,
                value: props.settings.homePageBannerSetting.item._id,
              }
            : null,
        },
        footerSetting: props.settings.footerSetting || {
          description: "",
        },
        socialMediaSetting: props.settings.socialMediaSetting || {
          facebook: "",
          twitter: "",
          instagram: "",
          linkedin: "",
          discord: "",
        },
        
        supportSetting: props.settings.supportSetting || {
          email: "",
          mobileNumber: "",
          address: "",
        },
      }));
    }
  }, [JSON.stringify(props?.settings)]);

  const handleChange = (name) => (event) => {
    const { value } = event.target;

    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  const handleInnerChange = (name) => (name2) => (event) => {
    const { value } = event.target;

    setFields((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], [name2]: value },
    }));
  };

  function submit() {
    onPutSettings({
      ...fields,
      homePageBannerSetting: {
        ...fields.homePageBannerSetting,
        item: fields.homePageBannerSetting.item?.value,
      },
      _id: props.settings?._id,
    });
  }

  return (
    <div className="page-content">
      <Container fluid>
        {/* <Breadcrumbs title="Settings" breadcrumbItem="Settings" /> */}

        <Row>
          <Col sm={1} lg={2}></Col>

          <Col sm={10} lg={8}>
            <Card>
              <CardBody className="spinner-content">
                {error && typeof error === "string" ? (
                  <Alert color="danger">{error}</Alert>
                ) : null}

                {loading && <div className="spinner"></div>}

                <GeneralSettings fields={fields} handleChange={handleChange} />

                <hr className="my-3" />

                {/* <HomePageSettings
                  fields={fields.homePageBannerSetting}
                  handleChange={handleInnerChange("homePageBannerSetting")}
                />

                <hr className="my-3" />

                <FooterSettings
                  fields={fields.footerSetting}
                  handleChange={handleInnerChange("footerSetting")}
                />

                <hr className="my-3" /> */}

                <SocialMediaSettings
                  fields={fields.socialMediaSetting}
                  handleChange={handleInnerChange("socialMediaSetting")}
                />

                <hr className="my-3" />

               

                <SupportSettings
                  fields={fields.supportSetting}
                  handleChange={handleInnerChange("supportSetting")}
                />

                <hr className="my-3" />

                <Row>
                  <Col></Col>

                  <Col className="d-flex justify-content-end">
                    {loading && <Spinner color="primary" className="mr-2" />}

                    <Button className="buttoncolor" onClick={submit}>
                      Save Changes
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col sm={1} lg={2}></Col>
        </Row>
      </Container>
    </div>
  );
};

Settings.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  onGetSettings: PropTypes.func,
  onPutSettings: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onPutSettings: (data) => dispatch(putSettings(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
);
