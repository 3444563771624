import {
  GET_DASHBOARD_REPORTS,
  GET_DASHBOARD_REPORTS_SUCCESS,
  GET_DASHBOARD_REPORTS_FAIL,
} from "./actionTypes"

export const dashboardReports = data => {
  return {
    type: GET_DASHBOARD_REPORTS,
    data,
  }
}

export const dashboardSuccess = data => ({
  type: GET_DASHBOARD_REPORTS_SUCCESS,
  payload: data,
})

export const dashboardFail = error => ({
  type: GET_DASHBOARD_REPORTS_FAIL,
  payload: error,
})
