import React, { useState, useEffect } from "react";
import { Button, textarea } from "react-bootstrap";
import {
  Col,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import Addbalancemod from "./Addbalancemod";
import Removebalance from "./Removebalance";
import toastr from "toastr";
import { useDebounce } from "common/debouncer";
const Profilebalance = (props) => {

  const { customer, onUpdateProfile, userBalance } = props;
  const [addmod, setAddmod] = useState(false);
  const [removebal, setRemovebal] = useState(false);
  const [profileInfo, setProfileInfo] = useState({});
  const [crystalBalance, setCrystalBalance] = useState(0);
  const [addBalance, setAddBalance] = useState({ amount: 0, type: "PPT" });
  const [reduceBalance, setReduceBalance] = useState({ amount: 0, type: "PPT" });
  const [comment, setComment] = useState("");

  const STATUS = {
    "active": "Active",
    "blocked": "Banned"
  }

  useEffect(() => {
    setProfileInfo(customer);
    setComment(customer?.adminComments)
  }, [customer]);

  useEffect(() => {
    setCrystalBalance((customer?.crystalBalance || 0) + (userBalance?.amount || 0));
  }, [customer, userBalance]);

  const handleChange = (e, key) => {
    console.log("key", key, e?.target?.value);
    if (key === "type") {
      setAddBalance((prev) => ({ ...prev, type: e.target.value }));
    }
    else if (key === "amount") {
      setAddBalance((prev) => ({ ...prev, amount: parseFloat(e.target.value) }));
    }
  }

  const handleChangeReduce = (e, key) => {
    if (key === "type") {
      setReduceBalance((prev) => ({ ...prev, type: e.target.value }));
    }
    else if (key === "amount") {
      setReduceBalance((prev) => ({ ...prev, amount: parseFloat(e.target.value) }));
    }
  }

  const handleChangeComment = (name) => (event) => {
    console.log("comment", event.target.value, name)
    setComment(() => (event.target.value));
    setTimeout(() => {
      handleSaveComment(event.target.value);
    }, 100);
  };

  const handleSaveComment = useDebounce((value) => {
    const callback = (res) => {
      if (res.status === "success") {
        toastr.success("Comment updated successfully.");
        setProfileInfo(res?.data)
      }
      else {
        toastr.error(res.message);
      }

    };
    onUpdateProfile({ adminComments: value, id: profileInfo?._id }, callback)
  }, 1000);

  // const handleSaveComment = (value) => {
  //   const callback = (res) => {
  //     if (res.status === "success") {
  //       toastr.success("Comment updated successfully.");
  //       setProfileInfo(res?.data)
  //     } 
  //     else {
  //       toastr.error(res.message);
  //     }

  //   };
  //   onUpdateProfile({ adminComments: value, id: profileInfo?._id }, callback)
  // }

  const handleDropdown = (name) => (value) => {
    console.log("name", name, value);
    let status = null;
    if (value == "Ban") {
      status = "blocked";
    }
    else if (value == "Unban") {
      status = "active";
    }
    const callback = (res) => {
      if (res.status === "success") {
        toastr.success("User status updated successfully.");
        setProfileInfo(res?.data);
      }
      else {
        toastr.error(res.message);
      }
    };
    onUpdateProfile({ status: status, id: profileInfo?._id }, callback)
  }
  console.log("adminRole---->", props?.adminRole)
  return (
    <>
      <Addbalancemod
        show={addmod}
        onhide={() => setAddmod(false)}
        handleChange={handleChange}
        onAddBalance={() => {
          if (addBalance.type === "PPT") {
            const callback = (res) => {
              setAddmod(false);
              setAddBalance({ amount: 0, type: "PPT" });
              if (res.status === "success") {
                toastr.success("PPT balance updated successfully.");
                setProfileInfo(res?.data);
              }
              else {
                toastr.error(res.message);
              }
            };

            onUpdateProfile({
              id: profileInfo?._id,
              pptBalance: parseFloat(profileInfo?.pptBalance) + parseFloat(addBalance?.amount)
            }, callback)
          }
          else if (addBalance.type === "Crystal") {
            const callback = (res) => {
              setAddmod(false);
              setAddBalance({ amount: 0, type: "PPT" });
              if (res.status === "success") {
                toastr.success("Crystal balance updated successfully.");
                setProfileInfo(res?.data);
                setCrystalBalance((res?.data?.crystalBalance || 0) + (userBalance?.amount || 0));
              }
              else {
                toastr.error(res.message);
              }
            };
            onUpdateProfile({
              id: profileInfo?._id,
              crystalBalance: parseInt(profileInfo?.crystalBalance) + parseInt(addBalance?.amount)
            }, callback)

          }
          else if (addBalance.type === "Energy") {
            const callback = (res) => {
              setAddmod(false);
              setAddBalance({ amount: 0, type: "Energy" });
              if (res.status === "success") {
                toastr.success("Energy balance updated successfully.");
                setProfileInfo(res?.data);
              }
              else {
                toastr.error(res.message);
              }
            };
            onUpdateProfile({
              id: profileInfo?._id,
              gameEnergy: parseInt(profileInfo?.gameEnergy + addBalance?.amount)
            }, callback)

          }
        }}
      />

      <Removebalance
        show={removebal}
        onhide={() => setRemovebal(false)}
        handleChange={handleChangeReduce}
        onReduceBalance={() => {
          if (reduceBalance.type === "PPT") {
            if (reduceBalance.amount > profileInfo?.pptBalance) {
              return toastr.error("Amount should be less than balance");
            }

            const callback = (res) => {
              setRemovebal(false);
              setReduceBalance({ amount: 0, type: "PPT" });
              if (res.status === "success") {
                toastr.success("PPT balance updated successfully.");
                setProfileInfo(res?.data);
              }
              else {
                toastr.error(res.message);
              }
            };
            onUpdateProfile({
              id: profileInfo?._id,
              pptBalance: parseFloat(profileInfo?.pptBalance) - parseFloat(reduceBalance?.amount)
            }, callback)
          }
          else if (reduceBalance.type === "Crystal") {
            if (reduceBalance.amount > profileInfo?.crystalBalance) {
              return toastr.error("Amount should be less than balance");
            }
            const callback = (res) => {
              setRemovebal(false);
              setReduceBalance({ amount: 0, type: "PPT" });
              if (res.status === "success") {
                toastr.success("Crystal balance updated successfully.");
                setProfileInfo(res?.data);
                setCrystalBalance((res?.data?.crystalBalance || 0) + (userBalance?.amount || 0));
              }
              else {
                toastr.error(res.message);
              }
            };
            onUpdateProfile({
              id: profileInfo?._id,
              crystalBalance: parseInt(profileInfo?.crystalBalance) - parseInt(reduceBalance?.amount)
            }, callback)
          }
          else if (reduceBalance.type === "Energy") {
            if (reduceBalance.amount > profileInfo?.gameEnergy) {
              return toastr.error("Amount should be less than balance");
            }
            const callback = (res) => {
              setAddmod(false);
              setAddBalance({ amount: 0, type: "Energy" });
              if (res.status === "success") {
                toastr.success("Energy balance updated successfully.");
                setProfileInfo(res?.data);
              }
              else {
                toastr.error(res.message);
              }
            };
            onUpdateProfile({
              id: profileInfo?._id,
              gameEnergy: parseInt(profileInfo?.gameEnergy - reduceBalance?.amount)
            }, callback)

          }
        }}
      />

      <section className="profile_balance text-start position-relative">
        <div className="profile_img">
          <img
            src={profileInfo?.profileImage ? profileInfo?.profileImage?.link : "/images/memone.png"}
            alt=""
            className="img-fluid" />
        </div>

        <div className="balance_card p-2">

          <ul className="balance_list my-3">
            <li>
              <p>PPT Balance: {profileInfo?.pptBalance?.toFixed(2) || 0}</p>
            </li>
            <li>
              <p>Crystal Balance : {parseInt(crystalBalance) || 0}</p>
            </li>
            <li>
              <p>Total Bonus : {profileInfo?.totalBonus || 0}</p>
            </li>
            <li>
              <p>Game Energy : {profileInfo?.gameEnergy || 0}</p>
            </li>
            <li>
              <p>Box Limit : {profileInfo?.dailyLimit || 0}</p>
            </li>
            <li>
              <p>Daily Earning : {profileInfo?.dailyEarning || 0}</p>
            </li>
            {/* <li>
              <p>BNB : 2</p>
            </li>
            <li>
              <p>BUSD : 32</p>
            </li> */}
          </ul>

          {props?.adminRole !== "SUBADMIN" && <div className="btns_profile d-flex gap-2">
            <Button className="btn_Add"
              onClick={() => setAddmod(true)}
            >Add</Button>
            <Button className="btn_Add"
              onClick={() => setRemovebal(true)}
            >Remove</Button>

          </div>}


          <Col className="mt-3 p-0">
            <FormGroup>
              <textarea
                type="text"
                id="formrow-comments-Input"
                placeholder="Admin Comments"
                value={comment}
                onChange={handleChangeComment("adminComments")}
              />
            </FormGroup>
          </Col>

          {props?.adminRole !== "SUBADMIN" && <div className="blocking_dropdown m-3">
            <p className="label">Current Status: {profileInfo?.status ? STATUS[profileInfo?.status] : ""}</p>
            <UncontrolledDropdown direction="top">
              <DropdownToggle caret>Ban/Unban User</DropdownToggle>
              <DropdownMenu>

                <DropdownItem onClick={() => handleDropdown("status")("Ban")}>Ban</DropdownItem>
                <DropdownItem onClick={() => handleDropdown("status")("Unban")}>Unban</DropdownItem>

              </DropdownMenu>
            </UncontrolledDropdown>
          </div>}
        </div>
      </section>
    </>
  );
};

export default Profilebalance;
