import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import { BLOCKCHAIN_CURRENCY_CODE } from "helpers/contants";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = ( toggleConfirmModal, t) => [
  {
    dataField: "user_id.username",
    text: t("Username"),
    formatter: (_, row) => (row.user_id?.username ? row.user_id?.username : "-"),
},
{
  dataField: "walletAddress",
  text: t("Wallet Address"),

},
{
    dataField: "crystalAmount",
    text: t("Crystal Amount"),

},

{
    dataField: "busdAmount",
    text: t("Busd Amount"),

},

{
    dataField: "fee",
    text: t("Fee"),

},
{
    dataField: "receivableAmount",
    text: t("Receivable Amount"),

},

{
  dataField: "status",
  text: t("Status"),
  formatter: (cellContent, row) => (
    <Badge
      className={
        "text-capitalize font-size-13 badge-soft-" 
      }
      color={row.badgeClass}
    >
      {t(row.status)}
    </Badge>
  ),
},


 
];

export default ListColumns;
