import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";
import { walletHooks } from "helpers/Wallet/walletHooks";
import Web3 from "web3";
// web3
import Web3Intraction from "helpers/web3Intraction";
import {
  getNetworkUrl,
} from "helpers/contants";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  Spinner,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import {
  getActivities,
  getFdCategories,
  airdropNFT,
  cancelNFTSale
} from "store/actions";
import ListColumns, { selectRow } from "./ListColumns";
import ConfirmModal from "./ConfirmModal";
import toastr from "toastr";
import CancelModal from "./CancelModal";

const MobileActivityList = ({
  accessLevel,
  history,
  items,
  totalItems,
  onGetActivities,
  onAirdropActivity,
  onCancelNftSale,
  loading,
  ...props
}) => {
  let auth = localStorage.getItem("authUser");
  let adminRole = JSON.parse(auth)?.data?.role;
  let country = JSON.parse(auth)?.data?.country;
  const wallet = walletHooks();
  const dispatch = useDispatch();
  const categories = useSelector((store) => store?.Categoryes?.fdCategories);
  const { settings } = useSelector((store) => store?.Settings);
  const [internalLoader, setinternalLoader] = useState(false);
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  });
  const [selected, setSelected] = useState([]);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    toAddress: "",
    item: "",
    internalLoader: internalLoader
  });
  const [cancelModal, setCancelModal] = useState({
    isOpen: false, 
    itemId: ""
  });

  const [filter, setFilter] = useState({
    orderBy: "_id",
    order: -1,
    page: 0,
    limit: 20,
    search: "",
    history_type: "bought",
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
  });

  const [searchText, setSearchText] = useState("");
  const [itemsList, setItemsList] = useState([]);
  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalItems,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    // setItemsList([])
    if (adminRole == "SUBADMIN") {
      onGetActivities({ ...filter, country: country });
    }
    else {
      onGetActivities(filter);
    }
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    dispatch(getFdCategories("new", filter));
  }, []);

  useEffect(() => {
    setItemsList(items.map((item, idx) => ({ ...item, _id: idx })) || []);
  }, [items]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText.trim());
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (item, toAddress) => {
    console.log("toggle item", item)
    setConfirmModal((prevState) => ({
      isOpen: !prevState.isOpen,
      item: item ? JSON.stringify(item): "",
      toAddress: toAddress || "",
      internalLoader: internalLoader
    }));
  };

  const toggleCancelModal = (itemId) => {
    console.log("cancel ------>", itemId)
    setCancelModal((prevState) => ({
      isOpen: !prevState.isOpen,
      itemId: itemId
    }));
  };

  const closePopup = () => {
    setConfirmModal((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  const closePopup2 = () => {
    setCancelModal((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  // const airdropConfirm = () => {
  //   const { itemId, isOpen, toAddress } = confirmModal;

  //   const callback = (res) => {
  //     console.log("airdrop response ----->", res);
  //     onGetActivities(filter);
  //   };

  //   setConfirmModal({ isOpen: !isOpen, itemId: null, itemName: null, toAddress: null });
  //   onAirdropActivity({ itemId: itemId, toAddress: toAddress }, callback);
  // };

  const onSearch = (event) => {
    event.preventDefault();
    setFilter((prevState) => ({ ...prevState, search: searchText.trim() }));
  };

  const handleItemStatusChange = (status) => {
    const callback = () => {
      setSelected([]);
      onGetActivities(filter);
    };

    // onPutActivitiesStatus({ _id: selected, status }, callback);
  };

  const airdropConfirm = async (evt) => {
    console.log("confirm modal ----->", confirmModal)
    evt.preventDefault();
    setinternalLoader(true)
    if (!wallet?.isActive) {
      setinternalLoader(false);
      return toastr.error("Please Connect To Metamask!")
    };
    if (!confirmModal?.item) {
      setinternalLoader(false);
      return toastr.error("Please Select NFT")
    };
    let parseData = JSON.parse(confirmModal?.item);
    console.log("nft Data", parseData)
    let web3 = new Web3();
    if (!web3.utils.isAddress(confirmModal.toAddress)) {
      setinternalLoader(false);
      return toastr.error("Invalid wallet address");
    }

    const web3Intraction = new Web3Intraction("BNB", settings);
    const networkUrl = await getNetworkUrl("BNB", settings);
    // wallet.connectWallet();

    await wallet.switchChain({ networkUrl });
    // const contract = await web3Intraction?.getContract(
    //   parseData?.collection_id?.contractAbi,
    //   parseData?.collection_id?.contractAddress
    // );
    const contract = await web3Intraction?.getContractEthers(
      parseData?.collection_id?.contractAbi,
      parseData?.collection_id?.contractAddress
    );
    const getData = await contract.owner();

    console.log("contractMethods", parseData?.attributes)
    console.log("getOwnerData", getData, "walletAccount", wallet?.account)
    let data = {
      type: "BNB",
      collectionId: parseData.collection_id,
      toAddress: web3.utils.toChecksumAddress(confirmModal.toAddress),
      tokenUri: parseData?.token_uri,
      itemId: parseData._id,
      level: parseData?.attributes?.level,
      charge: parseData?.attributes?.charge,
      voucher: parseData.voucher,
      _Shipname: parseData.category_id.catName,
      _Shipcategory: parseData.subcategory_id.catName,
      _MintType: "Airdrop",
      _Level: Number(parseData.attributes.level),
      _Durablity: parseData.attributes.Durablity,
      _Amount: parseData.price
    };
    const callback = (res) => {
      const { status, message } = res;
      if (status == "failure") {
        toastr.error(message);
      } 
      else {
        toastr.success(message);
        onGetActivities(filter);
      }
      setConfirmModal(() =>({ 
        isOpen: false,
        toAddress: "",
        item: "",
        internalLoader: false
      }));
    };
    if ((getData.toLowerCase()) != ((wallet?.account).toLowerCase())) {
      const firstPart = getData.slice(0, 5);
      // Extract the last 5 characters
      const lastPart = getData.slice(-5);
      // Create the transformed string
      const transformedString = `${firstPart}****${lastPart}`;
      setinternalLoader(false);
      setConfirmModal((prev) =>({ 
        isOpen: !prev.isOpen,
        toAddress: "",
        item: "",
        internalLoader: internalLoader
      }));
      return toastr.error(`Please Connect Metamask with ${transformedString} account!`);
    }
    console.log("data._Shipname,", data._Shipname)

    if (data._Shipname == "Ships") {
      try {
        const transaction = await contract.redeem(
          data.toAddress,
          data.voucher,
          data._Shipname,
          data._Shipcategory,
          data._MintType,
          "0",
          data._Level,
          data._Durablity,
        );

        const receipt = await transaction.wait();
        data.hash = receipt.transactionHash;
        setinternalLoader(false);
        onAirdropActivity(data, callback);
      } 
      catch (e) {
        setinternalLoader(false);
        console.log("e1", e)
        toastr.error("Transaction Failed!");
        setConfirmModal((prev) =>({ 
          isOpen: !prev.isOpen,
          toAddress: "",
          item: "",
          internalLoader: false
        }));
      }
    } 
    else if (data._Shipname == "Others") {
      try {
        const transaction = await contract.redeem(
          data.toAddress,
          data.voucher,
          "0",
          data._MintType,
        );

        const receipt = await transaction.wait();
        data.hash = receipt.transactionHash;
        setinternalLoader(false);
        onAirdropActivity(data, callback);
      } 
      catch (e) {
        setinternalLoader(false);
        console.log("e1", e)
        toastr.error("Transaction Failed!");
        setConfirmModal((prev) =>({ 
          isOpen: !prev.isOpen,
          toAddress: "",
          item: "",
          internalLoader: false
        }));
      }
    } 
    else if(data._Shipname == "Pirates") {
      try {
        console.log("datas------->", data.toAddress,
        data.voucher,
        data._MintType,
        data._Level)
        
        const transaction = await contract.redeem(
          data.toAddress,
          data.voucher,
          "0",
          data._MintType,
          data._Level,
          "0"
        );

        const receipt = await transaction.wait();
        data.hash = receipt.transactionHash;
        setinternalLoader(false);
        onAirdropActivity(data, callback);
      } 
      catch (e) {
        setinternalLoader(false);
        console.log("e2", e)
        toastr.error("Transaction Failed!");
        setConfirmModal((prev) =>({ 
          isOpen: !prev.isOpen,
          toAddress: "",
          item: "",
          internalLoader: false
        }));
      }
    }
  };

  const cancelNftSale = async (evt) => {
    console.log("cancel modal ----->", cancelModal)
    evt.preventDefault();
    const callback = (res) => {
      const { status, message } = res;
      if (status == "failure") {
        toastr.error(message);
      } 
      else {
        toastr.success(message);
        onGetActivities(filter);
      }
      setCancelModal(() =>({ 
        isOpen: false,
        itemId: "",
      }));
    };
    onCancelNftSale({ itemId: cancelModal.itemId }, callback);
  };


  // console.log(categories, "categories");
  return (
    <React.Fragment>
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={airdropConfirm}
        item={confirmModal.item}
        toAddress={confirmModal.toAddress}
        internalLoader={internalLoader}
        closePopup={closePopup}
      />

      <CancelModal
        isOpen={cancelModal.isOpen}
        toggle={toggleCancelModal}
        onConfirm={cancelNftSale}
        closePopup={closePopup2}
      />


      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Mobile Sales"
            breadcrumbItem={props.t("Mobile Sales")}
            breadcrumbItems={[{ title: props.t("Mobile Sales") }]}
          />

          <Row className="table-row">
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={itemsList || []}
                        columns={ListColumns(
                          history,
                          toggleConfirmModal,
                          toggleCancelModal,
                          props.t,
                          settings,
                          adminRole
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="3">
                                <SearchInput
                                  {...toolkitProps.searchProps}
                                  triggerSearch={onSearch}
                                  placeholder={props.t("Search")}
                                  searchText={searchText}
                                />
                              </Col>
                              <Col sm="3">
                                <Input
                                  type="select"
                                  onChange={(e) =>
                                    setFilter((prev) => ({
                                      ...prev,
                                      category_id: e.target.value,
                                    }))
                                  }
                                >
                                  <option value="">All</option>
                                  {categories?.map((o, idx) => (
                                    <option key={idx} value={o._id}>
                                      {o.catName}
                                    </option>
                                  ))}
                                </Input>
                              </Col>

                              <Col sm="2">
                                {!!filter.fields.fieldName ||
                                  (!!filter.search && (
                                    <div
                                      className="mr-4 mb-2"
                                      style={{
                                        height: "calc( 1.5em + 0.94rem + 2px)",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setFilter((prevState) => ({
                                            ...prevState,
                                            fields: {
                                              fieldName: "",
                                              fieldValue: "",
                                            },
                                            search: "",
                                          }));
                                          setSearchText("");
                                        }}
                                        className=""
                                      >
                                        {props.t("clear_filters")}
                                      </Link>
                                    </div>
                                  ))}
                              </Col>
                            </Row>

                            {(accesses.canEdit || accesses.canDelete) && (
                              <AnimateHeight
                                duration={500}
                                height={selected.length > 0 ? "auto" : 0}
                              >
                                <Row className="pb-2">
                                  {accesses.canEdit && (
                                    <Col sm="2">
                                      <div className="form-group row">
                                        <label className="col-md-5 pr-0 col-form-label">
                                          {props.t("action")}:
                                        </label>

                                        <div className="col-md-7">
                                          <select
                                            className="custom-select"
                                            onChange={(e) =>
                                              handleItemStatusChange(
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">
                                              {props.t("status")}
                                            </option>
                                            <option value="active">
                                              {props.t("active")}
                                            </option>
                                            <option value="inactive">
                                              {props.t("inactive")}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </Col>
                                  )}

                                  {accesses.canDelete && (
                                    <Col sm="2">
                                      <button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          handleItemStatusChange("archived")
                                        }
                                      >
                                        {props.t("delete")}{" "}
                                        {props.t("selected")}
                                      </button>
                                    </Col>
                                  )}

                                  <Col></Col>
                                </Row>
                              </AnimateHeight>
                            )}

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  // selectRow={selectRow({
                                  //   selected,
                                  //   onSelect,
                                  //   onSelectAll,
                                  // })}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

MobileActivityList.propTypes = {
  items: PropTypes.array,
  totalItems: PropTypes.number,
  onGetActivities: PropTypes.func,
  onCancelNftSale: PropTypes.func
};

const mapStateToProps = ({ Activities }) => ({
  loading: Activities.loading,
  items: Activities.items,
  totalItems: Activities.totalActivities,
});

const mapDispatchToProps = (dispatch) => ({
  onGetActivities: (data) => dispatch(getActivities(data)),
  onAirdropActivity: (data, callback) => dispatch(airdropNFT(data, callback)),
  onCancelNftSale: (data, callback) => dispatch(cancelNFTSale(data, callback))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MobileActivityList))
);
