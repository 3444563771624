import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox"></div>
  ),

  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}></div>
  ),
  ...props,
});

const ListColumns = (
  history,
  UpdateStatus,
  toggleConfirmModal,
  accesses,
  t,
  settings
) => [
  {
    dataField: "name",
    text: t("Name"),
    formatter: (_, row) => {
      return (
        <>
          <p>{row?.name || "----"}</p>
        </>
      );
    },
  },
  {
    dataField: "walletAddress.name",
    text: t("Email"),
    formatter: (_, row) => {
      return (
        <>
          <p>{row?.walletAddress?.email || "----"}</p>
        </>
      );
    },
  },
  {
    dataField: "walletAddress.username",
    text: t("Username"),
    formatter: (_, row) => {
      return (
        <>
          <p>{row?.walletAddress?.username || "----"}</p>
        </>
      );
    },
  },
  {
    dataField: "walletAddress",
    text: t("WalletAddress"),
    formatter: (_, row) => {
      return (
        <>
          <p>{row?.walletAddress?.walletAddress || "----"}</p>
        </>
      );
    },
  },
  {
    dataField: "itemId.name",
    text: t("Item Name"),
    formatter: (_, row) => {
      return (
        <>
          <p>{row?.itemId?.name || "----"}</p>
        </>
      );
    },
  },
  {
    dataField: "tnxHash",
    text: t("TnxHash"),
    formatter: (_, row) => {
      return (
        <>
          <p>
            <a
              href={
                settings?.blockchainNetworkMode == "mainnet"
                  ? `https://bscscan.com/tx/${row?.tnxHash}`
                  : `https://testnet.bscscan.com/tx/${row?.tnxHash}`
              }
              target={"_blank"}
            >
              {row?.tnxHash}
            </a>
          </p>
        </>
      );
    },
  },

  {
    text: t("Created Date"),
    dataField: "date_created_utc",
    sort: true,
    formatter: (_, row) => (
      <>
        {moment(row.date_created_utc).local().format("D MMM YYYY hh:mm A")}
        {/* moment(row.date_created_utc).format("DD MMM YYYY hh:mm A"), */}
      </>
    ),
  },
];

export default ListColumns;
