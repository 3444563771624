import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Row, Col } from "react-bootstrap";


export default function Confirmationmod(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add_balance"
    >
      <Modal.Body>
        <div className="modal_connect position-relative">
          <a
            href="javascript:void(0)"
            className="close-modal"
            onClick={props.onhide}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 28 28"
              fill="none"
            >
              <rect width="28" height="28" rx="14" fill="#42a498" />
              <path
                d="M9.34766 18.4277C9.76368 18.8379 10.4844 18.8203 10.8652 18.4395L14 15.3047L17.1231 18.4336C17.5273 18.8379 18.2246 18.8379 18.6348 18.4219C19.0508 18.0059 19.0566 17.3086 18.6523 16.9043L15.5293 13.7754L18.6523 10.6523C19.0566 10.248 19.0508 9.55078 18.6348 9.14063C18.2188 8.72461 17.5273 8.71875 17.1231 9.12305L14 12.2461L10.8652 9.11719C10.4844 8.73633 9.76368 8.71875 9.34766 9.13477C8.9375 9.55078 8.94922 10.2656 9.33594 10.6465L12.4707 13.7754L9.33594 16.9102C8.94922 17.291 8.93164 18.0117 9.34766 18.4277Z"
                fill="white"
                fill-opacity="0.8"
              />
              <rect
                x="0.5"
                y="0.5"
                width="27"
                height="27"
                rx="13.5"
                stroke="black"
                stroke-opacity="0.04"
              />
            </svg>
          </a>

          <div className="confirmation_modal">
            <div className="icon_confrimation">
            <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 24 24"><path fill="#ff9800" d="M12 17q.425 0 .713-.288Q13 16.425 13 16t-.287-.713Q12.425 15 12 15t-.712.287Q11 15.575 11 16t.288.712Q11.575 17 12 17Zm0-4q.425 0 .713-.288Q13 12.425 13 12V8q0-.425-.287-.713Q12.425 7 12 7t-.712.287Q11 7.575 11 8v4q0 .425.288.712q.287.288.712.288Zm0 9q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4Q8.65 4 6.325 6.325Q4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"/></svg>
            </div>
            <p>Are you sure you want to remove wallet address? </p>

            <div className="confirmation_btn d-flex gap-3 justify-content-center">
              <Button className="btn_back" onClick={props.onhide}>
                No, cancel
              </Button>
              <Button className="btn_Addmod" onClick={props.onConfirm}>Yes,remove it</Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
