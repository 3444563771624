export const MATCH_HISTORY_API_FAIL = "MATCH_HISTORY_API_FAIL";

// TOURNAMENTS
export const GET_MATCH_HISTORY = "GET_MATCH_HISTORY";
export const GET_MATCH_HISTORY_FAIL = "GET_MATCH_HISTORY_FAIL";
export const GET_MATCH_HISTORY_SUCCESS = "GET_MATCH_HISTORY_SUCCESS";

export const VIEW_MATCH_HISTORY = "VIEW_MATCH_HISTORY";
export const VIEW_MATCH_HISTORY_FAIL = "VIEW_MATCH_HISTORY_FAIL";
export const VIEW_MATCH_HISTORY_SUCCESS = "VIEW_MATCH_HISTORY_SUCCESS";


export const END_MATCH = "END_MATCH";
export const END_MATCH_FAIL = "END_MATCH_FAIL";
export const END_MATCH_SUCCESS = "END_MATCH_SUCCESS";
