import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip, img } from "reactstrap";
import moment from "moment-timezone";

const STATUS = {
  "active":"Active", 
  "inactive":"Inactive"
}

const TYPE = {
  "system":"System", 
  "primary":"Primary"
}

const NOTIFYTYPE = {
  "selected":"Selected", 
  "all":"All"
}

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = ( t) => [
  {
    dataField: "title",
    text: t("Title"),
    formatter: (_, row) => (row?.title_en ? row.title_en : "-"),
  },
  {
    dataField: "message",
    text: t("Message"),
    formatter: (_, row) => (row?.message_en ? row.message_en : "-"),
  },
  {
    dataField: "type",
    text: t("Type"),
    formatter: (_, row) => (row?.type ? TYPE[row.type] : "-"),
  },
  {
    dataField: "notifyType",
    text: t("Notify Type"),
    formatter: (_, row) => (row?.notifyType ? NOTIFYTYPE[row.notifyType]: "-"),
  },
  {
    dataField: "userIds",
    text: t("User Count"),
    formatter: (_, row) => (row?.userIds?.length > 0 ? row?.userIds?.length : "-"),
  },
  {
    dataField: "createdAt",
    text: t("Sent Date"),
    sort: true,
    sortValue: (_, row) =>row?.createdAt,
    formatter: (_, row) => (row?.createdAt ? moment(row?.createdAt).format("YYYY-MM-DD HH:mm:ss") : "--"),
  },
];

export default ListColumns;
