import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Signup from "../pages/Authentication/Signup";
import Airdrop from "pages/Settings/Airdrop";

import ProfileEdit from "../pages/Authentication/EditProfile";
import ProfileChangePassword from "../pages/Authentication/ChangePassword";
import GovernanceControl from "../pages/GovernanceControl";

import Dashboard from "../pages/Dashboard";
import TokenManagement from "../pages/TokenManagement";

import Customers_List from "../pages/Customers/CustomersList";
// import Customers_Profile from "../pages/Customers/CustomerProfile"

import CrewCodes_List from "../pages/CrewCodes";

import CrewMembers_List from "../pages/CrewCodes/CrewMembers";
import TransactionsList from "../pages/Transactions/List";

import ViewCustomer from "../pages/Customers/ViewCustomer";

import Settings from "../pages/Settings";
import Logs from "../pages/Logs";
import TransferLogs from "../pages/TransferLogs";

import MiniGameSettings from "../pages/Mini-Games-Settings";
import MobileAppSettings from "../pages/Mobile-App-Settings";
import MobileDailyLimit from "../pages/Mobile-Daily-Limit/List/index";
import EditMobileLimit from "../pages/Mobile-Daily-Limit/Edit";


import RewardHistory from "../pages/Rewards";
// Content Page
import ContentPage from "../pages/ContentPage/List";
import ContentPageAddEdit from "../pages/ContentPage/AddEdit";

// import General from "../pages/Settings/General";

import PinataIpfs from "pages/Settings/PinataIpfs";
import BlockchainNetwork from "../pages/Settings/BlockchainNetwork";
import Wallet from "../pages/Settings/Wallet";

import Document from "../pages/Document/List";
import DocumentAddEdit from "../pages/Document/AddEdit";

import CollectionsList from "../pages/Collections/List";
import CollectionAdd from "../pages/Collections/AddEdit";
import ViewCollection from "../pages/Collections/ViewCollection";

import GamesList from "../pages/Games/List";
import AddGame from "../pages/Games/Add";
import ViewTournament from "../pages/Games/View";

import MobileActivitiesList from "../pages/Mobile-Activities"

import TournamentHistoryList from "../pages/GamesHistory/List";
import ViewTournamentHistory from "../pages/GamesHistory/View";

import WithdrawList from "../pages/Mini-Games-Withdraw/Withdraw-List";
import WithdrawHistory from "../pages/Mini-Games-Withdraw/Withdraw-History";

import MatchHistoryList from "../pages/MatchHistory/List";
import ViewMatchHistory from "../pages/MatchHistory/View";

import AddBalance from "../pages/AddBalance/Add";
import RemoveBalance from "../pages/AddBalance/Remove";
import BalanceList from "../pages/AddBalance/List";

import ContractSettings from "../pages/Settings/Contract";
import Rewards from "pages/Settings/Rewards";
import ActivitiesList from "../pages/Activities/List";
import Faqs from "../pages/Faq/List";
import FaqAddEdit from "../pages/Faq/AddEdit";

import PromoCode from "../pages/Promo/List";
import PromoCodeAddEdit from "../pages/Promo/AddEdit";

import ItemsList from "../pages/Items/List";
import AdminItemsList from "../pages/AdminItems/List";

import AdminLayersList from "../pages/AdminLayers/List";
import AdminLayersAdd from "../pages/AdminLayers/AddEdit";
import AdminLayersView from "../pages/AdminLayers/ViewItem";

import CrystalPackagePurchaseList from "../pages/CrystalPackagePurchase";
import CrystalPackageList from "../pages/CrystalPackage/List";
import CrystalPackageAdd from "../pages/CrystalPackage/AddEdit";

import OperatorList from "../pages/Operators/List";
import OperatorAdd from "../pages/Operators/AddEdit";

import AddItem from "../pages/AdminItems/AddEdit";
import ChangeStatus from "../pages/AdminItems/changeStatus";
import ViewItem from "../pages/Items/ViewItem";
import AdminViewItem from "../pages/AdminItems/ViewItem";

import AddCategory from "../pages/Category/AddEdit";
import Categories from "../pages/Category/List";

import Leaderboard from "../pages/Mini-Games-Leaderboard"

import Dashboardtheme from "pages/HomeProfile/myprofile-theme";

import SailHistory from "../pages/SailHistory/List";
import CustomerActivity from "../pages/UserActivity";
import BonusHistory from "../pages/BonusHistory/List";
import CrystalPurchaseHistory from "../pages/CrystalPurchaseHistory/List";
import MGameSettings from "../pages/Mobile-App-Settings/Mini-Game";
import MobileGameHistory from "../pages/MobileGameHistory/List";
import MobileEnergyHistory from "../pages/MobileGameEnergyHistory/List";
import MobileGameLeaderboardHistory from "../pages/MobileGameLeaderboardHistory/List";
import MobileReferralClaim from "../pages/MobileReferralClaim/List";
import MobileGameDetail from "../pages/MobileGameHistory/View/index";
import UserMobileGameDetail from "../pages/HomeProfile/LuckyPirateHistory/view";
import LevelUpgradeSettings from "../pages/Mobile-App-Settings/Lvl-upgrade";
import NftLevelUpgradeHistory from "../pages/NftUpgradeHistory";
import NftRepairHistory from "../pages/NftRepairHistory";
import Languages from "../pages/Languages";
import AddMultipleItem from "../pages/AdminItems/AddMultiple";
import AddMultipleSameItem from "../pages/AdminItems/AddSameMultiple";

import ManageNotifications from "../pages/ManageNotification/list";

import SendNotification from "../pages/ManageNotification/add";

const userRoutes = (role, accessLevel) => [
  // Logout
  { path: "/logout", exact: true, component: Logout },
  { path: "/edit-profile", exact: true, component: ProfileEdit },
  {
    path: "/change-password",
    exact: true,
    component: ProfileChangePassword,
  },
  { path: "/dashboard", component: Dashboard },
  { path: "/users", component: Customers_List },
  // { path: "/users/profile/:id", component: ViewCustomer },
  { path: "/users/profile/:id", component: Dashboardtheme },
  { path: "/logs", component: Logs },
  { path: "/transaction-logs", component: TransferLogs },

  { path: "/user-activity", component: CustomerActivity},

  { path: "/content-pages", component: ContentPage },
  { path: "/content-pages/add", component: ContentPageAddEdit },
  { path: "/content-pages/:id/edit", component: ContentPageAddEdit },

  { path: "/token", component: TokenManagement },
  { path: "/ppg", component: TokenManagement },
  // { path: "/gov", component: GovernanceControl },

  { path: "/transactions", component: TransactionsList },

  { path: "/general", component: Settings },
  { path: "/blockchain", component: BlockchainNetwork },

  { path: "/wallet", component: Wallet },

  { path: "/documents", component: Document },
  { path: "/documents/add", component: DocumentAddEdit },
  { path: "/documents/:id/edit", component: DocumentAddEdit },

  { path: "/collections", component: CollectionsList },
  { path: "/collection/add", component: CollectionAdd },
  { path: "/collections/view/:id", component: ViewCollection },
  { path: "/contract", component: ContractSettings },
  { path: "/rewards", component: Rewards },

  { path: "/games", component: GamesList },
  { path: "/games/add", component: AddGame },
  { path: "/games/:id/edit", component: AddGame },
  { path: "/games/view/:id", component: ViewTournament },

  { path: "/add-balance", component: AddBalance },
  { path: "/remove-balance", component: RemoveBalance },

  { path: "/balance/list", component: BalanceList },

  { path: "/tournament-history", component: TournamentHistoryList },
  { path: "/tournament-history/view/:id", component: ViewTournamentHistory },

  { path: "/pending-withdraw", component: WithdrawList },
  { path: "/withdraw-history", component: WithdrawHistory },

  { path: "/match-history", component: MatchHistoryList },
  { path: "/match-history/view/:id", component: ViewMatchHistory },

  { path: "/activities", component: ActivitiesList },
  { path: "/faqs", component: Faqs },
  { path: "/faqs/add", component: FaqAddEdit },
  { path: "/faqs/:id/edit", component: FaqAddEdit },
  { path: "/mobile-activities", component: MobileActivitiesList },

  { path: "/mini-games-general", component: MiniGameSettings },
  { path: "/mobile-app-general", component: MobileAppSettings },
  { path: "/daily-limit", component: MobileDailyLimit },
  { path: "/edit-daily-limit/:id", component: EditMobileLimit },
  { path: "/sail-history", component: SailHistory },
  

  { path: "/mini-games-rewards", component: RewardHistory },
  { path: "/mini-games-leaderboard", component: Leaderboard },

  { path: "/promo-codes", component: PromoCode },
  { path: "/promo-codes/add", component: PromoCodeAddEdit },
  { path: "/promo-codes/:id/edit", component: PromoCodeAddEdit },

  { path: "/items", component: ItemsList },
  { path: "/adminitems", component: AdminItemsList },
  { path: "/adminitems/add", component: AddItem },
  { path: "/items/view/:id", component: ViewItem },
  { path: "/items/change/:id", component: ChangeStatus },
  { path: "/adminitems/view/:id", component: AdminViewItem },

  { path: "/background/layer", component: AdminLayersList },
  { path: "/background/layer/add", component: AdminLayersAdd },
  { path: "/background/layer/:id/edit", component: AdminLayersAdd },
  { path: "/background/layer/view/:id", component: AdminLayersView },

  { path: "/crystal/package", component: CrystalPackageList },
  { path: "/crystal/package/add", component: CrystalPackageAdd },
  { path: "/crystal/package/:id/edit", component: CrystalPackageAdd },

  { path: "/operator/list", component: OperatorList },
  { path: "/operator/add", component: OperatorAdd },
  { path: "/operator/:id/edit", component: OperatorAdd },

  {path: "/crystal/package/purchase", component: CrystalPackagePurchaseList },

  { path: "/airdrop", component: Airdrop },

  { path: "/categories", component: Categories },
  { path: "/add_category", component: AddCategory },
  { path: "/editCategory/:id/edit", component: AddCategory },
  { path: "/crew-codes", component: CrewCodes_List },
  { path: "/crew-codes/:_id", component: CrewMembers_List },
  { path: "/bonus-history", component: BonusHistory },
  { path: "/crystal-purchase-history", component: CrystalPurchaseHistory },
  { path: "/mobile-game-settings", component: MGameSettings }, 
  { path: "/nft-lvlup-settings", component: LevelUpgradeSettings},
  { path: "/mobile-game-history", component: MobileGameHistory },
  { path: "/mobile-game-history/detail/:id", component: MobileGameDetail },
  { path: "/user-game-history/detail/:id", component: UserMobileGameDetail },
  { path: "/mobile-energy-history", component: MobileEnergyHistory }, 
  { path: "/mobile-leaderboard-history", component: MobileGameLeaderboardHistory }, 
  { path: "/referral-claim-history", component: MobileReferralClaim },

  { path: "/nft-repair-history", component: NftRepairHistory},
  { path: "/nft-upgrade-history", component: NftLevelUpgradeHistory},
  { path: "/languages", component: Languages },
  { path: "/adminitems/add/multiple", component: AddMultipleItem },
  { path: "/adminitems/multiple/add", component: AddMultipleSameItem },
  { path: "/manage-notifications", component: ManageNotifications },
  { path: "/notifications/send", component: SendNotification },
  { path: "/", exact: true, component: () => role && role === "ADMIN" ? <Redirect to="/dashboard" />: <Redirect to= { accessLevel && accessLevel.filter((item) => {  return item.isSelected === true })?.[0]?.route || '/dashboard'}/> },
];

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/signup", component: Signup },

  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

export { userRoutes, authRoutes };
