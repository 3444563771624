export const DOCUMENT_API_FAIL = "DOCUMENT_API_FAIL";

// Documents
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENTS_FAIL = "GET_DOCUMENTS_FAIL";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";

// Add document
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const ADD_DOCUMENT_FAIL = "ADD_DOCUMENT_FAIL";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";

// Get document
export const GET_DOCUMENT = "GET_DOCUMENT";
export const GET_DOCUMENT_FAIL = "GET_DOCUMENT_FAIL";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";

// update document
export const PUT_DOCUMENT = "PUT_DOCUMENT";
export const PUT_DOCUMENT_FAIL = "PUT_DOCUMENT_FAIL";
export const PUT_DOCUMENT_SUCCESS = "PUT_DOCUMENT_SUCCESS";

// Delete document
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_DOCUMENT_FAIL = "DELETE_DOCUMENT_FAIL";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
