import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
/* import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import EditorUploadAdapterPlugin from "../../../plugins/ckeditor/UploadAdapterPlugin" */
import CKEditor from "react-ckeditor-component";
import FormButton from "components/Common/FormButtons";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

import { addPromoCode, getPromoCode, putPromoCode } from "store/actions";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";

const AddCustomer = ({
  accessLevel,
  history,
  error,
  onAddPromoCode,
  onGetPromoCode,
  onPutPromoCode,
  promoCode,
  loading,
  ...props
}) => {
  const { id } = useParams();
  const didMountRef = useRef(null);

  const [submitInfo, setSubmitInfo] = useState(null);
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
  });
  const [fields, setFields] = useState({
    promoCode: "",
    limit: "",
    status: "active",
  });

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    if (id) {
      onGetPromoCode(id);
    }
  }, [id]);

  useEffect(() => {
    if (didMountRef.current) {
      if (!!promoCode && !!promoCode.promoCode) {
        try {
          setFields({
            promoCode: promoCode.promoCode || "",
            limit: promoCode.limit || "",
            status: promoCode.status || "active",
          });
        } catch (err) {
          console.log("parsing error ", err);
        }
      }
    } else didMountRef.current = true;
  }, [JSON.stringify(promoCode)]);

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (loading) return;

    if (id) {
      if (!accesses.canEdit) return;
      try {
        onPutPromoCode({ _id: promoCode._id, ...fields }, history);
        setSubmitInfo("Edit  successfully!!");
      } catch {
        setSubmitInfo(" Some Error Occurred !!");
      }
    } else {
      try {
        if (!accesses.canAdd) return;
        onAddPromoCode({ ...fields }, history);
        setSubmitInfo("Added successfully!!");
      } catch {
        setSubmitInfo(" Some Error Occurred !!");
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Promo Codes"
            breadcrumbItem={
              (id ? props.t("edit") : props.t("add")) + " " + props.t("Promo Code")
            }
            breadcrumbItems={[
              { title: props.t("Promo Codes"), link: "/promo-codes" },
              { title: id ? props.t("edit") : props.t("add") },
            ]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form onSubmit={onSubmit} className="spinner-content">
                    {error && typeof error === "string" ? (
                      <Alert color="danger">{error}</Alert>
                    ) : null}

                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Promo Code")}
                          </Label>
                          <Input
                            type="text"
                            id="formrow-firstname-Input"
                            value={fields.promoCode}
                            onChange={handleChange("promoCode")}
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Limit")}
                          </Label>
                          <Input
                            type="number"
                            min={1}
                            id="formrow-firstname-Input"
                            value={fields.limit}
                            onChange={handleChange("limit")}
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>{props.t("status")}</Label>

                          <div className="status-switch square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              switch="none"
                              checked={fields.status == "active"}
                              onChange={() => {
                                const value =
                                  fields.status == "active"
                                    ? "inactive"
                                    : "active";

                                handleChange("status")({ target: { value } });
                              }}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label={props.t("active")}
                              data-off-label={props.t("inactive")}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormButton
                      needSubmit={id ? accesses.canEdit : accesses.canAdd}
                      goBack={() => history.goBack()}
                    />

                    {loading && (
                      <div className="spinner">
                        <Spinner color="primary" />
                      </div>
                    )}
                  </Form>
                  {/* <div className="w-64">
                    {submitInfo && <Alert>{submitInfo}</Alert>}
                  </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddCustomer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  promoCode: PropTypes.object,
  onAddPromoCode: PropTypes.func,
  onGetPromoCode: PropTypes.func,
  onPutPromoCode: PropTypes.func,
};

const mapStateToProps = ({ promoCodes }) => ({
  error: promoCodes.error,
  loading: promoCodes.loading,
  promoCode: promoCodes.promocode,
});

const mapDispatchToProps = (dispatch) => ({
  onAddPromoCode: (data, history) => dispatch(addPromoCode(data, history)),
  onGetPromoCode: (id) => dispatch(getPromoCode(id)),
  onPutPromoCode: (data, history) => dispatch(putPromoCode(data, history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCustomer))
);
