import {
    GET_CRYSTAL_PURCHASE_HISTORY,
    GET_CRYSTAL_PURCHASE_HISTORY_SUCCESS,
    GET_CRYSTAL_PURCHASE_HISTORY_FAIL,
  } from "./actionTypes";
  
  
  /* Get Crytal Purchase History */
  export const getCrystalPurchaseHistory = (data) => ({
    type: GET_CRYSTAL_PURCHASE_HISTORY,
    payload: data,
  });
  
  export const getCrystalPurchaseHistorySuccess = (sails) => ({
    type: GET_CRYSTAL_PURCHASE_HISTORY_SUCCESS,
    payload: sails,
  });
  
  export const getCrystalPurchaseHistoryFail = (error) => ({
    type: GET_CRYSTAL_PURCHASE_HISTORY_FAIL,
    payload: error,
  });