import {
  ADD_TOURNAMENT,
  REMOVE_TOURNAMENTS,
  UPDATE_TOURNAMENT,
  VIEW_TOURNAMENT,
} from "helpers/url_helper";
import {
  ADD_BALANCE,
  ADD_BALANCE_SUCCESS,
  ADD_BALANCE_FAIL,
  GET_BALANCE_HISTORY,
  GET_BALANCE_HISTORY_SUCCESS,
  GET_BALANCE_HISTORY_FAIL,
  GET_USER_BALANCE,
  GET_USER_BALANCE_SUCCESS,
  GET_USER_BALANCE_FAIL,
  REMOVE_BALANCE,
  REMOVE_BALANCE_SUCCESS,
  REMOVE_BALANCE_FAIL,
  GET_ACTIVITY_HISTORY,
  GET_ACTIVITY_HISTORY_FAIL,
  GET_ACTIVITY_HISTORY_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  tournamentDetail: [],
  balanceHistory: [],
  activityHistory: [],
  balance: [],
  totalBalance: 0,
  date: "",
  amount: 0
};

const AddBalance = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* add tournament */
    case ADD_BALANCE:
      return {
        ...state,
        loading: true,
      };

    case ADD_BALANCE_SUCCESS:
      return {
        ...state,
        balance: action.payload.data || [],
        totalBalance: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case ADD_BALANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case REMOVE_BALANCE:
      return {
        ...state,
        loading: true,
      };

    case REMOVE_BALANCE_SUCCESS:
      return {
        ...state,
        balance: action.payload.data || [],
        totalBalance: action.payload.totalCount || 0,
        loading: false,
        error: "",

      };

    case REMOVE_BALANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    /* Get Balance */
    case GET_BALANCE_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case GET_BALANCE_HISTORY_SUCCESS:
      console.log("action.payload.data", action.payload.data)
      return {
        ...state,
        balanceHistory: action.payload.data || [],
        totalBalance: action.payload.total || 0,
        loading: false,
        error: "",
      };

    case GET_BALANCE_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };


    case GET_ACTIVITY_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case GET_ACTIVITY_HISTORY_SUCCESS:
      return {
        ...state,
        activityHistory: action.payload.data || [],
        totalBalance: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case GET_ACTIVITY_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    /* Get User Balance */
    case GET_USER_BALANCE:
      return {
        ...state,
        loading: true,
      };

    case GET_USER_BALANCE_SUCCESS:
      return {
        ...state,
        balanceHistory: action.payload.data || [],
        totalBalance: action.payload || 0,
        loading: false,
        error: "",
      };

    case GET_USER_BALANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default AddBalance;
