import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Spinner,
  Badge,
  Label,
} from "reactstrap";
import moment from "moment";

//Import Images
import profile1 from "assets/images/profile-img.png";

// Breadcrumbs
import Breadcrumbs from "components/Common/Breadcrumb2";

// Actions
import { viewMatchHistory, viewTournament } from "store/actions";
import axios from "axios";
import { viewTournamentsHistory } from "store/gameHistory/actions";
import { indexOf } from "lodash";
import { Accordion, Modal } from "react-bootstrap";
import SnapModal from "./modal/SnapModal";

const ViewMatchHistory = (props) => {
  const {
    match: { params },
    onGetMatchDetail,
    MatchHistoryDetail,
    loading,
  } = props;
  useEffect(() => {
    console.log(params.id, "params.idparams.id");
    if (params && params.id) {
      onGetMatchDetail(params.id);
    }
  }, [params.id]);

  function downloadObjectAsJson(verifyLink) {
    const exportName = "abi.json";
    fetch(verifyLink)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        var dataStr =
          "data:text/json;charset=utf-8," +
          encodeURIComponent(JSON.stringify(data));
        var downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      })
      .catch(function (err) {
        console.warn("Something went wrong.", err);
      });
  }
  const [matchResult, setmatchResult] = useState();
  const [matchDetail, setmatchDetail] = useState();
  const [gameRound, setGameRound] = useState();
  useEffect(() => {
    if (MatchHistoryDetail) {
      setmatchResult(MatchHistoryDetail?.results);
      setmatchDetail(MatchHistoryDetail?.details);
    }
  }, [MatchHistoryDetail]);

  useEffect(() => {
    if (matchResult && matchResult.length > 0) {
      let arr = matchResult.map((item) => item.result);
      const singleArray = [].concat(...arr);
      setGameRound(singleArray);
    }
  }, [matchResult]);

  console.log(
    // MatchHistoryDetail,
    matchResult,
    "tournamentDetailtournamentDetail view"
  );

  const [SnapShot, setSnapShot] = useState(false);
  const [imgData, setImgData] = useState({});

  const handleSnapShot = (image) => {
    setSnapShot(!SnapShot);
    setImgData(image);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="spinner-content">
          {loading && (
            <div className="spinner">
              <Spinner color="primary" />
            </div>
          )}

          <Breadcrumbs title="Tournaments" breadcrumbItem="detail" />

          <Row className="justify-content-center">
            <Col sm="12">
              <Row>
                <Col>
                  <Card className="overflow-hidden">
                    <CardBody className="">
                      <Row>
                        <Col lg="12" className="my-2">
                          <h4 className="text-primary">Match Details</h4>
                          <ul className="list-unstyled ps-0 mb-0 mt-3 row">
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className=" font-weight-bold">
                                Match Creator :{" "}
                                <span className="text-muted">
                                  {matchDetail?.creator?.username}
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className=" font-weight-bold">
                                Total Round :{" "}
                                <span className="text-muted">
                                  {matchDetail?.totalRound}
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className=" font-weight-bold">
                                Looser Name :{" "}
                                <span className="text-muted">
                                  {matchDetail?.looser?.username || "-"}
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className=" font-weight-bold">
                                Looser Email :{" "}
                                <span className="text-muted">
                                  {matchDetail?.looser?.email || "-"}
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className=" font-weight-bold">
                                Winner Name :{" "}
                                <span className="text-muted">
                                  {matchDetail?.winner?.username}
                                </span>
                              </p>
                            </li>
                            <li className="col-lg-4 col-sm-6 my-2">
                              <p className=" font-weight-bold">
                                Winner Email :{" "}
                                <span className="text-muted">
                                  {matchDetail?.winner?.email}
                                </span>
                              </p>
                            </li>
                          </ul>
                        </Col>
                        <Col lg="12" className="my-2">
                          <h4 className="text-primary">Round Details</h4>
                          {matchResult &&
                            matchResult.length > 0 &&
                            matchResult.map((item, idx) => {
                              return (
                                <Accordion defaultActiveKey={0}>
                                  <Accordion.Item
                                    className="my-2"
                                    eventKey={idx}
                                  >
                                    <Accordion.Header>
                                      Round {item?.round}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <Row>
                                        <Col md="6" className="my-2">
                                          <div className="top text-center border-bottom mb-2">
                                            <h4 className="py-2 m-0 fw-bold theme-clr">
                                              Winner
                                            </h4>
                                          </div>
                                          <ul className="list-unstyled ps-0 mb-0">
                                            <li className="py-2">
                                              <p class=" font-weight-bold m-0">
                                                Name :{" "}
                                                <span class="text-muted">
                                                  {item?.winner_id?.username ||
                                                    "-"}
                                                </span>
                                              </p>
                                            </li>
                                            <li className="py-2">
                                              <p class=" font-weight-bold m-0">
                                                email :{" "}
                                                <span class="text-muted">
                                                  {item?.winner_id?.email}
                                                </span>
                                              </p>
                                            </li>
                                            <li className="py-2">
                                              <Accordion>
                                                <Accordion.Item eventKey="0">
                                                  <Accordion.Header>
                                                    Snapshots
                                                  </Accordion.Header>
                                                  <Accordion.Body>
                                                    <ul className="list-unstyled ps-0 mb-0 d-flex flex-wrap align-items-center gap-10 snapShotList justify-content-center">
                                                      {item?.winner_snapshot.map(
                                                        (data, index) => (
                                                          <li className="">
                                                            <img
                                                              onClick={() =>
                                                                handleSnapShot(
                                                                  data
                                                                )
                                                              }
                                                              src={data}
                                                              alt=""
                                                              className="img-fluid"
                                                            />
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  </Accordion.Body>
                                                </Accordion.Item>
                                              </Accordion>
                                            </li>
                                          </ul>
                                        </Col>
                                        <Col md="6" className="my-2">
                                          <div className="top text-center border-bottom mb-2">
                                            <h4 className="py-2 m-0 fw-bold theme-clr">
                                              Looser
                                            </h4>
                                          </div>
                                          <ul className="list-unstyled ps-0 mb-0">
                                            <li className="py-2">
                                              <p class=" font-weight-bold m-0">
                                                Name :{" "}
                                                <span class="text-muted">
                                                  {item?.looser_id?.username ||
                                                    "-"}
                                                </span>
                                              </p>
                                            </li>
                                            <li className="py-2">
                                              <p class=" font-weight-bold m-0">
                                                email :{" "}
                                                <span class="text-muted">
                                                  {item?.looser_id?.email ||
                                                    "-"}
                                                </span>
                                              </p>
                                            </li>
                                            <li className="py-2">
                                              <Accordion>
                                                <Accordion.Item eventKey="0">
                                                  <Accordion.Header>
                                                    Snapshots
                                                  </Accordion.Header>
                                                  <Accordion.Body>
                                                    <ul className="list-unstyled ps-0 mb-0 d-flex flex-wrap align-items-center gap-10 snapShotList justify-content-center">
                                                      {item?.looser_snapshot.map(
                                                        (data, index) => (
                                                          <li className="">
                                                            <img
                                                              onClick={() => {
                                                                handleSnapShot(
                                                                  data
                                                                );
                                                              }}
                                                              src={data}
                                                              alt=""
                                                              className="img-fluid"
                                                            />
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  </Accordion.Body>
                                                </Accordion.Item>
                                              </Accordion>
                                            </li>
                                          </ul>
                                        </Col>
                                      </Row>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              );
                            })}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col xl="8"></Col>
          </Row>
        </Container>
      </div>
      <SnapModal
        SnapShot={SnapShot}
        setSnapShot={setSnapShot}
        imgData={imgData}
      />
    </React.Fragment>
  );
};

ViewMatchHistory.propTypes = {
  tournaments: PropTypes.any,
  date: PropTypes.string,
  onGetMatchDetail: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = ({ MatchHistory }) => ({
  console: console.log(MatchHistory, "gameeeeeeeeeeeee"),
  loading: MatchHistory.loading,
  MatchHistoryDetail: MatchHistory.matchHistoryDetail?.data,
  date: MatchHistory.date,
  error: MatchHistory.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetMatchDetail: (data) => dispatch(viewMatchHistory(data)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ViewMatchHistory))
);
