import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams, Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import Toastr from "toastr";
import { useDropzone } from "react-dropzone";

import {
  Card,
  CardTitle,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
  Button,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import {
  addItem,
  viewBackgroundImg,
  putBackgroundImg,
  addBackgroundImg,
  uploadFile,
  removeFile,
} from "store/actions";
import { post } from "../../../helpers/api_helper";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { GET_COLLECTIONS, VIEW_CATEGORIES } from "../../../helpers/url_helper";
// import Nft3d from "common/nftUpload/index";

// Images
import avatar4 from "assets/images/users/avatar-9.jpg";

//i18n
import { withTranslation } from "react-i18next";
import toastr from "toastr";
import { acceptOnlyNumber, acceptOnlyNumberForLayer } from "helpers/contants";
import moment from "moment";
import "moment-timezone";

const AddItem = ({
  collections,
  onAddItem,
  onUploadFile,
  onRemoveFile,
  error,
  errorItem,
  onPutItem,
  onGetItem,
  singleImage,
  loading,
  ...props
}) => {
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const { id } = useParams();

  const [fields, setFields] = useState({
    current_owner: obj.data._id,
    author_id: obj.data._id,
    name: "",
    blockchain: "polygon",
    price: "",
    category_id: "",
    subcategory_id: "",
    collection_id: "",
    level: null,
    mediapreview: null,
    thumb: null,
    thumbpreview: null,
    description: "",
    status: "created",
    minted_start_date: null,
    minted_end_date: null,
    saleType: "offer",
    level: 1,
  });

  const history = useHistory();
  const [selectedFiles, setselectedFiles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [thumb, setThumb] = useState(false);

  useEffect(() => {
    if (id) {
      onGetItem(id);
    }
  }, [id]);

  useEffect(() => {
    if (singleImage && id) {
      setFields((prev) => ({
        ...prev,
        level: singleImage.level,

        image: singleImage.image?._id,
      }));
      setselectedFiles([{ preview: singleImage.image?.link || avatar4 }]);
    }
  }, [singleImage]);

  useEffect(() => {
    let formData = {
      orderBy: "date_created_utc",
      order: -1,
      page: 0,
      limit: 500,
      search: "",
      role: "ADMIN",
      fields: [
        {
          fieldName: "productId",
          fieldValue: null,
        },
        {
          fieldName: "",
          fieldValue: "",
        },
      ],
    };

    const headerToken = {
      Authorization: accessToken,
    };
    post(GET_COLLECTIONS, formData, JSON.stringify(headerToken))
      .then((res) => {
        getCollectionList(res.data || []);
      })
      .catch((err) => {
        setLoader(false);
      });
  }, []);

  const [collectionList, getCollectionList] = useState([]);
  // const [categoryList, getCategoryList] = useState([]);

  const uploadFileSuccess = (response, type) => {
    // onPutCustomer({ _id: customer._id, profileImage: response.data._id })
    setLoader(false);
    if (type == "thumb") {
      setFields((prevState) => ({
        ...prevState,
        thumb: response.data._id,
        thumbpreview: response.data.link,
      }));
    } else {
      setFields((prevState) => ({
        ...prevState,
        image: response.data._id,
        mediapreview: response.data.link,
      }));
    }
  };

  function handleAcceptedFiles(files) {
    setLoader(true);
    const ext = files[0].name.split(".").pop();
    try {
      if (files[0] && files[0].size >= 2048576) {
        Toastr.error("Please upload a file smaller than 2 MB");
        setLoader(false);
        return false;
      }
      if (
        ext !== "webm" &&
        ext !== "mp4" &&
        ext !== "gltf" &&
        ext !== "glb" &&
        // ext !== "mp3" &&
        ext !== "png" &&
        ext !== "jpg" &&
        ext !== "jpeg" &&
        // ext !== "wav" &&
        ext !== "ogg" &&
        ext !== "gif"
      ) {
        toastr.error("Only .jpg, .jpeg, .png, .gif extension allow!!");
        setLoader(false);
        return;
      }

      if (
        ext === "webm" ||
        ext === "mp4" ||
        ext === "gltf" ||
        ext === "glb" ||
        // ext === "mp3" ||
        // ext === "wav" ||
        ext === "ogg"
        // ext === "gif"
      ) {
        setThumb(true);
      } else {
        setThumb(false);
      }
      if (selectedFiles.length > 0) {
        if (fields.image) {
          onRemoveFile({ _id: fields.image });

          setFields((prevState) => ({ ...prevState, image: null }));
        }
      }
      console.log(files, "files");
      setFields((prevState) => ({ ...prevState, image: files[0] }));
      onUploadFile({ image: files[0] }, uploadFileSuccess);

      files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );

      setselectedFiles(files);
    } catch (error) {
      console.log(error, "<<=======err");
      setLoader(false);
    }
  }

  function handleAcceptedThumbFiles(files) {
    setLoader(true);
    const ext = files[0].name.split(".").pop();
    try {
      if (files[0] && files[0].size >= 10e6) {
        Toastr.error("Please upload a file smaller than 10 MB");
        setLoader(false);
        return false;
      }
      if (ext !== "png" && ext !== "jpg" && ext !== "jpeg") {
        toastr.error("Only .png,.jpg,.jpeg, extension allow!!");
        setLoader(false);
        return;
      }

      if (selectedFiles.length > 0) {
        if (fields.image) {
          onRemoveFile({ _id: fields.image });

          setFields((prevState) => ({ ...prevState, image: null }));
        }
      }
      console.log(files, "files");
      setFields((prevState) => ({ ...prevState, image: files[0] }));
      onUploadFile({ image: files[0] }, (res) =>
        uploadFileSuccess(res, "thumb")
      );

      files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );

      setselectedFiles(files);
    } catch (error) {
      console.log(error, "<<=======err");
      setLoader(false);
    }
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleChange = (name) => (event) => {
    if (name === "minted_start_date") {
      setFields((prevState) => ({
        ...prevState,
        ["minted_end_date"]: "",
      }));
    }
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (loading) return;
    if (fields.level > 5)
      return toastr.error("Level should be less then or equal to 5");
    if (!fields.image) {
      return toastr.error("Image is required", { timeOut: 5000 });
    }
    const itemCallback = (response) => {
      console.log(response, 1455454);
      if (response.status == "failure") {
        toastr.error(response.message);
      } else {
        if (id) {
          toastr.success("Layer updated successfully", { timeOut: 5000 });
        } else {
          toastr.success("Layer created successfully", { timeOut: 5000 });
        }
        history.push("/background/layer");
      }
    };
    if (id) {
      onPutItem(
        {
          image: fields.image,
          level: fields.level,
          id,
        },
        itemCallback
      );
    } else {
      onAddItem(
        {
          image: fields.image,
          level: fields.level,
        },
        itemCallback
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            {(loading || loader) && (
              <div className="spinner">
                <Spinner color="primary" />
              </div>
            )}
            <Col sm={1} lg={2}></Col>
            <Col sm={10} lg={8}>
              <Breadcrumbs
                title="Background Layers"
                breadcrumbItem={props.t("Add") + " " + props.t("Items")}
                breadcrumbItems={[
                  { title: props.t("Background Layers"), link: "/adminitems" },
                  { title: id ? "Edit" : "Add" },
                ]}
              />

              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                    >
                      {({
                        getRootProps: getRootMainProps,
                        getInputProps: getInputMainProps,
                      }) => {
                        const imageFile = selectedFiles[0];

                        return (
                          <div
                            className="dropzone-single-image avatar-xl"
                            {...getRootMainProps()}
                          >
                            <input
                              {...getInputMainProps()}
                              id="formrow-profile-image-Input"
                              multiple={false}
                            />
                            <img
                              className="rounded-circle avatar-xl"
                              alt={
                                !!imageFile && imageFile.name
                                  ? imageFile.name
                                  : "MintFactory"
                              }
                              src={!!imageFile ? imageFile.preview : avatar4}
                            />
                            <div className="edit">
                              <i className="bx bx-pencil"></i>
                            </div>
                          </div>
                        );
                      }}
                    </Dropzone>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Form onSubmit={onSubmit} className="spinner-content">
                        {error && typeof error === "string" ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}

                        <Row>
                          {thumb && (
                            <Col md={12}>
                              <Label for="formrow-profile-image-Input">
                                {props.t("Thumbnail")} {props.t("Image")}
                                <b>
                                  (File types supported: JPG, PNG size: 10 MB)
                                </b>
                              </Label>
                              <div className="dropzone-single-image avatar-xl">
                                <input
                                  type="file"
                                  onChange={(e) =>
                                    handleAcceptedThumbFiles(e.target.files)
                                  }
                                  required
                                />

                                {fields.thumbpreview && (
                                  <img
                                    className="rounded-circle custom_avtal_xl mt-2"
                                    src={
                                      fields.thumbpreview
                                        ? fields.thumbpreview
                                        : avatar4
                                    }
                                  />
                                )}
                              </div>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("Level")}</Label>

                              <InputGroup>
                                <Input
                                  type="number"
                                  onKeyDown={acceptOnlyNumberForLayer}
                                  onChange={handleChange("level")}
                                  min="1"
                                  value={fields.level}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>

                        {loading && (
                          <div className="spinner">
                            <Spinner color="info" />{" "}
                          </div>
                        )}
                        <div className="mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            {id ? "Update" : "Add"}
                          </button>

                          <button
                            type="button"
                            className="btn outline btn-outline-secondary w-md ml-3"
                            onClick={() => history.goBack()}
                          >
                            <i className="mdi mdi-arrow-left mr-1"></i>
                            {props.t("back")}
                          </button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm={1} lg={2}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddItem.propTypes = {
  t: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onAddItem: PropTypes.func,
  onUploadFile: PropTypes.func,
  onRemoveFile: PropTypes.func,
};

const mapStateToProps = ({ customers, items }) => ({
  error: customers.error,
  errorItem: items.error,
  singleImage: items.singleImage,
  loading: items.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onAddItem: (data, callback) => dispatch(addBackgroundImg(data, callback)),
  onPutItem: (data, callback) => dispatch(putBackgroundImg(data, callback)),
  onGetItem: (id) => dispatch(viewBackgroundImg(id)),
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback, true)),
  onRemoveFile: (data) => dispatch(removeFile(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddItem))
);
