import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip, img } from "reactstrap";
import moment from "moment-timezone";

const STATUS = {
  "active":"Active", 
  "inactive":"Inactive"
}

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = ( t) => [
  {
    dataField: "languageName",
    text: t("Name"),
    formatter: (_, row) => (row.languageName ? row.languageName : "-"),
  },
  {
    dataField: "languageNativeName",
    text: t("Native Name"),
    formatter: (_, row) => (row.languageNativeName ? row.languageNativeName : "-"),
  },
  {
    dataField: "localeCode",
    text: t("Locale Code"),
    formatter: (_, row) => (row.localeCode ? row.localeCode : "-"),
  },
  {
    dataField: "languageCode",
    text: t("Language Code"),
    formatter: (_, row) => (row.languageCode ? row.languageCode: "-"),
  },
  {
    dataField: "countryName",
    text: t("Country"),
    formatter: (_, row) => (row.countryName ? row.countryName: "-"),
  },
  {
    dataField: "flagUrl",
    text: t("Flag"),
    formatter: (_, row) => (row.flagUrl ? <img className= "img-fluid leaderboard-icon m-2" src={row.flagUrl} /> : "-"),
  },
  {
    dataField: "status",
    text: t("Status"),
    formatter: (cellContent, row) => (
      <Badge
        className={
          `text-capitalize font-size-13 badge-soft-${row.status}`
        }
        color={row.badgeClass}
      >
        {t(STATUS[row.status])}
      </Badge>
    ),
  },
  {
    dataField: "createdAt",
    text: t("Created Date"),
    sort: true,
    sortValue: (_, row) =>row?.createdAt,
    formatter: (_, row) => (row?.createdAt ? moment(row?.createdAt).format("YYYY-MM-DD HH:mm:ss") : "--"),
  },
];

export default ListColumns;
