import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import FormButton from "components/Common/FormButtons";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Select, { components } from "react-select";

import {
  addFdCategory,
  getFdCategory,
  putFdCategory,
  uploadFile,
  removeFile,
} from "store/actions";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Alert,
  Label,
  Spinner,
  CardSubtitle,
} from "reactstrap";

import { ROLES, CATEGORY_FORM } from "helpers/contants";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";

import SubCatForm from "./SubCatForm";
import SimpleForm from "./SimpleForm";

/**
 * Formats the size
 */
function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const AddCategory = ({
  accessLevel,
  role,
  vendorId,
  history,
  error,
  onAddFdCategory,
  onGetFdCategory,
  onPutFdCategory,
  onUploadFile,
  onRemoveFile,
  fdCategory,
  loading,
  activeStoreId,
  activeStoreType,
  uploadingFile,
  ...props
}) => {
  const { id, vendorId: _vendorIdParam } = useParams();
  const { vendor_name } = queryString.parse(props?.location?.search);
  const didMountRef = useRef(null);
  const location = useLocation();
  const [vendorIdParam, setvendorIdPara] = useState();
  const [genderType, setGenderType] = useState("None");
  const [genderTypeBoolean, setGenderTypeBoolean] = useState(false);
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
  });
  const [parentCategory, setParentCategory] = useState({
    label: "None",
    value: "",
  });
  const [allGender, setAllGender] = useState(
    {
      label: "Men",
      value: "Men",
    },
    {
      label: "Woman",
      value: "Woman",
    }
  );
  const [fields, setFields] = useState({
    storeTypeId: "",
    catName: "",
    catDesc: "",
    parent: "none",
    gender: genderType,
    catImage: null,
    isFeatured: false,
    sortOrder: 1,
    status: "active",
  });

  const [selectedFiles, setselectedFiles] = useState([]);

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
      };
      // if (id) {
      onGetFdCategory(id);
      // }
      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
    };
    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    if (id) {
      // if (!activeStoreId) return
      onGetFdCategory(id);
    }
  }, [id]);

  useEffect(() => {
    let vendor = "";

    switch (role) {
      case ROLES.admin:
      case ROLES.staff:
        vendor =
          activeStoreType?.storeVendorType === "SINGLE"
            ? activeStoreType?.singleVendorId
            : _vendorIdParam;
        break;

      case ROLES.vendor:
        vendor = vendorId;
        break;

      default:
        break;
    }

    if (vendor) {
      setFields((prevState) => ({
        ...prevState,
        vendor,
        storeTypeId: activeStoreId,
      }));
    }

    if (["SERVICEPROVIDER", "CARRENTAL"].includes(activeStoreType?.storeType)) {
      setFields({ ...fields, storeTypeId: activeStoreId });
    }

    setvendorIdPara(vendor);
  }, [activeStoreId, role, vendorId, _vendorIdParam]);

  useEffect(() => {
    console.log("fdCategory ========> ", fdCategory);
    // if (didMountRef.current) {
    // try {
    setFields((prevState) => ({
      ...prevState,
      _id: fdCategory._id,
      catName: fdCategory.catName || "",
      catDesc: fdCategory.catDesc || "",
      parent: fdCategory.parent || "",
      levelMin: fdCategory.levelMin || 0,
      levelMax: fdCategory.levelMax || 0,
      catImage: fdCategory.catImage || null,
      isFeatured: fdCategory.isFeatured || false,
      sortOrder: fdCategory.sortOrder || 1,
      maxSupply: fdCategory.maxSupply || 0,
      status: fdCategory.status || "active",
      gender: fdCategory.gender || "",
    }));

    if (fdCategory.catImage && fdCategory.catImage.link) {
      setselectedFiles([{ preview: fdCategory.catImage.link }]);
    }
    // } catch (err) {
    // }
    // } else didMountRef.current = true
  }, [JSON.stringify(fdCategory)]);

  const uploadFileSuccess = (response) => {
    const { _id: catImage } = response.data;

    setFields((prevState) => ({ ...prevState, catImage }));
  };

  function handleAcceptedFiles(_files) {
    if (id && !accesses.canEdit) return;

    const ext = _files[0]?.name?.split(".").pop();

    //checking extension
    if (!["png", "jpg", "jpeg"].includes(ext)) {
      toastr.error("Only .png,.jpg,.jpeg  extension allow!!");
      return;
    }

    const files = _files?.filter((file) => file.size < 5242880);

    if (files.length < _files.length) {
      return toastr.error(props.t("Max file size should be 1 MB"));
    }

    if (selectedFiles.length > 0) {
      if (fields.catImage) {
        onRemoveFile({ _id: fields.catImage });

        setFields((prevState) => ({ ...prevState, catImage: null }));
      }
    }

    setFields((prevState) => ({ ...prevState, catImage: files[0] }));
    onUploadFile({ image: files[0] }, uploadFileSuccess);

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setselectedFiles(files);
  }

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const handleParentChange = (data) => {
    setParentCategory(data);

    setFields((prevState) => ({ ...prevState, parent: data.value }));
  };
  const handleGenderChange = (data) => {
    console.log("data ===> ", data);
    setGenderType(data.value);
    setFields((prevState) => ({ ...prevState, gender: data.value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (loading || uploadingFile) return;
    // if (!activeStoreType?.storeType?.toLowerCase()) return

    if (id) {
      if (!accesses.canEdit) return;
      onPutFdCategory(
        activeStoreType?.storeType?.toLowerCase() || "",
        { _id: fdCategory._id, ...fields },
        history
      );
    } else {
      if (!accesses.canAdd) return;
      onAddFdCategory(
        activeStoreType?.storeType?.toLowerCase(),
        { ...fields },
        history
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={`${
              !!location.pathname.includes("/edit") ? "Edit" : "Add"
            } Category`}
            breadcrumbItem={props.t(
              `${
                !!location.pathname.includes("/edit") ? "Edit" : "Add"
              } Category`
            )}
            breadcrumbItems={[
              {
                title: props.t(
                  `${
                    !!location.pathname.includes("/edit") ? "Edit" : "Add"
                  } Category`
                ),
              },
            ]}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form onSubmit={onSubmit} className="spinner-content">
                    {/* {error && typeof error === "string" ? (
                      <Alert color="danger">{error}</Alert>
                    ) : null} */}

                    <SubCatForm
                      t={props.t}
                      id={id}
                      fields={fields}
                      catName={fdCategory?.catName}
                      handleChange={handleChange}
                      parentCategory={parentCategory}
                      handleParentChange={handleParentChange}
                      handleGenderChange={handleGenderChange}
                      handleAcceptedFiles={handleAcceptedFiles}
                      genderType={genderType}
                      selectedFiles={selectedFiles}
                      activeStoreId={activeStoreId}
                      activeStoreType={activeStoreType}
                    />

                    <FormButton
                      needSubmit={id ? accesses.canEdit : accesses.canAdd}
                      // submitDisabled={uploadingFile || loading}
                      goBack={() => history.goBack()}
                    />

                    {(loading || uploadingFile) && (
                      <div className="spinner">
                        <Spinner color="primary" />
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddCategory.propTypes = {
  role: PropTypes.string,
  vendorId: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  fdCategory: PropTypes.object,
  onAddFdCategory: PropTypes.func,
  onGetFdCategory: PropTypes.func,
  onPutFdCategory: PropTypes.func,
  onUploadFile: PropTypes.func,
  onRemoveFile: PropTypes.func,
};
const mapStateToProps = ({ File, Login, Categoryes }) => ({
  // role: Login?.user?.role || "",
  // vendorId: Login?.user?._id || "", // In Case Restaurant/Vendor based on role
  // vendor_name: Login?.user?.name,
  error: Categoryes.error,
  loading: Categoryes.loading,
  fdCategory: Categoryes.fdCategory,
  uploadingFile: File.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onAddFdCategory: (storeType, data, history) =>
    dispatch(addFdCategory(storeType, data, history)),
  onGetFdCategory: (id) => dispatch(getFdCategory(id)),
  onPutFdCategory: (storeType, data, history) =>
    dispatch(putFdCategory(storeType, data, history)),
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
  onRemoveFile: (data) => dispatch(removeFile(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCategory))
);
