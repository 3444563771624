export const TOURNAMENTS_API_FAIL = "COLLECTION_API_FAIL";

// TOURNAMENTS
export const GET_TOURNAMENTS = "GET_TOURNAMENTS";
export const GET_TOURNAMENTS_FAIL = "GET_TOURNAMENTS_FAIL";
export const GET_TOURNAMENTS_SUCCESS = "GET_TOURNAMENTS_SUCCESS";

export const ADD_TOURNAMENT = "ADD_TOURNAMENT";
export const ADD_TOURNAMENT_FAIL = "ADD_TOURNAMENT_FAIL";
export const ADD_TOURNAMENT_SUCCESS = "ADD_TOURNAMENT_SUCCESS";

export const REMOVE_TOURNAMENT = "REMOVE_TOURNAMENT";
export const REMOVE_TOURNAMENT_FAIL = "REMOVE_TOURNAMENT_FAIL";
export const REMOVE_TOURNAMENT_SUCCESS = "REMOVE_TOURNAMENT_SUCCESS";

export const VIEW_TOURNAMENT = "VIEW_TOURNAMENT";
export const VIEW_TOURNAMENT_FAIL = "VIEW_TOURNAMENT_FAIL";
export const VIEW_TOURNAMENT_SUCCESS = "VIEW_TOURNAMENT_SUCCESS";

export const UPDATE_TOURNAMENT = "UPDATE_TOURNAMENT";
export const UPDATE_TOURNAMENT_FAIL = "UPDATE_TOURNAMENT_FAIL";
export const UPDATE_TOURNAMENT_SUCCESS = "UPDATE_TOURNAMENT_SUCCESS";
