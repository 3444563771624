import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { connect, useSelector } from "react-redux";

//i18n
import { withTranslation } from "react-i18next";
import { getItems, getPromoCodeUsers } from "store/actions";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";

const ConfirmModal = (props) => {
  const { isOpen, toggle, items, Settings } = props;

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      autoFocus={true}
      centered={true}
      toggle={toggle}
      scrollable={"true"}
    >
      <div className="modal-content remove-confirm position-relative">
        <div className="d-flex justify-content-end">
          <a href="javascript:void(0)" onClick={toggle} className="closebtn">
            <svg width="32" height="32" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6L6.4 19Z"
              />
            </svg>
          </a>
        </div>
        <ModalBody>
          <Table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Contract Address</th>
                <th>Token Id</th>
                <th>Level</th>
                <th>price</th>
              </tr>
            </thead>
            <tbody>
              {items &&
                items?.map((list, idx) => {
                  return (
                    <tr>
                      <td>{idx + 1}</td>
                      <td>{list.name}</td>
                      <td>
                        <a
                          href={
                            Settings?.blockchainNetworkMode == "mainnet"
                              ? `https://bscscan.com/address/${list.collection_id.contractAddress}`
                              : `https://testnet.bscscan.com/address/${list.collection_id.contractAddress}`
                          }
                          target={"_blank"}
                        >
                          {list.collection_id.contractAddress}
                        </a>
                      </td>
                      <td>
                        <a
                          href={
                            Settings?.blockchainNetworkMode == "mainnet"
                              ? `https://bscscan.com/token/${list.collection_id.contractAddress}?a=${list.token_id}`
                              : `https://testnet.bscscan.com/token/${list.collection_id.contractAddress}?a=${list.token_id}`
                          }
                          target={"_blank"}
                        >
                          {list.token_id}
                        </a>
                      </td>
                      <td>{list.level}</td>
                      <td>{list.price}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </ModalBody>
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};
const mapStateToProps = ({ promoCodes, items }) => ({
  promoCodeUsers: promoCodes.promoCodeUsers,
  items: items.items,
});

const mapDispatchToProps = (dispatch) => ({
  onGetPromoCodeUsers: (data, callback) =>
    dispatch(getPromoCodeUsers(data, callback)),
  onGetItems: (data) => dispatch(getItems(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConfirmModal))
);
// export default withTranslation()(ConfirmModal);
